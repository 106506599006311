import {
    FETCH_AGENTS_PENDING,
    FETCH_AGENTS_SUCCESS,
    FETCH_AGENTS_ERROR,
    FLUSH_AGENTS,
    FLUSH_UPDATE,
    FLUSH_CREATE,
    UPDATE_AGENT,
    CREATE_AGENT,
    STORE_CURRENT_AGENT,
    FLUSH_FETCH_AGENTS,
    DELETE_AGENT
} from '../../actions/agents';

import { toast } from "react-toastify";

const initialState = {
    pending: false,
    agents: [],
    error: null,
    updateAgentStatus: "",
    create: null,
    currentAgent: {},
    fetchAgentsStatus: null,
}

const agentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FLUSH_CREATE:
            return {
                ...state,
                create: null
            }
        case CREATE_AGENT:
            return {
                ...state,
                create: action
            }
        case DELETE_AGENT:
            return {
                ...state,
                agents: action.status === "success" ? state.agents.filter(agent => agent.id !== action.agentId) : state.agents
            }
        case FLUSH_UPDATE:
            return {
                ...state,
                updateAgentStatus: action.status
            }
        case UPDATE_AGENT:
            action.status === "error" && toast.error(action.error);
            return {
                ...state,
                updateAgentStatus: action.status
            }
        case FETCH_AGENTS_PENDING:
            return {
                ...state,
                pending: true,
                fetchAgentsStatus: "pending",
            }
        case FETCH_AGENTS_SUCCESS:
            return {
                ...state,
                fetchAgentsStatus: "success",
                pending: false,
                agents: action.agents,
            }
        case FLUSH_FETCH_AGENTS:
            return {
                ...state,
                fetchAgentsStatus: null,
            }
        case FETCH_AGENTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case FLUSH_AGENTS:
            return {
                ...state,
                agents: []
            }
        case STORE_CURRENT_AGENT:
            return {
                ...state,
                currentAgent: action.currentAgent
            }
        default:
            return state;
    }
}
export default agentsReducer;