import React from 'react';
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EXPORT_URL } from '../../../configs/constants';



export const FETCH_COMPANY_EXPORTS_TEMPLATE = 'FETCH_COMPANY_EXPORTS_TEMPLATE';
export const FETCH_COMPANY_EXPORTS_LOGS = 'FETCH_COMPANY_EXPORTS_LOGS';
export const EXPORT_COMPANY_DATA = 'EXPORT_COMPANY_DATA';
export const FLUSH_EXPORT_STATUS = 'FLUSH_EXPORT_STATUS';
export const CREATE_EXPORT_CONFIGURATION = 'CREATE_EXPORT_CONFIGURATION';
export const UPDATE_EXPORT_CONFIGURATION = 'UPDATE_EXPORT_CONFIGURATION';
export const FLUSH_CREATE_EXPORT_CONFIGURATION = 'FLUSH_CREATE_EXPORT_CONFIGURATION';
export const DELETE_EXPORT_CONFIGURATION = 'DELETE_EXPORT_CONFIGURATION';


export const exportCompanyData = (data, target, modelId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: EXPORT_COMPANY_DATA, status: "pending" });
        return axios({
            method: 'post',
            // url: `https://am-export.eu.ngrok.io/export/${target}/${modelId}`,
            url: `${EXPORT_URL}/export/${target}/${modelId}`,
            data
        }).then(response => {
            dispatch({
                type: EXPORT_COMPANY_DATA,
                status: "success",
                settings: response,
                successToast: {
                    type: "EXPORT",
                    message: `Data successfully requested. ${response.data.message} ${target === "history" ? "Updated list of logs will be available shortly" : ""}`
                }
            });

        }).catch(err => {
            dispatch({ type: EXPORT_COMPANY_DATA, status: "error", error: err.message });
            toast.error(`Unable to request export: ${err}`)
        })
    }
}

export function fetchExportConfigurations(companyId) {
    return (dispatch, getState, { rcsdk, rcRPA, rcAlgolia }) => {
        dispatch({ type: FETCH_COMPANY_EXPORTS_TEMPLATE, status: "pending" })
        return (
            rcsdk
                .getExportConfigurations()
                .where({ companyId })
                .then(response => {
                    dispatch(
                        {
                            type: FETCH_COMPANY_EXPORTS_TEMPLATE,
                            config: response,
                            status: "success",
                        }
                    )
                })
                .catch(err => dispatch({ type: FETCH_COMPANY_EXPORTS_TEMPLATE, status: "error", error: err }))
        )
    }
};

export function fetchExportLog(id, companyId, refresh = false) {
    return (dispatch, getState, { rcsdk, rcRPA, rcAlgolia }) => {
        // dispatch({ type: FETCH_COMPANY_EXPORTS_LOGS, status: "pending" })
        let where = {
            companyId
        }
        if (id) where = { ...where, exportConfigurationId: id }
        return rcsdk
            .getExportLogs()
            .where(where)
            .then(response => {
                // dispatch(
                //     {
                //         type: FETCH_COMPANY_EXPORTS_LOGS,
                //         config: response,
                //         status: "success",
                //     }
                // )
                refresh && toast.success(`list of logs has been refreshed`)
                return response
            })
            .catch(err => {
                console.log(err)
                // dispatch({ type: FETCH_COMPANY_EXPORTS_LOGS, status: "error", error: err })
            })

    }
};


export function createExportConfiguration(data) {
    return (dispatch, getState, { rcsdk, rcRPA, rcAlgolia }) => {
        dispatch({ type: CREATE_EXPORT_CONFIGURATION, status: "pending" })
        return rcsdk
            .createExportConfiguration(data)
            .then(response => {
                dispatch(
                    {
                        type: CREATE_EXPORT_CONFIGURATION,
                        exportConfiguration: response,
                        status: "success",
                    }
                )
                toast.success(`configuration successfully saved`)
                return response
            })
            .catch(err => {
                console.log(err)
                dispatch({ type: CREATE_EXPORT_CONFIGURATION, status: "error", error: err })
            })

    }
};

export function updateExportConfiguration(id, data) {
    return (dispatch, getState, { rcsdk, rcRPA, rcAlgolia }) => {
        dispatch({ type: UPDATE_EXPORT_CONFIGURATION, status: "pending" })
        return rcsdk
            .updateExportConfiguration(id, data)
            .then(response => {
                dispatch(
                    {
                        type: UPDATE_EXPORT_CONFIGURATION,
                        exportConfiguration: response,
                        status: "success",
                    }
                )
                toast.success(`export configuration successfully updated`)
                return response
            })
            .catch(err => {
                console.log(err)
                toast.error(`unable to update export configuration : ${err}`)
                dispatch({ type: UPDATE_EXPORT_CONFIGURATION, status: "error", error: err })
            })
    }
};

export function deleteExportConfiguration(id) {
    return (dispatch, getState, { rcsdk, rcRPA, rcAlgolia }) => {
        dispatch({ type: DELETE_EXPORT_CONFIGURATION, status: "pending" })
        return rcsdk
            .deleteExportConfiguration(id)
            .then(response => {
                dispatch(
                    {
                        type: DELETE_EXPORT_CONFIGURATION,
                        id: id,
                        status: "success",
                    }
                )
                toast.success(<div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={["fas", "trash"]} className="mr-50" />
                    export configuration successfully deleted
                </div>)
                return response
            })
            .catch(err => {
                toast.error(`unable to delete export configuration : ${err}`)
                dispatch({ type: DELETE_EXPORT_CONFIGURATION, status: "error", error: err })
            })
    }
};
