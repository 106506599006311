
const ITEM_PER_PAGE = 20;

export const TOGGLE_CONTACTS_DRAWER = "TOGGLE_CONTACTS_DRAWER";
export const SET_CONTACTS_DRAWER_CURRENT_CONTACT = "SET_CONTACTS_DRAWER_CURRENT_CONTACT";
export const SET_CONTACTS_DRAWER_IS_EDITING = "SET_CONTACTS_DRAWER_IS_EDITING";

export const FETCH_CONTACTS_DRAWER_CONVERSATIONS_PENDING = 'FETCH_CONTACTS_DRAWER_CONVERSATIONS_PENDING';
export const FETCH_CONTACTS_DRAWER_CONVERSATIONS_SUCCESS = 'FETCH_CONTACTS_DRAWER_CONVERSATIONS_SUCCESS';
export const FETCH_CONTACTS_DRAWER_CONVERSATIONS_ERROR = 'FETCH_CONTACTS_DRAWER_CONVERSATIONS_ERROR';
export const FLUSH_CONTACTS_DRAWER_CONVERSATIONS = 'FLUSH_CONTACTS_DRAWER_CONVERSATIONS';

function fetchConversationsPending() {
    return {
        type: FETCH_CONTACTS_DRAWER_CONVERSATIONS_PENDING
    }
}

function fetchConversationsSuccess(conversations, total) {
    return {
        type: FETCH_CONTACTS_DRAWER_CONVERSATIONS_SUCCESS,
        conversations: conversations,
        total: total
    }
}

function fetchConversationsError(error) {
    return {
        type: FETCH_CONTACTS_DRAWER_CONVERSATIONS_ERROR,
        error: error
    }
}
export function flushConversations() {
    return {
        type: FLUSH_CONTACTS_DRAWER_CONVERSATIONS
    }
}

export const fetchConversations = ({
    startIndex = 0,
    stopIndex = ITEM_PER_PAGE,
    channelType = "all",
    flush = false,
    searchTerm = "",
    order = "default",
    filters = null
}) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        console.log('ici')
        if(flush) dispatch(flushConversations());
        dispatch(fetchConversationsPending());


        rcAlgolia.search({
            model: "conversations",
            searchTerm: searchTerm,
            startIndex: startIndex,
            stopIndex: stopIndex,
            filters: filters,
            facetFilters: [
                channelType !== "all" ? `channel:${channelType}` : ""
            ],
            order: order
        }).then(props => {
            console.log('la')
            dispatch(fetchConversationsSuccess(props.hits, props.nbHits));
        }).catch(err => {
            dispatch(fetchConversationsError(err.message));
        });
    }
}


export const setIsEditing = (isEditing = false) => {
    return {
        type: SET_CONTACTS_DRAWER_IS_EDITING,
        isEditing: isEditing
    }
}
export const setContactId = (contactId = null) => {
    return {
        type: SET_CONTACTS_DRAWER_CURRENT_CONTACT,
        contactId: contactId
    }
}

export const toggleDrawer = (show = null) => {
    return {
        type: TOGGLE_CONTACTS_DRAWER,
        show: show
    }
}
