import Lock from '../../../authServices/auth0/Lock';
import {AUTH_CONFIG} from '../../../authServices/auth0/auth0-variables';
import {updateMember} from "../members"

const jwtDecode = require('jwt-decode')

export const showLock = () => {
  // display lock widget
  return dispatch => {
    Lock.show();
  }
}

// Listen to authenticated event and get the Auth0 profile of the user
export const doAuthentication = () => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
      Lock.on("authenticated", function(authResult) {
            Lock.getUserInfo(authResult.accessToken, function(error, profile) {
              if (error) {
                // handle error
                return dispatch({ type: "LOGIN_ERROR", error })
              }

              if (!profile.email.includes("reecall")) {
                // handle not from reecall
                return dispatch(logout(false))
              }

              localStorage.setItem('profile', JSON.stringify(profile))
              localStorage.setItem('id_token', authResult.idToken)
              localStorage.setItem('access_token', authResult.accessToken);

              rcsdk.setToken(authResult.idToken);
              if(rcsdk.Stats){
                rcsdk.Stats.setToken(authResult.idToken);
              }
  

              return dispatch({ type: "LOGIN_SUCCESS", profile })
            });
      });
    }
}

export const logout = (toIdleScreen) => {
  return (dispatch, getState, {rcsdk, rcAlgolia})  => {
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('profile');

    if(getState().user?.rcuser){
      dispatch(updateMember(getState().user.rcuser.rcId, {connected: false}, false));
    }

    let logoutConfig = {
        clientId: AUTH_CONFIG.clientId,
    }
    if(!toIdleScreen){
      logoutConfig = {...logoutConfig, returnTo: escape(window.location.pathname) !== "/logout" ? window.location.origin + '/login?goto=' + escape(window.location.pathname) : window.location.origin + '/login'}
    } else {
      logoutConfig = {...logoutConfig, returnTo: window.location.origin + '/idle-screen?goto=' + escape(window.location.pathname)}
    }

    Lock.logout(logoutConfig);

    return dispatch({ type: "LOGOUT_SUCCESS" });
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}

export function getTokenExpiry() {
  let jwt = localStorage.getItem('id_token')
  if(jwt) {
    let jwtExp = jwtDecode(jwt).exp;
    let expiryDate = new Date(0);
    expiryDate.setUTCSeconds(jwtExp);
    return expiryDate;
  }
  else return null;  
}