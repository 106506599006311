import mock from "../mock"
// import avatarImg from "../../assets/img/portrait/small/avatar-s-20.jpg"
// import avatarImg2 from "../../assets/img/portrait/small/avatar-s-19.jpg"
// import avatarImg3 from "../../assets/img/portrait/small/avatar-s-18.jpg"
import avatarRaph from "../../assets/img/portrait/small/raphael.jpg"
import avatarMathieu from "../../assets/img/portrait/small/mathieub.jpg"
// import avatarCharles from "../../assets/img/portrait/small/charles.jpg"
// import avatarMaxime from "../../assets/img/portrait/small/maxime.jpg"

// import channelsDatas from "./channels";

let membersDatas = [
	{
		id: "5cffbe3206a65f000756d19d",
		firstName: "Raphaël",
		lastName: "Szymocha",
		avatar: avatarRaph,
    mail: "raphael@reecall.co",
    phone: "0123456789",
    role: "owner",
    channels: [
    ],
    online: false
	},

  {
    id: 2,
    firstName: "Mathieu",
    lastName: "Buonomo",
    avatar: avatarMathieu,
    mail: "mathieu@reecall.co",
    phone: "0123456789",
    role: "user",
    channels: [
    ],
    online: true
  },

  {
    id: 3,
    firstName: "Mathieu",
    lastName: "Gréard",
    mail: "mathieug@reecall.co",
    phone: "0683060863",
    role: "user",
    channels: [
    ],
    online: true
  },

  {
    id: 4,
    firstName: "Charles",
    lastName: "Cousyn",
    mail: "charles@reecall.co",
    phone: "9876543210",
    role: "manager",
    channels: [],
    online: false
  }
]

export default membersDatas;


// GET MEMBERS
mock.onGet("/api/members").reply(request => {
    return [200, membersDatas];
});

// GET MEMBER BY ID
mock.onGet("/api/member").reply(request => {
  let memberId = request.params.memberId;
  return [200, membersDatas.find(member => member.id === memberId)];
});

//UPDATE MEMBER
mock.onPost("/api/members/update").reply(request => {
  let member = JSON.parse(request.data).member;
  membersDatas = membersDatas.map(item => item.id === member.id ? member : item);

  return [201, { member: member }]
})

//UPDATE MEMBER
mock.onPost("/api/members/updateChannels").reply(request => {
  let channels = JSON.parse(request.data).channels;
  let memberId = JSON.parse(request.data).memberId;

  membersDatas = membersDatas.map(el => el.id === memberId ? {...el, channels: channels} : el);

  return [201, { member: membersDatas.find(el => el.id === memberId) }]
})


let membersPermissions = [
  {
    "type": "owner",
    "permissions": {
      "ADMIN": true,
      "Ticket": {
        "read": false,
        "readOwn": false,
        "create": false,
        "update": false,
        "assignSelf": false,
        "unassign": false,
        "assignAny": false,
        "archive": false,
      },
      "Conversation": {
        "read": false
      },
      "Contact": {
        "read": false,
        "create": false,
        "update": false
      },
      "Deck": {
        "read": false,
        "create": false,
        "update": false,
        "updateContext": false,
      },
      "Todo": {
        "read": false,
        "create": false,
        "createTemplate": false,
        "updateTodo": false
      },
      "Member": {
        "read": false,
        "create": false,
        "delete": false,
      },
      "Channel": {
        "read": false,
        "readOwn": false,
        "create": false,
        "update": false,
        "delete": false
      },
      "Agent": {
        "read": false,
        "create": false,
        "update": false
      },
      "ChannelConnection": {
        "readOwn": false,
        "read": false,
        "create": false,
        "updateStatus": false,
        "delete": false
      },
      "Reporting": {
        "read": false
      }
    }
  },
  {
    "type": "manager",
    "permissions": {
      "ADMIN": false,
      "Ticket": {
        "read": false,
        "readOwn": true,
        "create": true,
        "update": true,
        "assignSelf": true,
        "unassign": true,
        "assignAny": true,
        "archive": true,
      },
      "Conversation": {
        "read": false
      },
      "Contact": {
        "read": true,
        "create": true,
        "update": true
      },
      "Deck": {
        "read": true,
        "create": false,
        "update": false,
        "updateContext": false,
      },
      "Todo": {
        "read": false,
        "create": false,
        "createTemplate": false,
        "updateTodo": false
      },
      "Member": {
        "read": true,
        "create": false,
        "delete": false,
      },
      "Channel": {
        "read": true,
        "readOwn": false,
        "create": false,
        "update": false,
        "delete": false
      },
      "Agent": {
        "read": false,
        "create": false,
        "update": false
      },
      "ChannelConnection": {
        "readOwn": true,
        "read": false,
        "create": true,
        "updateStatus": false,
        "delete": true
      },
      "Reporting": {
        "read": false
      }
    }
  },
  {
    "type": "user",
    "permissions": {
      "ADMIN": false,
      "Ticket": {
        "read": false,
        "readOwn": true,
        "create": false,
        "update": false,
        "assignSelf": true,
        "unassign": false,
        "assignAny": false,
        "archive": false,
      },
      "Conversation": {
        "read": false
      },
      "Contact": {
        "read": true,
        "create": false,
        "update": false
      },
      "Deck": {
        "read": true,
        "create": false,
        "update": false,
        "updateContext": false,
      },
      "Todo": {
        "read": false,
        "create": false,
        "createTemplate": false,
        "updateTodo": false
      },
      "Member": {
        "read": false,
        "create": false,
        "delete": false,
      },
      "Channel": {
        "read": false,
        "readOwn": true,
        "create": false,
        "update": false,
        "delete": false
      },
      "Agent": {
        "read": false,
        "create": false,
        "update": false
      },
      "ChannelConnection": {
        "readOwn": true,
        "read": false,
        "create": false,
        "updateStatus": true,
        "delete": false
      },
      "Reporting": {
        "read": false
      }
    }
  }
]

export {membersPermissions}