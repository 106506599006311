import * as Twilio from 'twilio-client';
// import {setPhoneStatus} from "../user"
import {executeQuickAction} from "../integrations"

export const START_OUTGOING_CALL = 'START_OUTGOING_CALL';
export const END_CALL = 'END_CALL';

export const SET_PHONE_NUMBER_TO = 'SET_PHONE_NUMBER_TO';
export const SET_PHONE_NUMBER_FROM = 'SET_PHONE_NUMBER_FROM';
export const SET_CALL_DIRECTION = 'SET_CALL_DIRECTION';
export const SET_START_TIME = 'SET_START_TIME';

export const TOGGLE_SOFT_PHONE = 'TOGGLE_SOFT_PHONE';
export const TOGGLE_MUTE = 'TOGGLE_MUTE';

export const SET_TWILIO_CONN = 'SET_TWILIO_CONN';
export const SET_TWILIO_CONN_STATUS = 'SET_TWILIO_CONN_STATUS';

export const CALL_ERROR = 'CALL_ERROR';



export function startOutgoingCall(contact = null, phoneNumber = null) {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        let connection = Twilio.Device.connect({
            To: phoneNumber ? `+${phoneNumber}` : `phone_${contact.id}`
        });

        dispatch(initCall(connection, "outgoing"));
    }
}

export function endCall() {
    return {
        type: END_CALL
    }
}

export function setError(message){
    return {
        type: CALL_ERROR,
        message: message
    }
}

export function setPhoneNumberTo(number) {
    return {
        type: SET_PHONE_NUMBER_TO,
        number: number
    }
}

export function setPhoneNumberFrom(number) {
    return {
        type: SET_PHONE_NUMBER_FROM,
        number: number
    }
}

export function toggleSoftPhone(open) {
    return {
        type: TOGGLE_SOFT_PHONE,
        open: open
    }
}

export function toggleMute() {
    return {
        type: TOGGLE_MUTE
    }
}

export function setTwilioConn(conn) {
    return {
        type: SET_TWILIO_CONN,
        conn: conn
    }
}

export function setTwilioConnStatus(status) {
    return {
        type: SET_TWILIO_CONN_STATUS,
        status: status
    }
}

export function setCallDirection(dir){
    return {
        type: SET_CALL_DIRECTION,
        dir: dir
    }
}

export function setStartTime(time){
    return {
        type: SET_START_TIME,
        time: time
    }
}

export function initCall(conn, dir){
    return dispatch => {
        dispatch(setTwilioConn(conn));
        dispatch(setCallDirection(dir));
        dispatch(setTwilioConnStatus("ringing"));
        
        conn.on("ringing", function() {
            dispatch(setTwilioConnStatus("ringing"));
        })

        conn.on("accept", function(conn) {
            dispatch(setTwilioConnStatus(conn.status()));
            dispatch(setStartTime(+ new Date()));
        });
        
        conn.on("reject",  function(){
            dispatch(setTwilioConn(null));
            dispatch(setTwilioConnStatus(null));
            dispatch(toggleSoftPhone(false));
        });

        conn.on('disconnect', function() {
            dispatch(setTwilioConn(null));
            dispatch(setTwilioConnStatus(null));
            dispatch(toggleSoftPhone(false));
        });

        if(dir === "incoming"){
            dispatch(setPhoneNumberFrom(conn.parameters.From));
            dispatch(setPhoneNumberTo(""));
        } else {
            dispatch(setPhoneNumberFrom(null));
        }
        dispatch(toggleSoftPhone(true));
    }
}

export function hangOutCall(conn){
    return (dispatch, getState, {rcsdk, rcAlgolia}) => { 
        
        dispatch(executeQuickAction({
            quickAction: {
                "actionType": "rpa",
                "key":"releaseUserPhone",
                "integrationKey":"reecall",
                "datas":{
                    "memberId": getState().user.rcuser.rcId,
                    "companyId": getState().company.company.id
                }
            },
            context: {}
        }));

        dispatch(toggleSoftPhone(false));
        dispatch(setTwilioConn(null));
        dispatch(setCallDirection(null));
        dispatch(setTwilioConnStatus(null));
        dispatch(setPhoneNumberFrom(""));
        dispatch(setPhoneNumberTo(""));
        dispatch(setStartTime(null));
    }
}

export function disconnectCall(conn){
    return (dispatch, getState, {rcsdk, rcAlgolia}) => { 
        conn.disconnect();
        dispatch(hangOutCall(conn));
    }
}

export function rejectCall(conn){
    return (dispatch, getState, {rcsdk, rcAlgolia}) => { 
        conn.reject();
    }
}

export function acceptCall(conn){
    return (dispatch, getState, {rcsdk, rcAlgolia}) => { 
        conn.accept();
        dispatch(setTwilioConnStatus("open"));
        dispatch(setStartTime(+ new Date()));
    }
}