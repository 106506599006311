import { updateMember } from "../members";

export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const SET_OPENED_MENU = 'SET_OPENED_MENU';
export const TOGGLE_CHANNEL_MODAL = 'TOGGLE_CHANNEL_MODAL';
export const TOGGLE_TAGS_TRAIN_MODAL = 'TOGGLE_TAGS_TRAIN_MODAL';
export const TOGGLE_CONTACTS_MODAL = 'TOGGLE_CONTACTS_MODAL';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const STORE_USER_MENU_CONFIG = 'STORE_USER_MENU_CONFIG';
export const MEMBER_IS_UPDATING = 'MEMBER_IS_UPDATING';

export const setUserMenuConfig = (config, noToast = false) => {
    return (dispatch, getState) => {
        const user = getState().user?.rcuser
        dispatch({ type: MEMBER_IS_UPDATING, status: "pending" })
        if (user) {
            return dispatch(
                updateMember(
                    user.rcId,
                    { ...user, settings: { ...user.settings, boMenuCustomConfig: config } },
                    !noToast
                )).then(res => {
                    dispatch({ type: MEMBER_IS_UPDATING, status: "success", user: res.member })
                }).catch(err => dispatch({ type: MEMBER_IS_UPDATING, status: "error", error: err }));
        }
        return false;
    }
}