import { toast } from "react-toastify";

export const FETCH_SNIPPETS = "FETCH_SNIPPETS";
export const CREATE_SNIPPET = "CREATE_SNIPPET";
export const UPDATE_SNIPPET = "UPDATE_SNIPPET";
export const DELETE_SNIPPET = "DELETE_SNIPPET";
export const FLUSH_SNIPPETS_STATUS = "FLUSH_SNIPPETS_STATUS";

export const fetchSnippets = (type, search) => {
    
    let where = { "type": { "inq": type }}
    if (search) where = {
        "or": [
            { 'keywords': search },
            { "name": { "like": `.*${search}.*`, "options": "i" } }
        ],
        type
    }

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_SNIPPETS, status: "pending" });
        return rcsdk
            .getTemplates()
            .where(where)
            .then(snippets => {
                dispatch({ type: FETCH_SNIPPETS, snippets: snippets, status: "success" });
            })
            .catch(err => {
                dispatch({ type: FETCH_SNIPPETS, error: err, status: "error" });
            })
    }
}

export const createSnippet = (data) => {

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: CREATE_SNIPPET, status: "pending" });
        return rcsdk
            .createTemplate({ ...data, companyId: "*" })
            .then(snippet => {
                dispatch({ type: CREATE_SNIPPET, snippet: snippet, status: "success" });
                toast.success("Snippet successfully created");
            })
            .catch(err => {
                console.log(err)
                dispatch({ type: CREATE_SNIPPET, error: err, status: "error" });
                toast.error("unable to create snippet. Please try again");
            })
    }
}

export const updateSnippet = (id, data) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: UPDATE_SNIPPET, status: "pending" });
        return rcsdk
            .updateTemplate(id, data)
            .then(snippet => {
                dispatch({ type: UPDATE_SNIPPET, snippet: snippet, status: "success" });
                toast.success("Snippet successfully udpated");
            })
            .catch(err => {
                console.log(err)
                dispatch({ type: UPDATE_SNIPPET, error: err, status: "error" });
                toast.error("unable to update snippet. Please try again");
            })
    }
}

export const deleteSnippet = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: DELETE_SNIPPET, status: "pending" });
        return rcsdk
            .deleteTemplate(id)
            .then(() => {
                dispatch({ type: DELETE_SNIPPET, snippetId: id, status: "success" });
                toast.success("Snippet successfully deleted");
            })
            .catch(err => {
                console.log(err)
                dispatch({ type: DELETE_SNIPPET, error: err, status: "error" });
                toast.error("unable to delete snippet. Please try again");
            })
    }
}

