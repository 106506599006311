export const FETCH_COMPANIES_COMPANY = 'FETCH_COMPANIES_COMPANY';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_FILTER = 'FETCH_COMPANIES_FILTER';
export const FLUSH_COMPANIES = 'FLUSH_COMPANIES';
export const FLUSH_ALL_COMPANIES_STATUS = 'FLUSH_ALL_COMPANIES_STATUS';
export const FETCH_ALL_COMPANIES = 'FETCH_ALL_COMPANIES';
export const FLUSH_STATUS = 'FLUSH_STATUS';

export function fetchCompanies(searchTerm, startIndex, stopIndex, order, flush, selectedFilter, include) {

    const where = () => {
        switch (searchTerm.field) {
            case "":
                if (selectedFilter && selectedFilter.value !== "all") return {
                    'plan.status': {
                        inq: [selectedFilter.value]
                    }
                }
                else return {};
            case "name":
                if (selectedFilter && selectedFilter.value !== "all") return {
                    "and": [
                        {
                            'plan.status': {
                                inq: [selectedFilter.value]
                            }
                        },
                        { "name": { "like": `.*${searchTerm.search}.*`, "options": "i" } }
                    ]
                }
                else return { "name": { "like": `.*${searchTerm.search}.*`, "options": "i" } }
            // case "id":
            //     if (selectedFilter && selectedFilter.value !== "all") return {
            //         "and": [
            //             {
            //                 'plan.status': {
            //                     inq: [selectedFilter.value]
            //                 }
            //             },
            //             { "id": { "inq": searchTerm.search } }
            //         ]
            //     }
            //     else return { "id": { "inq": searchTerm.search } }
            default:
                break;
        }
    }

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_COMPANIES, status: "pending" })
        return (
            rcsdk
                .getCompanies()
                .include(include)
                .where(where())
                .skip(startIndex)
                .limit(stopIndex - startIndex)
                .order(order)
                .raw()
                .then(response => {
                    const total = response.headers["content-range"].substr(response.headers["content-range"].indexOf("/") + 1); // get data after /
                    dispatch(
                        {
                            type: FETCH_COMPANIES,
                            companies: response.data,
                            status: "success",
                            total: parseInt(total),
                            searchTerm: searchTerm.search,
                            flush: flush
                        }
                    )
                })
                .catch(err => dispatch({ type: FETCH_COMPANIES, status: "error", error: err }))
        )
    }
};

export function fetchCompaniesFilter(idsArray, flush) {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_COMPANIES_FILTER, status: "pending" })
        return (
            rcsdk
                .getCompanies()
                .where({ id: { inq: idsArray } })
                .then(response => {
                    dispatch(
                        {
                            type: FETCH_COMPANIES_FILTER,
                            companies: response,
                            status: "success",
                            ids: idsArray,
                            flush: flush
                        }
                    )
                })
                .catch(err => dispatch({ type: FETCH_COMPANIES_FILTER, status: "error", error: err }))
        )
    }
};

export function fetchCompany(companyId) {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_COMPANIES_COMPANY, status: "pending" })
        return (
            rcsdk
                .getCompany(companyId)
                .then(response => {
                    dispatch(
                        {
                            type: FETCH_COMPANIES_COMPANY,
                            company: response,
                            total: 1,
                            status: "success",
                        }
                    )
                })
                .catch(err => dispatch({ type: FETCH_COMPANIES_COMPANY, status: "error", error: err }))
        )
    }
};

export const flushCompanies = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FLUSH_COMPANIES })
    }
}

export const fetchAllCompanies = (keys = []) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_ALL_COMPANIES, status: "pending" })
        return (
            rcsdk
                .getCompanies()
                .fields(keys)
                .then(response => {
                    const total = response.length;
                    dispatch(
                        {
                            type: FETCH_ALL_COMPANIES,
                            companies: response,
                            status: "success",
                            total: total,
                            flush: true,
                        }
                    )
                })
                .catch(err => dispatch({ type: FETCH_ALL_COMPANIES, status: "error", error: err }))
        )
    }
};


