import {
    FETCH_RULES_PENDING,
    FETCH_RULES_SUCCESS,
    FETCH_RULES_ERROR,
    CREATE_RULE_PENDING,
    CREATE_RULE_SUCCESS,
    CREATE_RULE_ERROR,
    // FETCH_QUICK_ACTIONS_PENDING,
    FETCH_QUICK_ACTIONS_SUCCESS,
    FETCH_QUICK_ACTIONS_ERROR,
    FETCH_RULE_STEPS_CONDITIONS_PENDING,
    FETCH_RULE_STEPS_CONDITIONS_SUCCESS,
    FETCH_RULE_STEPS_CONDITIONS_ERROR,
    DELETE_RULE_PENDING,
    DELETE_RULE_SUCCESS,
    FLUSH_DELETE,
    UPDATE_RULE_SUCCESS,
    UPDATE_RULE_PENDING,
    UPDATE_RULE_ERROR,
    FLUSH_RULE,
    FLUSH_CREATE,
    FLUSH_UPDATE,
    FETCH_SOURCES_FILTERS_PENDING,
    FETCH_SOURCES_FILTERS_SUCCESS,
    FETCH_SOURCES_FILTERS_ERROR,
    FLUSH_FETCH_RULES,
    SET_EXPERT_MODE,
    FLUSH_RULES
} from '../../actions/rules';

import { toast } from "react-toastify";

const initialState = {
    pending: false,
    rules: [],
    quickActions: [],
    error: null,
    create: null,
    creationStatus: "",
    creationError: false,
    steps: [],
    deleteStatus: "",
    updateStatus: "",
    createdRuleId: null,
    createdRule: null,
    stepsCondsPending: false,
    sourcesAndFilters: null,
    duplicationStatus: "",
    fetchRulesStatus: null,
    expertMode: false,
}

const rulesReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_EXPERT_MODE:
            return {
                ...state,
                expertMode: action.status
            }
        case FETCH_RULES_ERROR:
            toast.error(action.error);
            return {
                ...state,
                error: action.error
            }
        case FETCH_RULES_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_RULES_SUCCESS:
            return {
                ...state,
                pending: false,
                fetchRulesStatus: "success",
                rules: action.rules,
            }
        case FLUSH_FETCH_RULES:
            return {
                ...state,
                fetchRulesStatus: null,
            }
        case CREATE_RULE_ERROR:
            toast.error(action.error);
            return {
                ...state,
                creationError: action.error
            }
        case CREATE_RULE_PENDING:
            return {
                ...state,
                creationStatus: "pending"
            }
        case CREATE_RULE_SUCCESS:
            toast.success(`rule ${action.rule.name} was created successfully`);
            return {
                ...state,
                creationStatus: "success",
                rules: [...state.rules, action.rule],
                createdRuleId: action.rule.id,
                createdRule: action.rule,
            }
        case FETCH_QUICK_ACTIONS_ERROR:
            toast.error(action.error);
            return {
                ...state,
            }
        case FETCH_QUICK_ACTIONS_SUCCESS:
            return {
                ...state,
                quickActions: action.quickActions,
            }
        case FETCH_RULE_STEPS_CONDITIONS_PENDING:
            return {
                ...state,
                stepsCondsPending: true,
            }
        case FETCH_RULE_STEPS_CONDITIONS_ERROR:
            toast.error(action.error);
            return {
                ...state,
                stepsCondsPending: false,
            }
        case FETCH_RULE_STEPS_CONDITIONS_SUCCESS:
            return {
                ...state,
                stepsCondsPending: false,
                steps: action.steps,
            }
        case DELETE_RULE_PENDING:
            return {
                ...state,
                deleteStatus: "pending"
            }
        case DELETE_RULE_SUCCESS:
            toast.success(`rule was deleted successfully`);
            return {
                ...state,
                deleteStatus: "success",
                rules: [...state.rules.filter(rule => rule.id !== action.ruleId)]
            }
        case FLUSH_RULE:
            return {
                ...state,
                steps: [],
                quickActions: [],
            }
        case FLUSH_RULES:
            return {
                ...state,
                rules: [],
                fetchRulesStatus: null,
            }
        case FLUSH_DELETE:
            return {
                ...state,
                deleteStatus: ""
            }
        case FLUSH_UPDATE:
            return {
                ...state,
                updateStatus: "",
                steps: []
            }
        case FLUSH_CREATE:
            return {
                ...state,
                creationStatus: "",
                createdRuleId: null,
                createdRule: null
            }
        case UPDATE_RULE_PENDING:
            return {
                ...state,
                updateStatus: "pending"
            }
        case UPDATE_RULE_ERROR:
            toast.error(action.error);
            return {
                ...state,
                updateStatus: "error"
            }
        case UPDATE_RULE_SUCCESS:
            toast.success(`rule ${action.rule.name} was updated successfully`);
            return {
                ...state,
                updateStatus: "success",
                rules: [
                    ...state.rules.filter(rule => rule.id !== action.rule.id), action.rule
                ]
            }
        case FETCH_SOURCES_FILTERS_PENDING:
            return {
                ...state,
            }
        case FETCH_SOURCES_FILTERS_ERROR:
            toast.error(action.error);
            return {
                ...state,
                sourcesAndFilters: [],
            }
        case FETCH_SOURCES_FILTERS_SUCCESS:
            return {
                ...state,
                sourcesAndFilters: action.data,
            }
        default:
            return state;
    }
}
export default rulesReducer;