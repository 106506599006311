import React from "react";
import { FormattedMessage } from "react-intl";
import { fetchCompanySettings, updateCompanySettings } from "../company";
import { getCollectedDatas } from "../collectedDatas";


export const FETCH_AGENTS_PENDING = 'FETCH_AGENTS_PENDING';
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS';
export const FETCH_AGENTS_ERROR = 'FETCH_AGENTS_ERROR';
export const UPDATE_AGENT = 'UPDATE_AGENT';
export const CREATE_AGENT = 'CREATE_AGENT';
export const DELETE_AGENT = 'DELETE_AGENT';
export const STORE_CURRENT_AGENT = "STORE_CURRENT_AGENT";

export const FLUSH_AGENTS = "FLUSH_AGENTS";
export const FLUSH_UPDATE = "FLUSH_UPDATE";
export const FLUSH_CREATE = "FLUSH_CREATE";
export const FLUSH_FETCH_AGENTS = "FLUSH_FETCH_AGENTS";

function fetchAgentsPending() {
    return {
        type: FETCH_AGENTS_PENDING
    }
}

function fetchAgentsSuccess(agents) {
    return {
        type: FETCH_AGENTS_SUCCESS,
        agents: agents
    }
}

function fetchAgentsError(error) {
    return {
        type: FETCH_AGENTS_ERROR,
        error: error
    }
}

const storeCurrentAgent = (currentAgent) => ({
    type: STORE_CURRENT_AGENT,
    currentAgent,
});

export const fetchAgents = (searchTerm, forChannelsPage) => {

    const search = () => {
        let searchFilters;
        const getSearchPart = (filter) => {
            if (filter.search.length === 0) return;
            switch (filter.field) {
                case "":
                    return
                case "keyword":
                    return { "name": { "like": `.*${filter.search}.*`, "options": "i" } };
                case "companyId":
                    return { "companyId": filter.search };
                case "id":
                    return { "id": filter.search };
                default:
                    break;
            }
        }

        if(Array.isArray(searchTerm)){
            let searchTerms = searchTerm.map(filter => {
                return getSearchPart(filter);
            })

            searchFilters = searchTerms.length > 0 ? { 
                "and": searchTerm.map(filter => {
                    return getSearchPart(filter);
                })
            } : null;
        } else if(searchTerm?.field) {
            searchFilters = getSearchPart(searchTerm);
        }
        return searchFilters;
    }

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchAgentsPending());
        return rcsdk
            .getAgents()
            .where(searchTerm && search())
            .then(agents => {
                if (forChannelsPage) {
                    dispatch(fetchAgentsSuccess(agents));
                }
                else {
                    let channelsPromises = [];
                    agents.forEach((agent) => {
                        let channelPromise = rcsdk.getChannels().where({ "agentId": agent.id }).then(data => data);
                        channelsPromises.push(channelPromise);
                    });
                    Promise.all([...channelsPromises]).then(channels => {
                        agents = agents.map((agent, index) => {
                            return {
                                ...agent,
                                channels: channels[index]
                            }
                        })
                        dispatch(fetchAgentsSuccess(agents));
                    })
                }
            })
            .then(dispatch(flushAgentUpdate("flushing")))
            .catch(err => {
                dispatch(fetchAgentsError(err.message));
            })
    }
}

export const getAgent = (agentId) => {

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {

        return rcsdk.getAgent(agentId)
            .then(response => {
                dispatch(storeCurrentAgent(response));
                return response
            })
            .catch(err =>
                console.err(err)
            )
    }
}

export const updateAgent = (agentId, data, message) => {

    const getTypesForVariables = (config, key) => {
        const types = config?.match(/(?<=<).+?(?=>)/g) || []
        const values = config?.match(/(?<=\|).+?(?=<)/g) || []
        values.push(config?.substr(config?.lastIndexOf('|') + 1))
        const index = values.findIndex(el => el.trim() === key)
        return types[index]
    }

    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: UPDATE_AGENT, status: "pending" });

        const getformattedMessage = (message) => {
            if (!message) return <FormattedMessage id="agents.toast.update" defaultMessage="Successfully updated agent" />
            else if (message.target === "scenario") {
                if (message.action === "create") return <FormattedMessage id="scenario.toast.create" defaultMessage="Successfully created scenario" />
                if (message.action === "update") return <FormattedMessage id="scenario.toast.update" defaultMessage="Successfully updated scenario" />
                if (message.action === "delete") return <FormattedMessage id="scenario.toast.delete" defaultMessage="Successfully deleted scenario" />
            }
        }

        return rcsdk.updateAgent(agentId, data)
            .then(response => {

                //update collectedDatasFormat in company settings
                dispatch(getCollectedDatas(response.companyId)).then(collectedDatas => {
                    const el = collectedDatas[agentId]
                    dispatch(fetchCompanySettings(response.companyId)).then(settings => {
                        let curAgentDatas = settings.value.collectedDatasFormat?.find(el2 => el2.agentId === agentId);
                        const obj = {
                            ...el,
                            collected: Object.entries(el.collected).map(([key, type]) => {
                                let curDataFromBDD = curAgentDatas?.collected.find(el2 => el2.key === key);
                                return {
                                    key: key,
                                    type: type,
                                    label: curDataFromBDD?.label || "",
                                    transformer: curDataFromBDD?.transformer || "",
                                    description: curDataFromBDD?.description || ""
                                }
                            })
                        }
                        const collected = obj.collected
                        const string = []
                        Object.values(data.config.rules).forEach(el => string.push(el[0]))
                        const collectedNew = []
                        collected.forEach(el3 => {
                            const type = getTypesForVariables(string.join(" "), el3.key)
                            if (type !== el.type) collectedNew.push({ ...el3, type: type })
                            else collectedNew.push(el3)
                        })
                        const objToUpdate = settings.value.collectedDatasFormat.find(el => el.agentId === agentId)
                        const formattedValueForSettings = {
                            ...settings.value,
                            collectedDatasFormat: [
                                ...settings.value.collectedDatasFormat.filter(el => el.agentId !== agentId),
                                {
                                    ...objToUpdate,
                                    "collected": collectedNew,
                                }
                            ],

                        }
                        dispatch(updateCompanySettings(formattedValueForSettings))
                    })
                })

                dispatch(
                    {
                        type: UPDATE_AGENT,
                        update: response,
                        status: "success",
                        successToast: {
                            type: "UPDATE",
                            message: getformattedMessage(message)
                        }
                    }
                )
                return "success"
            })
            .catch(err => dispatch({ type: UPDATE_AGENT, status: "error", error: err }))
    }
}

export const createAgent = (data) => {

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: CREATE_AGENT, status: "pending" });

        return rcsdk.createAgent(data)
            .then(response => {
                dispatch(
                    {
                        type: CREATE_AGENT,
                        created: response,
                        status: "success",
                        successToast: {
                            type: "ADD",
                            message: <FormattedMessage id="agents.toast.add" defaultMessage="Agent added successfully" />
                        }
                    }
                )
            })
            .catch(err => dispatch({ type: CREATE_AGENT, status: "error", error: err }))
    }
}

export const deleteAgent = (id) => {

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: DELETE_AGENT, status: "pending" });
        return rcsdk.deleteAgent(id)
            .then(response => {
                dispatch(
                    {
                        type: DELETE_AGENT,
                        deleted: response,
                        agentId: id,
                        status: "success",
                        successToast: {
                            type: "DELETE",
                            message: <FormattedMessage id="agents.toast.delete" defaultMessage={`Agent with id {id} deleted successfully`} values={{ id: id }} />
                        }

                    }
                )
            })
            .catch(err => dispatch({ type: DELETE_AGENT, status: "error", error: err }))
    }
}

export const flushAgentUpdate = (status) => {
    return { type: FLUSH_UPDATE, status: status }
}

export const flushCreateAgent = () => {
    return { type: FLUSH_CREATE }
}

export const flushAgents = () => {
    return { type: FLUSH_AGENTS }
}

export const selectAgentWithChannels = state => state.agents.agents.filter(el => el.channels.length > 0);
