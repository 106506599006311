import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { Card, CardHeader, CardBody, Spinner, Button} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {omit} from "lodash";
import { v4 as uuid } from 'uuid';
import { ArrowRight } from "react-feather";
import moment from 'moment';
import { defaultStaticRanges } from './localizedRanges';
import { isEqual } from 'date-fns';

const colors = {
    primary: {main: "#5f69ff", light: "#dfe0ff"},
    success: {main: "#20d48d", light: ""},
    warning: {main: "#ff9800", light: ""},
    danger: {main: "#ff5451", light: ""},
    purple: {main: "#af77ff", light: "#f2e7ff"},
    orange: {main: "#ffa900", light: "#ffeecc"},
    light: {main: "#8292a5", light: "#f7f7f9"},
};

export {colors as chartColors}


const ChartCard = ({children, className, loading = false, ...props}) => {
    return <Card className={`card-chart ${className}`} {...props} >
        {loading && <div className="m-auto p-3">
            <Spinner color="primary" />
        </div>}
        {!loading && children}
    </Card>
}
const StyledChartCard = styled(ChartCard)`
    flex-grow: 1
    a{
        color: ${colors["primary"].main};
    }
    .progress{
        height: 7px;
    }
    .text-success{
        color: #22e186 !important;
    }
    .bg-success{
        background-color: ${colors["success"].main} !important;
    }
    .text-danger{
        color: #ff6b61 !important;
    }
    .bg-danger{
        background-color: #ff6b61 !important;
    }
    .text-warning{
        color: ${colors["orange"].main} !important;
    }
    .bg-warning{
        background-color: ${colors["orange"].main} !important;
    }
    .tab-content .tab-pane{
        background-color: transparent !important;
    }
`;

const FlipChartCard = ({children, className, loading, renderFlipChildren = () => null, ...props}) => {
    const [flipped, setFlipped] = useState(false);

    const FlipButton = () => <div className="flip-trigger" onClick={() => setFlipped(!flipped)}>
        <Button color="white" className="btn-icon">
            {!flipped && <FontAwesomeIcon icon={["far", "cog"]} />}
            {flipped && <FontAwesomeIcon icon={["far", "times"]} />}
        </Button>
    </div>

    return <div className={className}>
        <div className={`flip-content ${flipped ? "flip-content--unflip" : "flip-content--flipped"}`}>
            <div className="flip-content-front">
                <FlipButton />
                <StyledChartCard loading={loading} {...props}>
                    {children}
                </StyledChartCard>
            </div>
            <div className="flip-content-back">
                <FlipButton />
                <StyledChartCard>
                    <StyledChartCardBody loading="false">
                        {renderFlipChildren()}
                    </StyledChartCardBody>
                </StyledChartCard>
            </div>
        </div>
    </div>
}

const StyledFlipChartCard = styled(FlipChartCard)`
    perspective: 600px;
    .flip-trigger{
        display: none;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        backface-visibility: hidden;
        transform: translate3d(0px, -100%, 0px);
        transform-origin: center center;
        transform-style: preserve-3d;
        padding-bottom: 0.5rem;
        .btn{
            position: relative;
            width: 2.5rem;
            height: 2.5rem;
            svg{
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, -50%);
            }
        }
    }
    &:hover .flip-trigger{
        display: flex;
    }
    .flip-content{
        transform: rotateY(180deg);
        transform-style: preserve-3d;
        &--flipped {
            animation: cardFlip .275s forwards linear;
            .flip-content-back{
                pointer-events: none;
                touch-events: none;
                z-index: 0;
            }
            .flip-content-front{
                z-index: 1;
            }
        }
        &--unflip {
            animation: cardUnFlip .275s forwards linear;
            .flip-content-front{
                pointer-events: none;
                touch-events: none;
                z-index: 0;
            }
            .flip-content-back{
                z-index: 1;
            }
            
            .flip-trigger{
                display: flex;
            }
        }
    }
    .flip-content-front, .flip-content-back{
        backface-visibility: hidden;
    }
    
    .flip-content-back{
        transform: rotateY( 180deg );
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        & > .card{
            height: 100%;
        }
    }
    @keyframes cardFlip {
        0% {
            transform: rotateZ(0deg) rotateY(180deg);
        } 
        50% {
            transform: rotateZ(-5deg) rotateY(90deg);
        }
        100% {
            transform: rotateZ(0deg) rotateY(0deg);
        }
        
    }
      
    @keyframes cardUnFlip {
        0% {
            transform: rotateZ(0deg) rotateY(0deg);
        } 
        50% {
            transform: rotateZ(-5deg) rotateY(90deg);
        }
        100% {
            transform: rotateZ(0deg) rotateY(180deg);
        }
        
    }
`



const ChartCardHeader = ({children, className, ...props}) => {
    return <CardHeader className={`card-chart--header ${className}`} {...props} >
        {
            children
        }
    </CardHeader>
}
const StyledChartCardHeader = styled(ChartCardHeader)`
    display: flex;
    align-items: flex-end !important;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.26px;
    width: 100%;
    .btn-group{
        .btn{
            padding: 6px 10px; 
            font-size: 13px;
            font-weight: 400;
            letter-spacing: -0.24px;
        }
        .btn-outline-primary{
            border: 1px solid #e5eaef;
            color: ${colors["light"].main};
            border-left: 0px solid #e5eaef;
            &:first-child{
                border-left: 1px solid #e5eaef;
            }
            &:not(.active):hover, &:not(.active):focus{
                background-color: #FFF;
                color: ${colors["primary"].main} !important;
            }
            &.active{
                background-color: ${colors["primary"].main};
                border: 1px solid ${colors["primary"].main};
                border-left: 0px solid ${colors["primary"].main};
                &:first-child{
                    border-left: 1px solid ${colors["primary"].main};
                }
            }
        }
    }
    .vx-checkbox-con{
        color: ${colors["light"].main};
    }
`;



const ChartCardTitle = ({children, className, ...props}) => {
    return <h5 className={`card-chart--header--title ${className}`} {...props}>{children}</h5>
}
const StyledChartCardTitle = styled(ChartCardTitle)`
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    color: ${colors["light"].main};
    margin-bottom: 0 !important;
`;

const ChartCardPeriod = ({dateRange, className, ...props}) => {
    const [label, setLabel] = useState(undefined);
    useEffect(() => {
        const element = defaultStaticRanges().find(el => isEqual(el.range().startDate, dateRange?.startDate) && isEqual(el.range().endDate, dateRange?.endDate))
        setLabel(element?.label)
    }, [dateRange]);

    return <span className={`test-muted ${className}`} {...props}>
        {label === undefined && <>
            {dateRange.startDate && moment(dateRange.startDate).format("LL")}
            {!dateRange.startDate && <FontAwesomeIcon icon={["fas", "infinity"]} />}
            <ArrowRight size={12} className="mr-50 ml-50" />
            {dateRange.endDate && moment(dateRange.endDate).format("LL")}
            {!dateRange.endDate && <FontAwesomeIcon icon={["fas", "infinity"]} />}
        </>}
        {label !== undefined && <>
            {label}
        </>}
    </span>
}
        
const StyledChartCardPeriod = styled(ChartCardPeriod)`
`


const ChartCardBody = ({children, className, ...props}) => {
    return <CardBody className={`card-chart--body ${className}`} {...props} >
        {
            children
        }
    </CardBody>
}
const StyledChartCardBody = styled(ChartCardBody)`
    font-size: 13px;
    font-weight: 400;
    color: ${colors["light"].main};
    .card-chart--icons-list{
        & > *{
            margin-left: 2px;
            margin-right: 2px;
        }
    }
    small{
        font-size: 10px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 14px;
        letter-spacing: -0.16px;
    }
    .card-chart--numberBox-list{
        display: flex;
        flex-direction: column;
        & > *{
            margin-bottom: 8px;
            &:last-child{
                margin-bottom: 0px;
            }
        }
    }
    .card-chart--numberBox-list-dashed{
        position: relative;
        &:before{
            content: "";
            position: absolute;
            top: 22px;
            left: 20px;
            height: calc(100% - 44px);
            width: 4px;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23bfcad8' stroke-width='4' stroke-dasharray='2%2c 4' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e");
            background-repeat: repeat-y;
            background-position-x: 2px;
        }
        & > *{
            border: 0;
            padding-left: 0;
            padding-right: 0;
            margin: 0;
        }
    }
    .is-truncated{
        display: block;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
    }
    .missing-data{
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
        color: ${colors["danger"].main};
        svg{
            margin-right: .35rem;
            font-size: 12px;
        }
    }
`;



const ChartNumber = ({value = null, percent = null, variation = null, variationColor = "success", variationClass = "", withArrow = false, size = "md", className, ...props}) => {

    return <div className={`card-chart--number ${className}`} {...props} >
        {value !== null && <span>{value}</span>}
        {percent !== null && <span className="card-chart--number-percent">{`(${percent}%)`}</span>}
        {
            withArrow && <span className={`card-chart--number-arrow`}>
                <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </span>
        }
        {
            variation !== null && <span className={`card-chart--number-variation text-${variationColor} is-truncated ${variationClass}`}>
                {typeof variation === "object" ||  variation === "—" ? variation : variation < 0 ? `${variation}%` : `+${variation}%` }
            </span>
        }
    </div>
}
const chartNumberStyles = size => {
    switch (size) {
        case "lg":
            return `
                font-size: 45px;
                font-weight: 500
            `;
        case "md":
            return `
                font-size: 25px;
                font-weight: 500;
            `;
        case "sm":
        default:
            return `
                font-size: 13px;
                font-weight: 500;
                letter-spacing: -0.24px;
            `;
    }
};
const StyledChartNumber = styled(ChartNumber)`
    display: inline-flex;
    align-items: flex-end;
    color: #444e5a;
    ${({ size }) => chartNumberStyles(size)};
    margin-bottom: ${({ size }) => size === "lg" ? "-7px" : size === "md" ? "-4px" : "0"};
    .card-chart--number-percent{
        color: ${colors["light"].main};
        font-weight: 400;
        margin-left: 4px;
    }
    .card-chart--number-variation{
        font-size: ${({ size }) => size === "sm" ? "13px" : "15px"};
        font-weight: 500;
        line-height: 18px;
        letter-spacing: ${({ size }) => size === "sm" ? "-0.24px" : "-0.28px"};
        margin-left: 4px;
        margin-bottom: ${({ size }) => size === "lg" ? "7px" : size === "md" ? "4px" : "0"};
        &.text-muted{
            color: #8292a5 !important;
        }
    }
    .card-chart--number-arrow{
        margin-right: 8px;
        margin-left: 8px;
        color: #bfcad8;
        font-size: 10px;
        line-height: 18px;
    }
`;



const ChartHighlightBox = ({children, color = "success", className, ...props}) => {
    return <div className={`card-chart--highlightbox bg-${color} ${className}`} {...props} >
        {children}
    </div>
}
const StyledChartHighlightBox = styled(ChartHighlightBox)`
    display: inline-block;
    padding: 5px 8px;
    border-radius: 6px;
    color: #FFF;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.16px;
    strong{
        font-size: 15px;
        font-weight: 500;
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        margin-right: 5px;
        margin-left: 5px;
        &:first-child{
            margin-right: 0px;
        }
        &:last-child{
            margin-left: 0px;
        }
    }
`;



const ChartIcon = ({color = "purple", icon = null, size="md", number=null, status=null, className, ...props}) => {
    return <div className={`card-chart--icon ${className}`} {...props}>
        {icon && icon}
        {number && <span className={`card-chart--icon-number ${icon && "ml-50"}`}>{number}</span>}

        {status ? (
          <span className={`card-chart--icon-status status-${status}`}></span>
        ) : null}
    </div>
}
const StyledChartIcon = styled(ChartIcon)`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({size = "md"}) => (size === "md" || size === "lg") ? "23px": "18px"};
    font-size: ${({size = "md"}) => (size === "md" || size === "lg") ? "20px": "14px"};
    font-weight: 600;
    flex: 0 0 auto;
    padding: ${({size = "md"}) => (size === "md" || size === "lg") ? "11px": "6px"};
    min-width: ${({size = "md"}) => (size === "md" || size === "lg") ? "45px": "35px"};
    height: ${({size = "md"}) => (size === "md" || size === "lg") ? "45px": "35px"};
    color: ${({ color = "purple" }) => colors[color].main};
    background-color: ${({ color = "purple" }) => colors[color].light};
    img{
        width: 100%;
        max-width: ${({size = "md"}) => size === "lg" ? "30px": "22px"};
        height: auto;
    }
    svg{
        max-width: ${({size = "md"}) => size === "lg" ? "30px": "22px"};
    }
    .card-chart--icon-number{
        font-weight: ${({size = "md"}) => size === "lg" ? "600": "500"};
    }
    .card-chart--icon-status{
        position: absolute;
        top: 2px;
        right: 2px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        &.status-online{
            background-color: #22e186;
        }
        &.status-offline{
            background-color: ${colors["danger"].main}
        }
        &.status-busy{
            background-color: ${colors["orange"].main}
        }
    }
`;


const ChartNumberBox = ({children, color = "purple", icon = null, className, ...props}) => {
    return <div className={`card-chart--numberbox ${className}`} {...props} >
        {
            icon && <StyledChartIcon 
                icon={icon}
                color={color}
                className="mr-1"
            />
        }
        {children}
    </div>
}
const StyledChartNumberBox = styled(ChartNumberBox)`
    display: flex;
    align-items: center;
    border: 2px solid #e5eaef;
    padding: 12px 16px;
    border-radius: 6px;
    color: ${colors["light"].main};
    font-size: 13px;
    font-weight: 400;
`;


const ChartInlineList = ({color = "purple", datas=null, className, ...props}) => {
    if(!datas || datas.length === 0) return null;
    return <ul className={`card-chart--inlineList ${className}`} {...props} >
        {datas.map((row, index) => {
            return <li key={`card-chart--inlineList-${index}`}>
                {row?.icon && row.icon}
                <span className="card-chart--inlineList-label">{row.label}</span>
                {" : "}
                <span className="card-chart--inlineList-value">{row.value}</span>
            </li>
        })}
    </ul>
}
const StyledChartInlineList = styled(ChartInlineList)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    li{
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -0.24px;
        margin-right: 16px;
        color: #bfcad8;
        &:last-child{
            margin-right: 0;
        }
    
        .card-chart--inlineList-label{
            margin-left: 0.25rem;
        }
    
        .card-chart--inlineList-value{
            color: ${colors["light"].main};
            margin-left: 0.25rem;
        }
    
        svg{
            color: ${({ color = "purple" }) => colors[color].main};
        }
    }
`;


const ChartSeparator = ({icon = <FontAwesomeIcon icon={["far", "bolt"]} />, className, ...props}) => {
    return <div className={`card-chart--separator ${className}`} {...props} >
        {icon && <span className="card-chart--separator-icon">{icon}</span>}
    </div>
}
const StyledChartSeparator = styled(ChartSeparator)`
    position: relative;
    display: flex;
    width: 2px;
    overflow: visible;
    &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #e5eaef;
    }
    .card-chart--separator-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        background-color: #FFF;
        transform: translate(-50%, 30px);
        font-size: 16px;
        color: #444e5a;
    }
`;



const ChartLegend = ({datas, className, selectedIndex = null, onSelectOption = () => {}, ...props}) => {
    const [legendId] = useState(new uuid());
    return <ul
        className={`card-chart--legend ${className}`}
        {...props}
    >
        {datas.map((row, index) => {
            // return <li key={`card-chart--legend-${index}`}>
            //     <span className="card-chart--legend-color" style={{backgroundColor: row.color}}></span>
            //     <span className="card-chart--legend-label">{row.label}</span>
            //     <span className="card-chart--legend-value">
            //         <StyledChartNumber {...omit(row.value, "variation", "variationColor")} size="sm"/>
            //         <StyledChartNumber withArrow size="sm" />
            //         <StyledChartNumber {...omit(row.value, ["value", "percent"])} size="sm" />
            //     </span>
            // </li>
            return <li
                key={`card-chart--legend-${legendId}-row-${index}`}
                className={`card-chart--legend-row ${selectedIndex !== null && selectedIndex !== index ? "unselected" : ""}`}
                onMouseEnter={() => onSelectOption(index)}
                onMouseLeave={() => onSelectOption(index)}
            >
                <span className="card-chart--legend-color" style={{backgroundColor: row.color}}></span>
                <div className="card-chart--legend-label">
                    <span className="truncate">
                        {row.label}
                    </span>
                </div>
                <StyledChartNumber 
                    {...omit(row.value, ["percent", "variation", "variationColor"])}
                    size="sm"
                    className="card-chart--legend-value"
                />
                <StyledChartNumber 
                    {...omit(row.value, ["value", "variation", "variationColor"])}
                    size="sm"
                    className="card-chart--legend-percent"
                />
                {
                    row.value?.variation !== null && <StyledChartNumber
                        withArrow
                        size="sm"
                        className="card-chart--legend-arrow"
                    />
                }
                <StyledChartNumber
                    {...omit(row.value, ["value", "percent"])}
                    size="sm"
                    className="card-chart--legend-variation"
                />
            </li>
        })}
    </ul>
}
const StyledChartLegend = styled(ChartLegend)`
    padding-left: 0;
    list-style-type: none;
    
    display: grid; 
    grid-auto-rows: 1fr; 
    grid-template-columns: 1fr; 
    gap: 0px 0px;
    align-items: center;
    margin-bottom: 0 !important;
    .card-chart--legend-row{
        display: grid;
        grid-auto-rows: 1fr; 
        grid-template-columns: 20px 1fr auto auto auto auto; 
        // grid-template-columns: 20px 1fr max-content max-content max-content max-content; 
        gap: 3px 0;
        align-items: center;
        // padding: 3px 0;
        &.unselected{
            opacity: 0.5;
        }
    }
    .card-chart--legend-color{
        width: 12px;
        height: 6px;
        border-radius: 3px;
        margin-right: 16px;
    }
    .card-chart--legend-label{
        color: ${colors["light"].main};
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.24px;
        margin-right: auto;
        display: contents;
        position: relative;
    }
    .card-chart--legend-value{
        margin-left: 14px;
        justify-self: flex-end;
    }
    .card-chart--legend-variation{
        justify-self: flex-end;
        min-width: 40px;
        text-align: right;
    }
    
`;



export {
    StyledChartCard as ChartCard,
    StyledFlipChartCard as FlipChartCard,
    StyledChartCardHeader as ChartCardHeader,
    StyledChartCardTitle as ChartCardTitle,
    StyledChartCardPeriod as ChartCardPeriod,
    StyledChartCardBody as ChartCardBody,
    StyledChartNumber as ChartNumber,
    StyledChartHighlightBox as ChartHighlightBox,
    StyledChartNumberBox as ChartNumberBox,
    StyledChartIcon as ChartIcon,
    StyledChartInlineList as ChartInlineList,
    StyledChartSeparator as ChartSeparator,
    StyledChartLegend as ChartLegend,
};
export default StyledChartCard;