import mock from "../mock"
// import React from "react"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPhone, faComment, faSms } from '@fortawesome/pro-solid-svg-icons'
// import { faWhatsapp, faFacebookMessenger } from "@fortawesome/free-brands-svg-icons"
import AircallLogo from "../../assets/img/logo/aircall-icon.svg"
import { v4 as uuid } from 'uuid'
// import dataAgents from "./agents";

const aircallDatas = {
  url : 'https://dashboard-v2.aircall.io/oauth/authorize',
  clientId: 'Ej4hv7EkTJ0U6ho1MvZ_TT8FwHK2IfQ1qhYmQ3OwlhU',
  callbackUri: 'https://app.reecall.co/aircall/oauth/callback'
}

const channelsGlobalDatas = [
    {
      type: "phone",
      icon: ['fas', 'phone'],
      color: "#465CFF",
      name: "Téléphone",
      description:"Ajoutez la possibilité pour vos clients de vous joindre par téléphone",
      helpText: "lorem ipsum 1 help text",
      helpLink: "http://www.reecall.co",
      live: false,
      config:{
        type:"wizard",
        steps:[
          {
              type:"twilio_number",
              config:{
              }
          },
          {
              type:"resume"
          }
        ]
      }
    },
    {
      type: "aircall",
      img: AircallLogo,
      color: "#465CFF",
      name:"Aircall",
      description:"",
      helpText: "lorem ipsum 2 help text",
      helpLink: "http://www.aircall.co",
      live: false,
      config:{
          type:"integration",
          name: "aircall",
          logo: AircallLogo,
          list: [
            "lorem ipsum 1",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
            "Duis luctus nunc tincidunt dolor molestie, scelerisque molestie urna ullamcorper."
          ],
          url: (companyId) => {
            return `${aircallDatas.url}?client_id=${aircallDatas.clientId}&redirect_uri=${aircallDatas.callbackUri}&response_type=code&scope=public_api&state=${companyId}`       
          }
      }
    },
    {
      type: "chat",
      icon: ['fas', 'comment'],
      color: "#465CFF",
      name: "Reecall Chat",
      description:"",
      helpText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras convallis libero quis risus maximus efficitur. In hac habitasse platea dictumst. Nulla mollis nisi vel mauris accumsan, vel posuere dolor lacinia. Praesent erat orci, lacinia et volutpat eu, congue a nisi. Sed nec convallis leo. Duis sem tortor, ornare ut nunc et, dictum rutrum dui. Ut eleifend feugiat leo ac euismod. Vestibulum sed eros ac eros hendrerit fermentum",
      helpLink: "http://www.reecall.co",
      live: true,
      config:{
        type:"standalone",
        steps:[]
      }
    }
]

export {channelsGlobalDatas};


const getChannelAditionnalDatas = (type) => {
  let channelGlobalDatas = channelsGlobalDatas.find((chanGlobal) => { return chanGlobal.id === type});
  return {
    icon: channelGlobalDatas?.icon,
    color: channelGlobalDatas?.color,
    img: channelGlobalDatas?.img,
    helpText: channelGlobalDatas?.helpText,
    helpLink: channelGlobalDatas?.helpLink,
    live: channelGlobalDatas?.live
  }
}
export { getChannelAditionnalDatas };

let channelsDatas = [
  // {
  //   id: "1",
  //   type:"chat",
  //   name:"Reecall chat",
  //   description:"Un chat pour vos users",
  //   datas:[
  //     {
  //       type: "token",
  //       name: "token",
  //       value: "mlskmljdlkmzopei-zamlk-23121"
  //     }
  //   ],
  //   ...getChannelAditionnalDatas("chat")
  // },
  // {
  //   id: "2",
  //   type:"aircall",
  //   name:"Aircall",
  //   description:"Aircall desc custom",
  //   datas:[
  //     {
  //       type: "url",
  //       name: "url",
  //       value: "http://www.aircall.com"
  //     }
  //   ],
  //   ...getChannelAditionnalDatas("aircall")
  // },
  // {
  //   id: "3",
  //   type:"phone",
  //   name:"Phone",
  //   description:"Phone desc custom",
  //   datas: [],
  //   ...getChannelAditionnalDatas("phone")
  // }
];

export default channelsDatas;


mock.onGet("/api/channelsGlobal").reply(request => {
    return [200, channelsGlobalDatas];
});


mock.onGet("/api/channels").reply(request => {
  return [200, channelsDatas];
});


mock.onPost("/api/channel/add").reply(request => {
  let channel = JSON.parse(request.data).channel;

  console.log(channel, "channel add");
  
  let channelFullDatas = {
    id: uuid(),
    type: channel.type,
    name: channel.name,
    description: channel.description,
    datas: channel?.datas || [],
    ...getChannelAditionnalDatas(channel.id)
  }
  channelsDatas.push(channelFullDatas);

  return [201, { channel: channelFullDatas }]
})


mock.onPost("/api/channel/update").reply(request => {
  let channel = JSON.parse(request.data).channel;
  channelsDatas = channelsDatas.map(el => el.id === channel.id ? {...channel} : el);

  return [201, { channel: channel }]
})