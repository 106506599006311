import mock from "../mock"
import { v4 as uuid } from 'uuid'
let data = [
	{
        id: 1,
        firstName: "Raphaël",
        lastName: "Szymocha",
        mail: "raphael@reecall.co",
        phone: "0123456789",
        role: "owner",
        channels: [],
        status: "pending",
        createdAt: "2020-02-20T12:16:25.268Z"
	},
    {
        id: 2,
        firstName: "Mathieu",
        lastName: "Buonomo",
        mail: "mathieu@reecall.co",
        phone: "0123456789",
        role: "user",
        channels: [],
        status: "pending",
        createdAt: "2020-02-20T12:16:25.268Z"
    },
    {
        id: 3,
        firstName: "Mathieu",
        lastName: "Greard",
        mail: "mathiegu@reecall.co",
        phone: "0683060863",
        role: "user",
        channels: [],
        status: "accepted",
        createdAt: "2020-02-20T12:16:25.268Z"
    },
    {
        id: 4,
        firstName: "Jean",
        lastName: "Bon",
        mail: "jb@reecall.co",
        phone: "0123456789",
        role: "manager",
        channels: [],
        status: "rejected",
        createdAt: "2020-02-20T12:16:25.268Z"
    }
]

export default data;


// GET INVITATIONS
mock.onGet("/api/invitations").reply(request => {
    return [200, data];
});

//ADD INVITATION
mock.onPost("/api/invitations/add").reply(request => {
    let invitation = JSON.parse(request.data).invitation;
    
    let invitationFullDatas = {
      id: uuid(),
      ...invitation,
      status: "pending",
      createdAt: + new Date()
    }
    data.push(invitationFullDatas);
  
    return [201, { invitation: invitationFullDatas }]
})

//DELETE INVITATION
mock.onDelete("/api/invitations").reply(request => {
    let id = request.params.id;
    data = [...data.filter(el => el.id !== id)]
    return [201, { invitationId: id }]
})