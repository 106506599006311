import React from 'react';
import { toast } from "react-toastify";
import moment from "moment";
import UnknowAvatar from "../assets/img/avatar-unknown.png"
import { FormattedMessage } from "react-intl";
import { dateTimeRegexp, dateRegexp } from "./regexs"
import { isEqual, transform, isObject, isArray } from 'lodash';
import { toLoopbackFilter } from '@reecall/external_utils'

var _ = require('lodash');

let contextRegexp = new RegExp("{{(.+?)}}");
const isContextValue = (value, regExp = null) => {
    if (regExp) {
        return regExp.test(value);
    }
    return contextRegexp.test(value);
}
export { isContextValue };

export function replaceAll(entity, needle, replacement, affectsKeys = false, affectsValues) {
    affectsKeys = typeof affectsKeys === "undefined" ? true : affectsKeys;
    affectsValues = typeof affectsValues === "undefined" ? true : affectsValues;

    var newEntity = {},
        regExp = new RegExp(needle, 'g');
    for (var property in entity) {
        if (!entity.hasOwnProperty(property)) {
            continue;
        }

        var value = entity[property],
            newProperty = property;

        if (affectsKeys) {
            newProperty = property.replace(regExp, replacement);
        }

        if (affectsValues) {
            if (typeof value === "object") {
                value = replaceAll(value, needle, replacement, affectsKeys, affectsValues);
            } else if (typeof value === "string") {
                value = value.replace(regExp, replacement);
            }
        }

        newEntity[newProperty] = value;
    }

    return newEntity;
};

export function getDefaultAvatar({ firstName, lastName }, size = 128) {
    firstName = (firstName || '').replace(/^\s*(.).*/, '$1');
    lastName = (lastName || '').replace(/^\s*(.).*/, '$1');

    if (firstName === "" && lastName === "") return UnknowAvatar;

    return `https://ui-avatars.com/api/?name=${firstName || lastName ? `${firstName || ''}${lastName || ''}` : '?'}&size=${size}`;
}

export function getInitiales(element) {
    let firstName = (element?.originalFirstName || element?.firstName || '').replace(/^\s*(.).*/, '$1');
    let lastName = (element?.originalLastName || element?.lastName || '').replace(/^\s*(.).*/, '$1');
    return firstName || lastName ? `${firstName || ''}${lastName || ''}` : '?';
}

export function getDefaultNames({ firstName, lastName }, reverse = false, defaultValue = 'inconnu') {
    const n = [];
    n.push((firstName || defaultValue).replace(/([^-_ \t])([^-_ \t]*)/ig, (m, a, b) => `${a.toUpperCase()}${b.toLowerCase()}`));
    n.push((lastName || defaultValue).toUpperCase());
    if (reverse) n.reverse();
    return n.filter(a => a);
}

export function truncateWithEllipses(text, max) {
    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
}

export function capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export function searchByText(collection, text, exclude) {
    text = _.toLower(text);
    return _.filter(collection, function (object) {
        return _(object).omit(exclude).some(function (string) {
            return _(string).toLower().includes(text);
        });
    });
}

export function getTicketColorByTreatmentDelay(minutes) {
    return getColorBetween([255, 84, 81], [255, 152, 0], minutes / 1440);
}

export function getColorBetween(color1, color2, factor) {
    if (arguments.length < 3) {
        factor = 0.5;
    }
    if (factor > 1) factor = 1;
    var result = color1.slice();
    for (var i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return `rgb(${result[0]}, ${result[1]}, ${result[2]})`;
}

export function getRandom(min, max) {
    return Math.random() * (max - min) + min;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomArray(aLength, min, max) {
    return Array.from({ length: aLength }, () => getRandom(min, max));
}

export function getRandomIntArray(aLength, min, max) {
    return Array.from({ length: aLength }, () => getRandomInt(min, max));
}

/**
  * Replaces all occurrences of needle (interpreted as a regular expression with replacement and returns the new object.
  * 
  * @param entity The object on which the context replacements should be applied to
  * @param needle The search phrase (as a regular expression)
  * @param context Context value for replacement
  * @param affectsKeys[optional=true] Whether keys should be replaced
  * @param affectsValues[optional=true] Whether values should be replaced
  */
export function ReplaceByContext(entity, needle, context, affectsKeys, affectsValues) {
    affectsKeys = typeof affectsKeys === "undefined" ? false : affectsKeys;
    affectsValues = typeof affectsValues === "undefined" ? true : affectsValues;

    var newEntity = {},
        regExp = new RegExp(needle, 'g');

    for (var property in entity) {
        if (!entity.hasOwnProperty(property)) {
            continue;
        }

        var value = entity[property],
            newProperty = property;

        if (affectsKeys) {
            newProperty = property.replace(regExp, function (a, b) {
                return _.get(context, b)
            });
        }

        if (affectsValues) {
            if (typeof value === "object") {
                value = ReplaceByContext(value, needle, context, affectsKeys, affectsValues);
            } else if (typeof value === "string") {
                value = value.replace(regExp, function (a, b) {
                    return _.get(context, b)
                });
            }
        }

        newEntity[newProperty] = value;
    }
    return newEntity;
};

export const processCSVData = (csv, separator) => {
    var allTextLines = csv.split(/\r\n|\n/);
    var lines = [];
    for (var i = 0; i < allTextLines.length; i++) {
        var data = allTextLines[i].split(separator);
        var tarr = [];
        for (var j = 0; j < data.length; j++) {
            tarr.push(data[j]);
        }
        lines.push(tarr);
    }
    return lines;
}

export const string_to_slug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

// get Data from all totalCards to export csv on company detail page and homepage
export const getDataForCSV = (data, dataToExport, cb) => {
    const array = [...dataToExport]
    const index = dataToExport.findIndex(e => e.type === data.type)
    if (index !== -1) {
        array[index] = data
        cb(array)
    } else cb([...dataToExport, data])
}

export const copyToClipboard = (str, type, showToast = true) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (showToast) {
        toast.success(`${type} copied into clipboard`);
    }
};

export const downloadTxtFile = (str, name) => {
    const element = document.createElement("a");
    const file = new Blob([str], {
        type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = `${name}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export function flatten(object, separator = '.') {
    return Object.assign({}, ...function _flatten(child, path = []) {
        return [].concat(...Object.keys(child).map(key => typeof child[key] === 'object'
            ? _flatten(child[key], path.concat([key]))
            : ({ [path.concat([key]).join(separator)]: child[key] })
        ));
    }(object));
}

export function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":";
    }

    ret += (mins < 10 ? "0" : "") + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}




export const getDecimalDuration = (milliseconds, forceFormat = null) => {
    if (milliseconds === null) return null;
    let formatedDuration;
    if (!forceFormat) {
        let tmpLocale = localStorage.getItem('reecall-webapp-locale') || navigator.language.slice(0, 2) || 'en';
        var roundingDefault = moment.relativeTimeRounding();
        moment.relativeTimeRounding((value) => {
            return Math.round((value + Number.EPSILON) * 100) / 100;
        });

        formatedDuration = moment().locale(`small_${tmpLocale}`);
        formatedDuration = formatedDuration.add({ milliseconds: milliseconds }).fromNow(true)

        // back to default
        moment.relativeTimeRounding(roundingDefault);
        return formatedDuration.slice(-4) === "mins" ? formatedDuration.slice(0, -1) : formatedDuration;
    } else {
        formatedDuration = moment.duration(milliseconds);
        return Math.round(formatedDuration?.[forceFormat]());
    }
}


export function getHours(duration) //millisecond
{
    // Hours
    var hrs = Number.parseFloat(duration / 3600000).toPrecision(1);
    return hrs;
}

export function getFormattedDuration(duration) //millisecond
{
    let time;
    let result;
    if (duration / 3600000 > 24) {
        time = parseFloat(duration / 3600000 / 24).toFixed(1)
        result = <FormattedMessage id="formattedDuration.days" defaultMessage="{time} d" values={{ time: time }} />
    } else if (duration / 3600000 < 1) {
        time = parseFloat(duration / 60000).toFixed(0)
        result = <FormattedMessage
            id="formattedDuration.mins"
            defaultMessage="{time, plural, =0 {0 min} one {# min} other {# min}}"
            values={{ time: time }}
        />
    } else {
        time = parseFloat(duration / 3600000).toFixed(1)
        result = <FormattedMessage id="formattedDuration.hours" defaultMessage="{time} h" values={{ time: time }} />
    }
    return result;
}


export const getVariation = (currVal = null, prevVal = null, forceValue = false) => {

    if ((prevVal === 0 || currVal === 0 || prevVal === null || currVal === null) && forceValue) return "—";

    if (prevVal === null || prevVal === 0) return null;
    if (currVal === null || currVal === 0) return null;

    return Math.round(((currVal - prevVal) / prevVal) * 100);
}



/**
 * largestRemainderRound will round each number in an array to the nearest
 * integer but make sure that the the sum of all the numbers still equals
 * desiredTotal. Uses largest remainder method.  Returns numbers in order they
 * came.
 *
 * @param {number[]} numbers - numbers to round
 * @param {number} desiredTotal - total that sum of the return list must equal
 * @return {number[]} the list of rounded numbers
 * @example
 *
 * var numbers = [13.6263, 47.9896, 9.5960, 28.7880];
 * largestRemainderRound(numbers, 100);
 *
 * // => [14, 48, 9, 29]
 *
 */
export const largestRemainderRound = (numbers, desiredTotal) => {
    var result = numbers.map(function (number, index) {
        return {
            floor: Math.floor(number),
            remainder: getRemainder(number),
            index: index,
        };
    }).sort(function (a, b) {
        return b.remainder - a.remainder;
    });

    var lowerSum = result.reduce(function (sum, current) {
        return sum + current.floor;
    }, 0);

    var delta = desiredTotal - lowerSum;
    for (var i = 0; i < delta; i++) {
        result[i].floor++;
    }

    return result.sort(function (a, b) {
        return a.index - b.index;
    }).map(function (result) {
        return result.floor;
    });
}

function getRemainder(number) {
    var remainder = number - Math.floor(number);
    return remainder.toFixed(4);
}

//Flatten object to {path.1: value, path.2: value2, ...}
export const flattenKeys = (obj, path = []) =>
    !_.isObject(obj)
        ? { [path.join('.')]: obj }
        : _.reduce(obj, (cum, next, key) => _.merge(cum, flattenKeys(next, [...path, key])), {});


export const unflattenKeys = _.flow([
    _.toPairs,
    (arr) => _.reduce(arr, (cum, [key, value]) => _.set(cum, key, value), {}),
]);

export const isDate = (str) => {
    return dateRegexp.test(str);
}

export const isDateTime = (str) => {
    return dateTimeRegexp.test(str);
}

export const getModelURI = ({ type = "conversation", id, parentId, companyId }) => {
    if (!id) return null;
    const baseName = window.location.origin;
    let modelURI = ({
        "conversation": companyId ? `/companies/all/${companyId}/conversations/${id}` : `/conversations/all/${id}`,
        "ticket": companyId ? `/companies/all/${companyId}/tickets/all/${id}` : `/tickets/all/${id}`,
        "contact": companyId ? `/companies/all/${companyId}/contacts/all/${id}` : `/contacts/all/${id}`,
        "member": companyId ? `/companies/all/${companyId}/members/${id}` : `/members/all/${id}`,
        "channel": companyId ? `/companies/all/${companyId}/channels/${id}` : `/channels/all/${id}`,
        "agent": companyId ? `/companies/all/${companyId}/agents/${id}` : `/agents/all/${id}`,
        "faq": companyId ? `/companies/all/${companyId}/faqs/all/${id}` : `/faqs/all/${id}`,
        "collection": companyId ? `/companies/all/${companyId}/collections/${id}` : `/collections/all/${id}`,
        "team": `settings/teams/details/${id}`,
        "company": `/companies/all/${id}/details`,
        "tag section": `/companies/all/${companyId}/tags`,
        "back-office user": `/users/backOffice/all/${id}`,
        "quickAction": companyId ? `/companies/all/${companyId}/quickActions/${id}` : `/quick-actions/builder/${id}`,
        "rule": companyId ? `/companies/all/${companyId}/rules/${id}` : `/rules/all/${id}`,
        "hook": companyId ? `/companies/all/${companyId}/hooks/all/${id}` : `/hooks/all/${id}`,
        "label": companyId ? `/companies/all/${companyId}/labels/all/${id}` : `/labels/all/${id}`,
        "export": companyId ? `/companies/all/${companyId}/exports/${id}` : `/exports/${id}`,
        "library": `/automatheque/examples/all/${id}`,
        "tagCategory": `/companies/all/${companyId}/smartTags/${id}`,
        "tag": `/companies/all/${companyId}/smartTags/${parentId}/${id}`,
    })?.[type]

    return modelURI ? `${baseName}${modelURI}` : null;
}


export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const difference = (object, base) => {
    function changes(object, base) {
        return transform(object, function (result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base)
}


export const getIconFromString = (str) => {
    let iconProps = str.includes(":") ?
        str.split(/(%7C|\|)/gm).reduce(
            (previousValue, currentValue) => {
                let [key, val] = currentValue.split(":");
                return {
                    ...previousValue,
                    [key]: val
                }
            },
            {}
        )
        :
        { icon: str }

    return iconProps
}

export const generateWhere = ({
    searchTerm,
    filters,
    companyId,
    dateRange,
    channelType,
    filtersFromTable,
    statusFilters
}) => {
    let search = null;

    if (isObject(searchTerm) && searchTerm?.search !== "") {
        switch (searchTerm?.field) {
            case "":
                return
            case "keyword":
                search = { "title": { "like": `.*${searchTerm.search}.*`, "options": "i" } };
                // if (companyId !== null) search["companyId"] = companyId;
                break;
            case "companyId":
                search = { "companyId": searchTerm.search };
                break;
            case "id":
                search = { "id": searchTerm.search };
                // if (companyId !== null) search["companyId"] = companyId;
                break;
            default:
                break;
        }
    }

    let filtersToSend = {}

    if (filtersFromTable) filtersToSend = toLoopbackFilter(filtersFromTable)
    else if (filters) {
        if (isArray(filters)) filtersToSend = toLoopbackFilter(filters)
        else filtersToSend = filters
    }

    let where = { ...filtersToSend }

    const formatWhere = (obj) => {
        let tmp = { ...where };
        if (tmp["and"]) tmp = { "and": [...tmp["and"], obj] }
        else if (Object.keys(tmp)?.length > 0) {
            tmp = { "and": [tmp, obj] }
        }
        else tmp = obj
        where = tmp
    }

    if (companyId) formatWhere({ "companyId": { "eq": companyId } })

    if (search) formatWhere(search)

    if (statusFilters) formatWhere(statusFilters)

    let dateRangeFilter = dateRange?.startDate || dateRange?.endDate ? {
        and: [
            dateRange?.startDate ? { "createdAt": { "gte": dateRange.startDate } } : null,
            dateRange?.endDate ? { "createdAt": { "lte": dateRange.endDate } } : null,
        ].filter(el => el)
    } : null;

    if (dateRangeFilter) where = Object.assign(where, dateRangeFilter);

    if (channelType && channelType !== "all" && channelType !== "archived") formatWhere({ "channelId": { "eq": channelType } })
    // if (channelType && channelType !== "all" && channelType !== "archived") {
    //     where["channelId"] = { "eq": channelType };
    // }

    // console.log(where)
    return where
}
