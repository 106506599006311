import React from "react"
import mock from "../mock"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AircallLogoWhite from "../../assets/img/logo/aircall-icon-white.svg"
import AircallLogo from "../../assets/img/logo/aircall-icon.svg"
import WooCommerceLogo from "../../assets/img/logo/WooCommerce_logo.svg"
import WooCommerceLogoRevert from "../../assets/img/logo/WooCommerce_logo_revert.svg"

const deckActions = [
  {
    label: "Reecall",
    icon: ["fal" , "magic"],
    color: "danger",
    name: "reecall-main",
    childrens:[
      {
        label: "Send Mail",
        description: "Envoyer un mail au contact",
        icon: ["fal" , "comment"],
        color: "danger",
        name: "send-message",
        action: {
          name: "send-message"
        }
      },
      {
        label: "Goodbye",
        description: "Prefill chat with goodbye message",
        icon: ["fal" , "hand-peace"],
        color: "danger",
        name: "say-goodbye",
        action: {
          name: "fill-input",
          params: {
            message: "Bonnne journée 👋\nL'Équipe Reecall"
          }
        }
      },

      {
        label: "Action test",
        description: "Action de test",
        icon: ["fal" , "cog"],
        color: "warning",
        name: "action-test-1"
      },

      {
        label: "Action test 2",
        description: "Action de test 2",
        icon: ["fal" , "pen"],
        color: "success",
        name: "action-test-2"
      }
    ]
  },
  {
    label: "Phone",
    icon: ["fal" , "phone"],
    color: "primary",
    name: "phone-main",
    childrens:[
      {
        label: "Appeler le contact",
        icon: ["fal" , "phone"],
        color: "primary",
        name: "phone-call",
        action: {
          name: "call-contact"
        }
      }
    ]
  },
  {
    label: "Aircall",
    icon: ["fal" , "phone"],
    color: "primary",
    name: "aircall-main",
    childrens:[
      {
        label: "Aircall Call",
        description: "passer un appel aircall",
        img: AircallLogoWhite,
        imgHover: AircallLogo,
        color: "#00B388",
        name: "aircall-call",
        action: {
          name: "aircall-call"
        }
      }
    ]
  },
  {
    label: "Woocommerce",
    img: WooCommerceLogoRevert,
    imgHover: WooCommerceLogo,
    color: "#9B5C8F",
    name: "woocommerce-main",
    childrens:[
      {
        label: "Annuler la commande",
        description: "Annuler une commande woocommerce",
        icon: <div className="fa-layers fa-3x fa-fw">
          <FontAwesomeIcon icon={["fas",  "circle"]} mask={['fas', 'shopping-cart']} transform="shrink-4 down-4 right-8" />
          <FontAwesomeIcon icon={["fas",  "times-circle"]} transform="shrink-6 down-4 right-8" />
        </div>,
        color: "#9B5C8F",
        name: "woocommerce-cancel-order",
        action: {
          name: "woocommerce-cancel-order"
        }
      }
    ]
  }
]
export default deckActions;


// GET : CONTACTS
mock.onGet("/api/deck-actions").reply(request => {
    return [200, deckActions];
});