import mock from "../mock"
import contactsDatas from "./contacts"

const liveInbounds = [
  {
    id: "1",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mattis id arcu ut semper. Ut id blandit metus. Maecenas posuere consequat lorem, sed facilisis nunc aliquet ac.",
    from: "bot",
    time: "2020-06-22T08:30:59.135Z",
    type: "message"
  },
  
  {
    id: "2",
    message: "Suspendisse placerat varius elit eu tristique. Nulla at ligula ac neque faucibus facilisis. Integer consectetur, ligula sed sagittis viverra, lorem nunc suscipit lectus, aliquet iaculis erat purus at felis. ",
    from: "user",
    time: "2020-06-22T13:30:59.135Z",
    type: "message"
  },

  {
    id: "3",
    message: "Integer a lobortis diam. Quisque purus arcu, accumsan et fermentum et, pretium ullamcorper arcu.",
    from: "agent",
    time: "2020-06-21T08:30:59.135Z",
    type: "message"
  },

  {
    id: "4",
    message: "Duis a volutpat nibh, at consequat mauris.",
    from: "user",
    time: "2020-03-22T08:30:59.135Z",
    type: "message",
    guess: {
      "type": "time",
      "datas": {
        "from": "2019-10-31T18:00:00.000+01:00",
        "to": null
      }
    }
  },

  {
    id: "5",
    message: null,
    from: "user",
    time: "2020-09-07T09:30:59.135Z",
    type: "hangup"
  }
]

const livesDatas = [
  {
    id: "1",
    channelId:  "5f7d9320117e1e0008267240",
    name: "room name 1",
    contact: contactsDatas[0],
    inbounds: [...liveInbounds],
    typing: false,
    unread: 0,
    status: "online"
  },
  {
    id: "2",
    channelId:  "5f7d9320117e1e0008267240",
    name: "room name 2",
    contact: contactsDatas[1],
    inbounds: [liveInbounds[1]],
    typing: false,
    unread: 0,
    status: "offline"
  },
  {
    "id": "3",
    channelId: "5f7d9320117e1e0008267240",
    name: "room name 3",
    contact: contactsDatas[2],
    inbounds: [liveInbounds[2]],
    typing: true,
    unread: 3,
    status: "away"
  },
  {
    id: "4",
    channelId: "5f7d9320117e1e0008267240",
    name: "room name 4",
    contact: contactsDatas[0],
    inbounds: [liveInbounds[3]],
    typing: false,
    unread: 0,
    status: "online"
  },
  {
    id: "5",
    channelId: "5f7d9320117e1e0008267240",
    name: "room name 5",
    contact: contactsDatas[1],
    inbounds: [liveInbounds[0]],
    typing: true,
    unread: 3,
    status: "online"
  }
]

export default livesDatas;

// GET : LIVES
mock.onGet("/api/lives").reply(request => {
    return [200, livesDatas];
});