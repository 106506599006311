import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from "reactstrap";
import "../../../assets/scss/components/deck/deck.scss"
import classnames from "classnames"
import find from 'lodash/find'
import styled from 'styled-components'
import { X } from 'react-feather';
import {useDispatch} from "react-redux";
import {executeQuickAction} from "../../../redux/actions/integrations"
import { truncateWithEllipses} from '../../../utility/utils';

const themeColors = ["primary", "warning", "danger", "success", "light"];

const StyledDeckItem = styled.div`
  ${({ isThemeColor, color }) => {
    if(!isThemeColor){
      // const gradColor = lighten(0.0533, saturate(0.119, adjustHue(0.1, color)));

      return !isThemeColor && `
        background: ${color} !important;
        color: #fff;

        .ellipsis{
          color: #fff;
        }
      `
    }
    return null;
  }}
`
const DeckItemIcon = ({icon = null, img = null}) => {
  if(icon){
    if(React.isValidElement(icon)){
      return <>{icon}</>
    } else {
      if(Array.isArray(icon) || typeof icon === "string"){
        return <FontAwesomeIcon icon={icon} />;
      }
    }
  }

  if(img){
    return <>
      <img src={img} className="deck-item-img" alt="deck icon" draggable={false}/>
    </>
  }

  return null;
} 
export {DeckItemIcon};

export const DeckItem = ({icon = null, img = null, title = '', description = '', color = '', size = "sm", name = "", tooltipPlacement, hasChildrens, deletable = false, onlyIcon = false, className = '', ...rest}) => {
  return <StyledDeckItem 
    className={classnames({
      "deck-item": true,
      [color]: themeColors.includes(color),
      "is-theme-color": themeColors.includes(color),
      "deletable": deletable,
      [className]: true
    })}
    isThemeColor={themeColors.includes(color)}
    color={color}
    {...rest}
  >
    <div className="deck-item-inner" id={`deck-item-${rest.id}`}>
      <div className="deck-item-inner-content">
        <DeckItemIcon icon={icon} img={img}/>
        {!onlyIcon && title && size !== "sm" && <span className="deck-item--label">
          {truncateWithEllipses(title, 16)}
        </span>}
        {!onlyIcon && description && size === "lg" && <div className="deck-item--description">
          <p className="truncate font-small-3">
            {description}
          </p>
        </div>}
        {hasChildrens && <FontAwesomeIcon icon={["fal", "ellipsis-h"]} className="ellipsis"/>}
      </div>
      {
        deletable && <div className="deletable-icon"><X size={12}/></div>
      }
    </div>

    { 
      title && tooltipPlacement && size !== "lg" && <UncontrolledTooltip
        placement={tooltipPlacement}
        target={`deck-item-${rest.id}`}
        modifiers={{
          preventOverflow: { enabled: false }
        }}
      >
        {title}
      </UncontrolledTooltip>
    }
  </StyledDeckItem>
}

const Deck = ({direction, size, maxRows, maxColumns, deckDatas, tooltipPlacement, className, type, onSelect, onDone, style, context, ...rest}) => {
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(null);
  const [currentItem, setCurrentItem] = useState([]);
  const [prevItem, setPrevItem] = useState(null);

  useEffect(() => {
    const formatAction = (action) => {
      return action.itemType === "folder" ? 
      {color: "#465CFF", icon: action.img ? null : ["fas", "folder"], ...action }
      :
      {...action}
    }

    if(currentId){
      setPrevItem(formatAction(currentItem));
      setCurrentItem(find(deckDatas, ['id', currentId]).childrens.map(child => { return formatAction(child)}));
    } else {
      setCurrentItem(deckDatas.map(item => { return formatAction(item)}));
      setPrevItem(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId]);

  return <div className={`deck-list-wrapper ${className}`} {...rest}>
    <div className={classnames({
        "deck-list" : true,
        [`deck-list-${direction}`]: true,
        [`deck-list-${size}`]: true
      })}
      style={{
        "--max-rows": maxRows,
        "--max-columns": maxColumns,
        ...style
      }}
    >
      <div className={"deck-list-inner"}>
        {
          prevItem && <DeckItem
            title="Previous"
            icon={["fal" , "arrow-left"]}
            color="#22292f"
            name="item-prev"
            onClick={() => {
              setCurrentId(prevItem.name);
            }}
            tooltipPlacement={tooltipPlacement}
           />
        }
        { 
          currentItem && currentItem.map(itemData => {
            if(itemData){
              return <DeckItem
                color="#465CFF"
                {...itemData}
                size={size}
                key={`deck-item-${itemData.id}`}
                onClick={() => {
                  if(itemData.childrens && itemData.childrens.length > 0){
                    setCurrentId(itemData.id);
                  } else {
                    if(type === "normal"){
                      dispatch(executeQuickAction({quickAction: itemData, context: context}));
                    } else if(type === "select"){
                      onSelect(itemData);
                    }
                  }
                }}
                tooltipPlacement={tooltipPlacement}
                hasChildrens={itemData.childrens ? true : false}
              />
            } else {
              return null;
            }
          }) 
        }
      </div>
    </div>
  </div>
}

Deck.defaultProps = {
  direction: "horizontal",
  size:"sm",
  deckDatas: [],
  maxRows: "auto-fill",
  maxColumns: "auto-fill",
  tooltipPlacement: "top",
  type: "normal", //normal / select,
  onSelect: (val) => { return val; },
  onDone: () => {},
  context: {}
}

export default Deck
