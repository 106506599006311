import React from "react";
import { FormattedMessage } from "react-intl";
export const FETCH_INVITATIONS = 'FETCH_INVITATIONS';
export const ADD_INVITATION = 'ADD_INVITATION';
export const DELETE_INVITATION = 'DELETE_INVITATION';

export const fetchInvitations = ({searchTerm = {field: "", search: ""}, startIndex = 0, stopIndex = 20, order, flush = false, companyId = null, status = ["pending"]}) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch({
            type: FETCH_INVITATIONS,
            status: "pending",
            flush: flush,
            startIndex,
            stopIndex
        });

        let where = [];
        if(searchTerm.field === "email"){
            where.push({"email":{ "like": `.*${searchTerm.search}.*`, "options": "i" }});
        } else if(searchTerm.field === "companyId"){
            where.push({"companyId": { "eq": searchTerm.search }})
        }

        if(companyId){
            where.push({"companyId": { "eq": companyId }})
        }

        if(status){
            where.push({"status": { "inq": status }})
        }

        return rcsdk
            ._request().route(`/invitations`)
            .where({"and" : where})
            .skip(startIndex)
            .limit(stopIndex-startIndex)
            .order(order)
            .include(["company"])
            .raw()
            .then(response => {
                const total = parseInt(response.headers["content-range"].substr(response.headers["content-range"].indexOf("/") + 1));
                
                dispatch({
                    type: FETCH_INVITATIONS,
                    status: "success",
                    invitations: response.data,
                    total: total,
                    startIndex,
                    stopIndex
                });

                return {invitations: response.data, total: total}
        }).catch(err => {
            dispatch({
                type: FETCH_INVITATIONS,
                status: "error",
                error: err
            });
        })
    }
}

export const getInvitation = (id) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        return rcsdk._request()
        .route(`/invitations/${id}`)
        .err(`Could not get invitation`)
        .catch(err => {
            dispatch({
                error: err
            });
        })
    }
} 

export const createInvitation = (datas) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {

        dispatch({
            type: ADD_INVITATION,
            status: "pending"
        });
        
        return rcsdk._request()
        .method('POST')
        .route(`/invitations`)
        .data({...datas, status: "pending"})
        .err(`Could not create invitation`)
        .then(resp => {
            dispatch({
                type: ADD_INVITATION,
                status: "success",
                invitation: resp,
                    successToast: {
                        type: "ADD",
                        message: <FormattedMessage id="invitations.toast.add" defaultMessage="Invitation added successfully"/>
                    }
            });
            return resp;
        })
        .catch(err => {
            dispatch({
                type: ADD_INVITATION,
                status: "error",
                error: err
            });
        })
    }
}

export const deleteInvitation = (id) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch({
            type: DELETE_INVITATION,
            status: "pending"
        });

        return rcsdk._request()
        .method('DELETE')
        .route(`/invitations/${id}`)
        .err(`Could not delete invitation with id ${id}`)
        .then(resp => {
            dispatch({
                type: DELETE_INVITATION,
                status: "success",
                id: id,
                successToast: {
                    type: "DELETE",
                    message: <FormattedMessage id="invitations.toast.delete" defaultMessage="Invitation deleted successfully"/>
                }
            });
            return resp;
        })
        .catch(err => {
            dispatch({
                type: DELETE_INVITATION,
                status: "error",
                error: err
            });
        })
    
    }
}
