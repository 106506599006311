import mock from "./mock"
import "./apps/agents"
import "./apps/conversations"
import "./apps/tickets"
import "./apps/contacts"
import "./apps/members"
import "./apps/organizations"
import "./apps/lives"
import "./apps/channels"
import "./apps/deck"
import "./apps/invitations"
import "./apps/dashboards"
mock.onAny().passThrough()
