export const BASE_URL            = process.env?.REACT_APP_BASE_URL            || "https://bo.reecall.com"
export const CORE_URL            = process.env?.REACT_APP_CORE_URL            || "https://core.reecall.io"
export const API_BASE            = process.env?.REACT_APP_API_URL             || "https://api.reecall.io"
export const COLLECTION_URL      = process.env?.REACT_APP_COLLECTION_URL      || "https://collections.reecall.io"
export const EXPORT_URL          = process.env?.REACT_APP_EXPORT_URL          || "https://export.reecall.io"
export const BILLING_URL         = process.env?.REACT_APP_BILLING_URL         || "https://billing.reecall.io"
export const NLU_URL             = process.env?.REACT_APP_NLU_MODEL_STORE_URL || "https://nlumodelstore.reecall.io"
export const MSNLU_URL           = process.env?.REACT_APP_MSNLU_URL           || "https://msnlu.reecall.io"
export const HOOK_URL            = process.env?.REACT_APP_HOOKS_URL           || "https://hooks.reecall.io"
export const INGESTION_URL       = process.env?.REACT_APP_INGESTION_URL       || "https://ingestion.reecall.io"
export const ACD_URL             = process.env?.REACT_APP_ACD_URL             || "https://acd.reecall.io"
export const PHONE_MANAGEMENT_URL= process.env?.REACT_APP_PHONE_MANAGEMENT_URL|| "https://phone.reecall.io"
export const SEARCH_URL          = process.env?.REACT_APP_SEARCH_URL          || "https://search.reecall.io"
export const STATS_URL           = process.env?.REACT_APP_STATS_URL           || "https://stats.reecall.io"

export const AIRCALL_CLIENT_ID = process.env?.REACT_APP_AIRCALL_CLIENT_ID         || "Ej4hv7EkTJ0U6ho1MvZ_TT8FwHK2IfQ1qhYmQ3OwlhU" || "3AoNuDR0wDDpBh5Zlqkfhc31MmeepKp9oxpXjE54LQw";
export const AIRCALL_CLIENT_SECRET = process.env?.REACT_APP_AIRCALL_CLIENT_SECRET || "d0iPgd-lLpJqKzKwH5EV_XnOnafZdcRG-WpzOK-91P0" || "BnmwjQFqHWp3eDINskCSzp1_pVhmBf7oj0RuiDbkt10";

export const AUTH0_DOMAIN = process.env?.REACT_APP_AUTH0_DOMAIN || 'auth.reecall.co'
export const AUTH0_CLIENT_ID = process.env?.REACT_APP_AUTH0_CLIENT_ID || 'TODLg9AwKPMAT3Ia2FeT9nW1kLPsZ3zq'

export const TWILIO_URL = process.env?.REACT_APP_TWILIO_URL || "https://production.aws.reecall.io/twilio";
export const TWILIO_USERNAME     = process.env?.REACT_APP_TWILIO_USERNAME     || "AC06581df421ee1ed5c1e486fb0a8c7acc";
export const TWILIO_PASSWORD     = process.env?.REACT_APP_TWILIO_PASSWORD     || "3846d7600b700c044d5b338183b16873";

export const AI_MODEL_STATS_URL =
    process.env?.REACT_APP_AI_MODEL_STATS_URL || "https://aimodelstats.reecall.io";

export const TAG_URL_OLD = "https://tag.reecall.io";
export const TAG_URL = process.env?.REACT_APP_TAG_URL || TAG_URL_OLD;

export const CLASSIFIER_URL_OLD = "https://classifier.reecall.io";
export const CLASSIFIER_URL = process.env?.REACT_APP_CLASSIFIER_URL || CLASSIFIER_URL_OLD;

export const FAQ_URL_OLD = "https://faq.reecall.io";
export const FAQ_URL = process.env?.REACT_APP_FAQ_URL || FAQ_URL_OLD;

export const RPA_URL_OLD = "https://rpa.reecall.co";
export const RPA_URL = process.env?.REACT_APP_RPA_URL || RPA_URL_OLD;

export const getUrlByInfra = (target, infra) => {
    const urlsMap = {
        "FAQ_URL": FAQ_URL,
        "FAQ_URL_OLD": FAQ_URL_OLD,
        "TAG_URL": TAG_URL,
        "TAG_URL_OLD": TAG_URL_OLD,
        "CLASSIFIER_URL": CLASSIFIER_URL,
        "CLASSIFIER_URL_OLD": CLASSIFIER_URL_OLD,
        "RPA_URL": RPA_URL,
        "RPA_URL_OLD": RPA_URL_OLD,
    }
    const old = infra === "old" ? "_OLD" : ""
    const url = target.toUpperCase()+"_URL"+old
    return urlsMap[url]
}



