import {
    FETCH_LOGS
} from '../../actions/logs';


const initialState = {
    logs: [],
    status: null
}

const logsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LOGS:
            return {
                ...state,
                status: action.status,
                logs: action.status === "success" ? action.logs : state.logs
            }
        default:
            return state;
    }
}
export default logsReducer;