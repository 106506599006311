import { SET_TOKENS_REQUEST_STATUS, STORE_TOKENS } from "../../actions/tokens";


const initialState = {
    tokens: [],
    requestStatus: "",
}

const TokensReducer = (state = initialState, action) => {
    switch(action.type) {
        case STORE_TOKENS:
            return {
                ...state,
                tokens: action.tokens,
            }
        case SET_TOKENS_REQUEST_STATUS:
            return {
                ...state,
                requestStatus: action.requestStatus,
            }
        default:
            return state;
    }
}
export default TokensReducer;
