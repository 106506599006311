import React, {useState, useRef} from "react";
import ReactPlayer from 'react-player';

const Player = ({url, children, ...rest}) => {
    const player = useRef(null);
    const [ready, setReady] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [playerState, setPlayerState] = useState({
      played: 0,
      loaded: 0
    });
  
    const onSeek = amount => {
      if (player.current) {
        player.current.seekTo(amount, 'fraction');
      }
    };

    if(!url) return null;
    return <div className="d-flex align-items-center w-100">
      <ReactPlayer
        ref={player}
        url={url}
        playing={playing}
        height="0"
        width="0"
        onProgress={setPlayerState}
        onReady={() => setReady(true)}
        progressInterval={50}
        onEnded={() => setPlaying(false)}
        {...rest}
      />
      
      {React.Children.map(children, (child, index) => {
			  return <>
          {React.cloneElement(child, {
            played: playerState.played,
            loaded: playerState.loaded,
            ready: ready,
            playing: playing,
            onSeek: child.props?.onSeek || onSeek,
            onTogglePlaying: () => setPlaying(!playing),
            playerRef: player
          })}
        </>
      })}
    </div>
}

Player.defaultProps = {
    url: null
}

export default Player;