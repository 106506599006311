import React, { useEffect, useState } from "react"
import {
	Container,
	Row,
	Col,
	Modal,
	ModalHeader,
	ModalBody
} from "reactstrap"
import ToggleSwitch from "../../../../components/reecall/form/ToggleSwitch";
import ChannelCard from "../../../../components/reecall/channels/ChannelCard"
import "react-toggle/style.css"
import "../../../../assets/scss/plugins/forms/switch/react-toggle.scss"

import { useSelector, useDispatch } from 'react-redux';
import { updateChannelConnection, updateChannelConnections } from '../../../../redux/actions/channels'

import { FormattedMessage, injectIntl } from 'react-intl'

const ChannelsListModal = ({ isOpen, toggle, intl }) => {
	const dispatch = useDispatch();
	const app = useSelector(state => state.app);
	const rcuser = useSelector(state => state.user.rcuser);
	const channels = useSelector(state => state.channels.channels);
	const channelConnections = useSelector(state => state.channels.channelConnections);
	const [memberChannelConnections, setMemberChannelConnections] = useState([]);

	useEffect(() => {
		if (app.loaded) {
			setMemberChannelConnections(channelConnections.filter(el => el.memberId === rcuser.rcId && (channels.find(channel => channel.id === el.channelId)?.data?.live || channels.find(channel => channel.id === el.channelId)?.data?.escalation)));
		}
	}, [channelConnections, channels, app, rcuser]);

	return <Modal
		isOpen={isOpen}
		toggle={() => toggle()}
		className="channel-list-modal modal-dialog-centered"
		size="lg"
		container="body"
	>
		<ModalHeader toggle={() => toggle()}>
			<FormattedMessage id="modals.chat.togglechannels.title" defaultMessage="Channels" />
			<label className="d-flex align-items-center">
				<ToggleSwitch
					name="channels-disable-all"
					size={"md"}
					className="mr-0"
					text={[
						intl.formatMessage({ id: "modals.chat.togglechannels.disableall", defaultMessage: "Disable All" }),
						intl.formatMessage({ id: "modals.chat.togglechannels.enableall", defaultMessage: "Enable All" })
					]}
					checked={memberChannelConnections.filter(el => el.active).length > 0}
					onChange={(e) => {
						dispatch(updateChannelConnections(memberChannelConnections.map(el => el.id), { active: !memberChannelConnections.filter(el => el.active).length > 0 }));
					}}
				/>
			</label>
		</ModalHeader>
		<ModalBody>
			<Container>
				<Row>
					{
						memberChannelConnections.map(channelConnection => {
							let channel = channels.find(el => el.id === channelConnection.channelId);
							if (!channel) return null;
							return <Col sm="12" md="6" lg="3" className="match-height" key={`channel-${channel.id}`}>
								<ChannelCard
									channel={channel}
									active={channelConnection.active}
									showToggle
									onToggle={() => {
										dispatch(updateChannelConnection(channelConnection.id, { active: !channelConnection.active }));
									}}
								/>
							</Col>
						})
					}
				</Row>
			</Container>
		</ModalBody>
	</Modal>
}

export default injectIntl(ChannelsListModal)