import React from "react";
import { Media } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";

const TypeIconMap = {
    "success": ["fas", "check"],
    "warning": ["fas", "exclamation"],
    "error": ["fas", "exclamation"],
    "info": ["fas", "info"]
}

const TypeColorMap = {
    "success": "success",
    "warning": "warning",
    "error": "danger",
    "info": "info"
}

const Notification = ({title, content, icon, type, timestamp, color, headerClassName}) => {

    const renderIcon = (icon = null, colorClassName = null, colorStyle = "inherit") => {
        return icon ? <FontAwesomeIcon
            icon={icon}
            className={`${colorClassName || ""}`}
            style={{
                color: colorStyle
            }}
        /> : null
    }

    const typeIcon =  icon || TypeIconMap[type] || null;
    const typeColorClass = TypeColorMap[type] || null;

    return <div className="d-flex justify-content-between">
        <Media className="d-flex align-items-start">
            <Media left href="#">
                {renderIcon(typeIcon, typeColorClass, typeColorClass ? null : color)}
            </Media>
            <Media body>
                <Media 
                    heading
                    className={`${typeColorClass} ${headerClassName} media-heading`}
                    tag="h6"
                    style={{
                        color: typeColorClass ? 'inherit' : color
                    }}
                >
                    {title}
                </Media>
                <p className="notification-text">
                    {content}
                </p>
            </Media>
            <small>
                <time
                    className="media-meta"
                    dateTime={moment.unix(timestamp)}
                >
                    {moment.unix(timestamp).fromNow()}
                </time>
            </small>
        </Media>
    </div>
}

Notification.defaultProps = {
    timestamp: Math.floor(Date.now() / 1000),
    icon: null,
    title: "",
    content: "",
    color: null,
    headerClassName: "",
    type: null
}

export default Notification;