// import { setCompany } from "../company"; 
import { updateMember } from "../members"; 
import { getUrlByInfra } from "../../../configs/constants";

export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const TOGGLE_CHANNEL = 'TOGGLE_CHANNEL';
export const TOGGLE_CHANNELS = 'TOGGLE_CHANNELS';


export const setPhoneStatus = (status) => {
    return (dispatch, getState) => {
		console.log(status, "setPhoneStatus");
		if(getState().user?.rcuser){
			return dispatch(updateMember(getState().user.rcuser.rcId, {status: {...getState().user.rcuser.status, phone: status}}, false));
		}
		return false;
	}
}

function fetchUserPending() {
    return {
        type: FETCH_USER_PENDING
    }
}

function fetchUserSuccess(user) {
    return {
        type: FETCH_USER_SUCCESS,
        rcuser: user
    }
}

function fetchUserError(error) {
    return {
        type: FETCH_USER_ERROR,
        error: error
    }
}

export const toggleChannel = (channelName) => {
    return (dispatch, getState) => {
        dispatch({ type: TOGGLE_CHANNEL, channelName: channelName, active: !getState().user.rcuser.settings.channels.find(channel => channel.name === channelName).active })
    }
}

export const toggleChannels = (active) => {
    return (dispatch, getState) => {
    	getState().user.rcuser.settings.channels.map(channel => {
        	dispatch({ type: TOGGLE_CHANNEL, channelName: channel.name, active: active });
        	return null;
    	})
    }
}

export const fetchUser = () => {
    return (dispatch, getState, {rcsdk, rcRPA, rcAlgolia}) => {
        dispatch(fetchUserPending());
	    return rcsdk.getMyself().then(user => {
			return rcsdk._request().route(`/members/${user.id}/companies`).then(companies => {
				// dispatch(setCompany(companies[0]));

				const infra = getState().company?.company?.companySetting?.[0]?.value?.infra || 'production';
                rcRPA.url = getUrlByInfra('rpa', infra);

				let rcuser = {};
				rcuser.rcId = user.id;
				rcRPA.setUserId(user.id)
				rcuser.settings = user.settings || undefined;
				rcuser.settings.activePhone = (rcuser.settings.activePhone) ? rcuser.settings.activePhone : [];
				rcuser.settings.activeChat = (rcuser.settings.activeChat) ? rcuser.settings.activeChat : [];

				rcuser.firstName = user.firstName || undefined;
				rcuser.lastName = user.lastName || undefined;
				rcuser.phone = user.phone || undefined;
				rcuser.email = user.email || undefined;
				rcuser.organizations = user.organizations || [];

				rcuser.rights = {
					"ADMIN": user.permissions[companies[0].id].ADMIN,
					...user.permissions[companies[0].id]?.rtPerms
				}
				
				rcuser.settings = {
					...rcuser.settings,
					backOfficeProfile: rcuser.settings.backOfficeProfile || "csm",
					channels: [],
				}

				// rcAlgolia.setDefaultFilters(`companyId:"${companies[0].id}"`);
				// rcuser.settings.backOfficeProfile = "dev"

				dispatch(fetchUserSuccess(rcuser));
				return rcuser;
			}).catch(err => {
				console.log(err, "err fetch company");
			})
	    }).catch(err => {
        	dispatch(fetchUserError(err.message));
	    });

    }
}