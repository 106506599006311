import React from 'react'
import { FormattedMessage } from 'react-intl';
import { fetchExportConfigurations } from '../export'

export const FETCH_AGENTS_FOR_DUPLICATION_PENDING = 'FETCH_AGENTS_FOR_DUPLICATION_PENDING';
export const FETCH_AGENTS_FOR_DUPLICATION_SUCCESS = 'FETCH_AGENTS_FOR_DUPLICATION_SUCCESS';
export const FETCH_AGENTS_FOR_DUPLICATION_ERROR = 'FETCH_AGENTS_FOR_DUPLICATION_ERROR';

export const FLUSH_FETCH_FOR_DUPLICATION_AGENTS = "FLUSH_FETCH_FOR_DUPLICATION_AGENTS";
export const SET_TEMP_DATA_FOR_DUPLICATION = "SET_TEMP_DATA_FOR_DUPLICATION";

export const CREATE_AGENT_BY_DUPLICATION = "CREATE_AGENT_BY_DUPLICATION";
export const CREATE_AGENT = "CREATE_AGENT"; //to trigger agents list refresh in agents reducer

export const DUPLICATE_RULE = "DUPLICATE_RULE";
export const DUPLICATE_RULE_SUCCESS = "DUPLICATE_RULE_SUCCESS";
export const DUPLICATE_RULE_PENDING = "DUPLICATE_RULE_PENDING";
export const DUPLICATE_RULE_ERROR = "DUPLICATE_RULE_ERROR";
export const FLUSH_DUPLICATE = "FLUSH_DUPLICATE";
export const DUPLICATE_QUICK_ACTION = "DUPLICATE_QUICK_ACTION";
export const DUPLICATE_EXPORT = "DUPLICATE_EXPORT";

export const CREATE_QUICK_ACTION_LIBRAIRIES_SUCCESS = "CREATE_QUICK_ACTION_LIBRAIRIES_SUCCESS"; //to trigger qa list refresh in qa reducer

function fetchAgentsPending() {
    return {
        type: FETCH_AGENTS_FOR_DUPLICATION_PENDING
    }
}

function fetchAgentsSuccess(agents) {
    return {
        type: FETCH_AGENTS_FOR_DUPLICATION_SUCCESS,
        agents: agents
    }
}

function fetchAgentsError(error) {
    return {
        type: FETCH_AGENTS_FOR_DUPLICATION_ERROR,
        error: error
    }
}

export const fetchAgentsForDuplication = (searchTerm, forChannelsPage) => {

    const search = () => {
        if (searchTerm.search.length === 0) return;
        switch (searchTerm.field) {
            case "":
                return
            case "keyword":
                return { "name": { "like": `.*${searchTerm.search}.*`, "options": "i" } };
            case "companyId":
                return { "companyId": searchTerm.search };
            case "id":
                return { "id": searchTerm.search };
            default:
                break;
        }
    }

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchAgentsPending());
        return rcsdk
            .getAgents()
            .where(searchTerm && search())
            .then(agents => {
                if (forChannelsPage) {
                    dispatch(fetchAgentsSuccess(agents));
                }
                else {
                    let channelsPromises = [];
                    agents.forEach((agent) => {
                        let channelPromise = rcsdk.getChannels().where({ "agentId": agent.id }).then(data => data);
                        channelsPromises.push(channelPromise);
                    });
                    Promise.all([...channelsPromises]).then(channels => {
                        agents = agents.map((agent, index) => {
                            return {
                                ...agent,
                                channels: channels[index]
                            }
                        })
                        dispatch(fetchAgentsSuccess(agents));
                    })
                }
            })
            .then()
            .catch(err => {
                dispatch(fetchAgentsError(err.message));
            })
    }
}

export const createAgentByDuplication = (data, sameCompany = false) => {

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: CREATE_AGENT_BY_DUPLICATION, status: "pending" });

        return rcsdk.createAgent(data)
            .then(response => {
                const obj = {
                    created: response,
                    status: "success",
                    successToast: {
                        type: "ADD",
                        message: <FormattedMessage id="agents.toast.add" defaultMessage="Agent added successfully" />
                    }
                }
                dispatch(
                    {
                        type: CREATE_AGENT_BY_DUPLICATION,
                        ...obj
                    }
                )
                sameCompany && dispatch(
                    {
                        type: CREATE_AGENT,
                        ...obj
                    }
                )
            })
            .catch(err => dispatch({ type: CREATE_AGENT_BY_DUPLICATION, status: "error", error: err }))
    }
}

export const duplicateRule = (sequence, company, name, description) => {

    const companyId = company.id

    return async (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: DUPLICATE_RULE_PENDING });

        var rule = {
            name,
            description,
            companyId,
            sequence: [],
        }

        const qaId = {}

        for (var i = 0; i < sequence.length; i++) {
            var stepC = {
                type: sequence[i].conditionType,
                companyId,
                conditions: []
            };
            const newQaArray = []
            for (let index = 0; index < sequence[i].quickActions.length; index++) {
                const qa = sequence[i].quickActions[index];

                if (!Object.keys(qaId).includes(qa.id)) {
                    const obj = {
                        actions: qa.actions,
                        color: qa.color,
                        companyId: companyId,
                        description: qa.description,
                        icon: qa.icon,
                        title: qa.title
                    }
                    const newQa = await rcsdk.createQuickActionLibrary(obj)
                    qaId[qa.id] = newQa.id
                    newQaArray.push(qa.queued ? `!${newQa.id}`: newQa.id)
                } else {
                    newQaArray.push(qa.queued ? `!${qaId[qa.id]}`: qaId[qa.id])
                }
            }

            if (sequence[i].conditionType !== "ELSE") {
                for (var y = 0; y < sequence[i].rows.length; y++) {
                    var condition = { ...sequence[i].rows[y], ...{ companyId } }
                    if (condition.subSource !== "") condition.source = condition.source + "." + condition.subSource
                    delete condition.subSource;
                    var cond = await rcsdk.createRuleCondition(condition);
                    stepC.conditions.push(cond.id);
                }
                var step = await rcsdk.createRuleStep(stepC);
                rule.sequence.push({ step: i, stepId: step.id, then: newQaArray })
            } else {
                rule.sequence.push({ step: i, stepId: null, then: newQaArray })
            }
        }

        return rcsdk
            .createRule(rule)
            .then(response => {
                dispatch({ type: DUPLICATE_RULE_SUCCESS, rule: response, company: company })
            })
            .catch(err => {
                dispatch({ type: DUPLICATE_RULE_ERROR, error: err })
            })
    }
}

export const duplicateQuickActionLibrary = (data, companyToDuplicateTo) => {

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({
            type: DUPLICATE_QUICK_ACTION,
            status: "pending"
        });
        return rcsdk
            .createQuickActionLibrary(data)
            .then(response => {
                dispatch({
                    type: DUPLICATE_QUICK_ACTION,
                    status: "success",
                    quickActionLibrairy: response,
                    company: companyToDuplicateTo
                });
                data.title.includes("copy") && dispatch({
                    type: CREATE_QUICK_ACTION_LIBRAIRIES_SUCCESS,
                    status: "success",
                    quickActionLibrairy: response
                });
                return response
            }).catch(err => {
                dispatch({
                    type: DUPLICATE_QUICK_ACTION,
                    status: "error",
                    error: err
                });
            })
    }
}

export function duplicateExport(data, originCompanyId, companyToDuplicate) {
    return (dispatch, getState, { rcsdk, rcRPA, rcAlgolia }) => {
        dispatch({ type: DUPLICATE_EXPORT, status: "pending" })
        return rcsdk
            .createExportConfiguration(data)
            .then(response => {
                dispatch(
                    {
                        type: DUPLICATE_EXPORT,
                        export: response,
                        company: companyToDuplicate,
                        status: "success",
                    }
                )
                data.companyId === originCompanyId && dispatch(fetchExportConfigurations(data.companyId))
                return response
            })
            .catch(err => {
                console.log(err)
                dispatch({ type: DUPLICATE_EXPORT, status: "error", error: err })
            })

    }
};


