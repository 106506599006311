import React from 'react';
import styled, {keyframes, css} from 'styled-components';

const ballAnimation = keyframes`
    from {
        transform: translateX(-50%) translateY(0) scaleY(.8);
    }
    to {
        transform: translateX(-50%) translateY(-100%) scaleY(1);
    }
`;

const ball = css`
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scaleY(0.8);
    display: block;
    width: ${props => props.bubbleRadius}px;
    height: ${props => props.bubbleRadius}px;
    border-radius: 50%;
    background: ${props => props.color};
    z-index: 2;
    /*margin-top: 4px;*/
    animation: ${ballAnimation} .45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
`;

const StyledLoading = styled.span`
    display: block;
    position: relative;
    max-width: ${props => (props.bubbleRadius * 5)}px;
    width: 100%;
    white-space: nowrap;
    margin: ${props => props.height / 2}px 0 ${props => props.height / 2}px;

    &::before {
        ${ball}
        margin-left: 0px;
        border: none;
        animation-delay: .15s;
    }

    & span {
        display: block;
        width: 100%;
        height: ${props => props.height};
        position: relative;

        &::before {
            ${ball}
            margin-left: -${props => props.bubbleRadius*2}px;
        }

        &::after {
            ${ball}
            margin-left: ${props => props.bubbleRadius*2}px;
            animation-delay: .3s;
        }
    }
`;

const ChatTyping = ({className, ...rest}) =>  <StyledLoading className={className} {...rest}>
    <span></span>
</StyledLoading>;

ChatTyping.defaultProps = {
    color: 'rgba(191, 202, 216, .5)',
    bubbleRadius: 6,
    height: 17
}

export default ChatTyping;