export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const MARK_ALL_AS_READ = 'MARK_ALL_AS_READ';

export const addNotification = (notification) => {
    return dispatch => {
        dispatch({ type: ADD_NOTIFICATION, notification: notification })
    }
}

export const markAllNotificationsAsRead = () => {
    return dispatch => {
        dispatch({ type: MARK_ALL_AS_READ })
    }
}