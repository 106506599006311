import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { chartColors } from "./ChartCard";
import {Spinner} from 'reactstrap'

const ChartStatusIcon = ({color = "danger", className, ...props}) => {
    return <div className={className} {...props}>
        {color === "pending" ? <Spinner size="sm" className="text-primary"/> : <FontAwesomeIcon icon={["fas", "pennant"]}/>}
    </div>
}
const StyledChartStatusIcon = styled(ChartStatusIcon)`
    .fa-primary {
        fill: #8292a5;
        opacity: 0.5;
    }
    .fa-secondary {
        fill: ${({color}) => color !== "pending" && chartColors[color].main};
        opacity: 0.8;
    }
`;

export default StyledChartStatusIcon;