import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from "classnames";

const Team = ({team, className, showIcon = false, colored = false, ...rest}) => {
    if(!team) return null;
    
    return <div 
        className={classnames({"contact-type": true, [className]: className})}
        style={colored ? {color: team.color} : {}}
        {...rest}
    >
        { showIcon && team.icon && <FontAwesomeIcon icon={["fas", team.icon]} className="mr-50"/> }
        <span>{team.name}</span>
    </div> 
}
export default Team;