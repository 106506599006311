import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'
import customizer from "./customizer/"
import auth from "./auth/"
import user from "./user/"
import wp_integrations from "./wp_integrations/"
import integrations from "./integrations/"
import tickets from "./tickets/"
import ticketTags from "./ticketTags/"
import tags from "./tags/"
import conversations from "./conversations/"
import collections from "./collections/"
import members from "./members/"
import contacts from "./contacts/"
import contactsDrawer from "./contacts/drawer"
import contactGroups from "./contactGroups/"
import agents from "./agents/"
import rules from "./rules/"
import hooks from "./hooks/"
import channels from "./channels/"
import lives from "./lives/"
import app from "./app/"
import error from "./error/"
import deck from "./deck/"
import call from "./call/"
import aircall from "./aircall/"
import notifications from "./notifications/"
import assistant from "./assistant/"
import invitations from "./invitations/"
import dashboards from "./dashboards/"
import company from "./company/"
import companies from "./companies/"
import details from "./details/"
import teams from "./teams/"
import modals from "./modals/"
import toasts from "./toasts/"
import globalSearch from "./globalSearch/"
import quickActions from "./quickActions/"
import timeline from "./timeline"
import faqs from "./faqs/"
import helpText from "./helpText"
import menu from "./menu/"
import acd from "./acd/"
import tokens from "./tokens"
import labels from "./labels"
import collectedDatas from "./collectedDatas"
import logs from "./logs"
import automatheque from "./automatheque"
import billing from "./billing"
import duplication from "./duplication"
import exports from "./export"
import snippets from "./snippets"
import libraries from "./libraries"

const rootReducer = (history) => combineReducers({
  customizer: customizer,
  auth: auth,
  user: user,
  wp_integrations: wp_integrations,
  integrations: integrations,
  tickets: tickets,
  ticketTags: ticketTags,
  tags: tags,
  conversations: conversations,
  members: members,
  contacts: contacts,
  contactsDrawer: contactsDrawer,
  contactGroups: contactGroups,
  agents: agents,
  channels: channels,
  lives: lives,
  app: app,
  error: error,
  deck: deck,
  call: call,
  aircall: aircall,
  assistant: assistant,
  notifications: notifications,
  invitations: invitations,
  dashboards: dashboards,
  company: company,
  companies: companies,
  details: details,
  teams: teams,
  modals: modals,
  toasts: toasts,
  globalSearch: globalSearch,
  quickActions: quickActions,
  collections: collections,
  timeline: timeline,
  faqs: faqs,
  helpText: helpText,
  menu: menu,
  acd: acd,
  rules: rules,
  tokens: tokens,
  labels: labels,
  hooks: hooks,
  collectedDatas: collectedDatas,
  logs: logs,
  automatheque: automatheque,
  billing: billing,
  duplication: duplication,
  exports: exports,
  snippets: snippets,
  libraries: libraries,
  router: connectRouter(history)
})

export default rootReducer
