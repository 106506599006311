import React from 'react'
import { FormattedMessage } from "react-intl";

export const GET_BILLING_SETTINGS = 'GET_BILLING_SETTINGS';
export const UPDATE_BILLING_SETTINGS = 'UPDATE_BILLING_SETTINGS';
export const FLUSH_BILLING_STATUS = 'FLUSH_BILLING_STATUS';

// const tmp = async () => {
//     return {
//         reecall: {
//             logo: '//assets.reecall.co/logos/svg/logo_reecall_2020.svg',
//             address: {
//                 street: '8 Allée du Levant',
//                 city: 'La Tour de Salvagny',
//                 postalCode: '69890',
//                 country: 'France',
//             },
//             siret: 'SIRET',
//             rcs: 'RCS',
//             tva: 'TVA',
//         },
//         paymentFrequencies: [
//             { period: 12, discount: 20 },
//             { period: 6, discount: 5 },
//             { period: 3, discount: 0 },
//             { period: 1, discount: 0 },
//         ],
//         commitment: [
//             {
//                 period: 12,
//                 discount: 10,
//             },
//             {
//                 period: 6,
//                 discount: 5,
//             },
//         ],
//         expires_at: '2021-12-28T00:00:00Z',
//         cpa: "L'abonnement inclus:\n\t- 1 numéro de téléphone Fronce pour la réception des appels (numéro technique, non visible des appelants)\n\t- Paramétrage de scénarios d'appel personnalisés\n\t- Accès au dashboord Reecall\n\t- Assistance de niveau 1 à l'utilisation, aux heures de bureau, sons intervention technique.",
//         cgv: "Ce devis est régi par les conditions générales de vente et d'utilisation disponibles à : https://reecall.com/fr/conditions-generales-de-vente-et-dutilisation-cgvu\nEn signant ce devis, le client déclare avoir lu les CGVU et les accepter sons réserve.\nFacturation â la signature du devis.",
//     }
// }

export function getBillingSettings() {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: GET_BILLING_SETTINGS, status: "pending" });
        return             rcsdk.getBillingSettings()
            .limit(1)
            .order("createdAt DESC")
            .then(data => {
                dispatch({ type: GET_BILLING_SETTINGS, status: "success", settings: data[0] });
            }).catch(err => {
                dispatch({
                    type: GET_BILLING_SETTINGS,
                    status: "error",
                    error: err,
                    errorToast: {
                        type: "ERROR",
                        message: <FormattedMessage id="billing.settings.get.error" defaultMessage="Oups... could not fetch Billing settings. Please try again." />
                    }
                });
            })
    }
}

export function updateBillingSettings(data) {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: UPDATE_BILLING_SETTINGS, status: "pending" });
        return rcsdk.createBillingSetting(data).then(data => {
            dispatch({
                type: UPDATE_BILLING_SETTINGS,
                status: "success",
                settings: data,
                successToast: {
                    type: "FLUSH",
                    message: <FormattedMessage id="billing.settings.update" defaultMessage="Billing settings updated successfully" />
                }
            });
        }).catch(err => dispatch({
            type: UPDATE_BILLING_SETTINGS,
            status: "error",
            error: err,
            errorToast: {
                type: "ERROR",
                message: <FormattedMessage id="billing.settings.update.error" defaultMessage="Oups... could not update Billing settings. Please try again." />
            }
        }))
    }
}