import {
    TOGGLE_CONTACTS_DRAWER,
    SET_CONTACTS_DRAWER_CURRENT_CONTACT,
    SET_CONTACTS_DRAWER_IS_EDITING,
    FETCH_CONTACTS_DRAWER_CONVERSATIONS_PENDING,    
    FETCH_CONTACTS_DRAWER_CONVERSATIONS_SUCCESS,    
    FETCH_CONTACTS_DRAWER_CONVERSATIONS_ERROR,  
    FLUSH_CONTACTS_DRAWER_CONVERSATIONS
} from '../../actions/contacts/drawer';

const initialState = {
    show: false,
    contactId: null,
    isEditing: false,
    conversationsList: [],
    conversationsPending: false,
    error: null
}

const contactsDrawerReducer = (state = initialState, action) => {
    switch(action.type) {

        case SET_CONTACTS_DRAWER_IS_EDITING:
            return {
                ...state,
                isEditing: action?.isEditing
            }

        case SET_CONTACTS_DRAWER_CURRENT_CONTACT:
            return {
                ...state,
                contactId: action?.contactId
            }

        case TOGGLE_CONTACTS_DRAWER:
            return {
                ...state,
                show: action?.show || !state.show
            }

        case FETCH_CONTACTS_DRAWER_CONVERSATIONS_PENDING: 
            return {
                ...state,
                conversationPending: true
            }

        case FETCH_CONTACTS_DRAWER_CONVERSATIONS_SUCCESS:
            return {
                ...state,
                conversationPending: false,
                conversationsList: [...state.conversationsList, ...action.conversations],
                conversationsTotal: action.total
            }

        case FETCH_CONTACTS_DRAWER_CONVERSATIONS_ERROR:
            return {
                ...state,
                conversationPending: false,
                error: action.error
            }

        case FLUSH_CONTACTS_DRAWER_CONVERSATIONS:
            return {
                ...state,
                conversationsList: []
            }

        default: 
            return state;
    }
}
export default contactsDrawerReducer;