import {
    FETCH_CONVERSATIONS_DETAILS,
    FETCH_CONVERSATIONS_DETAILS_24H,
    FETCH_TICKETS_DETAILS,
    FETCH_TICKETS_DETAILS_24H,
    FETCH_CONTACTS_DETAILS,
    FETCH_CONTACTS_DETAILS_24H,
    FETCH_LAST_5_CONVERSATIONS,
    FETCH_LAST_5_TICKETS,
    SET_PREVIOUS_URL,
    FETCH_MEMBERS_TOTAL,
    FETCH_CHANNELS_TOTAL,
    FETCH_AGENTS_TOTAL,
    FETCH_INVITATIONS_TOTAL,
    FETCH_COMPANIES_TOTAL,
    FETCH_CONVERSATIONS_DETAILS_CHART,
    FETCH_TICKETS_DETAILS_CHART,
    FETCH_CONTACTS_DETAILS_CHART,
    FLUSH_DETAILS_CHART,
    COUNT_FAQS,
    COUNT_COLLECTIONS,
    FLUSH_INVITATIONS_STATUS,
    FETCH_DECKS_TOTAL,
    FETCH_RULES_TOTAL,
    FETCH_TAGS_TOTAL,
    FETCH_INTEGRATION_CONNECTION_TOTAL,
    FLUSH_TOTALS
} from '../../actions/details';

const initialState = {
    details: { conversations: { conversations: [], pending: null, total: 0 }, tickets: { tickets: [], pending: null, total: 0 }, contacts: { contacts: [], pending: null, total: 0 } },
    last24h: { conversations: { conversations: [], pending: null, total: 0 }, tickets: { tickets: [], pending: null, total: 0 }, contacts: { contacts: [], pending: null, total: 0 } },
    lastConversations: { conversations: [], total: 0 },
    lastTickets: { tickets: [], total: 0 },
    previousUrl: "",
    membersTotal: { total: null, pending: null },
    channelsTotal: { total: null, pending: null },
    agentsTotal: { total: null, pending: null },
    companiesTotal: { total: null, pending: null },
    decksTotal: { total: null, pending: null },
    rulesTotal: { total: null, pending: null },
    tagsTotal: { total: null, pending: null },
    integrationConnectionTotal: { total: null, pending: null },
    invitations: { total: null, pending: null },
    conversationsChartData: {},
    ticketsChartData: {},
    contactsChartData: {},
    totalFaqs: "pending",
    totalCollections: "pending",
}

const detailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case COUNT_FAQS:
            return {
                ...state,
                totalFaqs: action?.status === "pending" ? "pending" : action?.error ? "error" : action.total
            }
        case COUNT_COLLECTIONS:
            return {
                ...state,
                totalCollections: action?.status === "pending" ? "pending" : action?.error ? "error" : action.total
            }
        case FLUSH_DETAILS_CHART:
            return {
                ...state,
                convsChartData: {},
                ticketsChartData: {},
                contactsChartData: {}
            };
        case FETCH_TICKETS_DETAILS_CHART:
            return {
                ...state,
                ticketsChartData: { data: action.data, dates: action.dates }
            };
        case FETCH_CONTACTS_DETAILS_CHART:
            return {
                ...state,
                contactsChartData: { data: action.data, dates: action.dates }
            };
        case FETCH_CONVERSATIONS_DETAILS_CHART:
            return {
                ...state,
                conversationsChartData: { data: action.data, dates: action.dates }
            };
        case FETCH_COMPANIES_TOTAL:
            return {
                ...state,
                companiesTotal: { total: action.total, pending: action.status }
            };
        case FETCH_AGENTS_TOTAL:
            return {
                ...state,
                agentsTotal: { total: action.total, pending: action.status }
            };
        case FETCH_CHANNELS_TOTAL:
            return {
                ...state,
                channelsTotal: { total: action.total, pending: action.status }
            };
        case FETCH_MEMBERS_TOTAL:
            return {
                ...state,
                membersTotal: { total: action.total, pending: action.status },
            };
        case FETCH_DECKS_TOTAL:
            return {
                ...state,
                decksTotal: { total: action.total, pending: action.status },
            };
        case FETCH_RULES_TOTAL:
            return {
                ...state,
                rulesTotal: { total: action.total, pending: action.status },
            };
        case FETCH_TAGS_TOTAL:
            return {
                ...state,
                tagsTotal: { total: action.total, pending: action.status },
            };
        case FETCH_INTEGRATION_CONNECTION_TOTAL:
            return {
                ...state,
                integrationConnectionTotal: { total: action.total, pending: action.status },
            };
        case SET_PREVIOUS_URL:
            return {
                ...state,
                previousUrl: action.url,
            };
        case FETCH_LAST_5_CONVERSATIONS:
            return {
                ...state,
                lastConversations: action.status === "success" ? { conversations: action.conversations, total: action.conversations.length } : { ...state.lastConversations },
            };
        case FETCH_LAST_5_TICKETS:
            return {
                ...state,
                lastTickets: action.status === "success" ? { tickets: action.tickets, total: action.tickets.length } : { ...state.lastTickets },
            };
        case FETCH_CONVERSATIONS_DETAILS:
            return {
                ...state,
                details: { ...state.details, conversations: { conversations: action.conversations, pending: action.status, total: action.status === "success" ? action.total : state.details.conversations.total } },
            };
        case FETCH_CONVERSATIONS_DETAILS_24H:
            return {
                ...state,
                details: { ...state.details },
                last24h: { ...state.last24h, conversations: { conversations: action.conversations, pending: action.status, total: action.status === "success" ? action.total : state.details.conversations.total } }
            };
        case FETCH_TICKETS_DETAILS:
            return {
                ...state,
                details: { ...state.details, tickets: { tickets: action.tickets, pending: action.status, total: action.status === "success" ? action.total : state.details.tickets.total } },
            };
        case FETCH_TICKETS_DETAILS_24H:
            return {
                ...state,
                details: { ...state.details },
                last24h: { ...state.last24h, tickets: { tickets: action.tickets, pending: action.status, total: action.status === "success" ? action.total : state.details.tickets.total } }
            };
        case FETCH_CONTACTS_DETAILS:
            return {
                ...state,
                details: { ...state.details, contacts: { contacts: action.contacts, pending: action.status, total: action.status === "success" ? action.total : state.details.contacts.total } },
            };
        case FETCH_CONTACTS_DETAILS_24H:
            return {
                ...state,
                details: { ...state.details },
                last24h: { ...state.last24h, contacts: { contacts: action.contacts, pending: action.status, total: action.status === "success" ? action.total : state.details.contacts.total } }
            };
        case FETCH_INVITATIONS_TOTAL:
            return {
                ...state,
                invitations: { total: action.total, pending: action.status }
            }
        case FLUSH_INVITATIONS_STATUS:
            return {
                ...state,
                invitations: { total: state.invitations.total, pending: null }
            }
        case FLUSH_TOTALS:
            return {
                ...state,
                membersTotal: { total: null, pending: null },
                channelsTotal: { total: null, pending: null },
                agentsTotal: { total: null, pending: null },
                companiesTotal: { total: null, pending: null },
                decksTotal: { total: null, pending: null },
                rulesTotal: { total: null, pending: null },
                tagsTotal: { total: null, pending: null },
                integrationConnectionTotal: { total: null, pending: null },
                invitations: { total: null, pending: null },
            }
        default:
            return state;
    }
}

export default detailsReducer;