import mock from "../mock"
import { v4 as uuid } from 'uuid';

const dataAgents = [
	{
		id: "brigitte",
		name: "Brigitte",
    rank: "senior",
    channels: [],
    tags: ["RH", "SAV"]
  },
  {
    id: uuid(),
    name: "Jean",
    rank: "junior",
    channels: [],
    tags: ["RH"]
  },
  {
    id: uuid(),
    name: "ThiBOT",
    rank: "senior",
    channels: [],
    tags: ["Comptabilité"]
  },
  {
    id: uuid(),
    name: "Mathieu",
    rank: "junior",
    channels: []
  }
]

export default dataAgents;

const getAgentByChannelId = (chanId) => {
  if(chanId){
    const agentsList = dataAgents.find(agent => {
      console.log(agent, "agent");
      if(agent.channels.find(chan => chan.id === chanId)){
        return agent;
      }
      return null
    });

    return agentsList;
  }
}

export {getAgentByChannelId};

// GET : AGENT By ID
mock.onGet("/api/agent").reply(request => {
    const id = parseInt(request?.params?.id) || null;
    if(id){
      const currentAgent = dataAgents.find(agent => {
        if(agent.id === id){
          return agent;
        }
        return null
      });

      return [200, currentAgent]
    }
    return [500];
});

// GET : AGENT
mock.onGet("/api/agents").reply(request => {
    return [200, dataAgents];
});