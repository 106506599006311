import axios from 'axios';

// const WpURI = `http://localhost:8888/reecall_website_v3/`;
const WpURI = `https://reecallblogpreprod.reecall.co/`;
const WpRESTPath = `wp-json/wp/v2/`;
const WpIntegrationsPath = `integrations`;
const integrationsMainPageWpId = 510;

export const FETCH_WPINTEGRATIONS_PENDING = 'FETCH_WPINTEGRATIONS_PENDING';
export const FETCH_WPINTEGRATIONS_SUCCESS = 'FETCH_WPINTEGRATIONS_SUCCESS';
export const FETCH_WPINTEGRATIONS_ERROR = 'FETCH_WPINTEGRATIONS_ERROR';


export const FETCH_WPINTEGRATION_PAGE_PENDING = 'FETCH_WPINTEGRATION_PAGE_PENDING';
export const FETCH_WPINTEGRATION_PAGE_SUCCESS = 'FETCH_WPINTEGRATION_PAGE_SUCCESS';
export const FETCH_WPINTEGRATION_PAGE_ERROR = 'FETCH_WPINTEGRATION_PAGE_ERROR';

function fetchIntegrationsPending() {
    return {
        type: FETCH_WPINTEGRATIONS_PENDING
    }
}

function fetchIntegrationsSuccess(integrations) {
    return {
        type: FETCH_WPINTEGRATIONS_SUCCESS,
        integrations: integrations
    }
}

function fetchIntegrationsError(error) {
    return {
        type: FETCH_WPINTEGRATIONS_ERROR,
        error: error
    }
}

export const fetchIntegrations = (lang, id = null) => {
    return dispatch => {
        dispatch(fetchIntegrationsPending());
		return axios.get(`${WpURI}${lang === 'fr' ? '' : `${lang}/`}${WpRESTPath}${WpIntegrationsPath}?_embed${id ? `&include[]=${id}`: ''}`)
       	.then(({ data }) => {
	        dispatch(fetchIntegrationsSuccess(data));
	    }).catch(err => {
        	dispatch(fetchIntegrationsError(err.message));
	    })
    }
}


function fetchIntegrationPagePending() {
    return {
        type: FETCH_WPINTEGRATION_PAGE_PENDING
    }
}

function fetchIntegrationPageSuccess(integration_page) {
    return {
        type: FETCH_WPINTEGRATION_PAGE_SUCCESS,
        integration_page: integration_page
    }
}

function fetchIntegrationPageError(error) {
    return {
        type: FETCH_WPINTEGRATION_PAGE_ERROR,
        error: error
    }
}

export const fetchIntegrationMainPageInfos = (lang) => {
    return dispatch => {
        dispatch(fetchIntegrationPagePending());
        return axios.get(`${WpURI}${lang === 'fr' ? '' : `${lang}/`}${WpRESTPath}pages?_embed&include[]=${integrationsMainPageWpId}`)
        .then(({ data }) => {
            const meaArticle = data?.[0]?.acf?.mea_article_or_page;
            if(meaArticle){
                return axios.get(`${WpURI}${WpRESTPath}${meaArticle.post_type}?_embed&include[]=${data?.[0]?.acf?.mea_article_or_page?.ID}`).then(mea => {
                    dispatch(fetchIntegrationPageSuccess({...data, mea_object: mea.data?.[0]}));
                }).catch(err => {
                    dispatch(fetchIntegrationPageError(err.message));
                })
            } else {
                dispatch(fetchIntegrationPageSuccess(data));
            }
        }).catch(err => {
            dispatch(fetchIntegrationPageError(err.message));
        })
    }
}