import {
    FETCH_COMPANY_PENDING,
    FETCH_COMPANY_SUCCESS,
    FETCH_COMPANY_ERROR,
    FETCH_COMPANY_SETTINGS,
    UPDATE_COMPANY_SETTINGS,
    FLUSH_UPDATE_COMPANY_SETTINGS,
    FETCH_COMPANY,
    UPDATE_COMPANY_LOGO,
    UPDATE_COMPANY,
    FLUSH_COMPANY,
    SET_COMPANY_REQUEST_STATUS,
    CREATE_COMPANY,
    FLUSH_CREATE_COMPANY,
    FETCH_ALL_SETTINGS,
    DELETE_COMPANY,
    FLUSH_COMPANY_DELETION,
    FLUSH_COMPANY_SETTINGS_STATUS,
    FLUSH_UPDATE_STATUS,
    EXPORT_COMPANY_DATA,
    FLUSH_EXPORT_DATA,
    SET_COMPANY_SETTINGS_TAB,
    FETCH_STRIPE_CUSTOMERS
} from '../../actions/company';

const initialState = {
    pending: false,
    company: null,
    companySettings: null,
    companySettingsStatus: null,
    updateCompanySettingsStatus: null,
    updateCompanyStatus: null,
    error: null,
    companyCreationSteps: [],
    creationCompleted: false,
    allSettings: [],
    allSettingsStatus: null,
    deletionStatus: null,
    exportCompanyDataStatus: null,
    companySettingsTab: null,
    stripeCustomers: [],
    getStripeCustomersPending: false,
}

const companyReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_STRIPE_CUSTOMERS:
            return {
                ...state,
                stripeCustomers: action.status === "success" ? action.stripeCustomers : state.stripeCustomers,
                getStripeCustomersPending: action.status === "pending"
            }

        case SET_COMPANY_SETTINGS_TAB:
            return {
                ...state,
                companySettingsTab: action.tab,
            }

        case EXPORT_COMPANY_DATA:
            return {
                ...state,
                exportCompanyDataStatus: action.status,
            }

        case FLUSH_EXPORT_DATA:
            return {
                ...state,
                exportCompanyDataStatus: null,
            }

        case FLUSH_CREATE_COMPANY:
            return {
                ...state,
                companyCreationSteps: [],
                creationCompleted: false
            }

        case FLUSH_UPDATE_STATUS:
            return {
                ...state,
                pending: false,
                updateCompanySettingsStatus: null,
            }

        case FETCH_ALL_SETTINGS:
            return {
                ...state,
                allSettings: action.settings,
                allSettingsStatus: action.status
            }

        case CREATE_COMPANY:
            return {
                ...state,
                companyCreationSteps: [...state.companyCreationSteps.filter(step => step.step !== action.step), { step: action.step, status: action.status, target: action.target }],
                company: action.company,
                creationCompleted: action.creationCompleted
            }
        case UPDATE_COMPANY_LOGO:
            return {
                ...state,
                pending: action.status,
                company: action.company,
                error: action.error
            }
        case UPDATE_COMPANY:
            return {
                ...state,
                pending: action.status,
                company: action.company,
                error: action.error
            }
        case SET_COMPANY_REQUEST_STATUS:
            return {
                ...state,
                pending: action.requestStatus,
            }
        case FETCH_COMPANY_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_COMPANY_SUCCESS:
            return {
                ...state,
                pending: false,
                company: action.company
            }
        case FETCH_COMPANY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case FETCH_COMPANY:
            return {
                ...state,
                pending: false,
                company: action.company,
            }
        case FETCH_COMPANY_SETTINGS:
            return {
                ...state,
                companySettings: action.companySettings,
                companySettingsStatus: "success"
            }
        case FLUSH_COMPANY_SETTINGS_STATUS:
            return {
                ...state,
                // companySettings: action.companySettings,
                companySettingsStatus: null
            }

        case UPDATE_COMPANY_SETTINGS:
            return {
                ...state,
                companySettings: action.companySettings,
                updateCompanySettingsStatus: "success",
                allSettings: [...state.allSettings.filter(setting => setting.companyId !== action.companySettings.companyId), action.companySettings]
            }

        case FLUSH_UPDATE_COMPANY_SETTINGS:
            return {
                ...state,
                updateCompanySettingsStatus: null
            }

        case FLUSH_COMPANY:
            return {
                ...state,
                company: null,
            }

        case FLUSH_COMPANY_DELETION:
            return {
                ...state,
                deletionStatus: null
            }

        case DELETE_COMPANY:
            return {
                ...state,
                deletionStatus: action.status,
            }

        default:
            return state;
    }
}
export default companyReducer;