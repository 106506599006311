import {TOGGLE_PHONE} from '../../actions/aircall';

const initialState = {
    isVisible  : false,
    isInCall   : false
}

const AircallReducer = (state = initialState, action) => {
    switch(action.type) {
        case TOGGLE_PHONE:
            return {
                ...state,
                isVisible: action.show || !state.isVisible
            }
        default: 
            return state;
    }
}
export default AircallReducer;