import React, {useRef} from 'react';
import classnames from "classnames";
import Player from "./Player";
import "../../../assets/scss/components/audio/circleControls.scss"

const getCircleCenterCoords = ({x, y, width}) => {
  const radius = width / 2;
  return {x: x + radius, y: y + radius};
};

const getRotationForPoint = (vertex, point) => {
  // Probably better way to name and approach these
  // My high school trigonometry knowledge is fuzzy, please help!
  const adjacent = vertex.y - point.y;
  const opposite = point.x - vertex.x;
  const centralAngle = Math.atan(opposite / adjacent);
  const mod = point.y > vertex.y ? Math.PI : 2 * Math.PI;
  const rotation = (centralAngle + mod) > 2 * Math.PI ? centralAngle : centralAngle + mod;
  return rotation / (2 * Math.PI);
};

const getDefaultLabels = playing => ({
  PLAY_BUTTON: playing ? 'Pause' : 'Play'
});

// eslint-disable-next-line react/prop-types
const PlayIcon = ({playing}) => <span className={`rpcc-play-icon${playing ? ' pause' : ''}`}/>;

const CircleControls = ({
  ariaLabels,
  color = "primary",
  icon,
  loaded = 0,
  played = 0,
  playing,
  size = "sm",
  onSeek,
  onTogglePlaying,
  showRing = true,
  className = null
}) => {
  const playerRef = useRef(null);
  const buttonRef = useRef(null);
  const labels = ariaLabels || getDefaultLabels(playing, played);
  const vars = {
    '--rpcc-progress-loaded': loaded,
    '--rpcc-progress-played': played,
  };

  const onSeekClick = e => {
    if (buttonRef.current && buttonRef.current.contains(e.target)) {
      return;
    }

    const point = {x: e.clientX, y: e.clientY};
    const vertex = getCircleCenterCoords(playerRef.current.getBoundingClientRect());
    onSeek(getRotationForPoint(vertex, point));
  };

  return (
    <div 
        className={classnames({
            "rpcc-player": true,
            [`rpcc-player--${size}`]: true,
            [`rpcc-player--${color}`]: true,
            [`rpcc-player--no-ring`]: !showRing,
            [className]: className
        })}
        style={vars}
        onClick={showRing ? onSeek && onSeekClick : () => {}}
    >
        <div ref={playerRef} className="rpcc-player-inner">
            {showRing && <svg className="rpcc-ring-container">
                <circle shapeRendering="geometricPrecision" className="rpcc-ring rpcc-ring__duration"/>
                <circle shapeRendering="geometricPrecision" className="rpcc-ring rpcc-ring__loaded"/>
                <circle shapeRendering="geometricPrecision" className="rpcc-ring rpcc-ring__played"/>
            </svg>}
            <button
                ref={buttonRef}
                type="button"
                className="rpcc-play-button"
                aria-label={labels.PLAY_BUTTON}
                onClick={() => {
                  // if(loaded === 1){
                    onTogglePlaying()
                  // }
                }}
            >
                {icon || <PlayIcon playing={playing}/>}
            </button>
        </div>
    </div>
  );
};

CircleControls.defaultProps = {
    ariaLabels: null,
    icon: null,
    loaded: 0,
    played: 0,
    playing: false,
    size: "sm",
    onSeek: null,
    onTogglePlaying: null,
    color: "primary",
    showRing: true,
    className: null
};

export {CircleControls};


const CirclePlayer = ({url, size, color, showRing, className}) => {
  return <div className={classnames({[className]: className})}>

    <Player 
      url={url}
    >
      <CircleControls
        size={size}
        color={color}
        showRing={showRing}
      />
    </Player>

  </div>
}


CirclePlayer.defaultProps = {
  url: null,
  size: "sm",
  className: null,
  color: "primary",
  showRing: true
}

export default CirclePlayer;