import axios from 'axios';
import { getUrlByInfra } from '../../../configs/constants';

export const FETCH_LOGS = 'FETCH_LOGS';

export const fetchLogs = (companyId, processId) => {
    const query = processId ? `?process_id=${processId}` : companyId ? `?company_id=${companyId}` : ""
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        const company = getState().company?.company
        const infra = company?.id === companyId ? company?.companySetting?.[0]?.value?.infra : 'production'
        dispatch({ type: FETCH_LOGS, status: "pending" });
        return Promise.all([
            axios.get(`${getUrlByInfra('rpa', infra)}/audits${query}`),
        ]).then((res) => {
            dispatch({ type: FETCH_LOGS, logs: res[0].data, status: "success" });
        }).catch(err => dispatch({ type: FETCH_LOGS, error: err, status: "error" }));

    }
}