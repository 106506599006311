import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Form,
  Button,
  Collapse
} from "reactstrap"
import { FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { Search, X, Calendar, Folder } from "react-feather";
import Select from 'react-select';
import "../../../assets/scss/plugins/forms/react-select/_react-select.scss"

const ListSearch = ({ filterOptions, searchTerm, onChange, onSubmit, filterConfig, className, inputProps, intl }) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(filterOptions?.[0] || null);
  const [tempSearchTerm, setTempSearchTerm] = useState("");

  useEffect(() => {
    if (filterOptions && searchTerm?.field) {
      let currentFilter = filterOptions?.find(el => el.value === searchTerm.field);
      setSelectedFilter(currentFilter)
    }
  }, [filterOptions, searchTerm]);

  useEffect(() => {
    if (typeof searchTerm === 'object' && searchTerm !== null) {
      if (searchTerm.field === "") setSelectedFilter(filterOptions?.[0])
      // if (searchTerm?.search !== "") {
        setTempSearchTerm(searchTerm.search);
      // }
    } else if (searchTerm) {
      setTempSearchTerm(searchTerm);
    }
  }, [searchTerm, filterOptions])

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 100 }),
    option: (provided, state) => ({
      ...provided,
      border: 'none'
    }),
    container: base => ({
      ...base,
      width: '130px',
      fontSize: "0.85rem"
    }),
    menu: base => ({
      ...base,
      fontSize: "0.85rem",
      overflow: "hidden",
    }),
    control: base => ({
      ...base,
      border: 'none'
    }),
    singleValue: base => ({
      ...base,
      maxWidth: "calc(100% - 28px)"
    }),
    valueContainer: base => ({
      ...base,
      padding: "8px 1rem"
    }),
    menuList: base => ({
      ...base,
      paddingTop: "0px",
      paddingBottom: "0px"
    })
  }

  return <div
    className={`list-search ${filterConfig.show ? "withFilters" : ""} ${filterConfig.currents && filterConfig.currents.length > 0 ? "has-filters" : ""} ${filtersOpen ? "filters-open" : ""} ${className}`}
    style={{ display: "flex" }}
  >

    {
      filterOptions && <div className="d-flex align-items-center">
        <Select
          options={filterOptions}
          styles={customStyles}
          menuPortalTarget={document.body}
          onChange={setSelectedFilter}
          placeholder={filterOptions[0].label}
          className="React-Select list-filters"
          classNamePrefix="select"
          isSearchable={false}
          value={filterOptions.filter(option => option.value === selectedFilter?.value)}
        />
      </div>
    }

    <Form className="list-searchForm d-flex w-100" onSubmit={(e) => {
      e.preventDefault();
      if (selectedFilter) {
        onSubmit({ field: selectedFilter.value, search: tempSearchTerm });
      } else {
        onSubmit(tempSearchTerm);
      }
      return false;
    }}>

      <FormGroup className="has-icon-left position-relative mb-0 w-100">

        {
          filterConfig.currents && filterConfig.currents.length > 0 && <div className="filters-list d-none-md">
            {
              filterConfig.currents.map(filter => <div className="filters-list-filter" key={`tag-filter-${filter.name}-${filter.value}`}>
                <span className="filter-icon">
                  {({
                    "org": <Folder size="13" />,
                    "date": <Calendar size="13" />,
                  })[filter.type]}
                </span>
                <span className="filter-label">{filter.label}</span>
                <span className="filter-remove" onClick={() => filterConfig.onRemove(filter.name, filter.value)}>
                  <X size="13" />
                </span>
              </div>)
            }
          </div>
        }

        <Input
          type="search"
          placeholder={intl.formatMessage({ id: "global.placeholder.search", defaultMessage: "Search" })}
          bsSize="md"
          autoComplete="off"
          value={tempSearchTerm}
          onChange={(e) => {
            setTempSearchTerm(e.currentTarget.value);
            onChange({field: selectedFilter?.value, search: e.currentTarget.value});
            // onSubmit(e.currentTarget.value);
          }}
          {...inputProps}
        />
        <div className="form-control-position icon-left">
          <Search size={15} />
        </div>

        <Button
          color="primary"
          size="sm"
          outline
          className="button-search mr-1"
          type="submit"
        >
          <FormattedMessage id="list.search" defaultMessage="Search" />
        </Button>

        {
          filterConfig.show && <Button
            color="primary"
            outline
            onClick={e => {
              e.stopPropagation();
              setFiltersOpen(!filtersOpen);
            }}
            className={`icon-right ${filtersOpen ? "actif" : ""}`}
          >
            <FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 15 }} />
          </Button>
        }
      </FormGroup>
    </Form>

    {
      filterConfig.show && <div className="list-filters">
        <Collapse isOpen={filtersOpen}>
          <Card className="mb-0">
            <CardBody className="pt-1 pb-2">
              {filterConfig.renderForm()}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    }
  </div>
}

ListSearch.defaultProps = {
  searchTerm: "",
  onChange: () => { },
  onSubmit: () => { },
  filterConfig: {
    show: true,
    renderForm: () => { },
    currents: [],
    onRemove: (name, value) => { }
  },
  className: 'mb-1',
  inputProps: {
    name: "search",
    id: "search"
  },
  filterOptions: null
}

export default injectIntl(ListSearch);