import {
    GET_LABELS,
    GET_MODELS,
    GET_LABELS_DATA,
    CREATE_LABEL,
    CREATE_CLASSIFIER,
    CREATE_DATA,
    FLUSH_ALL_CREATION_STATUS,
    PATCH_DATA,
    SET_ACTIVATION,
    EDIT_LABEL,
    SET_INHERITANCE,
    DELETE_LABEL,
    DELETE_CLASSIFIER,
    FAQ_CONFLICT,
    FLUSH_FAQ_CONFLICT
} from "../../actions/labels";


const initialState = {
    labels: [],
    fetchLabelsStatus: null,
    createLabelStatus: null,
    patchLabelStatus: null,
    models: [],
    fetchModelsStatus: null,
    createModelStatus: null,
    deleteModelStatus: null,
    data: [],
    faqConflictsData: {},
    patchDataStatus: null,
    fetchDataStatus: null,
    createDataStatus: null,
    deleteLabelStatus: null
}

const LabelsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FAQ_CONFLICT:
            return {
                ...state,
                faqConflictsData: action,
            }
        case FLUSH_FAQ_CONFLICT:
            return {
                ...state,
                faqConflictsData: {},
            }
        case FLUSH_ALL_CREATION_STATUS:
            return {
                ...state,
                createLabelStatus: null,
                createModelsStatus: null,
                createDataStatus: null,
                patchLabelStatus: null,
                deleteLabelStatus: null,
                deleteModelStatus: null
            }
        case CREATE_DATA:
            return {
                ...state,
                data: action.status === "success" ? [...state.data.filter(el => !action.data.map(e => e.id).includes(el.id)), ...action.data] : state.data,
                createDataStatus: action.status
            }
        case PATCH_DATA:
            return {
                ...state,
                data: action.status === "success" ? [...state.data.filter(el => !action.data.map(e => e.id).includes(el.id)), ...action.data] : state.data,
                patchDataStatus: action.status
            }
        case CREATE_LABEL:
            return {
                ...state,
                labels: action.status === "success" ? [...state.labels, action.data] : state.labels,
                createLabelStatus: action.status
            }
        case DELETE_LABEL:
            return {
                ...state,
                deleteLabelStatus: action.status,
                labels: action.status === "success" ? [...state.labels.filter(label=>label.id !== action.id)] : state.labels,
            }
        case DELETE_CLASSIFIER:
            return {
                ...state,
                deleteModelStatus: action.status,
                models: action.status === "success" ? [...state.models.filter(model=>model.id !== action.id)] : state.models,
            }
        case SET_INHERITANCE:
            return {
                ...state,
                patchLabelStatus: action.status
            }
        case SET_ACTIVATION:
            return {
                ...state,
                patchLabelStatus: action.status
            }
        case EDIT_LABEL:
            return {
                ...state,
                patchLabelStatus: action.status
            }
        case CREATE_CLASSIFIER:
            return {
                ...state,
                createModelStatus: action.status
            }
        case GET_LABELS:
            return {
                ...state,
                labels: action.labels ? action.labels : state.labels,
                fetchLabelsStatus: action.status
            }
        case GET_MODELS:
            return {
                ...state,
                models: action.models ? action.models : state.models,
                fetchModelsStatus: action.status
            }
        case GET_LABELS_DATA:
            return {
                ...state,
                data: action.data ? action.data : state.data,
                fetchDataStatus: action.status
            }
        default:
            return state;
    }
}
export default LabelsReducer;
