export const TOGGLE_GLOBAL_SEARCH = "TOGGLE_GLOBAL_SEARCH";
export const GLOBAL_SEARCH_SET_SELECTED_VALUES = "GLOBAL_SEARCH_SET_SELECTED_VALUES";
export const FETCH_COMPANIES_GLOBAL_SEARCH = "FETCH_COMPANIES_GLOBAL_SEARCH";
export const FETCH_AGENTS_GLOBAL_SEARCH = "FETCH_AGENTS_GLOBAL_SEARCH";
export const FETCH_CHANNELS_GLOBAL_SEARCH = "FETCH_CHANNELS_GLOBAL_SEARCH";
export const FETCH_RULES_GLOBAL_SEARCH = "FETCH_RULES_GLOBAL_SEARCH";
export const FETCH_ACTIONS_GLOBAL_SEARCH = "FETCH_ACTIONS_GLOBAL_SEARCH";
export const FETCH_TAGS_GLOBAL_SEARCH = "FETCH_TAGS_GLOBAL_SEARCH";
export const GLOBAL_SEARCH_IN_SEARCH = "GLOBAL_SEARCH_IN_SEARCH";
export const FLUSH_GLOBAL_SEARCH = "FLUSH_GLOBAL_SEARCH";

export function fetchCompaniesGlobalSearch(
  searchTerm,
  order,
  flush,
  selectedFilter
) {
  const where = () => {
    switch (searchTerm.field) {
      case "":
        if (selectedFilter && selectedFilter.value !== "all")
          return {
            "plan.status": {
              inq: [selectedFilter.value],
            },
          };
        else return {};
      case "name":
        if (selectedFilter && selectedFilter.value !== "all")
          return {
            and: [
              {
                "plan.status": {
                  inq: [selectedFilter.value],
                },
              },
              {
                or: [
                  { id: searchTerm.search },
                  { name: { like: `.*${searchTerm.search}.*`, options: "i" } },
                ],
              },
            ],
          };
        else
          return {
            or: [
              { id: searchTerm.search },
              { name: { like: `.*${searchTerm.search}.*`, options: "i" } },
            ],
          };
      // case "id":
      //     if (selectedFilter && selectedFilter.value !== "all") return {
      //         "and": [
      //             {
      //                 'plan.status': {
      //                     inq: [selectedFilter.value]
      //                 }
      //             },
      //             { "id": { "inq": searchTerm.search } }
      //         ]
      //     }
      //     else return { "id": { "inq": searchTerm.search } }
      default:
        break;
    }
  };

  return (dispatch, getState, { rcsdk, rcAlgolia }) => {
    dispatch({ type: FETCH_COMPANIES_GLOBAL_SEARCH, status: "pending" });
    return rcsdk
      .getCompanies()
      .where(where())
      .order(order)
      .then((response) => {
        dispatch({
          type: FETCH_COMPANIES_GLOBAL_SEARCH,
          companies: response,
          status: "success",
        });
      })
      .catch((err) =>
        dispatch({
          type: FETCH_COMPANIES_GLOBAL_SEARCH,
          status: "error",
          error: err,
        })
      );
  };
}

export const fetchElementsGlobalSearch = (searchTerm, category, companyId) => {
  const search = () => {
    if (searchTerm?.search?.length === 0) return;
    else {
      const obj = {
          or: [
            { name: { like: `.*${searchTerm.search}.*`, options: "i" } },
            { title: { like: `.*${searchTerm.search}.*`, options: "i" } },
            { id: searchTerm.search },
          ],
        };
      if (companyId) return { "and": [{ "companyId": { "eq": companyId } }, obj] }
      return obj
    }
  };
  return (dispatch, getState, { rcsdk, rcAlgolia }) => {
    let method;
    let type;
    let include = [{
      relation: "company", // include the owner object
      scope: {
        // further filter the owner object
        fields: ["settings", "name"], // only show two fields
      },
    }];
    let fields = ["id", "name", "companyId", "title"];

    switch (category) {
      case "channels":
        method = rcsdk.getChannels();
        type = FETCH_CHANNELS_GLOBAL_SEARCH;
        break;
      case "agents":
        method = rcsdk.getAgents();
        type = FETCH_AGENTS_GLOBAL_SEARCH;
        break;
      case "rules":
        method = rcsdk.getRules();
        type = FETCH_RULES_GLOBAL_SEARCH;
        break;
      case "quickActions":
        method = rcsdk.getQuickActionLibraries();
        type = FETCH_ACTIONS_GLOBAL_SEARCH;
        break;
      case "tags":
        method = rcsdk.getTags();
        type = FETCH_TAGS_GLOBAL_SEARCH;
        include = [...include, {
          relation: "parentTag", // include the owner object
          scope: {
            // further filter the owner object
            fields: ["name"], // only show those fields
          },
        }];
        fields = [...fields, "parentTagId", "isSmart", "color"]
        break;
      default:
        break;
    }

    dispatch({ type: type, status: "pending" });
    return method
      .include(include)
      .where(searchTerm && search())
      .fields(fields)
      .then((response) => {
        dispatch({
          type: type,
          [category]: response,
          status: "success",
        });
      })
      .catch((err) => {
        dispatch({ type: type, status: "pending" });
      });
  };
};
