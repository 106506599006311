import mock from "../mock"

const collectedDatas = [
  {
    label: "Nom de l'interlocuteur",
    value: "Mathieu Gréard",
    original: "Je m'appele Mathieu Gréart",
    guess: true
  },
  {
    label: "Date de naissance",
    value: "23/06/1987",
    original: "23/06/1987",
    validated: true
  },
  {
    label: "Numero de dossier",
    value: "1H32BdsQDdf2123s",
    original: "1H32BdsQDdf2123s"
  }
];

const historyDatas = [{
    "time": "2019-10-30T08:30:59.135Z",
    "type": "message",
    "from": "bot",
    "message": "Bonjour, je suis Test, l'assistant vocal de Reecall.  en quoi puis-je vous être utile ?"
  },
  {
    "time": "2019-10-30T08:31:09.369Z",
    "type": "message",
    "from": "user",
    "message": "Bonjour, je souhaiterais parler à mathieu"
  },
  {
    "time": "2019-10-30T08:31:09.532Z",
    "type": "message",
    "from": "bot",
    "message": "Quelle est la raison de votre appel ?"
  },
  {
    "time": "2019-10-30T08:31:25.884Z",
    "type": "message",
    "from": "user",
    "message": "prise de rendez-vous téléphonique"
  },
  {
    "time": "2019-10-30T08:31:26.120Z",
    "type": "message",
    "from": "bot",
    "message": "Entendu j'ai pris note.  Si nous devons vous rappeler, quel est le meilleur créneau ?"
  },
  {
    "time": "2019-10-30T08:31:39.572Z",
    "type": "message",
    "from": "user",
    "message": "demain à partir de 18h",
    "guess": {
      "type": "time",
      "datas": {
        "from": "2019-10-31T18:00:00.000+01:00",
        "to": null
      }
    }
  },
  {
    "time": "2019-10-30T08:31:39.750Z",
    "type": "message",
    "from": "bot",
    "message": "D'accord.  Pouvez-vous me communiquer vos noms et prénoms s'il vous plait ?"
  },
  {
    "time": "2019-10-30T08:31:48.140Z",
    "type": "message",
    "from": "user",
    "message": "Mathieu Gréard"
  },
  {
    "time": "2019-10-30T08:31:48.296Z",
    "type": "message",
    "from": "bot",
    "message": "C'est bien noté.  Merci pour votre appel.  Bonne journée."
  },
  {
    "time": "2019-12-05T09:39:17.079Z",
    "type": "message",
    "from": "bot",
    "message": "A qui souhaitez-vous parler ?"
  },
  {
    "time": "2019-12-05T09:39:20.537Z",
    "type": "message",
    "from": "user",
    "message": "Mathieu",
    "guess": {
      "type": "choice",
      "datas": {
        "nb": 2,
        "result": [
          "Mathieu Buonomo",
          "Mathieu Gréard"
        ]
      }
    }
  },
  {
    "time": "2019-12-05T09:39:20.802Z",
    "type": "message",
    "from": "bot",
    "message": "D'accord.  Quelle est la raison de votre appel ?"
  },
  {
    "time": "2020-02-25T16:14:05.676Z",
    "type": "hangup",
    "from": "user"
  }
]

const data = [
	{
		id: 1,
  	title: "Bonjour, je souhaiterais parler à mathieu",
		contactId: 1,
		date: "1592814063",
  	channel: "phone",
  	tickets: [],
  	isBookmarked: true,
  	tags: [
  		{
  			color: "danger",
				label: "Reecall"
  		}
  	],
  	player: {
  		show: true,
  		duration: 23
  	},
  	ticketsId: ["1", "2", "3"],
  	collectedDatas: collectedDatas,
    history: historyDatas
	},
	{
		id: 2,
  	title: "Demande de contact",
		contactId: 2,
		date: "1587543663",
  	channel: "aircall",
  	tickets: [],
  	isBookmarked: false,
  	tags: [
  		{
  			color: "primary",
				label: "RH"
  		}
  	],
  	player: {
  		show: true,
  		duration: 12
  	},
  	ticketsId: [],
  	collectedDatas: collectedDatas,
    history: historyDatas
	},
	{
		id: 3,
    title: "Annulation de commande",
		contactId: 1,
		date: "1587543663",
  	channel: "chat",
  	tickets: [],
  	isBookmarked: true,
  	tags: [
  		{
  			color: "danger",
				label: "Reecall"
  		}
  	],
  	player: {
  		show: false
  	},
  	ticketsId: [],
  	collectedDatas: collectedDatas,
    history: historyDatas
	}
]


// GET : CONVERSATION
mock.onGet("/api/conversation").reply(request => {
  	const id = request?.params?.id || null;

  	if(id){
  		const currentConversation = data.find(conversation => {
	  		if(conversation.id.toString() === id.toString()){
	  			return conversation;
	  		}
        return null;
	  	});

		  return [200, currentConversation]
  	}

  	return [500];
});


// GET : CONVERSATIONS
mock.onGet("/api/conversations").reply(request => {
    let channelType = request.params.channelType;
    if(!channelType || channelType === "all"){
      return [200, data];
    } else {
      return [200, data.filter(conv => conv.channel === channelType)]
    }
});


// POST : Add ticket to conversation
mock.onPost("/api/conversations/link-ticket").reply(request => {
  // Get task from post data
  let conversationId = JSON.parse(request.data).conversationId;
  let ticketId = JSON.parse(request.data).ticketId;

  data.find(conversation => conversation.id.toString() === conversationId.toString()).ticketsId.push(ticketId);

  return [201, { ticketId: ticketId, conversationId: conversationId }]
})
