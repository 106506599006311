import {
    FETCH_CONTACT_GROUPS_PENDING,
    FETCH_CONTACT_GROUPS_SUCCESS,
    FETCH_CONTACT_GROUPS_ERROR,
    UPDATE_CONTACT_GROUP,
    ADD_CONTACT_GROUP,
    DELETE_CONTACT_GROUP
} from '../../actions/contactGroups';

const initialState = {
    pending: false,
    contactGroups: [],
    error: null
}

const contactGroupsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_CONTACT_GROUPS_PENDING: 
            return {
                ...state,
                pending: true
            }
            
        case FETCH_CONTACT_GROUPS_SUCCESS:
            return {
                ...state,
                pending: false,
                contactGroups: action.contactGroups
            }

        case FETCH_CONTACT_GROUPS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case UPDATE_CONTACT_GROUP:
            return {
                ...state,
                contactGroups: [
                    ...state.contactGroups.map(elt => {
                        if(elt.id === action.contactGroup.id){
                            return {
                                ...elt,
                                ...action.contactGroup
                            }
                        } else {
                            return elt
                        }
                    })
                ]
            }

        case ADD_CONTACT_GROUP:
            return {
                ...state,
                contactGroups: [...state.contactGroups, action.contactGroup]
            }

        case DELETE_CONTACT_GROUP:
            return {
                ...state,
                contactGroups: [...state.contactGroups.filter(contactGroup => contactGroup.id !== action.id)]
            }

        default: 
            return state;
    }
}
export default contactGroupsReducer;