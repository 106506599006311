import React, { useState, useEffect } from "react"
import {
    Link,
    useHistory,
    useLocation
} from "react-router-dom"
import classnames from "classnames"
import { Badge, Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChevronRight } from "react-feather"
import { useSelector, useDispatch } from "react-redux"
import { Menu, Item, contextMenu, Submenu } from "react-contexify"
import { SET_ACTIVE_MENU, SET_OPENED_MENU } from "../../../redux/actions/menu"
import { FLUSH_COMPANY } from "../../../redux/actions/company"


const MenuItemText = ({ id, icon, title, level, token = null, data = null, dashboardStatus = null }) => {
    return <div className={`menu-text ${level === 2 && "ml-1"}`}>
        {icon}
        <div className="menu-item menu-title d-flex justify-content-center" style={{ maxWidth: "145px" }}>
            <span className="text-truncate">{title}</span>
            {dashboardStatus && <span className={`ml-1 text-${dashboardStatus.props.color}`}>{dashboardStatus}</span>}
        </div>
    </div>
}

const ButtonDeselectCompany = ({ className }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    return <Button
        className={`p-0 d-flex justify-content-center align-items-center ${className ? className : ""}`}
        outline
        color={"primary"}
        style={{ width: "20px", height: "20px", border: "1px solid transparent" }}
        onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            dispatch({ type: FLUSH_COMPANY });
            dispatch({ type: SET_ACTIVE_MENU, id: "companies" });
            history.push(`/companies/all/`);
        }}
    >
        <FontAwesomeIcon icon={["fal", "times"]} className="m-0" style={{ top: 0 }} />
    </Button>
}

const MenuItem = ({ item, sidebarCollapsed, windowSize, toggleMenu, level = 0, itemParent }) => {
    const location = useLocation()
    const dispatch = useDispatch();
    const menuStore = useSelector(state => state.menu);
    const CustomAnchorTag = item.type === "external-link" ? `a` : Link;
    const history = useHistory();

    const [hovered, setHovered] = useState(false);
    const [targetToOpen, setTargetToOpen] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const onItemClick = ({ el, event, inContextMenu = false }) => {
        if (el.type === "item") {
            if (windowSize.width <= 1200) {
                toggleMenu()
            }
            dispatch({ type: SET_OPENED_MENU, id: el?.parent });
            dispatch({ type: SET_ACTIVE_MENU, id: el.id });
        } else if (el.type === "collapse" && !sidebarCollapsed) {
            dispatch({
                type: SET_OPENED_MENU,
                id: level === 1 ?
                    itemParent?.id
                    :
                    el?.id !== menuStore.openedMenu ? el.id : null,
                inner: level === 1 && el?.id !== menuStore.openedMenuInner ? el.id : null
            });
        }
        if (!inContextMenu) {
            if (el.type === "collapse" && sidebarCollapsed && windowSize.width > 1200) {
                contextMenu.show({
                    id: `menu-${el.id}`,
                    event: event,
                });
            }
            return el.type === "collapse" ? event.preventDefault() : ""
        }
    }

    useEffect(() => {
        setTargetToOpen(level === 0 ? menuStore.openedMenu : menuStore.openedMenuInner)
    }, [menuStore, level])

    useEffect(() => {
        // force location change if page refresh so opened menu and opened inner are set in reducer
        if (firstLoad && location.pathname.includes("/companies/all/")) {
            if (!menuStore.openedMenuInner) history.push(location.pathname)
            setFirstLoad(false)
        }
    }, [menuStore, location, history, firstLoad])

    return <>
        <li
            className={classnames({
                "nav-item": !item.isChild,
                "has-sub": item.type === "collapse",
                disabled: item.disabled,
                open: targetToOpen === item.id,
                "sidebar-group-active": menuStore.openedMenu === item.id,
                active: menuStore.activeMenu === item.id
            })}
            key={item.id}
            id={`menuitem-${item.id}`}
        >
            <CustomAnchorTag
                to={item.navLink && item.type === "item" ? item.navLink : ""}
                href={item.type === "external-link" ? item.navLink : ""}
                className={`d-flex align-items-center ${item.badgeText
                    ? "justify-content-between"
                    : "justify-content-start"
                    }`}
                onClick={(e) => { onItemClick({ el: item, event: e }) }}
                target={item.newTab ? "_blank" : undefined}
            >
                <div

                    className="d-flex align-items-center"
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}>
                    <MenuItemText {...item} level={level} icon={
                        hovered && item.id === "selected-company" && item.type === "collapse" && !sidebarCollapsed ? <ButtonDeselectCompany className={"mr-1"} /> : item.icon
                    }
                    />
                </div>


                {item.statusBadge && (!item.badge || item.badge === "") &&
                    <div className="ml-auto d-flex align-items-center" style={{ marginRight: "-6px" }}>
                        <FontAwesomeIcon icon={["fas", "circle"]} className={classnames("mr-0", { "success": item.active, "danger": !item.active })} style={{ width: "11px", height: "11px" }} />
                    </div>
                }

                {item.badge &&
                    <div className="menu-badge">
                        <Badge color={item.badge} className="mr-1" pill>
                            {item.badgeText}
                        </Badge>
                    </div>
                }

                {item.type === "collapse" && <ChevronRight className="menu-toggle-icon" size={13} />}

            </CustomAnchorTag>

            {item.type === "collapse" && <ul className="menu-content">
                {item.children && item.children.map(child => {
                    return <MenuItem
                        item={{ ...child, isChild: true, parent: item.id }}
                        level={level + 1}
                        itemParent={item}
                        windowSize={windowSize}
                        sidebarCollapsed={sidebarCollapsed}
                        key={`submenu-${child.id}`}
                        toggleMenu={toggleMenu}
                    />
                })}
            </ul>}

            {
                sidebarCollapsed && item.children &&
                <Menu id={`menu-${item.id}`} className={`menu-contextify navigation`} style={{ marginTop: "-16px" }}>
                    <Item
                        key={item.id}
                        className="not-hoverable"
                    >
                        <div className={"d-flex justify-content-between w-100 text-bold-500"} style={{ padding: "0.6rem" }} >
                            {item.title} {item.id === "selected-company" && <ButtonDeselectCompany />}
                        </div>
                    </Item>
                    {item.children.map(child => {
                        const CustomAnchorTag = child.type === "external-link" ? `a` : Link;
                        return child.type !== "collapse" ?
                            <Item
                                key={child.id}
                                onClick={() => contextMenu.hideAll()}
                                className={classnames({
                                    active: menuStore.activeMenu === child.id
                                })}
                            >
                                <CustomAnchorTag
                                    className={"d-flex justify-content-between w-100"}
                                    to={child.navLink && child.type === "item" ? child.navLink : ""}
                                    href={child.type === "external-link" ? child.navLink : ""}
                                    target={child.newTab ? "_blank" : undefined}
                                    onClick={(e) => onItemClick({ el: { ...child, parent: item.id }, event: e, inContextMenu: true })}
                                >
                                    {child.title}
                                </CustomAnchorTag>
                            </Item>
                            :
                            <Submenu
                                key={child.id}
                                label={
                                    <Item
                                        key={child.id}
                                        style={{ padding: "0.6rem", width: "100%" }}
                                        className={"not-hoverable"}
                                    >
                                        <div className="d-flex justify-content-between align-items-center w-100 text-bold-500">
                                            <div>
                                                {child.title}
                                            </div>
                                            <ChevronRight className="" size={13} />
                                        </div>
                                    </Item>
                                }
                                arrow={null}
                            >
                                {child.children.map(innerChild => {
                                    return <Item
                                        key={innerChild.id}
                                        onClick={() => contextMenu.hideAll()}
                                        className={classnames({
                                            active: menuStore.activeMenu === innerChild.id
                                        })}
                                    >
                                        <CustomAnchorTag
                                            className={"d-flex justify-content-between w-100"}
                                            to={innerChild.navLink && innerChild.type === "item" ? innerChild.navLink : ""}
                                            href={innerChild.type === "external-link" ? innerChild.navLink : ""}
                                            target={innerChild.newTab ? "_blank" : undefined}
                                            onClick={(e) => onItemClick({ el: { ...innerChild, parent: child.id }, event: e, inContextMenu: true })}
                                        >
                                            {innerChild.title}
                                        </CustomAnchorTag>
                                    </Item>
                                })}
                            </Submenu>
                    })}
                </Menu>
            }
        </li>
    </>
}

export default MenuItem