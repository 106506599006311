import {HIDE_ERROR} from '../../actions/error';

const initState = {
	error: null,
	isOpen: false
};

const errorReducer = (state = initState, action) => {
	const { error } = action;

	if(error){
		console.warn(error, "error reducer");
		return {
			error: error,
			isOpen: true
		}
	}else if(action.type === HIDE_ERROR){
		return {
			error: null,
			isOpen: false
		}
	}

	return state;
}

export default errorReducer;