import {
    FETCH_INVITATIONS,
    ADD_INVITATION,
    DELETE_INVITATION
} from '../../actions/invitations';

const initialState = {
    status: "pending",
    invitations: [],
    total: null,
    error: null
}

const invitationsReducer = (state = initialState, action) => {
    switch(action.type) {

        case FETCH_INVITATIONS:
            let newInvitations = [];
            if(action.status === "pending"){
                if(action.flush){
                    newInvitations = new Array(action.stopIndex - action.startIndex).fill("pending");
                } else {
                    newInvitations = [...state.invitations]
                    newInvitations.splice(action.startIndex,  action.stopIndex - state.invitations.length, ...new Array(action.stopIndex - state.invitations.length).fill("pending"));
                }
            } else if(action.status === "success") {
                newInvitations = [...state.invitations]
                newInvitations.splice(action.startIndex, action.invitations.length, ...action.invitations);
                newInvitations = newInvitations.filter(el => el !== "pending");
            } else {
                newInvitations= [...state.invitations];
            }

            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                invitations: [...newInvitations],
                total: action.status === "success" ? action.total : state.total
            }


        case ADD_INVITATION:
            return {
                ...state, 
                status: action.status,
                error: action.status === "error" ? action.error : null,
                invitations: action.status === "success" ? [...state.invitations, action.invitation] : [...state.invitations]
            }

        case DELETE_INVITATION:
            return {
                ...state, 
                status: action.status,
                error: action.status === "error" ? action.error : null,
                invitations: action.status === "success" ? [...state.invitations.filter(el => el.id !== action.id)] : [...state.invitations]
            }

        default: 
            return state;
    }
}
export default invitationsReducer;