import { v4 as uuid } from 'uuid';
import { ADD_NOTIFICATION, MARK_ALL_AS_READ } from '../../actions/notifications';
import { NEW_NOTIFICATION } from '../../actions/pusher';

const initialState = {
    pending: false,
    notifications: [
        // {
        //     id: uuid(),
        //     title: "test notif",
        //     content: "content notif",
        //     type: "success",
        //     status: "read"
        // },

        // {
        //     id: uuid(),
        //     title: "test notif 2",
        //     content: "content notif 2",
        //     icon: ["fas", "phone"],
        //     color: "#FF0000",
        //     status: "new"
        // }
    ],
    error: null,
    wizzNotification: false
}

const notificationReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_NOTIFICATION: 
            return {
                ...state,
                notifications: [...state.notifications, action.notif]
            }

        case NEW_NOTIFICATION:
            if(action.data.type === "notification"){
                return {
                    ...state,
                    notifications: [...state.notifications, {
                        id: uuid(),
                        status: "new",
                        ...action.data.datas
                    }],
                    wizzNotification: true
                }
            } else {
                return {...state}
            }

        case MARK_ALL_AS_READ:
            return {
                ...state,
                notifications: [...state.notifications.map(notif => {
                    return {
                        ...notif,
                        status: "read"
                    }
                })],
                wizzNotification: false
            }
       
        default: 
            return state;
    }
}
export default notificationReducer;