import React from 'react';
import reecallAvatarBorderedUrl from '../../../assets/img/logo/bubble_reecall_2020_white_bordered.svg';
import moment from "moment";
import { FormattedMessage } from "react-intl";
import CirclePlayer from "../audio/CirclePlayer";
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent"

const ChatMessage = ({
    avatarInitiales,
    reecallAvatar,
    direction,
    children,
    className,
    time,
    channel,
    from,
    audioUrl,
    ...rest
}) => {
    return <div
        className={`${className ? className : ""} chat-message chat-message-${from} ${direction === "left" ? "chat-message-left" : "chat-message-right"}`}
        {...rest}
    >
        <div className={`chat-avatar ${reecallAvatar ? "chat-avatar-reecall" : ""}`}>
          {!reecallAvatar && <Avatar 
            img={false}
            content={avatarInitiales}
            className="m-0"
            size="chat-message"
          />}
          
          {reecallAvatar && <div className="avatar m-0">
            <img src={reecallAvatarBorderedUrl} height="40" width="40" alt="avatar"/>
          </div>}
        </div>
        
        <div className="chat-message-body">
            <div className="chat-message-content">{children}</div>
            <div className="text-muted chat-message-time d-flex align-items-center">
              {
                direction === "right" && audioUrl && <>
                  <CirclePlayer url={audioUrl} showRing={false} size="xs" className="mr-25"/>
                  {" - "}
                </>
              }
              {moment(time).format("HH:mm")}
              {channel && <>
                {" "}
                {<FormattedMessage id="chat.via" defaultMessage="via"/>}
                {" "}
                {channel}
              </>}
              {
                direction === "left" && audioUrl && <>
                  {" - "}
                  <CirclePlayer url={audioUrl} showRing={false} size="xs" className="ml-25"/>
                </>
              }
            </div>
        </div>
    </div>
}

ChatMessage.defaultProps = {
    avatarInitiales: "?",
    direction: "left",
    time: new Date(),
    reecallAvatar: false,
    from: "bot",
    audioUrl: null
}

export default ChatMessage;