import axios from "axios"
import { TWILIO_USERNAME, TWILIO_PASSWORD } from "../../../configs/constants";

export const STORE_CURRENT_ELEMENT = 'STORE_CURRENT_ELEMENT';
export const STORE_TWILIO_ELEMENTS = 'STORE_TWILIO_ELEMENTS';
export const STORE_MESSAGES_ELEMENTS = 'STORE_MESSAGES_ELEMENTS';
export const RESET_TIMELINE = 'RESET_TIMELINE';
export const FETCH_TWILIO_ELEMENTS = 'FETCH_TWILIO_ELEMENTS';
export const GENERATE_DATAS ='GENERATE_DATAS';
export const STORE_FILTERED_DATAS = 'STORE_FILTERED_DATAS';
export const SET_VOICEWAVE_READY = 'SET_VOICEWAVE_READY';
export const STORE_COLLECTED_DATAS = "STORE_COLLECTED_DATAS";

export const storeCurrentElement = (element) => ({  
    type: STORE_CURRENT_ELEMENT,
    element,
}); 

export const storeTwilioElements = (datas) => ({  
    type: STORE_TWILIO_ELEMENTS,
    datas,
}); 

export const generateDatas = () => ({  
    type: GENERATE_DATAS,
}); 

export const storeMessagesElements = (datas) => ({  
    type: STORE_MESSAGES_ELEMENTS,
    datas,
}); 

export const resetTimeline = () => ({  
    type: RESET_TIMELINE,
});

export const storeFilteredDatas = (datas) => ({  
    type: STORE_FILTERED_DATAS,
    datas,
});

export const setVoiceWaveReady = (bool) => ({  
    type: SET_VOICEWAVE_READY,
    bool,
});

export const storeCollectedDatas = (collectedDatas) => ({  
    type: STORE_COLLECTED_DATAS,
    collectedDatas,
});


export const fetchTwilioElements = (callSid) => {
    return (dispatch) => {
    axios.get(`https://insights.twilio.com/v1/Voice/${callSid}/Events`, {
    auth: {
        username: TWILIO_USERNAME,
        password: TWILIO_PASSWORD,
    }
    })
    .then((response) => {
        if (response.data) {
        const twilioElements = response.data.events.map((element) => ({
                name: 'twilio',
                content: element.name,
                timestamp: element.timestamp,
        }));
        dispatch(storeTwilioElements(twilioElements));
        }
        dispatch(generateDatas());
    })
    .catch((error) => {
        console.log(error);
    })
}
};