import { defineMessages } from 'react-intl'

const totalCardMessages = defineMessages({
    "total.card.ticket": {
        id: "total.card.ticket",
        defaultMessage: "{count, plural, =0 {ticket} one {ticket} other {tickets}}"
    },
    "total.card.contact": {
        id: "total.card.contact",
        defaultMessage: "{count, plural, =0 {contact} one {contact} other {contacts}}"
    },
    "total.card.conversation": {
        id: "total.card.conversation",
        defaultMessage: "{count, plural, =0 {conversation} one {conversation} other {conversations}}"
    },
    "total.card.member": {
        id: "total.card.member",
        defaultMessage: "{count, plural, =0 {member} one {member} other {members}}"
    },
    "total.card.channel": {
        id: "total.card.channel",
        defaultMessage: "{count, plural, =0 {channel} one {channel} other {channels}}"
    },
    "total.card.agent": {
        id: "total.card.agent",
        defaultMessage: "{count, plural, =0 {agent} one {agent} other {agents}}"
    },
    "total.card.company": {
        id: "total.card.company",
        defaultMessage: "{count, plural, =0 {company} one {company} other {companies}}"
    },
    "total.card.collection": {
        id: "total.card.collection",
        defaultMessage: "{count, plural, =0 {collection} one {collection} other {collections}}"
    },
    "total.card.invitation": {
        id: "total.card.invitation",
        defaultMessage: "{count, plural, =0 {invitation} one {invitation} other {invitations}}"
    },
    "total.card.faq": {
        id: "total.card.faq",
        defaultMessage: "{count, plural, =0 {faq} one {faq} other {faqs}}"
    },
});


export { totalCardMessages }


const variationsDefaultColors = [
    // {
    //     "key": "activity",
    //     "colors": [
    //         {
    //           "from": -5,
    //           "to": "Infinity",
    //           "color": "success"
    //         },
    //         {
    //           "from": -15,
    //           "to": -5,
    //           "color": "warning"
    //         },
    //         {
    //           "from": "-Infinity",
    //           "to": -15,
    //           "color": "danger"
    //         }
    //       ]
    // },
    // {
    //     "key": "conversation",
    //     "colors": [
    //         {
    //             "from": "-Infinity",
    //             "to": -15,
    //             "color": "danger"
    //         },
    //         {
    //           "from": -15,
    //           "to": -5,
    //           "color": "warning"
    //         },
    //         {
    //           "from": -5,
    //           "to": "Infinity",
    //           "color": "success"
    //         }
    //       ]
    // },
    // {
    //     "key": "hangup",
    //     "colors": [
    //         {
    //             "from": "-Infinity",
    //             "to": -10,
    //             "color": "danger"
    //         },
    //         {
    //           "from": -10,
    //           "to": 0,
    //           "color": "warning"
    //         },
    //         {
    //           "from": 0,
    //           "to": "Infinity",
    //           "color": "success"
    //         }
    //       ]
    // },
    // {
    //     "key": "performance",
    //     "colors": [
    //         {
    //           "from": "-Infinity",
    //           "to": -5,
    //           "color": "danger"
    //         },
    //         {
    //           "from": -5,
    //           "to": 5,
    //           "color": "warning"
    //         },
    //         {
    //           "from": 5,
    //           "to": "Infinity",
    //           "color": "success"
    //         }
    //       ]
    // },
    {
        "key": "ata",
        "colors": [
            {
                "from": "-Infinity",
                "to": -5,
                "color": "danger"
            },
            {
                "from": -5,
                "to": 0,
                "color": "warning"
            },
            {
                "from": 0,
                "to": "Infinity",
                "color": "success"
            }
        ]
    },
    {
        "key": "ticketClosedByHumanAgent",
        "colors": [
            {
                "from": "-Infinity",
                "to": -5,
                "color": "danger"
            },
            {
                "from": -5,
                "to": 0,
                "color": "warning"
            },
            {
                "from": 0,
                "to": "Infinity",
                "color": "success"
            }
        ]
    },
    {
        "key": "tagsCategory",
        "colors": [
            {
                "from": "-Infinity",
                "to": -5,
                "color": "danger"
            },
            {
                "from": -5,
                "to": 0,
                "color": "warning"
            },
            {
                "from": 0,
                "to": "Infinity",
                "color": "success"
            }
        ]
    },
    {
        "key": "tags_autonomous",
        "colors": [
            {
                "from": "-Infinity",
                "to": -5,
                "color": "danger"
            },
            {
                "from": -5,
                "to": 0,
                "color": "warning"
            },
            {
                "from": 0,
                "to": "Infinity",
                "color": "success"
            }
        ]
    },
    {
        "key": "tags_confirmed",
        "colors": [
            {
                "from": "-Infinity",
                "to": -5,
                "color": "danger"
            },
            {
                "from": -5,
                "to": 0,
                "color": "warning"
            },
            {
                "from": 0,
                "to": "Infinity",
                "color": "success"
            }
        ]
    },
    {
        "key": "tags_collaborators",
        "colors": [
            {
                "from": "-Infinity",
                "to": -5,
                "color": "danger"
            },
            {
                "from": -5,
                "to": 0,
                "color": "warning"
            },
            {
                "from": 0,
                "to": "Infinity",
                "color": "success"
            }
        ]
    },
    {
        "key": "tags_removed",
        "colors": [
            {
                "from": "-Infinity",
                "to": 0,
                "color": "success"
            },
            {
                "from": 0,
                "to": 5,
                "color": "warning"
            },
            {
                "from": 5,
                "to": "Infinity",
                "color": "danger"
            }
        ]
    }
]

const getVariationDefaultColorsByKey = (key) => {
    return variationsDefaultColors.find(el => el.key === key);
}

export { variationsDefaultColors, getVariationDefaultColorsByKey };