import {
    SET_ACTIVE_MENU,
    SET_OPENED_MENU,
    TOGGLE_CHANNEL_MODAL,
    TOGGLE_TAGS_TRAIN_MODAL,
    TOGGLE_CONTACTS_MODAL,
    SET_USER_PROFILE,
    STORE_USER_MENU_CONFIG,
} from '../../actions/menu';


const initialState = {
    activeMenu: null,
    openedMenu: null,
    showChannelsModal: false,
    showTagsTrainModal: false,
    showContactsModal: false,
    openedMenuInner: null,
    userProfile: null,
    inMain: [],
    userMenuConfig: [],
    ids: [
        "details",
        "conversations",
        "tickets",
        "contacts",
        "collections",
        "faqs",
        "members",
        "invitations",
        "agents",
        "channels",
        "general-settings",
        "billing-settings",
        "settings",
        "acds",
        "tokens",
        "rules",
        "integrations",
        "quickActions",
        "tags",
        "chat",
        "progressions",
        "labels",
        "dashboard",
        "hooks",
        "auditLogs",
        "users",
        "clusters",
        "exports",
        "events",
        "list",
        "documentations",
        "smartTags",
        "examples"
    ],
    mainByProfile: {
        "dev": ["exports", "rules", "hooks", "quickActions", "agents", "channels", "collections", "conversations", "integrations"],
        "csm": ["dashboard", "exports", "tickets", "conversations", "invitations", "quickActions"],
        "conversationDesigner": ["agents", "rules", "tags", "labels", "smartTags", "quickActions", "collections", "exports"]
    },
    orderArray: [
        {
            id: "selected-company-dashboard",
            order: 2,
            category: "main"
        },
        {
            id: "selected-company-details",
            order: 1,
            category: "main"
        },
        {
            id: "selected-company-conversations",
            order: 0,
            category: "main"
        },
        {
            id: "selected-company-chat",
            order: 3,
            category: "main"
        },
        {
            id: "selected-company-tickets",
            order: 4,
            category: "main"
        },
        {
            id: "selected-company-contacts",
            order: 5,
            category: "main"
        },
        {
            id: "selected-company-agents",
            order: 6,
            category: "main"
        },
        {
            id: "selected-company-acds",
            order: 8,
            category: "secondary"
        },
        {
            id: "selected-company-channels",
            order: 7,
            category: "secondary"
        },
        {
            id: "selected-company-members",
            order: 9,
            category: "secondary"
        },
        {
            id: "selected-company-collections",
            order: 10,
            category: "secondary"
        },
        {
            id: "selected-company-invitations",
            order: 11,
            category: "secondary"
        },
        {
            id: "selected-company-faqs",
            order: 12,
            category: "secondary"
        },
        {
            id: "selected-company-rules",
            order: 13,
            category: "secondary"
        },
        {
            id: "selected-company-hooks",
            order: 14,
            category: "secondary"
        },
        {
            id: "selected-company-tags",
            order: 15,
            category: "secondary"
        },
        {
            id: "selected-company-labels",
            order: 16,
            category: "secondary"
        },
        {
            id: "selected-company-tokens",
            order: 17,
            category: "secondary"
        },
        {
            id: "selected-company-settings",
            order: 18,
            category: "secondary"
        },
        {
            id: "selected-company-quickActions",
            order: 19,
            category: "secondary"
        },
        {
            id: "selected-company-integrations",
            order: 20,
            category: "secondary"
        },
        {
            id: "selected-company-auditLogs",
            order: 21,
            category: "secondary"
        },
        {
            id: "selected-company-clusters",
            order: 22,
            category: "secondary"
        },
        {
            id: "selected-company-exports",
            order: 23,
            category: "secondary"
        },
        {
            id: "selected-company-smartTags",
            order: 24,
            category: "secondary"
        },
    ]
}
const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_PROFILE:
            return {
                ...state,
                userProfile: action.userProfile
            }

        case SET_ACTIVE_MENU:
            return {
                ...state,
                activeMenu: action.id
            }

        case SET_OPENED_MENU:
            return {
                ...state,
                openedMenu: action.id,
                openedMenuInner: action.inner
            }

        case TOGGLE_CHANNEL_MODAL:
            return {
                ...state,
                showChannelsModal: action?.show ? action.show : !state.showChannelsModal
            }
        case TOGGLE_TAGS_TRAIN_MODAL:
            return {
                ...state,
                showTagsTrainModal: action?.show ? action.show : !state.showTagsTrainModal
            }
        case TOGGLE_CONTACTS_MODAL:
            return {
                ...state,
                showContactsModal: action?.show ? action.show : !state.showContactsModal
            }
        case STORE_USER_MENU_CONFIG:
            return {
                ...state,
                userMenuConfig: action?.userMenuConfig,
                inMain: action.inMain
            }
        case "@@router/LOCATION_CHANGE":
            const url = action.payload.location.pathname
            let activeItem;
            let openedInner = null;
            const ids = state.ids
            let sectionToOpen = (activeItem) => {
                let section = ""

                if (url.includes("/automatheque/")) section = "automatheque"
                if (url.includes("/snippets/")) section = "snippets"
                if (activeItem === "all-conversations" || activeItem === "all-tickets" || activeItem === "all-contacts" || activeItem === "all-chat") section = "collected-data"
                if (activeItem === "all-collections" || activeItem === "all-faqs" || activeItem === "all-integrations" || activeItem === "all-quick-actions") section = "provided-data"
                if (activeItem === "all-members" || activeItem === "all-invitations") section = "members"
                if (activeItem === "all-agents" || activeItem === "all-channels") section = "AgentsChannels"
                if (activeItem === "all-tokens" || activeItem === "all-rules" || activeItem === "all-tags" || activeItem === "all-progressions" || activeItem === "all-users") section = "administrative"
                if (activeItem === "all-billing-settings") section = "billing"
                return section
            }
            const item = ids.find(el => url.includes(el));
            if (item !== undefined) {
                if (url.includes("/companies/all/")) {
                    activeItem = "selected-company-" + item;
                    if (state.userMenuConfig.length > 0) openedInner = state.inMain.includes(item) ? null : "others"
                    else if (state.userProfile && !state.mainByProfile[state.userProfile].includes(item)) openedInner = "others"
                } else if (url.includes("/automatheque/")) {
                    activeItem = "automatheque-" + item;
                } else if (url.includes("/snippets/")) {
                    activeItem = "snippets-" + item;
                } else {
                    activeItem = item === "general-settings" ? item : "all-" + item
                }
            }
            else if (url === "/" || url.includes("/companies/all")) activeItem = "companies"
            else if (url === "/general-reporting") activeItem = "general-reporting"
            return {
                ...state,
                activeMenu: activeItem,
                openedMenu: url.includes("/companies/all/") ? activeItem.includes("selected") ? "selected-company" : state.openedMenu : sectionToOpen(activeItem),
                openedMenuInner: openedInner ? "selected-company-" + openedInner : null
            }

        default:
            return state;
    }
}
export default menuReducer;