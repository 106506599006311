import { UPDATE_BILLING_SETTINGS, GET_BILLING_SETTINGS, FLUSH_BILLING_STATUS } from "../../actions/billing"

const initialState = {
    status: null,
    settings: null,
    error: null
}

export const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FLUSH_BILLING_STATUS:
            return {
                ...state,
                status: null,
            }
        case GET_BILLING_SETTINGS:
            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                settings: action.status === "success" ? action.settings : state.settings
            }
        case UPDATE_BILLING_SETTINGS:
            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                settings: action.status === "success" ? action.settings : state.settings
            }

        default:
            return state
    }
}

export default billingReducer;