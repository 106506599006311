export const TOGGLE_ASSISTANT = 'TOGGLE_ASSISTANT';
export const PUSH_INSIGHT = 'PUSH_INSIGHT';
export const TOGGLE_INSIGHTS = 'TOGGLE_INSIGHTS';
export const REMOVE_INSIGHT = 'REMOVE_INSIGHT';

export const toggleAssistant = () => {
    return dispatch => {
        dispatch({ type: TOGGLE_ASSISTANT })
    }
}

export const toggleInsights= () => {
    return dispatch => {
        dispatch({ type: TOGGLE_INSIGHTS })
    }
}

export const pushInsight = (insight) => {
    return dispatch => {
        dispatch({ type: PUSH_INSIGHT, insight: insight })
    }
}

export const removeInsight = (insightId) => {
    return dispatch => {
        dispatch({ type: REMOVE_INSIGHT, insightId: insightId })
    }
}