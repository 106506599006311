import moment from 'moment';
import { omit } from "lodash";

export const COUNT_FAQS = 'COUNT_FAQS';
export const COUNT_COLLECTIONS = 'COUNT_COLLECTIONS';

export const FETCH_CONVERSATIONS_DETAILS = 'FETCH_CONVERSATIONS_DETAILS';
export const FETCH_CONVERSATIONS_DETAILS_24H = 'FETCH_CONVERSATIONS_DETAILS_24H';
export const FETCH_TICKETS_DETAILS = 'FETCH_TICKETS_DETAILS';
export const FETCH_TICKETS_DETAILS_24H = 'FETCH_TICKETS_DETAILS_24H';
export const FETCH_CONTACTS_DETAILS = 'FETCH_CONTACTS_DETAILS';
export const FETCH_CONTACTS_DETAILS_24H = 'FETCH_CONTACTS_DETAILS_24H';

export const FETCH_LAST_5_CONVERSATIONS = 'FETCH_LAST_5_CONVERSATIONS';
export const FETCH_LAST_5_TICKETS = 'FETCH_LAST_5_TICKETS';

export const FETCH_MEMBERS_TOTAL = 'FETCH_MEMBERS_TOTAL';
export const FETCH_CHANNELS_TOTAL = 'FETCH_CHANNELS_TOTAL';
export const FETCH_AGENTS_TOTAL = 'FETCH_AGENTS_TOTAL';
export const FETCH_COMPANIES_TOTAL = 'FETCH_COMPANIES_TOTAL';
export const FETCH_INVITATIONS_TOTAL = 'FETCH_INVITATIONS_TOTAL';
export const FETCH_DECKS_TOTAL = 'FETCH_DECKS_TOTAL';
export const FETCH_RULES_TOTAL = 'FETCH_RULES_TOTAL';
export const FETCH_TAGS_TOTAL = 'FETCH_TAGS_TOTAL';
export const FETCH_INTEGRATION_CONNECTION_TOTAL = 'FETCH_INTEGRATION_CONNECTION_TOTAL';
export const FETCH_INVITATIONS_TOTAL_TMP = 'FETCH_INVITATIONS_TOTAL_TMP';
export const FLUSH_INVITATIONS_STATUS = 'FLUSH_INVITATIONS_STATUS';

export const SET_PREVIOUS_URL = 'SET_PREVIOUS_URL';

export const FETCH_CONVERSATIONS_DETAILS_CHART = 'FETCH_CONVERSATIONS_DETAILS_CHART';
export const FETCH_CONTACTS_DETAILS_CHART = 'FETCH_CONTACTS_DETAILS_CHART';
export const FETCH_TICKETS_DETAILS_CHART = 'FETCH_TICKETS_DETAILS_CHART';
export const FLUSH_DETAILS_CHART = 'FLUSH_DETAILS_CHART';

export const FLUSH_TOTALS = 'FLUSH_TOTALS';


export const setPreviousUrl = (url) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: SET_PREVIOUS_URL, url: url })
    }
}

const ModelCountActionMap = {
    "Faq": COUNT_FAQS,
    "Collection": COUNT_COLLECTIONS,
    "Channel": FETCH_CHANNELS_TOTAL,
    "Agent": FETCH_AGENTS_TOTAL,
    "Membership": FETCH_MEMBERS_TOTAL,
    "Invitation": FETCH_INVITATIONS_TOTAL,
    "Deck": FETCH_DECKS_TOTAL,
    "Rule": FETCH_RULES_TOTAL,
    "Tag": FETCH_TAGS_TOTAL,
    "IntegrationConnection": FETCH_INTEGRATION_CONNECTION_TOTAL,
}

const ModelDetailActionMap = {
    "Conversation": FETCH_CONVERSATIONS_DETAILS,
    "NewTicket": FETCH_TICKETS_DETAILS,
    "Contact": FETCH_CONTACTS_DETAILS
}

const ModelDetail24HActionMap = {
    "Conversation": FETCH_CONVERSATIONS_DETAILS_24H,
    "NewTicket": FETCH_TICKETS_DETAILS_24H,
    "Contact": FETCH_CONTACTS_DETAILS_24H
}

const ModelDetailChartActionMap = {
    "Conversation": FETCH_CONVERSATIONS_DETAILS_CHART,
    "NewTicket": FETCH_TICKETS_DETAILS_CHART,
    "Contact": FETCH_CONTACTS_DETAILS_CHART
}

export const countItems = (model, companyId, timeFilter) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: ModelCountActionMap[model], status: "pending" });

        let where = {}
        if (timeFilter) where = {
            ...where, 
            createdAt: {
                $gte: `date:${moment(timeFilter?.startDate === null ? moment().subtract(1, "years"): timeFilter?.startDate).format()}`,
                $lte: `date:${moment(timeFilter?.endDate).format()}`
            }
        }
        if (companyId) where = {...where, companyId : `id:${companyId}`}

        return rcsdk.Stats
        .getCount(model)
        .where(where)
        .then(response => {
            return dispatch({ type: ModelCountActionMap[model], total: response.count, status:"success" });
        }).catch(err => {
            return dispatch({ type: ModelCountActionMap[model], error: err.message, status:"error" });
        })
    }
}

export const fetchDetails = (companyId, category, timeFilter, last24h) => {
    let model;
    switch (category) {
        case "conversations":
            model = "Conversation"
            break;
        case "tickets":
            model = "NewTicket"
            break;
        case "contacts":
            model = "Contact"
            break;
        default:
            break;
    }
    
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {

        let startDate;
        let endDate;
        let typeToDispatch = last24h ? ModelDetail24HActionMap[model] : ModelDetailActionMap[model];

        if (last24h) {
            startDate = moment().subtract(1, "day");
            endDate = moment();
        } else {
            startDate = timeFilter?.startDate === null ? moment().subtract(1, "years"): timeFilter?.startDate
            endDate = timeFilter?.endDate;
        }

        let where = {
            createdAt: {
                $gte: `date:${moment(startDate).format()}`,
                $lte: `date:${moment(endDate).format()}`
            }
        }
        if (companyId) where = {...where, companyId : `id:${companyId}`}

        dispatch({ type: typeToDispatch, status: "pending" })

        rcsdk.Stats.getCount(model)
        .where(where)
        .then(resp => {
            dispatch({ type: typeToDispatch, status: "success", total: resp.count});
        }).catch(err => {
            dispatch({ type: typeToDispatch, status: "error", error: err });
        })
    }
};

export const flushDetailsForChart = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FLUSH_DETAILS_CHART })
    }
}


export const fetchDetailsForChart = (companyId, category, timeFilter) => {
    let model;
    switch (category) {
        case "conversations":
            model = "Conversation"
            break;
        case "tickets":
            model = "NewTicket"
            break;
        case "contacts":
            model = "Contact"
            break;
        default:
            break;
    }
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        const startDate = timeFilter?.startDate === null ? moment().subtract(1, "years").valueOf(): timeFilter.startDate
        const endDate = timeFilter?.endDate
        const diffInDays = moment(endDate).diff(moment(startDate), "days") + 1;
        const diffInWeeks = moment(endDate).diff(moment(startDate), "weeks") + 1;
        const diffInMonths = moment(endDate).diff(moment(startDate), "months") + 1;
        let type = "days"
        let timeFrame = diffInDays
        if (diffInWeeks > 5) {type = "week"; timeFrame = diffInWeeks}
        if (diffInMonths > 3) {type = "month"; timeFrame = diffInMonths}

        const queries = [];
        const dates = [];

        for (let i = 0; i < timeFrame; i++) {
            let where = {
                createdAt: {
                    $gte: `date:${moment(startDate).startOf(type).add(i, type).format()}`,
                    $lte: `date:${moment(startDate).endOf(type).add(i, type).format()}`
                }
            }
            if (companyId) where = {...where, companyId : `id:${companyId}`}
            dates.push(moment(startDate).startOf(type).add(i, type).valueOf());
            let datePromise = rcsdk.Stats.getCountByDay(model)
            .where(where)
            .then(data => data);
            queries.push(datePromise);
        }

        dispatch({ type: ModelDetailChartActionMap[model], status: "pending" })
        Promise.all([...queries]).then(allResp => {
            dispatch({
                type: ModelDetailChartActionMap[model],
                status: "success",
                data: allResp.map(resp => {
                    return {nbHits: resp.reduce((a, b) => a + (b["count"] || 0), 0)}
                }),
                dates: dates
            });
        }).catch(err => {
            dispatch({ type: ModelDetailChartActionMap[model], status: "error", error: err });
        });
    }
};

export const fetchLastConversations = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_LAST_5_CONVERSATIONS, status: "pending" });

        rcsdk.getConversations()
        .where({"companyId": companyId})
        .limit(5)
        .order("createdAt DESC")
        .then(conversations => {
            dispatch({
                type: FETCH_LAST_5_CONVERSATIONS,
                status: "success",
                conversations: [...conversations.map(conv => {
                    return {
                        ...omit(conv, ["ticketId"]),
                        channel: conv.channelId,
                        type: "conversation"
                    }
                })],
                total: conversations.length
            });
        }).catch(err => {
            dispatch({ type: FETCH_LAST_5_CONVERSATIONS, status: "error", error: err });
        })
    }
}

export const fetchLastTickets = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_LAST_5_TICKETS, status: "pending" });

        rcsdk.getTickets()
        .include(["assignee", {"relation": "conversations", "fields": ["id"]}])
        .where({"companyId": companyId})
        .limit(5)
        .order("createdAt DESC")
        .then(tickets => {
            dispatch({
                type: FETCH_LAST_5_TICKETS,
                status: "success",
                tickets: [...tickets.map(ticket => {
                    const ticketTags = ticket?.tags ? ticket?.tags : [];
                    // if(ticket?.tagIds){
                    //     ticket.tagIds.map(tagId => {
                    //         ticketTags.push({
                    //             id: tagId,
                    //             confirmed: true,
                    //             confirmedType: "agent"
                    //         })
                    //         return null;
                    //     })
                    // }
                    return {
                        ...ticket,
                        tags: ticketTags
                    }
                })],
                total: tickets.length
            });
        }).catch(err => {
            dispatch({ type: FETCH_LAST_5_TICKETS, status: "error", error: err });
        })
    }
}

export const fetchMembersTotal = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_MEMBERS_TOTAL, status: "pending" });
        return rcsdk._request().route(`/members/count`).where(companyId ? {"companyId":companyId} : {}).then(response => {
            return dispatch({ type: FETCH_MEMBERS_TOTAL, status: "success", total: response.count });
        }).catch(err => {
            dispatch({ type: FETCH_MEMBERS_TOTAL, status: "error", error: err });
        })
    }
}

export const fetchChannelsTotal = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_CHANNELS_TOTAL, status: "pending" });
        return rcsdk._request().route(`/channels/count`).then(response => {
            return dispatch({ type: FETCH_CHANNELS_TOTAL, status: "success", total: response.count });
        }).catch(err => {
            dispatch({ type: FETCH_CHANNELS_TOTAL, status: "error", error: err });
        })
    }
}

export const fetchAgentsTotal = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_AGENTS_TOTAL, status: "pending" });
        return rcsdk._request().route(`/agents/count`).then(response => {
            return dispatch({ type: FETCH_AGENTS_TOTAL, status: "success", total: response.count });
        }).catch(err => {
            dispatch({ type: FETCH_AGENTS_TOTAL, status: "error", error: err });
        })
    }
}

export const fetchCompaniesTotal = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_COMPANIES_TOTAL, status: "pending" });
        return rcsdk._request().route(`/companies/count`).then(response => {
            return dispatch({ type: FETCH_COMPANIES_TOTAL, status: "success", total: response.count });
        }).catch(err => {
            dispatch({ type: FETCH_COMPANIES_TOTAL, status: "error", error: err });
        })
    }
}


export const fetchInvitationsTotal = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_INVITATIONS_TOTAL_TMP, status: "pending" });
        return rcsdk._request()
            .route(`/invitations`)
            .raw()
            .where({ "and": [{ "status": { "eq": "pending" } }, { "companyId": { "eq": companyId } }] })

            .then(response => {
                const total = response.headers["content-range"].substr(response.headers["content-range"].indexOf("/") + 1);
                return dispatch({ type: FETCH_INVITATIONS_TOTAL_TMP, status: "success", total: total });
            }).catch(err => {
                dispatch({ type: FETCH_INVITATIONS_TOTAL_TMP, status: "error", error: err });
            })
    }
}

export const countInvitations = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk._request()
            .route(`/invitations`)
            .raw()
            .where({ "and": [{ "status": { "eq": "pending" } }, { "companyId": { "eq": companyId } }] })

            .then(response => {
                const total = response.headers["content-range"].substr(response.headers["content-range"].indexOf("/") + 1);
                return total
            }).catch(err => {
                console.log(err)
            })
    }
}