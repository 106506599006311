import {
    FETCH_COLLECTIONS,
    ADD_COLLECTION,
    DELETE_COLLECTION,
    UPDATE_COLLECTION,
    FETCH_COLLECTION_DATA,
    ADD_COLLECTION_DATA,
    UPDATE_COLLECTION_DATA,
    DELETE_COLLECTION_DATA,
    SET_COLLECTION_DATA_STATUS,
    FLUSH_COLLECTION_UPDATE_STATUS
} from '../../actions/collections';

const initialState = {
    status: "pending",
    error: null,
    collections: [],
    updateStatus: null,
    collectionDatasStatus: "pending",
    collectionDatasError: null,
    collectionDatas: []
}

const collectionsReducer = (state = initialState, action) => {
    switch(action.type) {

        case FETCH_COLLECTIONS:
        
            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                collections: action.status === "success" ? [...action.collections] : [...state.collections]
            }

        case ADD_COLLECTION:
            return {
                ...state, 
                status: action.status,
                error: action.status === "error" ? action.error : null,
                collections: action.status === "success" ? [...state.collections, action.collection] : [...state.collections]
            }
        
        case DELETE_COLLECTION:
            return {
                ...state, 
                collections: action.status === "success" ? [...state.collections.filter(el => el.id !== action.id)] : [...state.collections]
            }
        
        case UPDATE_COLLECTION:
            return {
                ...state, 
                updateStatus: action.status,
                collections: action.status === "success" ? [
                    ...state.collections.map(elt => {
                        if(elt.id === action.id){
                            return {
                                ...action.collection
                            }
                        } else {
                            return elt
                        }
                    })
                ] : [...state.collections]
            }

        case FLUSH_COLLECTION_UPDATE_STATUS:
            return {
                ...state, 
                updateStatus: null,
            }

        case SET_COLLECTION_DATA_STATUS:
            return {
                ...state,
                collectionDatasStatus: action.status,
            }
    
        case FETCH_COLLECTION_DATA:
            return {
                ...state,
                collectionDatasStatus: action.status,
                collectionDatasError: action.status === "error" ? action.error : null,
                collectionDatas: action.status === "success" ? [...action.collectionDatas] : [...state.collectionDatas]
            }

        case ADD_COLLECTION_DATA:
            return {
                ...state,
                collectionDatas: [...state.collectionDatas, action.collectionData]
            }

        case UPDATE_COLLECTION_DATA:
            return {
                ...state,
                collectionDatas: [
                    ...state.collectionDatas.map(elt => {
                        if(elt.id === action.collectionData.id){
                            return {
                                ...action.collectionData
                            }
                        } else {
                            return elt
                        }
                    })
                ]
            }

        case DELETE_COLLECTION_DATA:
            return {
                ...state, 
                collectionDatas: [...state.collectionDatas.filter(el => el.id !== action.id)]
            }

        default: 
            return state;
    }
}


export default collectionsReducer;