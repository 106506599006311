import { FETCH_USER_PENDING, FETCH_USER_SUCCESS, FETCH_USER_ERROR, TOGGLE_CHANNEL } from '../../actions/user';
import { UPDATE_MEMBER } from '../../actions/members';
import { PUSHER_UPDATE_MEMBER } from '../../actions/pusher';
import { MEMBER_IS_UPDATING } from '../../actions/menu';

const initialState = {
    pending: false,
    rcuser: null,
    error: null,
    menuUpdate: null,
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case MEMBER_IS_UPDATING:
            return {
                ...state,
                menuUpdate: action.status,
                rcuser: action.status === "success" ? action.user : state.rcuser,
            }
        case FETCH_USER_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                rcuser: action.rcuser
            }
        case FETCH_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case TOGGLE_CHANNEL:
            return {
                ...state,
                rcuser: {
                    ...state.rcuser, settings: {
                        ...state.rcuser.settings, channels: [
                            ...state.rcuser.settings.channels.map(channel => {
                                return {
                                    ...channel,
                                    active: channel.name === action.channelName ? action.active : channel.active
                                }
                            })
                        ]
                    }
                }
            }

        case UPDATE_MEMBER:
            return {
                ...state,
                rcuser: state.rcuser?.rcId === action.member?.id ? {
                    ...state.rcuser,
                    ...action.member,
                    company: { ...state.rcuser.company },
                    rights: { ...state.rcuser.rights },
                    settings: { ...state.rcuser.settings }
                } : { ...state.rcuser }
            }


        case PUSHER_UPDATE_MEMBER:
            return {
                ...state,
                rcuser: state.rcuser.rcId === action.data.memberId ?
                    {
                        ...state.rcuser,
                        status: {
                            ...state.rcuser.status,
                            ...action.data.status
                        },
                        connected: action.data.connected
                    }
                    : { ...state.rcuser }
            }
        default:
            return state;
    }
}
export default userReducer;

export const getUser = state => state.rcuser;
export const getUserPending = state => state.pending;
export const getUserError = state => state.error;