import {
    FETCH_MEMBERS_PENDING,
    FETCH_MEMBERS_SUCCESS,
    FETCH_MEMBERS_ERROR,
    FLUSH_MEMBERS,
    UPDATE_MEMBER,
    FLUSH_UPDATE_MEMBER,
    TOGGLE_LIST_DRAWER,
    OPEN_INTERNAL_CHAT
} from '../../actions/members';

import { PUSHER_UPDATE_MEMBER } from "../../actions/pusher";

const initialState = {
    pending: false,
    status: null,
    members: [],
    error: null,
    drawer: {
        enabled: false,
        show: false,
        currentLiveId: null
    },
    updateMemberStatus: null
}

const membersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MEMBERS_PENDING:
            return {
                ...state,
                pending: true,
                status: action.status
            }
        case FETCH_MEMBERS_SUCCESS:
            return {
                ...state,
                pending: false,
                members: action.members,
                status: action.status
            }
        case FETCH_MEMBERS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
                status: action.status
            }

        case FLUSH_MEMBERS:
            return {
                ...state,
                members: []
            }

        case UPDATE_MEMBER:
            return {
                ...state,
                updateMemberStatus: action.status,
                members: action.status === "success" ?
                    [
                        ...state.members.map(memb => {
                            return memb.id !== action.member.id ? { ...memb } : { ...memb, ...action.member }
                        })
                    ]
                    :
                    state.members
            }

        case FLUSH_UPDATE_MEMBER:
            return {
                ...state,
                updateMemberStatus: null,
            }

        case PUSHER_UPDATE_MEMBER:
            return {
                ...state,
                members: [
                    ...state.members.map(member => {
                        return member.id === action.data.memberId ? {
                            ...member,
                            status: {
                                ...member.status,
                                ...action.data.status
                            },
                            connected: action.data.connected
                        } : { ...member }
                    })
                ]
            }

        case TOGGLE_LIST_DRAWER:
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    show: !state.drawer.show
                }
            }

        case OPEN_INTERNAL_CHAT:
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    show: true,
                    currentLiveId: action.liveId
                }
            }
        case "@@router/LOCATION_CHANGE":
            let regexp = /^\/members\/\w+$/;
            if (action.payload.action === "PUSH" && regexp.test(action.payload.location.pathname)) {
                return {
                    ...state,
                    forceReload: true
                }
            } else {
                return {
                    ...state
                }
            }

        default:
            return state;
    }
}
export default membersReducer;