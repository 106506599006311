import {
    FETCH_TICKETS_PENDING,
    FETCH_TICKETS_SUCCESS,
    FETCH_TICKETS_ERROR,
    FETCH_TICKET_PENDING,
    FETCH_TICKET_SUCCESS,
    FETCH_TICKET_ERROR,
    ADD_TICKET,
    TOGGLE_TODO,
    TOGGLE_QUICK_ACTION,
    FLUSH_TICKETS,
    ADD_TODO,
    UNSET_CURRENT_TICKET,
    ASSIGN_TICKET,
    UPDATE_TICKET,
    UPDATE_TICKET_STATUS,
    ADD_NOTE,
    CLEAR_TICKET,
} from '../../actions/tickets';

import _ from "lodash";

const initialState = {
    pending: false,
    pendingDetail: false,
    tickets: [],
    current: null,
    total: 0,
    error: null,
    forceReload: false
}

const ticketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_TICKET:
            return {
                ...state,
                current: null
            }
        case FETCH_TICKETS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_TICKETS_SUCCESS:
            return {
                ...state,
                pending: false,
                tickets: [...state.tickets, ...action.tickets],
                total: action.total,
                forceReload: false
            }
        case FETCH_TICKETS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case FETCH_TICKET_PENDING:
            return {
                ...state,
                pendingDetail: true
            }
        case FETCH_TICKET_SUCCESS:
            return {
                ...state,
                pendingDetail: false,
                current: action.ticket
            }

        case UNSET_CURRENT_TICKET:
            return {
                ...state,
                pendingDetail: false,
                current: null
            }

        case FETCH_TICKET_ERROR:
            return {
                ...state,
                pendingDetail: false,
                error: action.error
            }

        case ADD_TICKET:
            return {
                ...state,
                tickets: [
                    {
                        ..._.omit(action.ticket, ["isDelete", "createdAt", "updatedAt", "history", "lastHistoryFields", "id", "viewBy", "assigneeId"]),
                        todo: { done: 0, total: 0 },
                        rcid: action.ticket.id,
                        objectID: action.ticket.id,
                        createdDate: action.ticket.createdAt,
                        createdDate_timestamp: Date.parse(action.ticket.createdAt) / 1000
                    },
                    ...state.tickets
                ],
                pending: false
            }

        case TOGGLE_TODO:
            return {
                ...state,
                current: {
                    ...state.current,
                    todo: [
                        ...action.ticket.todo
                    ]
                },
                tickets: [
                    ...state.tickets.map(ticket => {
                        return ticket.rcid === action.ticket.id ? {
                            ...ticket,
                            todo: {
                                ...ticket.todo,
                                done: action.done ? ticket.todo.done + 1 : ticket.todo.done - 1
                            }
                        } : { ...ticket }
                    })
                ]
            }

        case TOGGLE_QUICK_ACTION:
            return {
                ...state,
                current: {
                    ...state.current,
                    todo: [...state.current.todo.map(todo => {
                        return {
                            ...todo,
                            quickActions: action.todoId === todo.id ? [...todo.quickActions.map(quickAction => {
                                return {
                                    ...quickAction,
                                    done: action.quickActionName === quickAction.name ? !quickAction.done : quickAction.done
                                }
                            })
                            ] : todo.quickActions
                        }
                    })]
                },
                tickets: [
                    ...state.tickets.map(ticket => {
                        return {
                            ...ticket,
                            todo: action.ticketId === ticket.id ? [
                                ...state.current.todo.map(todo => {
                                    return {
                                        ...todo,
                                        quickActions: action.todoId === todo.id ? [...todo.quickActions.map(quickAction => {
                                            return {
                                                ...quickAction,
                                                done: action.quickActionName === quickAction.name ? !quickAction.done : quickAction.done
                                            }
                                        })
                                        ] : todo.quickActions
                                    }
                                })
                            ] : ticket?.todo
                        }
                    })
                ]
            }


        case ADD_TODO:
            return {
                ...state,
                current: {
                    ...state.current,
                    todo: [...state.current.todo, action.todo]
                },
                tickets: [
                    ...state.tickets.map(ticket => {
                        return {
                            ...ticket,
                            todo: action.ticketId === ticket.rcid ? { ...ticket.todo, total: ticket.todo.total + 1 } : ticket?.todo
                        }
                    })
                ]
            }

        case FLUSH_TICKETS:
            return {
                ...state,
                tickets: []
            }

        case "@@router/LOCATION_CHANGE":
            let regexp = /^\/tickets\/\w+$/;
            if (action.payload.action === "PUSH" && regexp.test(action.payload.location.pathname)) {
                return {
                    ...state,
                    forceReload: true
                }
            } else {
                return {
                    ...state
                }
            }

        case UPDATE_TICKET_STATUS:
            return {
                ...state,
                current: {
                    ...state.current,
                    status: state.current.id === action.id ? action.status : state.current.status,
                    history: [
                        ...state.current.history,
                        {
                            type: "field_updated",
                            field: "status",
                            oldValue: state.current.status,
                            newValue: action.status,
                            author: {
                                ...action.author
                            },
                            timestamp: new Date(),
                        }
                    ],
                    timeline: [
                        ...state.current.timeline,
                        {
                            type: "field_updated",
                            field: "status",
                            oldValue: state.current.status,
                            newValue: action.status,
                            author: {
                                ...action.author
                            },
                            timestamp: new Date(),
                        }
                    ]
                },
                tickets: [
                    ...state.tickets.map(ticket => {
                        return {
                            ...ticket,
                            status: ticket.rcid === action.id ? action.status : ticket.status
                        }
                    })
                ]
            }

        case UPDATE_TICKET:
            return {
                ...state
            }

        case ASSIGN_TICKET:
            return {
                ...state,
                current: { ...action.ticket },
                tickets: [
                    ...state.tickets.map(ticket => {
                        return action.ticket.id === ticket.rcid ? { ...action.ticket } : { ...ticket }
                    })
                ]
            }

        case ADD_NOTE:
            return {
                ...state,
                current: {
                    ...state.current,
                    notes: [
                        ...state.current.notes,
                        action.note
                    ],
                    timeline: [
                        ...state.current.timeline,
                        {
                            type: "note",
                            ...action.note
                        }
                    ]
                }
            }

        default:
            return state;
    }
}
export default ticketsReducer;

export const getTickets = state => state.tickets;
export const getTicketsPending = state => state.pending;
export const getTicketsError = state => state.error;