const organizationsDatas = [
  {
    id: "1",
    "name": "Reecall",
    "parentOrgId": null,
    "color": "#beef0f",
    "ticketColors": {
      "base": "#A5AFFF",
      "selectedBackground": "#F6F7FF",
      "id": "blue2"
    }
  },
  {
    id: "2",
    "name": "RH",
    "parentOrgId": "1",
    "color": "#B00B1E",
    "ticketColors": {
      "base": "#F88673",
      "selectedBackground": "#FEF2F1",
      "id": "orange"
    }
  },
  {
    "id": "3",
    "name": "Compta",
    "parentOrgId": "1",
    "color": "#baaffe",
    "ticketColors": {
      "base": "#FEA5DE",
      "selectedBackground": "#FEF6FB",
      "id": "pink"
    }
  },
  {
    id: "4",
    "name": "Commercial",
    "importedById": null,
    "parentOrgId": "1",
    "color": "green",
    "ticketColors": {
      "base": "#FFBA8C",
      "selectedBackground": "#FFF8F3",
      "id": "orange2"
    }
  }
]
export default organizationsDatas;