import {
    STORE_CURRENT_ELEMENT,
    STORE_TWILIO_ELEMENTS,
    STORE_MESSAGES_ELEMENTS,
    GENERATE_DATAS,
    RESET_TIMELINE,
    STORE_FILTERED_DATAS,
    SET_VOICEWAVE_READY,
    STORE_COLLECTED_DATAS,
} from '../../actions/timeline';

const initialState = {
    currentElement: {},
    messages: [],
    twilio: [],
    collectedDatas: [],
    datas: [],
    filteredDatas: [],
    voiceWaveReady: false,
}

const timelineReducer = (state = initialState, action) => {
    switch(action.type) {
        case STORE_CURRENT_ELEMENT:
            return {
                ...state,
                currentElement: action.element,
            }
        case STORE_TWILIO_ELEMENTS:
            return {
                ...state,
                twilio: [...action.datas],
            }
        case STORE_MESSAGES_ELEMENTS:
        return {
            ...state,
            messages: [...action.datas],
        }
        case RESET_TIMELINE:
            return {
                currentElement: {},
                messages: [],
                twilio: [],
                collectedDatas: [],
                datas: [],
                filteredDatas: [],
                voiceWaveReady: false,
            }
        case GENERATE_DATAS:
            return {
                ...state,
                datas: [...state.twilio, ...state.messages, ...state.collectedDatas],
            }
        case STORE_FILTERED_DATAS:
            return {
                ...state,
                filteredDatas: [...action.datas],
            }
        case SET_VOICEWAVE_READY:
            return {
                ...state,
                voiceWaveReady: action.bool,
            }
        case STORE_COLLECTED_DATAS:
            return {
                ...state,
                collectedDatas: action.collectedDatas,
            }
        default:
            return state;
    }
}
export default timelineReducer;
