import {
    CREATE_DECK,
    SET_ACTIONS_LIST,
    ADD_ACTION,
    CREATE_FOLDER,
    REMOVE_ACTION,
    SET_CURRENT_DECK,
    SELECT_FOLDER,
    SELECT_ACTION,
    UPDATE_ACTION,
    UPDATE_DECK,
    DELETE_DECK,
    FETCH_DECKS_PENDING,
    FETCH_DECKS_SUCCESS,
    FETCH_DECKS_ERROR,
    FLUSH_DECKS,
    FETCH_DECKS_BY_COMPANY_ID,
    FLUSH_FETCH_DECKS_BY_COMPANY_ID
} from '../../actions/deck';
import { v4 as uuid } from 'uuid';

const initialState = {
    pending: false,
    error: null,
    currentDeckId: null,
    selectedFolderId: null,
    selectedActionId: null,
    decks: [],
    decksByCompanyId: [],
    decksByCompanyIdStatus: null,
}

const deckReducer = (state = initialState, action) => {
    switch(action.type) {

        case FLUSH_FETCH_DECKS_BY_COMPANY_ID:
            return { ...state, decksByCompanyIdStatus: null }

        case FETCH_DECKS_BY_COMPANY_ID:
            return { ...state, decksByCompanyId: action.decks, decksByCompanyIdStatus: action.status }

        case FLUSH_DECKS:
            return { ...state, decks: []}

        case FETCH_DECKS_PENDING:
            return { ...state, pending: true }
            
        case FETCH_DECKS_SUCCESS:
            return { ...state, pending: false, decks: [...state.decks, ...action.decks] }

        case FETCH_DECKS_ERROR:
            return { ...state, pending: false, error: action.error }

        case SET_CURRENT_DECK:
            return {...state, currentDeckId: action.deckId, selectedActionId: null, selectedFolderId: null}

        case SELECT_FOLDER:
            return {...state, selectedFolderId: action.folderId, selectedActionId: null}

        case SELECT_ACTION:
            return {...state, selectedActionId: action.actionId}

        case CREATE_DECK: 
            return {
                ...state,
                decks: [
                    ...state.decks,
                    {
                        ...action.deck
                    }
                ]
            }

        case SET_ACTIONS_LIST: 
            return {
                ...state,
                decks: [
                    ...state.decks.map(deck => {
                        return {
                            ...deck,
                            children: action.deckId === deck.id ? [...action.children] : [...deck.children]
                        }
                    })
                ]
            }

        case ADD_ACTION:
            return {
                ...state,
                decks: [
                    ...state.decks.map(deck => {
                        return {
                            ...deck,
                            children: action.deckId === deck.id ?
                                action.folderId ? [
                                    ...deck.children.map(actionList => {
                                        if(actionList.id === action.folderId){
                                            return {
                                                ...actionList, 
                                                childrens: [
                                                    ...actionList.childrens.slice(0, action.index - 1),
                                                    {...action.action},
                                                    ...actionList.childrens.slice(action.index - 1)
                                                ]};
                                        } else {
                                            return {...actionList};
                                        }
                                    })
                                ] : 
                                [
                                    ...deck.children.slice(0, action.index),
                                    {...action.action},
                                    ...deck.children.slice(action.index)
                                ]
                            : [...deck.children]
                        }
                    })
                ]
            }

        case UPDATE_ACTION:
            return {
                ...state,
                decks: [
                    ...state.decks.map(deck => {
                        return {
                            ...deck,
                            children: action.deckId === deck.id ?
                                [
                                    ...deck.children.map(actionList => {
                                        if(action.actionId === actionList.id){
                                            return {
                                                ...actionList,
                                                ...action.updatedValues,
                                                datas: {
                                                    ...actionList.datas,
                                                    ...action.updatedValues.datas
                                                },
                                                fields : [...actionList.fields.map(el => {
                                                    let defaultValue = Object.assign({}, actionList.datas, action.updatedValues.datas)?.[el.key];
                                                    return defaultValue ? {...el, default: defaultValue} : el;
                                                })]
                                            }
                                        } else if(actionList.itemType === "folder"){
                                            return {
                                                ...actionList,
                                                childrens: [
                                                    ...actionList.childrens.map(child => {
                                                        if(child.id === action.actionId){
                                                            return {
                                                                ...child,
                                                                ...action.updatedValues,
                                                                datas: {
                                                                    ...child.datas,
                                                                    ...action.updatedValues.datas
                                                                },
                                                                fields : [...child.fields.map(el => {
                                                                    let defaultValue = Object.assign({}, child.datas, action.updatedValues.datas)?.[el.key];
                                                                    return defaultValue ? {...el, default: defaultValue} : el;
                                                                })]
                                                            }
                                                        }
                                                        return {...child}
                                                    })
                                                ]
                                            }
                                        }
                                        return {...actionList}
                                    })
                                ]
                            : [...deck.children]
                        }
                    })
                ]
            }

        case UPDATE_DECK:
            return {
                ...state,
                decks: [
                    ...state.decks.map(deck => {
                        return action.deckId === deck.id ? {...action.deck} : {...deck}
                    })
                ]
            } 

        case DELETE_DECK:
            return {
                ...state,
                currentDeckId: null,
                selectedFolderId: null,
                selectedActionId: null,
                decks: [...state.decks.filter(deck => deck.id !== action.deckId)]
            }

        case CREATE_FOLDER:
            return {
                ...state,
                decks: [
                    ...state.decks.map(deck => {
                        return {
                            ...deck,
                            children: action.deckId === deck.id ? [
                            ...deck.children.slice(0, action.index),
                            {
                                id: `folder-${uuid()}`,
                                itemType: "folder",
                                color: "primary",
                                icon: ["fas", "folder"],
                                fields : [],
                                childrens: []
                            },
                            ...deck.children.slice(action.index)
                            ] : [...deck.children]
                        }
                    })
                ]
            }

        case REMOVE_ACTION:
            return {
                ...state,
                decks: [
                    ...state.decks.map(deck => {
                        return {
                            ...deck,
                            children: action.deckId === deck.id ?
                                [
                                    ...deck.children.map(actionList => {
                                        if(actionList?.childrens){
                                            return {
                                                ...actionList, 
                                                childrens: [
                                                   ...actionList.childrens.filter( child => child.id !== action.actionId)
                                                ]};
                                        } else {
                                            return { ...actionList }
                                        }
                                    })
                                ].filter( child => child.id !== action.actionId)
                            : [...deck.children]
                        }
                    })
                ]
            }

        default: 
            return state;
    }
}
export default deckReducer;