import React from "react"
import { FormattedMessage } from "react-intl";
import { selectChannelsConnectionsByMemberId, selectChannelById } from "../channels"

export const FETCH_MEMBERS_PENDING = 'FETCH_MEMBERS_PENDING';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_ERROR = 'FETCH_MEMBERS_ERROR';
export const FLUSH_MEMBERS = 'FLUSH_MEMBERS';
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const FLUSH_UPDATE_MEMBER = "FLUSH_UPDATE_MEMBER";
export const TOGGLE_LIST_DRAWER = "TOGGLE_LIST_DRAWER";
export const OPEN_INTERNAL_CHAT = "OPEN_INTERNAL_CHAT";

export const openInternalChat = (liveId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: OPEN_INTERNAL_CHAT, liveId: liveId });
    }
}

function fetchMembersPending() {
    return {
        type: FETCH_MEMBERS_PENDING,
        status: "pending"
    }
}

function fetchMembersSuccess(members) {
    return {
        type: FETCH_MEMBERS_SUCCESS,
        members: members,
        status: "success"
    }
}

function fetchMembersError(error) {
    return {
        type: FETCH_MEMBERS_ERROR,
        error: error,
        status: "error"
    }
}

//search for fetchMembers & fetchAllMembers
const search = (searchTerm) => {
    if (searchTerm.search.length === 0) return;
    switch (searchTerm.field) {
        case "":
            return
        case "keyword":
            return {
                "or": [
                    { "firstName": { "like": `.*${searchTerm.search}.*`, "options": "i" } },
                    { "lastName": { "like": `.*${searchTerm.search}.*`, "options": "i" } },
                    { "email": { "like": `.*${searchTerm.search}.*`, "options": "i" } }]
            };
        case "email":
            return { "email": searchTerm.search };
        case "companyId":
            return { "companyId": searchTerm.search };
        case "id":
            return { "id": searchTerm.search };
        default:
            break;
    }
}

export const fetchMembers = (companyId, searchTerm) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchMembersPending());
        return rcsdk
            .getCompanyMembers(companyId ? companyId : getState().company.company.id)
            .include("memberships")
            .where(searchTerm && search(searchTerm))
            .then(members => {
                return dispatch(fetchMembersSuccess(members));
            })
            .catch(err => {
                dispatch(fetchMembersError(err.message));
            })
    }
}

export const fetchBoUsers = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchMembersPending());
        return rcsdk
            .getMembers()
            .include("memberships")
            .where({ "reecallMember": true })
            .then(members => {
                return dispatch(fetchMembersSuccess(members));
            })
            .catch(err => {
                dispatch(fetchMembersError(err.message));
            })
    }
}

export const fetchHubspotProfiles = () => {
    return (dispatch, getState, { rcsdk, rcPayment }) => {
        return rcPayment.getHubspotOwners()
            .then(response => {
                return response
            }).catch(err => {
                console.log(err)
            })
    }
}



export const fetchCountMembers = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        let where = companyId ? { companyId: `id:${companyId}` } : {}
        return rcsdk.Stats
            .getCount("Membership")
            .where(where)
            .then(response => {
                return response.count
            }).catch(err => {
                console.log(err)
            })
    }
}

export const fetchAllMembers = (searchTerm, limit) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchMembersPending());
        return rcsdk
            .getMembers()
            .include("memberships")
            .where(searchTerm && search(searchTerm))
            .limit(limit)
            .order("createdAt desc")
            .then(members => {
                return dispatch(fetchMembersSuccess(members));
            }).catch(err => {
                dispatch(fetchMembersError(err.message));
            })
    }
}

export function flushMembers() {
    return {
        type: FLUSH_MEMBERS
    }
}

export function updateMember(id, datas, showToast = true) {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: UPDATE_MEMBER, status: "pending" })
        return rcsdk
            .updateMember(id, datas)
            .then(member => {
                return dispatch({
                    type: UPDATE_MEMBER,
                    member: member,
                    status: "success",
                    successToast: showToast ? {
                        type: "UPDATE",
                        message: <FormattedMessage id="members.toast.update.success" defaultMessage="Member updated successfully" />
                    } : null
                });
            })
            .catch(err => {
                dispatch({
                    type: UPDATE_MEMBER,
                    status: "error",
                    error: err,
                    errorToast: {
                        type: "ERROR",
                        message: <FormattedMessage id="members.toast.update.error" defaultMessage="Oups... could not could not update member. Please try again" />
                    }
                });
            });
    }
}

export function getMembership(id) {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk
            .getMembership(id)
            .then(membership => {
                console.log(membership)
            })
            .catch(err => {
                console.log(err.message);
            });
    }
}

export function updateMembership(id, datas, member, showToast = true) {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk
            .updateMembership(id, datas)
            .then(membership => {
                let obj = member;
                obj["memberships"] = [membership]
                return dispatch({
                    type: UPDATE_MEMBER,
                    member: obj,
                    successToast: showToast ? {
                        type: "UPDATE",
                        message: <FormattedMessage id="members.toast.update" defaultMessage="Member updated successfully" />
                    } : null
                });
            })
            .catch(err => {
                console.log(err.message);
            });
    }
}


const selectMembers = state => state.members.members;
const selectMemberById = (state, { memberId }) => {
    if (!memberId) return null;
    return state.members.members.find(el => el.id === memberId);
}

const selectMembersWithTeam = (state, { excludeMe }) => {
    const members = state.members.members.map(member => {
        if (excludeMe && member.id === state.user.rcuser.rcId) return null;
        const teamsMember = state.teams.teams.filter(team => team.memberIds.includes(member.id))
        return {
            ...member,
            teams: teamsMember
        }
    }).filter(el => el)
    return members;
}

const selectMembersWithChannelConnections = (state, { excludeMe }) => {
    return selectMembersWithTeam(state).map(member => {
        if (excludeMe && member.id === state.user.rcuser.rcId) return null;

        const channelConnections = selectChannelsConnectionsByMemberId(state, { memberId: member.id }).map(channelConnection => {
            return {
                ...channelConnection,
                channel: selectChannelById(state, { channelId: channelConnection.channelId })
            }
        });
        return {
            ...member,
            channelConnections: channelConnections,
            isPhoneActive: channelConnections ? !!channelConnections.filter(channelConnection => channelConnection.active && channelConnection.channel.type === "phone").length : false
        }
    }).filter(el => el)
}

const selectConnectedMembers = state => selectMembers(state).filter(el => el.connected);

const isDrawerListOpen = state => state.members.drawer.show;

export { selectMembers, selectMemberById, selectMembersWithTeam, selectMembersWithChannelConnections, isDrawerListOpen, selectConnectedMembers }