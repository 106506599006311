
import React from "react";
import { FormattedMessage } from "react-intl";
import moment from 'moment';
import axios from "axios";
import { generateWhere } from '../../../utility/utils'

export const FETCH_CONVERSATIONS_PENDING = 'FETCH_CONVERSATIONS_PENDING';
export const FETCH_CONVERSATIONS_SUCCESS = 'FETCH_CONVERSATIONS_SUCCESS';
export const FETCH_CONVERSATIONS_ERROR = 'FETCH_CONVERSATIONS_ERROR';
export const FETCH_CONVERSATION_PENDING = 'FETCH_CONVERSATION_PENDING';
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS';
export const FETCH_CONVERSATION_ERROR = 'FETCH_CONVERSATION_ERROR';
export const FLUSH_CONVERSATIONS = 'FLUSH_CONVERSATIONS';
export const LINK_TICKET = 'LINK_TICKET';
export const UNSET_CURRENT_CONVERSATION = 'UNSET_CURRENT_CONVERSATION';
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION'
export const UPDATE_CONVERSATION_COLLECTED_DATAS = 'UPDATE_CONVERSATION_COLLECTED_DATAS';
export const SET_NAVBAR_CONVERSATION_TOTAL = 'SET_NAVBAR_CONVERSATION_TOTAL';
export const FETCH_CONVERSATION_LIST_TOTAL = 'FETCH_CONVERSATION_LIST_TOTAL';
export const INCREMENT_UNFETCHED_CONVERSATION_TOTAL = "INCREMENT_UNFETCHED_CONVERSATION_TOTAL";
export const RELOAD_LIST = "RELOAD_LIST";

const ITEM_PER_PAGE = 100;


export const unsetCurrentConversation = () => {
    return {
        type: UNSET_CURRENT_CONVERSATION
    }
}

function fetchConversationsPending() {
    return {
        type: FETCH_CONVERSATIONS_PENDING
    }
}

function fetchConversationsSuccess(conversations, total, forCompanyDetails) {
    return {
        type: FETCH_CONVERSATIONS_SUCCESS,
        conversations: conversations,
        total: total,
    }
}

function fetchConversationsError(error) {
    return {
        type: FETCH_CONVERSATIONS_ERROR,
        error: error
    }
}

function fetchConversationPending() {
    return {
        type: FETCH_CONVERSATION_PENDING
    }
}

function fetchConversationSuccess(conversation) {
    return {
        type: FETCH_CONVERSATION_SUCCESS,
        conversation: conversation
    }
}

function fetchConversationError(error) {
    return {
        type: FETCH_CONVERSATION_ERROR,
        error: error
    }
}

export function flushConversations() {
    return {
        type: FLUSH_CONVERSATIONS,
    }
}

export const linkTicketToConversation = (conversationId, ticketId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.setConversationTicket(conversationId, ticketId).then(conversationResponse => {
            dispatch({
                type: UPDATE_CONVERSATION,
                conversation: conversationResponse
            })
        }).catch(err => {
            dispatch(fetchConversationError(err.message));
        })
    }
}

export const fetchConversations = ({
    startIndex = 0,
    stopIndex = ITEM_PER_PAGE,
    channelType = "all",
    flush = false,
    searchTerm = "",
    order = "createdAt DESC",
    companyId = null,
    filters = null,
    filtersFromTable = null
}) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        if (flush) {
            dispatch(flushConversations());
            dispatch(fetchConversationsPending());
        }

        const where = generateWhere({ searchTerm, filters, companyId, channelType, filtersFromTable }) || {}

        return rcsdk.getConversations()
            // .params({ groupContacts: "true" })
            .include(["contact"])
            .skip(startIndex)
            .limit(stopIndex - startIndex + 1)
            .raw()
            .where(where)
            .order(order)
            .then((resp) => {
                const total = parseInt(resp.headers["content-range"].substr(resp.headers["content-range"].indexOf("/") + 1));
                dispatch(fetchConversationsSuccess(resp.data, total));
                return { total: total, totalRequest: resp.data.length }
            }).catch(err => {
                dispatch(fetchConversationsError(err.message));
            })
    }
}

export const fetchConversationListTotals = (channelType = "all", searchTerm = "" || {}, dateRange, companyId = null, filters = null, filtersFromTable = null) => {
    return (dispatch, getState, { rcsdk }) => {

        dispatch({
            type: FETCH_CONVERSATION_LIST_TOTAL,
            pending: true
        })

        const where = generateWhere({ searchTerm, filters, companyId, dateRange, channelType, filtersFromTable }) || {}

        // let promiseCountMissed = rcsdk.getConversations().raw().where({ ...where, missed: true })
        // let promiseCountNotMissed = rcsdk.getConversations().raw().where({ ...where, missed: false })
        let promiseCountMissed = rcsdk._request().route(`/conversations/count`).params({ where: { ...where, missed: true } })
        let promiseCountNotMissed = rcsdk._request().route(`/conversations/count`).params({ where: { ...where, missed: false } })

        Promise.all([
            promiseCountMissed,
            promiseCountNotMissed,
        ]).then(resp => {
            // const totalMissed = parseInt(resp[0].headers["content-range"].substr(resp[0].headers["content-range"].indexOf("/") + 1));
            // const totalNotMissed = parseInt(resp[1].headers["content-range"].substr(resp[1].headers["content-range"].indexOf("/") + 1));

            dispatch({
                type: FETCH_CONVERSATION_LIST_TOTAL,
                missed: resp[0]?.count,
                notMissed: resp[1]?.count
                // missed: totalMissed,
                // notMissed: totalNotMissed
            })
        });
    }
}

export const fetchConversation = (id, isCallSid = false) => {
    return (dispatch, getStat, { rcsdk }) => {
        dispatch(fetchConversationPending());
        if (isCallSid) {
            return rcsdk.getConversations().where({ callSid: id }).include(["contact"]).then((conversations) => {
                if (conversations[0]) {
                    dispatch(fetchConversationSuccess(conversations[0]));
                    return conversations[0];
                } else {
                    dispatch(fetchConversationError(null));
                }
            }).catch(err => {
                dispatch(fetchConversationError(err.message));
            })
        } else {
            return rcsdk.getConversation(id).include(["contact"]).then((conversation) => {
                dispatch(fetchConversationSuccess(conversation));
                return conversation;
            }).catch(err => {
                dispatch(fetchConversationError(err.message));
            })
        }
    }
}

export const updateConversation = ({ id, datas }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.updateConversation(id, datas)
            .then((conversation) => {
                const dispatchUpdate = (formattedConversation) => {
                    dispatch({
                        type: UPDATE_CONVERSATION,
                        conversation: formattedConversation,
                        successToast: {
                            type: "UPDATE",
                            message: <FormattedMessage id="conversations.toast.update" defaultMessage="Conversation updated successfully" />
                        }
                    })
                    return formattedConversation;
                }

                if (conversation.contactId) {
                    rcsdk.getContact(conversation.contactId).then(contact => {
                        dispatchUpdate({ ...conversation, contact: contact });
                    })
                } else {
                    dispatchUpdate({ ...conversation, contact: null });
                }
            }).catch(err => {
                console.log(err, "err");
            });
    }
}

export const updateConversationCollectedDatas = (id, datas) => {
    return (dispatch, getState, { rcsdk }) => {

        return rcsdk.editConversationData(id, datas).then((resp) => {
            dispatch({
                type: UPDATE_CONVERSATION_COLLECTED_DATAS,
                id: id,
                datas: resp.collectedData
            })
        });
    }
}

export const editConversationTagsByCaller = (convId, tagIds) => {
    return (dispatch, getState, { rcsdk }) => {
        return rcsdk.editConversationTags(convId, tagIds)
    }
}

export const fetchNavBarConversationTotal = () => {
    return (dispatch, getState, { rcsdk }) => {
        rcsdk.Stats.getCount("Conversation")
            .where({
                missed: true,
                createdAt: { $gte: `date:${moment().startOf("day").format()}` }
            })
            .then(resp => {
                dispatch({ type: SET_NAVBAR_CONVERSATION_TOTAL, total: resp.count });
            })
    }
}

export const updateConversationInApp = ({ conversationId }) => {
    return (dispatch, getState, { rcsdk }) => {
        return rcsdk.getConversation(conversationId).include(["contact"]).then((conversation) => {
            dispatch({
                type: UPDATE_CONVERSATION,
                conversation: conversation
            });
        }).catch(() => { })
    }
}

export const incrementUnfetchedConversationTotal = () => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: INCREMENT_UNFETCHED_CONVERSATION_TOTAL });
    }
}


const getConversationsSubjectsFragment = ({
    order = "createdAt DESC",
    companyId = null,
    filters = {},
    startIndex = 0,
    limit = 500,
    subjectKey = "rcsubjet"
}) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        let where = companyId ? { "companyId": { "eq": companyId }, ...filters } : { ...filters };

        return rcsdk.getConversations()
            .fields(["collectedData"])
            .where(where)
            .order(order)
            .skip(startIndex)
            .limit(startIndex + limit)
            .raw()
            .then((resp) => {
                const total = parseInt(resp.headers["content-range"].substr(resp.headers["content-range"].indexOf("/") + 1));
                let subjects = resp.data.map(el => el.collectedData.find(el2 => el2.label === subjectKey))
                    .filter(el => el && el?.value && el?.value !== "")
                    .map(el => el?.value);

                return {
                    data: subjects,
                    nextPage: total > startIndex + limit ? startIndex + limit : undefined
                }
            })
    }
};

export const getConversationsSubjects = ({
    order = "createdAt DESC",
    companyId = null,
    filters = {},
    startIndex = 0,
    limit = 500,
    subjectKey = "rcsubject"
}) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return dispatch(getConversationsSubjectsFragment({ order, companyId, filters, startIndex, limit, subjectKey }))
            .then(fragment => {
                if (fragment.nextPage) {
                    return dispatch(getConversationsSubjects({ order, companyId, filters, startIndex: fragment.nextPage, limit, subjectKey }))
                        .then(nextFragment => {
                            return fragment.data.concat(nextFragment)
                        })
                } else {
                    return fragment.data;
                }
            }).catch(err => {
                dispatch({ type: "NOACTION", error: err.message })
            })
    }
}

export const getConversationClusters = ({
    eps = 0.95,
    ...props
}) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return dispatch(getConversationsSubjects({ ...props })).then(data => {
            let subjects = [...new Set(data)].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })) //remove duplicate and sort

            const token = rcsdk.TOKEN
            const axiosConfig = {
                headers: { Authorization: `Bearer ${token}` },
            }

            if (subjects.length > 0) {
                return axios.post(
                    `https://bastien.reecall.co/cluster`,
                    { "sentences": subjects, "eps": eps },
                    axiosConfig
                ).then((response) => {
                    return {
                        subjects: subjects,
                        ...response.data
                    }
                }).catch(error => {
                    const err = new Error(error.message);
                    err.error = error;
                    throw err;
                })
            } else {
                return {
                    subjects: false,
                    clusters: false,
                    outliers: false
                }
            }
        }).catch(error => {
            const err = new Error(error.message);
            err.error = error;
            throw err;
        })
    }
}