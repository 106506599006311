import {
    SHOW_MODAL,
    HIDE_MODAL,
    SET_MODAL_CALLBACK_VALUE,
    SET_MODAL_STATE,
    SET_SPINNER_STATUS
} from '../../actions/modals';

const initialState = {
    modalType: null,
    modalProps: {},
    callbackValue: null,
    modalState: null,
    spinnerStatus: null
}

const modalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MODAL_STATE:
            return {
                ...state,
                modalState: action.value
            }
        case SET_SPINNER_STATUS:
            return {
                ...state,
                spinnerStatus: action.status
            }
        case SET_MODAL_CALLBACK_VALUE:
            return {
                ...state,
                callbackValue: action.value
            }
        case SHOW_MODAL:
            return {
                modalType: action.modalType,
                modalProps: action.modalProps,
                callbackValue: action.callbackValue || state.callbackValue,
            }
        case HIDE_MODAL:
            return initialState
        default:
            return state
    }
}

export default modalsReducer;