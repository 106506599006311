import React from "react"
import { NavLink } from "react-router-dom"
import { X, Menu } from "react-feather"
import { NavLink as NavLinkReactStrap } from "reactstrap"
import classnames from "classnames"

const SidebarHeader = ({toggleSidebarMenu, sidebarCollapsed, toggle, sidebarVisibility}) => {
    return (
        <div className="navbar-header">
            <ul className="nav navbar-nav flex-row">
                <li className="nav-item nav-item-brand mr-auto">
                    {
                    sidebarCollapsed  === false ? (
                        <NavLink to="/" className="navbar-brand active">
                            <div className="brand-logo" />
                        </NavLink>
                    ) : (
                        <NavLinkReactStrap
                            className="navbar-brand active menu-toggle d-flex justify-content-center"
                            onClick={() => toggleSidebarMenu(false)}
                        >
                            <div className="brand-logo" />
                        </NavLinkReactStrap>
                    )
                    }
                </li>
                <li className="nav-item nav-toggle">
                    <div className="nav-link modern-nav-toggle">
                        {
                            <Menu 
                                onClick={() => {
                                    toggleSidebarMenu(sidebarCollapsed === false)
                                    toggle()
                                }}
                                className={classnames(
                                    "ficon toggle-icon icon-x d-none d-xl-block font-medium-4"
                                )}
                                size={24}
                                data-tour="toggle-icon"
                            />
                        }
                        <X
                            onClick={sidebarVisibility}
                            className={classnames(
                                "toggle-icon icon-x d-block d-xl-none font-medium-4"
                            )}
                            size={20}
                        />
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default SidebarHeader