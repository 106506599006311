import React, { useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import MemberListItem from "./MemberListItem";
import { selectMembersWithTeam } from "../../../redux/actions/members"
import ListSearch from "../lists/ListSearch"
import { searchByText } from "../../../utility/utils";
import { FormattedMessage } from "react-intl";

const MembersList = ({ excludeMe, onChat, onClose }) => {
    const [search, setSearch] = useState("");
    const members = useSelector(state => selectMembersWithTeam(state, { excludeMe: excludeMe }));
    return <>
        <ListSearch
            searchTerm={search}
            onChange={value => {
                setSearch(value)
            }}
            className="m-1 flex-shrink-0"
            filterConfig={{ show: false }}
            filterOptions={[
                {value: 'keyword', label: <FormattedMessage id="listsearch.keyword" defaultMessage="Keyword" />},
                { value: 'companyId', label: <FormattedMessage id="listsearch.companyId" defaultMessage="Company Id" /> },
                { value: 'id', label: <FormattedMessage id="listsearch.id" defaultMessage="Id" /> },
            ]}
        />
        <div className="membersList overflow-auto px-1" >
            {_.orderBy(searchByText(members, search, ["id", "avatar", "channels"]), ["connected", "lastName"], ["desc", "asc"]).map(member => {
                return <MemberListItem
                    key={`membersList-member-${member.id}`}
                    member={member}
                    onChat={onChat}
                    onClose={onClose}
                />
            })}
        </div>
    </>
}

MembersList.defaultProps = {
    excludeMe: false,
    onChat: () => { },
    onClose: () => { }
}

export default MembersList;