import React from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {useSelector, useDispatch} from "react-redux"
import { SET_ACTIVE_MENU, SET_OPENED_MENU } from "../../../redux/actions/menu"

const MenuItemText = ({icon, title, token = null, data = null}) => {
    return <div className="menu-text">
        {icon}
        <div className="menu-item menu-title d-flex flex-column justify-content-center">
        
            <span className="text-truncate" style={{maxWidth: "145px"}}>{title}</span>
      
            <div className="d-flex align-items-center menu-description">
                {token && <span className="menu-token text-muted font-small-2">
                    { token }
                </span>}
                <div className={`text-muted font-small-2 ${token ? "ml-50 " : ""}`}>
                    { data?.live && <FontAwesomeIcon icon={["far", "bolt"]} className="mr-0"/> }
                    { data?.escalation && <FontAwesomeIcon icon={["far", "user-chart"]} className={`mr-0 ${data?.live ? "ml-50" : ""}`} /> }
                </div>
            </div>

        </div>
    </div>
}

const MenuChannel = ({item, windowSize, toggleMenu}) => {
    const dispatch = useDispatch();
    const menuStore = useSelector(state => state.menu);
    const CustomAnchorTag = item?.isChatItem && item.active ? Link : `a`;
     
    const onItemClick = ({el, event}) => {
        if(!el?.isLiveItem || (el.active && el.isChatItem)){
            if (windowSize.width <= 1200) {
                toggleMenu()
            }
            dispatch({type: SET_ACTIVE_MENU, id: el.id});
            dispatch({type: SET_OPENED_MENU, id: null});
        }
    } 

    return <>
        <li
            className={classnames({
                "nav-item": !item.isChild,
                disabled: item.disabled,
                active: menuStore.activeMenu === item.id
            })}
            key={item.id}
            id={`menuitem-${item.id}`}
        >
            <CustomAnchorTag
                to={ item.navLink ? item.navLink : "" }
                className={`d-flex`}
                onClick={(e) => onItemClick({el: item, event: e})}
            >
                <MenuItemText {...item} />

                {item.statusBadge && (!item.badge || item.badge === "") && 
                    <div className="ml-auto d-flex align-items-center" style={{marginRight: "-6px"}}>
                        <FontAwesomeIcon icon={["fas", "circle"]} className={classnames("mr-0", {"success": item.active, "danger": !item.active })} style={{  width: "11px", height: "11px" }} />
                    </div>
                }
            </CustomAnchorTag>
        </li>
    </>
} 

export default MenuChannel