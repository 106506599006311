import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

const reecallToaster = (requestStatus) => {
    if(requestStatus === "success")
        toast.success(<FormattedMessage id="global.successToast" defaultMessage="Saved successfully ! 👌" /> , {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            progress: undefined,
        })
    else if (requestStatus === "error")
        toast.error(<FormattedMessage id="global.errorToast" defaultMessage="An error occured, please try again" /> , {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            progress: undefined,
        })
    else if(requestStatus === "updated")
        toast.success(<FormattedMessage id="global.updatedToast" defaultMessage="Updated successfully ! 👌" /> , {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            progress: undefined,
        })
    else if(requestStatus === "deleted")
        toast.success(<FormattedMessage id="global.deletedToast" defaultMessage="Deleted successfully ! 👌" /> , {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            progress: undefined,
        })
    else if(requestStatus === "created")
        toast.success(<FormattedMessage id="global.createdToast" defaultMessage="Created successfully ! 👌" /> , {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            progress: undefined,
        })
};

export default reecallToaster;