import React, { useState } from "react"
import { Button, Spinner } from "reactstrap"
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import { HIDE_MODAL } from "../../../redux/actions/modals";
import { TOGGLE_TAGS_TRAIN_MODAL } from "../../../redux/actions/menu"
import { useDispatch, useSelector } from "react-redux";
import { setTrainNotifStatus } from "../../../redux/actions/ticketTags"
import { GET_DIRTY } from "../../../redux/actions/ticketTags"
import { updateCompanySettings } from "../../../redux/actions/company";



const TrainingButton = ({ companyId, inModal, style }) => {

    const dispatch = useDispatch();

    const trainNotif = useSelector(state => state.ticketTags.trainNotifStatus);

    const [trainingIsClicked, setTrainingIsClicked] = useState(false);

    return <>
        {trainNotif && !inModal && <FontAwesomeIcon icon={["fas", "bell"]} className="text-danger wobble-hor-top" style={{ position: "absolute", marginTop: "-5px", marginRight: "-5px", height: "14px" }} />}
        <Button
            outline
            style={style || { padding: "6px" }}
            size={"sm"}
            color={"primary"}
            onClick={() => {
                setTrainingIsClicked(true)
                dispatch(setTrainNotifStatus(false))
                axios
                    .post('https://tag.reecall.co/update', { companyId: companyId })
                    .then((response) => {
                        setTrainingIsClicked(false)
                        dispatch(updateCompanySettings({ "shouldTrainTags": false }, true))
                        dispatch({ type: TOGGLE_TAGS_TRAIN_MODAL, show: false })
                        dispatch({ type: GET_DIRTY, value: false })
                        toast.success(<FormattedMessage id="tag.training.success" defaultMessage="Training complete" />)
                        if (inModal) {
                            dispatch({ type: HIDE_MODAL })
                        }
                    }).catch(function (error) {
                        dispatch(setTrainNotifStatus(true))
                        setTrainingIsClicked(false)
                        toast.error(error)
                        console.log(error, "ERROR");
                        dispatch({ type: TOGGLE_TAGS_TRAIN_MODAL, show: true })
                    });
            }}>
            {trainingIsClicked ?
                <div className="d-flex align-items-center text-bold-500" style={{ fontSize: "0.9rem", width: "160px" }}>
                    <FormattedMessage id="tag.training" defaultMessage="Training..." />
                    <Spinner size="sm" color="primary" className="ml-50" />
                </div>
                :
                <div className="d-flex align-items-center text-bold-500" style={{ fontSize: "0.9rem" }}>
                    <FormattedMessage id="tag.train" defaultMessage="Train" />
                    <FontAwesomeIcon icon={["fas", "chalkboard"]} className="ml-50" style={{ height: "14px" }} />
                </div>
            }
        </Button>
    </>
}

TrainingButton.defaultProps = {
    companyId: null,
    inModal: false
}

export default TrainingButton;
