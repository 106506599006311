import { AUTOMATHEQUE_FETCH_QAS } from "../../actions/automatheque/qaActions"

const initialState = {
    status: "pending",
    error: null,
    quickActionLibrairies: []
}

export const quickActions = (state = initialState, action) => {
    switch (action.type) {
        case AUTOMATHEQUE_FETCH_QAS:
            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                quickActionLibrairies: action.status === "success" ? [...action.quickActionLibrairies] : [...state.quickActionLibrairies]
            }

        default:
            return state
    }
}