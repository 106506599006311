import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toggleJsonBeautifierModal, toggleMarkdownBeautifierModal } from "../../../redux/actions/app"
import {useDispatch} from "react-redux"


const ToolsDropdown = () => {
    const dispatch = useDispatch();
    return <>
        <UncontrolledDropdown tag="li" className="nav-item">
            <DropdownToggle tag="a" className="nav-link nav-link-label">
                <FontAwesomeIcon icon={["far", "toolbox"]} style={{width: "21px", height: "21px"}}/>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => dispatch(toggleJsonBeautifierModal())}>
                    <FontAwesomeIcon icon={["far", "brackets-curly"]} /> JSON beautifier
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(toggleMarkdownBeautifierModal())}>
                    <FontAwesomeIcon icon={["fab", "markdown"]} /> Markdown editor
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    </>
}

export default ToolsDropdown;