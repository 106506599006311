const AUTOMATHEQUE_COMPANY_ID = "61e8175572468d00088e32a2";
const AUTOMATHEQUE_FETCH_RULES = "AUTOMATHEQUE_FETCH_RULES";
export { AUTOMATHEQUE_COMPANY_ID, AUTOMATHEQUE_FETCH_RULES }

export const fetchRules = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: AUTOMATHEQUE_FETCH_RULES,
            status: "pending"
        });

        return rcsdk
            .getRules()
            .where({companyId: AUTOMATHEQUE_COMPANY_ID})
            .then(rules => {
                dispatch({
                    type: AUTOMATHEQUE_FETCH_RULES,
                    status: "success",
                    rules: rules.map(rule => {
                        return {...rule, keywords: !rule?.keywords ? [] : rule?.keywords}
                    })
                });
        }).catch(err => {
            dispatch({
                type: AUTOMATHEQUE_FETCH_RULES,
                status: "error",
                error: err
            });
        })
    }
}