import {
    FETCH_FAQS_PENDING,
    FETCH_FAQS_SUCCESS,
    FETCH_FAQS_ERROR,
    FLUSH_FAQS,
    ADD_FAQ,
    UPDATE_FAQ,
    DELETE_FAQ,
    FETCH_FAQ_CATEGORIES,
    ADD_FAQ_CATEGORY,
    UPDATE_FAQ_CATEGORY,
    DELETE_FAQ_CATEGORY,
} from '../../actions/faqs';

const initialState = {
    pending: false,
    status: null,
    faqs: [],
    error: null,
    faqsCategories: [],
    faqsCategoriesStatus: null,
    faqsCategoriesActionStatus: null,
    trainStatus: null
}

const faqsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FAQS_PENDING:
            return {
                ...state,
                pending: true,
                status: "pending"
            }
        case FETCH_FAQS_SUCCESS:
            return {
                ...state,
                pending: false,
                faqs: action.faqs,
                status: "success"
            }
        case FETCH_FAQS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case FLUSH_FAQS:
            return {
                ...state,
                faqs: []
            }
        case ADD_FAQ:
            return {
                ...state,
                faqs: [...state.faqs, action.faq],
                pending: false
            }
        case UPDATE_FAQ:
            return {
                ...state,
                faqs: [...state.faqs.map(faq => {
                    return faq.id !== action.faq.id ? { ...faq } : { ...faq, ...action.faq }
                })],
                pending: false
            }
        case DELETE_FAQ:
            return {
                ...state,
                faqs: [...state.faqs.filter(el => el.id !== action.id)],
                pending: false
            }
        case FETCH_FAQ_CATEGORIES:
            return {
                ...state,
                faqsCategoriesStatus: action.status,
                faqsCategories: action.status === "success" ? [...action.faqsCategories] : [...state.faqsCategories]
            }

        case ADD_FAQ_CATEGORY:
            return {
                ...state,
                faqsCategoriesActionStatus: action.status,
                faqsCategories: action.status === "success" ? [...state.faqsCategories, action.faqCategory] : [...state.faqsCategories]
            }

        case UPDATE_FAQ_CATEGORY:
            return {
                ...state,
                faqsCategoriesActionStatus: action.status,
                faqsCategories: action.status === "success" ? [...state.faqsCategories.map(faqCategory => {
                    return faqCategory.id !== action.faqCategory.id ? { ...faqCategory } : { ...faqCategory, ...action.faqCategory }
                })] : [...state.faqsCategories]
            }

        case DELETE_FAQ_CATEGORY:
            return {
                ...state,
                faqsCategoriesActionStatus: action.status,
                faqsCategories: action.status === "success" ? [...state.faqsCategories.filter(el => el.id !== action.id)] : [...state.faqsCategories]
            }

        // case TRAIN_FAQS:
        //     return {
        //         ...state,
        //         trainStatus: action.status
        //     }
        default:
            return state;
    }
}
export default faqsReducer;