import React from "react"
import { FormattedMessage } from "react-intl";
// import FaqsService from "../../../faqsServices/index"
// import { omit } from "lodash";

export const FETCH_FAQS_PENDING = 'FETCH_FAQS_PENDING';
export const FETCH_FAQS_SUCCESS = 'FETCH_FAQS_SUCCESS';
export const FETCH_FAQS_ERROR = 'FETCH_FAQS_ERROR';
export const FLUSH_FAQS = 'FLUSH_FAQS';
export const ADD_FAQ = 'ADD_FAQ';
export const UPDATE_FAQ = 'UPDATE_FAQ';
export const DELETE_FAQ = 'DELETE_FAQ';

export const FETCH_FAQ_CATEGORIES = "FETCH_FAQ_CATEGORIES";
export const ADD_FAQ_CATEGORY = "ADD_FAQ_CATEGORY";
export const UPDATE_FAQ_CATEGORY = "UPDATE_FAQ_CATEGORY";
export const DELETE_FAQ_CATEGORY = "DELETE_FAQ_CATEGORY";

// let faqsService = new FaqsService();

function fetchFaqsPending() {
    return {
        type: FETCH_FAQS_PENDING
    }
}

function fetchFaqsSuccess(faqs) {
    return {
        type: FETCH_FAQS_SUCCESS,
        faqs: faqs.filter(faq => faq.company)
    }
}

function fetchFaqsError(error) {
    return {
        type: FETCH_FAQS_ERROR,
        error: error
    }
}

export function flushFaqs() {
    return {
        type: FLUSH_FAQS
    }
}

export const fetchFaqs = (searchTerm) => {

    // console.log("faq", searchTerm)

    let where = undefined || {}
    let include = null

    if (searchTerm !== undefined) {
        if (searchTerm.field === "name") include = { "name": { "like": `.*${searchTerm.search}.*`, "options": "i" } }
        else where[searchTerm.field] = searchTerm.search
    }

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchFaqsPending());
        return rcsdk
            .getFaqs()
            .include({
                relation: 'company',
                scope: {
                    where: include
                }
            })
            .where(where)
            .then(faqs => {
                return dispatch(fetchFaqsSuccess(faqs));
                // let faqsScorePromises = [];
                // faqs.map(faq => {
                //     faqsScorePromises.push(
                //         faqsService.getScore({question: faq.question, answer: faq.answer}).catch(err => err)
                //     );
                //     return null;
                // });

                // Promise.all(faqsScorePromises).then(response => {
                //     let formatedFaqs = faqs.map((faq, faqIdx) => {
                //         return {...faq, feedback: omit(response[faqIdx].feedback, ["question", "answer"])}
                //     });
                //     return dispatch(fetchFaqsSuccess(formatedFaqs));
                // }).catch(err => {
                //     dispatch(fetchFaqsError(err.message));
                // })

            }).catch(err => {
                dispatch(fetchFaqsError(err.message));
            })
    }
}


function addFaqSuccess(faq) {
    return {
        type: ADD_FAQ,
        faq: faq,
        successToast: {
            type: "ADD",
            message: <FormattedMessage id="faqs.toast.add" defaultMessage="FAQ added successfully" />
        }
    }
}

export const addFaq = (datas) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchFaqsPending());

        return rcsdk.createFaq(datas)
            .then((faq) => {
                dispatch(addFaqSuccess(faq));
                return faq;
            }).catch(err => {
                dispatch(fetchFaqsError(err.message));
            });
    }
}

function updateFaqSuccess(faq) {
    return {
        type: UPDATE_FAQ,
        faq: faq,
        successToast: {
            type: "UPDATE",
            message: <FormattedMessage id="faqs.toast.update" defaultMessage="FAQ updated successfully" />
        }
    }
}

export const updateFaq = ({ id, datas }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchFaqsPending());

        return rcsdk.updateFaq(id, datas)
            .then((response) => {
                dispatch(updateFaqSuccess(response));
            }).catch(err => {
                dispatch(fetchFaqsError(err.message));
            });
    }
}

function deleteFaqSuccess(faqId) {
    return {
        type: DELETE_FAQ,
        id: faqId,
        successToast: {
            type: "DELETE",
            message: <FormattedMessage id="faqs.toast.delete" defaultMessage="FAQ deleted successfully" />
        }
    }
}

export const deleteFaq = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchFaqsPending());

        return rcsdk.deleteFaq(id)
            .then((response) => {
                dispatch(deleteFaqSuccess(id));
            }).catch(err => {
                dispatch(fetchFaqsError(err.message));
            });
    }
}


// export const getFeedback = (faq) => {
//     return (dispatch, getState, {rcsdk, rcAlgolia}) => {
//         return faqsService.getScore({question: faq.question, answer: faq.answer})
//         .then(resp => {
//             return resp.feedback;
//         })
//         .catch(err => err)
//     }
// }

export const getAudio = (text) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk
            ._request()
            .route(`/tools/voice`)
            .params({ text: text })
            .err(`Could not get audio`)
            .then((results) => {
                return results;
            });
    }
}

export const fetchFaqsCategories = (companyId) => {
    return (dispatch, getState, {rcsdk}) => {
        let where = {};
        if(companyId){
            where = {"companyId": companyId}
        }

        dispatch({type: FETCH_FAQ_CATEGORIES, status: "pending"});
        return rcsdk.getFaqCategories().where(where).then(resp => {
            dispatch({type: FETCH_FAQ_CATEGORIES, status: "success", faqsCategories: resp});
        }).catch(err => {
            dispatch({type: FETCH_FAQ_CATEGORIES, status: "error", error: err.message});
        })
    }
}


export const addFaqCategory = (data) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: ADD_FAQ_CATEGORY, status: "pending"});
        return rcsdk.createFaqCategory({companyId: getState().company.company.id, ...data}).then(resp => {
            dispatch({type: ADD_FAQ_CATEGORY, status: "success", faqCategory: resp});
        }).catch(err => {
            dispatch({type: ADD_FAQ_CATEGORY, status: "error", error: err.message});
        })
    }
}

export const updateFaqCategory = (id, data) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: UPDATE_FAQ_CATEGORY, status: "pending"});
        return rcsdk.updateFaqCategory(id, data).then(resp => {
            dispatch({type: UPDATE_FAQ_CATEGORY, status: "success", faqCategory: resp});
        }).catch(err => {
            dispatch({type: UPDATE_FAQ_CATEGORY, status: "error", error: err.message});
        })
    }
}

export const deleteFaqCategory = (id, action) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({type: DELETE_FAQ_CATEGORY, status: "pending"});

        let deleteCategory = rcsdk.deleteFaqCategory(id).then(resp => {
            dispatch({type: DELETE_FAQ_CATEGORY, status: "success", id: id});
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch({type: DELETE_FAQ_CATEGORY, status: "error", error: err.message});
            } else {
                dispatch({type: DELETE_FAQ_CATEGORY, status: "success", id: id});
            }
        })

        if(!action){
            return deleteCategory;
        } else {
            let relatedFaqs = getState().faqs.faqs.filter(el => el.categoryId === id);
            let relatedFaqsPromises = relatedFaqs.map(relatedFaq => {
                if(action === "delete"){
                    return dispatch(deleteFaq(relatedFaq.id, false));
                } else if(action === "move"){
                    return dispatch(updateFaq({id: relatedFaq.id , datas: {categoryId: null}}));
                }
                return null;
            }).filter(el => el)

            return Promise.all([...relatedFaqsPromises]).then(() => {
                return deleteCategory;
            });
        }
    }
}