import React, { useRef, useState, useEffect, useCallback } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { COLUMN_NAMES } from "./constants";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, CardBody, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icon from "react-feather"
import { FormattedMessage } from 'react-intl'

import { setUserMenuConfig } from "../../../../redux/actions/menu"
import { FLUSH_UPDATE_MEMBER } from "../../../../redux/actions/members"

const MovableItem = ({
    name,
    index,
    currentColumnName,
    moveCardHandler,
    setGlobalItems
}) => {
    const changeItemColumn = (currentItem, columnName) => {
        setGlobalItems((prevState) => {
            const arr = prevState.map((e) => {
                return {
                    ...e,
                    column: e.name === currentItem.name ? columnName : e.column
                };
            });
            return arr
        });
    };

    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: "item",
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCardHandler(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });
    const [{ isDragging }, drag] = useDrag({
        item: { index, currentColumnName, type: "item", name },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();

            if (dropResult.name !== currentColumnName) {
                const { name } = dropResult;
                const { MAIN, OTHERS } = COLUMN_NAMES;
                switch (name) {
                    case MAIN:
                        changeItemColumn(item, MAIN);
                        break;
                    case OTHERS:
                        changeItemColumn(item, OTHERS);
                        break;
                    default:
                        break;
                }
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });
    const opacity = isDragging ? 0.4 : 1;

    drag(drop(ref));

    return (
        <div ref={ref} className="movable-item mb-50" style={{ opacity, cursor: "grab" }}>
            <Card className="mb-0" style={{ borderRadius: 0 }}>
                <CardBody className="d-flex justify-content-between align-items-center p-1 text-muted">
                    {name}
                    <FontAwesomeIcon icon={["far", "grip-dots-vertical"]} style={{ width: "15px", height: "15px" }} />
                </CardBody>
            </Card>
        </div>
    );
};

const Column = ({ children, className, title, setGlobalItems, items }) => {

    const [internalItems, setInternalItems] = useState(children)

    useEffect(() => {
        setInternalItems(children)
    }, [children])


    const moveCardHandler = (dragIndex, hoverIndex) => {
        const dragItem = internalItems[dragIndex];
        if (dragItem) {
            let arr = []
            setInternalItems((prevState) => {
                const coppiedStateArray = [...prevState];

                // remove item by "hoverIndex" and put "dragItem" instead
                const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

                // remove item by "dragIndex" and put "prevItem" instead
                coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
                arr = coppiedStateArray
                return coppiedStateArray;
            });
            arr.length > 0 && setGlobalItems(items => {
                const filtered = items.filter(item => item.column !== title)
                return [...filtered, ...arr]
            })

        }
    };

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: "item",
        drop: (item, monitor) => {
            return ({ name: title })
        },
        collect: (monitor) => {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            }
        },
        // Override monitor.canDrop() function
        // canDrop: (item) => {
        //     const { MAIN, OTHERS } = COLUMN_NAMES;
        //     const { currentColumnName } = item;
        //     return (
        //         currentColumnName === title ||
        //         (currentColumnName === MAIN && title === OTHERS) ||
        //         (currentColumnName === OTHERS && title === MAIN)
        //     );
        // }
    });

    const getBackgroundColor = () => {

        if (isOver) {
            if (canDrop) {
                return "rgb(188,251,255)";
            } else if (!canDrop) {
                return "rgb(255,188,188)";
            }
        } else {
            return "";
        }
    };

    return (
        <div
            ref={drop}
            className={"d-flex flex-column h-100"}
        >
            <div className="w-100 d-flex justify-content-between p-1">
                <div className='w-100 d-flex align-items-center pb-50' style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                    <div className='mr-50'>
                        {title === "Main" ?
                            <FontAwesomeIcon icon={["far", "house"]} style={{ width: "15px", height: "15px" }} />
                            :
                            <Icon.MoreHorizontal size={15} />}
                    </div>
                    {title.toUpperCase()}
                    {/* <div className="w-100 ml-50" style={{ border: "1px solid #919295" }}></div> */}
                </div>
            </div>
            <div
                className={`w-100 flex-grow-1 p-1 ${isOver ? "bg-rgba-primary" : ""}`}
                style={{
                    // border: "1px solid #919295",
                    borderRadius: "0.42rem",
                    backgroundColor: getBackgroundColor()
                }}>
                {internalItems.map((item, index) => (
                    <MovableItem
                        key={item.id}
                        name={item.name}
                        currentColumnName={item.column}
                        setItems={setInternalItems}
                        setGlobalItems={setGlobalItems}
                        index={index}
                        moveCardHandler={moveCardHandler}
                    />
                ))}
            </div>
        </div>
    );
};

const MenuConfigurator = ({ onCancel }) => {
    const dispatch = useDispatch()
    const userMenuConfig = useSelector(state => state.menu.userMenuConfig)
    const updateMemberStatus = useSelector(state => state.members.updateMemberStatus)
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (updateMemberStatus === "success") {
            onCancel()
            dispatch({ type: FLUSH_UPDATE_MEMBER })
        }
        if (updateMemberStatus === "error") dispatch({ type: FLUSH_UPDATE_MEMBER })
    }, [updateMemberStatus, dispatch, onCancel])

    const getItems = useCallback((elements) => {
        const { MAIN, OTHERS } = COLUMN_NAMES;

        const mainArray = []
        let othersArray = []

        elements.forEach((item, index) => {
            const obj = {
                ...item,
                textId: item.id,
                name: item.title,
                index
            }

            return item.id.includes("others") ?
                othersArray = item.children.map((item2, i) => ({
                    ...obj,
                    // id: "configurableItem" + (index + i),
                    id: index + i,
                    column: OTHERS,
                    name: item2.title,
                    textId: item2.id,
                }))
                :
                mainArray.push({
                    ...obj,
                    id: index,
                    // id: "configurableItem" + index,
                    column: MAIN
                })
        })

        setItems([...mainArray, ...othersArray])
    }, [])

    useEffect(() => {
        getItems(userMenuConfig)
    }, [userMenuConfig, getItems])


    const { MAIN, OTHERS } = COLUMN_NAMES;

    return (<>
        <Row className="p-2">
            <DndProvider backend={HTML5Backend} >
                {[MAIN, OTHERS].map(column => <Col sm="6" key={"configuratorCol" + column}>
                    <Column
                        title={column}
                        className="h-100"
                        children={items.filter((item) => item.column === column)}
                        setGlobalItems={setItems}
                        ietms={items}
                    />
                </Col>)}
            </DndProvider>
        </Row>
        <div className="w-100 d-flex justify-content-end px-2 py-1" style={{ borderTop: "1px solid rgba(0, 0, 0, 0.05)" }}>
            <Button color="light" onClick={() => onCancel()}>
                <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
            </Button>
            <Button color="primary" className="mx-1" onClick={() => {
                const { MAIN, OTHERS } = COLUMN_NAMES;
                const getElements = (elements) => elements.map((el, index) => ({ id: el.textId, innerOrder: index, column: el.column }))
                const main = getElements(items.filter(item => item.column === MAIN))
                const others = getElements(items.filter(item => item.column === OTHERS))
                dispatch(setUserMenuConfig([...main, ...others]))
            }}>
                {updateMemberStatus === "pending" ?
                    <div>
                        <FormattedMessage id="menu.updating" defaultMessage="Updating..." />
                        <Spinner size="sm" className="ml-50" />
                    </div>
                    :
                    <FormattedMessage id="global.save" defaultMessage="Save" />}
            </Button>
        </div>
    </>
    );
};

export default MenuConfigurator;
