import {
    UNSET_CURRENT_CONVERSATION,
    FETCH_CONVERSATIONS_PENDING,
    FETCH_CONVERSATIONS_SUCCESS,
    FETCH_CONVERSATIONS_ERROR,
    FETCH_CONVERSATION_PENDING,
    FETCH_CONVERSATION_SUCCESS,
    FETCH_CONVERSATION_ERROR,
    FLUSH_CONVERSATIONS,
    LINK_TICKET,
    UPDATE_CONVERSATION,
    UPDATE_CONVERSATION_COLLECTED_DATAS,
    SET_NAVBAR_CONVERSATION_TOTAL,
    INCREMENT_UNFETCHED_CONVERSATION_TOTAL,
    RELOAD_LIST,
    FETCH_CONVERSATION_LIST_TOTAL,
} from '../../actions/conversations';

import { UPDATE_CONTACT } from "../../actions/contacts";

import _ from "lodash";

const initialState = {
    pending: true,
    pendingDetail: true,
    conversations: [],
    current: null,
    total: 0,
    error: null,
    forceReload: false,
    mainList: {
        channelType: null,
        totals: {
            missed: "pending",
            unmissed: "pending"
        }
    },
    totalNavBar: null,
    newConversationTotal: 0,
}

const conversationsReducer = (state = initialState, action) => {
    switch (action.type) {

        case UNSET_CURRENT_CONVERSATION:
            return {
                ...state,
                current: null
            }

        case FETCH_CONVERSATIONS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_CONVERSATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                conversations: [...state.conversations, ...action.conversations],
                total: action.total,
                forceReload: false
            }
        case FETCH_CONVERSATIONS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case FETCH_CONVERSATION_PENDING:
            return {
                ...state,
                pendingDetail: true
            }
        case FETCH_CONVERSATION_SUCCESS:
            return {
                ...state,
                pendingDetail: false,
                current: action.conversation
            }
        case FETCH_CONVERSATION_ERROR:
            return {
                ...state,
                pendingDetail: false,
                error: action.error
            }

        case FETCH_CONVERSATION_LIST_TOTAL:
            if (action?.pending) {
                return {
                    ...state,
                    mainList: {
                        ...state.mainList,
                        totals: { missed: "pending", unmissed: "pending" }
                    }
                }
            } else {
                return {
                    ...state,
                    mainList: {
                        ...state.mainList,
                        totals: {
                            missed: action.missed,
                            unmissed: action.notMissed
                        }
                    },
                }
            }

        case FLUSH_CONVERSATIONS:
            return {
                ...state,
                conversations: [],
            }

        case LINK_TICKET:
            return {
                ...state,
                current: { ...state.current, ticketsId: [...state.current.ticketsId, action.ticket.id], tickets: [...state.current.tickets, action.ticket] }
            }

        case UPDATE_CONVERSATION:
            return {
                ...state,
                current: action.conversation.id === state.current?.id ? { ...state.current, ...action.conversation } : { ...state.current },
                conversations: [
                    ...state.conversations.map(conv => {
                        return conv.id === action.conversation.id ? {
                            ...conv,
                            ..._.omit(action.conversation, ["isDelete", "createdAt", "updatedAt", "id"])
                        } : { ...conv }
                    })
                ],
            }

        case UPDATE_CONTACT:
            return {
                ...state,
                current: state.current?.contactId === action.contact.id ? {
                    ...state.current,
                    contact: {
                        ...state.contact,
                        ...action.contact
                    }
                } : { ...state.current }
            }

        case UPDATE_CONVERSATION_COLLECTED_DATAS:
            return {
                ...state,
                current: state.current?.id === action.id ? {
                    ...state.current,
                    collectedData: [...action.datas]
                } : { ...state.current }
            }

        case SET_NAVBAR_CONVERSATION_TOTAL:
            return {
                ...state,
                totalNavBar: action.total
            }

        case INCREMENT_UNFETCHED_CONVERSATION_TOTAL:
            return {
                ...state,
                newConversationTotal: state.newConversationTotal + 1
            }

        case RELOAD_LIST:
            return {
                ...state,
                forceReload: true,
                newConversationTotal: 0
            }


        case "@@router/LOCATION_CHANGE":
            let regexp = /^\/conversations\/\w+$/;
            if (action.payload.action === "PUSH" && regexp.test(action.payload.location.pathname)) {
                return {
                    ...state,
                    current: null,
                    mainList: {
                        ...state.mainList,
                        inSearch: false,
                        searchTerm: "",
                        channelType: action.payload.location.pathname.split("/").pop()
                    },
                    forceReload: (state.mainList.channelType === action.payload.location.pathname.split("/").pop()) ? true : false
                }
            } else {
                return {
                    ...state,
                    forceReload: false,
                    mainList: {
                        ...state.mainList,
                        inSearch: false,
                        searchTerm: "",
                        channelType: null
                    }
                }
            }

        default:
            return state;
    }
}
export default conversationsReducer;