import {
    FETCH_TICKET_TAGS_PENDING,
    FETCH_TICKET_TAGS_SUCCESS,
    FETCH_TICKET_TAGS_ERROR,
    UPDATE_TICKET_TAG,
    ADD_TICKET_TAG,
    DELETE_TICKET_TAG,
    FETCH_MODEL_TAGS_PENDING,
    FETCH_MODEL_TAGS_SUCCESS,
    FETCH_MODEL_TAGS_ERROR,
    FLUSH_ADD,
    FLUSH_UPDATE,
    FLUSH_TAGS,
    FETCH_TICKET_DATA_PENDING,
    FETCH_TICKET_DATA_SUCCESS,
    FETCH_TICKET_DATA_ERROR,
    UPDATE_TICKET_DATA_PENDING,
    UPDATE_TICKET_DATA_SUCCESS,
    // UPDATE_TICKET_DATA_ERROR,
    UPDATE_TICKET_TAG_PENDING,
    FLUSH_FETCH_DATA,
    GET_DIRTY,
    TRAIN_NOTIF,
    FLUSH_FETCH_TAGS,
} from '../../actions/ticketTags';
import { toast } from "react-toastify";

const initialState = {
    pending: false,
    modelPending: false,
    ticketTags: [],
    modelTags: [],
    error: null,
    addStatus: null,
    tagsData: null,
    updateStatus: "",
    fetchTicketDataStatus: "",
    dirty: false,
    trainNotifStatus: false,
    fetchTagsStatus: null
}

const ticketTagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRAIN_NOTIF:
            return {
                ...state,
                trainNotifStatus: action.status
            }
        case GET_DIRTY:
            return {
                ...state,
                dirty: action.value
            }
        case FETCH_MODEL_TAGS_PENDING:
            return {
                ...state,
                modelPending: true
            }

        case FETCH_MODEL_TAGS_SUCCESS:
            return {
                ...state,
                modelPending: false,
                modelTags: action.modelTags
            }

        case FETCH_MODEL_TAGS_ERROR:
            return {
                ...state,
                modelPending: false,
                error: action.error
            }

        case FETCH_TICKET_TAGS_PENDING:
            return {
                ...state,
                pending: true
            }

        case FETCH_TICKET_TAGS_SUCCESS:
            return {
                ...state,
                pending: false,
                fetchTagsStatus: "success",
                ticketTags: action.ticketTags
            }

        case FLUSH_FETCH_TAGS:
            return {
                ...state,
                fetchTagsStatus: null,
            }

        case FETCH_TICKET_TAGS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case FETCH_TICKET_DATA_PENDING:
            return {
                ...state,
                fetchTicketDataStatus: "pending",
            }

        case FETCH_TICKET_DATA_SUCCESS:
            return {
                ...state,
                fetchTicketDataStatus: "success",
                tagsData: action.data
            }

        case FETCH_TICKET_DATA_ERROR:
            return {
                ...state,
                error: action.error
            }

        case UPDATE_TICKET_TAG:
            return {
                ...state,
                updateStatus: "success",
                ticketTags: [
                    ...state.ticketTags.map(elt => {
                        if (elt.id === action.ticketTag.id) {
                            return {
                                ...elt,
                                ...action.ticketTag
                            }
                        } else {
                            return elt
                        }
                    })
                ]
            }

        case UPDATE_TICKET_TAG_PENDING:
            return {
                ...state,
                updateStatus: "pending",
            }

        case ADD_TICKET_TAG:
            return {
                ...state,
                addStatus: { id: action.ticketTag.id },
                ticketTags: [...state.ticketTags, action.ticketTag]
            }
        case FLUSH_ADD:
            return {
                ...state,
                addStatus: false,
            }
        case FLUSH_UPDATE:
            return {
                ...state,
                updateStatus: "",
            }
        case FLUSH_TAGS:
            return {
                ...state,
                ticketTags: [],
            }
        case FLUSH_FETCH_DATA:
            return {
                ...state,
                fetchTicketDataStatus: "",
            }

        case DELETE_TICKET_TAG:
            return {
                ...state,
                pending: false,
                ticketTags: [...state.ticketTags.filter(ticketTag => ticketTag.id !== action.id)]
            }

        case UPDATE_TICKET_DATA_PENDING:
            return {
                ...state,
                updateStatus: "pending",
            }
        case UPDATE_TICKET_DATA_SUCCESS:
            toast.success(`the ticket tag "${action.name}" has been successfully updated`);
            return {
                ...state,
                updateStatus: "success",
                currentTagData: action.data,
                ticketTags: [
                    ...state.ticketTags.map(elt => {
                        if (elt.id === action.data.id) {
                            return {
                                ...elt,
                                ...action.ticketTag
                            }
                        } else {
                            return elt
                        }
                    })
                ]
            }

        default:
            return state;
    }
}
export default ticketTagsReducer;