import {
  FETCH_LIBRARY,
  FETCH_LIBRARY_PAGE,
  ADD_LIBRARY_PAGE,
  UPDATE_LIBRARY_PAGE,
  DELETE_LIBRARY_PAGE,
  SET_JSON_ERRORS
} from "../../actions/libraries";

const initialState = {
  pending: false,
  library: null,
  error: null,
  pages: [],
  jsonErrors: []
};

export const librariesReducer = (state = initialState, action) => {
  console.log(action?.type);
  switch (action.type) {
    case FETCH_LIBRARY:
      return {
        ...state,
        status: action.status,
        error: action.status === "error" ? action.error : null,
        library: action.status === "success" ? action.library : state.library,
      };

    case FETCH_LIBRARY_PAGE:
      return {
        ...state,
        status: action.status,
        error: action.status === "error" ? action.error : null,
        pages:
          action.status === "success" ? [...action.pages] : [...state.pages],
      };

    case UPDATE_LIBRARY_PAGE:
      return {
        ...state,
        pages: [
          ...state.pages.map((page) => {
            if (page.id !== action.payload.id) return page;
            return {
              ...page,
              ...action.payload,
            };
          }),
        ],
      };

    case ADD_LIBRARY_PAGE:
      return {
        ...state,
        pages: [...state.pages, { ...action.payload }],
      };

    case DELETE_LIBRARY_PAGE:
      return {
        ...state,
        pages: state.pages.filter((el) => el.id !== action.id),
      };

    case SET_JSON_ERRORS:
      return {
        ...state,
        jsonErrors: action?.jsonErrors || []
      }

    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        jsonErrors: []
      }

    default:
      return state;
  }
};

export default librariesReducer;
