import React from "react"
import { FormattedMessage } from "react-intl";
import { generateWhere } from '../../../utility/utils'

const ITEM_PER_PAGE = 20;

export const FETCH_CONTACTS_PENDING = 'FETCH_CONTACTS_PENDING';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_ERROR = 'FETCH_CONTACTS_ERROR';

export const FETCH_CONTACT_PENDING = 'FETCH_CONTACT_PENDING';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';
export const FETCH_CONTACT_ERROR = 'FETCH_CONTACT_ERROR';

export const FLUSH_CONTACTS = 'FLUSH_CONTACTS';

export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const ADD_CONTACT = "ADD_CONTACT";
export const UNSET_CURRENT_CONTACT = "UNSET_CURRENT_CONTACT";

export const ADD_IMPORTED_CONTACTS = "ADD_IMPORTED_CONTACTS";
export const FLUSH_ADD_IMPORTED_CONTACTS = "FLUSH_ADD_IMPORTED_CONTACTS";
export const SAVE_CONTACTS_FROM_MODAL = "SAVE_CONTACTS_FROM_MODAL";

export const unsetCurrentContact = () => {
    return {
        type: UNSET_CURRENT_CONTACT
    }
}

export function flushContacts() {
    return {
        type: FLUSH_CONTACTS
    }
}

function fetchContactsPending() {
    return {
        type: FETCH_CONTACTS_PENDING
    }
}

function fetchContactsSuccess(contacts, total) {
    return {
        type: FETCH_CONTACTS_SUCCESS,
        contacts: contacts,
        total: total
    }
}

function fetchContactsError(error) {
    return {
        type: FETCH_CONTACTS_ERROR,
        error: error
    }
}

export const fetchContacts = ({
    startIndex = 0,
    stopIndex = ITEM_PER_PAGE,
    flush = false,
    searchTerm = "",
    companyId = null,
    order = "createdAt DESC",
    filtersFromTable = null
}) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        if (flush) dispatch(flushContacts());
        dispatch(fetchContactsPending());

        const where = generateWhere({ searchTerm, companyId, filtersFromTable }) || {}

        return rcsdk.getContacts()
            // .include(["assignee", { "relation": "conversations", "fields": ["id"] }])
            .skip(startIndex)
            .limit(stopIndex - startIndex + 1)
            .raw()
            .where(where)
            .order(order)
            .then((resp) => {
                const total = parseInt(resp.headers["content-range"].substr(resp.headers["content-range"].indexOf("/") + 1));
                dispatch(fetchContactsSuccess(resp.data.map(contact => contact), total));
            })


            .catch(err => {
                dispatch(fetchContactsError(err.message));
            })
    }
}

// export const fetchContacts = ({
//     startIndex = 0,
//     stopIndex = ITEM_PER_PAGE,
//     flush = false,
//     searchTerm = "",
//     order = "default",
//     facet = {},
// }) => {
//     return (dispatch, getState, { rcsdk, rcAlgolia }) => {
//         if (flush) dispatch(flushContacts());
//         dispatch(fetchContactsPending());

//         rcAlgolia.search({
//             model: "contacts",
//             searchTerm: searchTerm.field === "keyword" ? searchTerm.search : "",
//             startIndex: startIndex,
//             stopIndex: stopIndex,
//             filters: "",
//             facetFilters: [
//                 facet.companyId ? `companyId:${facet.companyId}` : "",
//                 searchTerm.field === "companyId" ? `companyId:${searchTerm.search}` : "",
//                 searchTerm.field === "id" ? `objectID:${searchTerm.search}` : "",
//                 // searchTerm.field === "name" ? `name:${searchTerm.search}` : "",
//             ],
//             order: order
//         }).then(props => {
//             dispatch(fetchContactsSuccess(props.hits, props.nbHits));
//         }).catch(err => {
//             dispatch(fetchContactsError(err.message));
//         });
//     }
// }

function fetchContactPending() {
    return {
        type: FETCH_CONTACT_PENDING
    }
}

function fetchContactSuccess(contact) {
    return {
        type: FETCH_CONTACT_SUCCESS,
        contact: contact
    }
}

function fetchContactError(error) {
    return {
        type: FETCH_CONTACT_ERROR,
        error: error
    }
}

export const fetchContact = (id) => {
    return (dispatch, getStat, { rcsdk, rcAlgolia }) => {
        dispatch(fetchContactPending());
        rcsdk.getContact(id).then(contact => {
            dispatch(fetchContactSuccess(contact));
        }).catch(err => {
            dispatch(fetchContactError(err.message));
        })
    }
}


export const fetchContactGdpr = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.gdprExportContact(id).then(resp => {
            return resp;
        }).catch(err => {
            dispatch({
                error: err
            })
        })
    }
}

function addContactSuccess(contact) {
    return {
        type: ADD_CONTACT,
        contact: contact,
        successToast: {
            type: "ADD",
            message: <FormattedMessage id="contacts.toast.add" defaultMessage="Contact added successfully" />
        }
    }
}

export const addContact = (datas) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchContactsPending());

        let formattedDatas = {
            ...datas,
            phone: datas.phone.filter(el => el !== null && el !== ""),
            email: datas.email.filter(el => el !== null && el !== "")
        }

        return rcsdk.createContact(formattedDatas)
            .then((contact) => {
                dispatch(addContactSuccess({
                    ...contact,
                    company: {
                        ...getState().user.rcuser.company
                    }
                }));
                return contact;
            }).catch(err => {
                console.log(err, "err");
                dispatch(fetchContactsError(err.message));
            });
    }
}

export const addImportedContacts = (datas, headers, companyId) => {
    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
    const PNF = require('google-libphonenumber').PhoneNumberFormat;

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: ADD_IMPORTED_CONTACTS, status: "pending" });

        const invalid = [];
        const setInvalid = (contact, field) => {
            const isInvalidIndex = invalid.findIndex(el => el.id === contact.id)
            if (isInvalidIndex === -1) invalid.push({ ...contact, invalidFields: [field] })
            else invalid[isInvalidIndex] = { ...contact, invalidFields: [...invalid[isInvalidIndex].invalidFields, field] }
        }

        let contactsPromises = [];

        datas.filter(el => el.rcstatus === "new").forEach(((contact, i) => {

            let formattedContact = {
                // isTest: true
            };

            for (const property in contact) {
                const bddMatch = headers?.find(header => header.key === property)?.bddMatch
                contact[property] = contact[property].replace(/['"]+/g, '').trim()

                const formattedPhoneNumbers = []
                if (bddMatch === "phone") {
                    const phones = contact[property].split(',')
                    phones.length > 0 && phones.forEach((phone) => {
                        try {
                            if (phone !== "") {
                                const trimed = phone.replace(/['"]+/g, '').trim()
                                const parsedPhone = phoneUtil.parseAndKeepRawInput(trimed, 'FR');
                                const formattedPhoneNumber = phoneUtil.format(parsedPhone, PNF.INTERNATIONAL);
                                if (!phoneUtil.isValidNumber(phoneUtil.parse(formattedPhoneNumber, 'FR'))) {
                                    setInvalid(contact, `phone : ${phone}`)
                                }
                                else formattedPhoneNumbers.push(formattedPhoneNumber)
                            } else {
                                setInvalid(contact, `phone : empty phone`)
                            }
                        } catch (err) {
                            setInvalid(contact, `phone : ${phone}`)
                        }
                    })
                }

                const checkedEmails = []
                if (bddMatch === "email") {
                    const emails = contact[property].split(',')
                    emails.length > 0 && emails.forEach((email) => {
                        const trimed = email.replace(/['"]+/g, '').trim()
                        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimed)) setInvalid(contact, `email : ${email.length > 0 ? email : "empty email"}`)
                        else checkedEmails.push(trimed)
                    })
                }

                const notMeta = ["gender", "firstName", "lastName", "organization", "email", "phone"]

                if (!(property === "undefined" || property === "id" || property === "rcstatus")) {
                    if (!notMeta.includes(bddMatch) || !bddMatch) {
                        formattedContact = {
                            ...formattedContact,
                            meta: { ...formattedContact["meta"], [bddMatch || property]: contact[property] }
                        }
                    } else {
                        formattedContact = {
                            ...formattedContact,
                            [bddMatch]: contact[property]
                        }
                    }
                    if (bddMatch === "phone") formattedContact = {
                        ...formattedContact,
                        [bddMatch]: formattedPhoneNumbers
                    }
                    if (bddMatch === "email") formattedContact = {
                        ...formattedContact,
                        [bddMatch]: checkedEmails
                    }
                }
            }

            formattedContact["companyId"] = companyId;
            let contactPromise = rcsdk.createContact(formattedContact).then(data => data).catch(err => Promise.reject({ err, formattedContact }))
            contactsPromises.push(contactPromise);
        }))

        return Promise.allSettled([...contactsPromises]).then(values => {
            dispatch({
                type: ADD_IMPORTED_CONTACTS,
                status: "success",
                rejected: values.filter(el => el.status === "rejected").map(el => el.reason.formattedContact),
                fulfilled: values.filter(el => el.status === "fulfilled").map(el => el.value),
                invalid: invalid
            });
        })
    }
}


function updateContactSuccess(contact, inTable) {
    return {
        type: UPDATE_CONTACT,
        contact: contact,
        inTable: inTable,
        successToast: !inTable && {
            type: "UPDATE",
            message: <FormattedMessage id="contacts.toast.update" defaultMessage="Contact updated successfully" />
        }
    }
}

export const updateContact = ({ id, datas }, inTable) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.updateContact(id, datas).then((response) => {
            return dispatch(updateContactSuccess(response, inTable));
        }).catch(err => {
            dispatch(fetchContactsError(err.message));
        });
    }
}

export const editConversationContact = ({ conversationId, datas }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.editConversationContact(conversationId, datas)
    }
}