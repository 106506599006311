// import {fetchMembers} from "../members";
// import { fetchContactGroups } from "../contactGroups";
// import { fetchTicketTags } from "../ticketTags";
// import { fetchDecks } from "../deck";
// import { fetchTeams } from "../teams";
// import { fetchAgents } from "../agents";
// import { fetchCompanySettings } from "../company";
// import { fetchIntegrations } from "../../../redux/actions/integrations"
// import { fetchChannelConnections, fetchChannels } from "../../../redux/actions/channels"

export const TOGGLE_QUOTA_MESSAGE = 'TOGGLE_QUOTA_MESSAGE';
export const SET_APP_TITLE = 'SET_APP_TITLE';
export const SET_APP_LOADED = 'SET_APP_LOADED';
export const LOAD_ALL_MODELS = 'LOAD_ALL_MODELS';
export const TOGGLE_JSON_BEAUTIFIER_MODAL = 'TOGGLE_JSON_BEAUTIFIER_MODAL';
export const SET_JSON_BEAUTIFIER_DATA = 'SET_JSON_BEAUTIFIER_DATA';
export const SET_JSON_BEAUTIFIER_PENDING_UPDATE = 'SET_JSON_BEAUTIFIER_PENDING_UPDATE';
export const TOGGLE_MARKDOWN_BEAUTIFIER_MODAL = 'TOGGLE_MARKDOWN_BEAUTIFIER_MODAL';

export const toggleQuotaMessage = (show) => {
    return dispatch => {
        dispatch({ type: TOGGLE_QUOTA_MESSAGE, show: show })
    }
}

export const toggleJsonBeautifierModal = (show, json, title, onUpdate, updatableKeys) => {
    return dispatch => {
        dispatch({ 
            type: TOGGLE_JSON_BEAUTIFIER_MODAL, 
            show: show,
            json: json,
            title: title,
            onUpdate: onUpdate,
            updatableKeys: updatableKeys
        })
    }
}

export const toggleMarkdownBeautifierModal = (show) => {
    return dispatch => {
        dispatch({ 
            type: TOGGLE_MARKDOWN_BEAUTIFIER_MODAL, 
            show: show
        })
    }
}


export const setAppTitle = (title) => {
    return dispatch => {
        dispatch({ type: SET_APP_TITLE, title: title })
    }
}

export const setAppLoaded = () => {
    return dispatch => {
        console.log("LOADED");
        dispatch({ type: SET_APP_LOADED })
    }
}

export const loadAllModels = () => {
    return async (dispatch) => {

        await Promise.all([
            // dispatch(fetchCompanySettings()),
            // dispatch(fetchAgents()),
            // dispatch(fetchTeams()),
            // dispatch(fetchContactGroups()),
            // dispatch(fetchTicketTags()),
            // dispatch(fetchMembers()),
            // dispatch(fetchDecks()),
            // dispatch(fetchIntegrations()),
            // dispatch(fetchChannels()),
            // dispatch(fetchChannelConnections())
        ]);
        
        return dispatch(setAppLoaded());
    }
}

