import {
    FETCH_CONTACTS_PENDING,
    FETCH_CONTACTS_SUCCESS,
    FETCH_CONTACTS_ERROR,
    FETCH_CONTACT_PENDING,
    FETCH_CONTACT_SUCCESS,
    FETCH_CONTACT_ERROR,
    FLUSH_CONTACTS,
    UPDATE_CONTACT,
    ADD_CONTACT,
    UNSET_CURRENT_CONTACT,
    ADD_IMPORTED_CONTACTS,
    FLUSH_ADD_IMPORTED_CONTACTS,
    SAVE_CONTACTS_FROM_MODAL
} from '../../actions/contacts';

const initialState = {
    pending: false,
    pendingDetail: false,
    contacts: [],
    current: null,
    error: null,
    forceReload: false,
    editionDrawer: {
        show: false,
        contactId: null,
        isEditing: false
    },
    importStatus: null,
    importedContacts: [],
    rejectedContacts: [],
    invalid: [],
    saveFromModal: false,
}

const contactsReducer = (state = initialState, action) => {
    switch (action.type) {

        case UNSET_CURRENT_CONTACT:
            return {
                ...state,
                current: null
            }

        case SAVE_CONTACTS_FROM_MODAL:
            return {
                ...state,
                saveFromModal: action.status
            }

        case FLUSH_CONTACTS:
            return {
                ...state,
                contacts: []
            }

        case FETCH_CONTACTS_PENDING:
            return {
                ...state,
                pending: true
            }

        case FETCH_CONTACTS_SUCCESS:
            return {
                ...state,
                pending: false,
                contacts: action.contacts,
                total: action.total,
                forceReload: false
            }

        case FETCH_CONTACTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case FETCH_CONTACT_PENDING:
            return {
                ...state,
                pendingDetail: true
            }
        case FETCH_CONTACT_SUCCESS:
            return {
                ...state,
                pendingDetail: false,
                current: action.contact
            }

        case FETCH_CONTACT_ERROR:
            return {
                ...state,
                pendingDetail: false,
                error: action.error
            }

        case UPDATE_CONTACT:
            return {
                ...state,
                contacts: [
                    ...state.contacts.map(elt => {
                        if (elt.id === action.contact.id) {
                            return {
                                ...elt,
                                ...action.contact
                            }
                        } else {
                            return elt
                        }
                    })
                ],
                current: action.inTable ? null : {
                    ...action.contact,
                    formattedMeta: state.current.formattedMeta,
                }
            }

        case ADD_CONTACT:
            return {
                ...state,
                contacts: [...state.contacts, action.contact]
            }

        case ADD_IMPORTED_CONTACTS:
            return {
                ...state,
                importStatus: action.status,
                importedContacts: action.fulfilled,
                rejectedContacts: action.rejected,
                invalid: action.invalid,
            }

        case FLUSH_ADD_IMPORTED_CONTACTS:
            return {
                ...state,
                importStatus: null,
                importedContacts: [],
                rejectedContacts: [],
                invalid: [],
            }

        case "@@router/LOCATION_CHANGE":
            let regexp = /^\/contacts\/\w+$/;
            if (action.payload.action === "PUSH" && regexp.test(action.payload.location.pathname)) {
                return {
                    ...state,
                    forceReload: true
                }
            } else {
                return {
                    ...state
                }
            }

        default:
            return state;
    }
}
export default contactsReducer;