import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSlash } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl'

const ChatHangUp = ({
    className,
    ...rest
}) => {
    return <div className={`${className ? className : ""} chat-hangup chat-message chat-message-right`} {...rest}>

        <div className={`chat-avatar`}>
          <div className="avatar m-0">
            <FontAwesomeIcon icon={faPhoneSlash} />
          </div>
        </div>
        
        <div className="chat-message-body">
            <div className="chat-message-content font-bold-400">
              <FormattedMessage id="chat.userhangup" defaultMessage="User hang up" />
            </div>
        </div>
    </div>
}

ChatHangUp.defaultProps = {}

export default ChatHangUp;