import axios from "axios";
import { isArray } from "lodash"
import { toast } from "react-toastify";
import { HIDE_MODAL } from "../modals";
import { NLU_URL, MSNLU_URL } from "../../../configs/constants";

export const GET_LABELS = 'GET_LABELS';
export const GET_MODELS = 'GET_MODELS';
export const GET_LABELS_DATA = 'GET_LABELS_DATA';
export const CREATE_LABEL = 'CREATE_LABEL';
export const CREATE_CLASSIFIER = 'CREATE_CLASSIFIER';
export const CREATE_DATA = 'CREATE_DATA';
export const FLUSH_ALL_CREATION_STATUS = 'FLUSH_ALL_CREATION_STATUS';
export const EDIT_LABEL = 'EDIT_LABEL';
export const PATCH_DATA = 'PATCH_DATA';
export const SET_ACTIVATION = 'SET_ACTIVATION';
export const SET_INHERITANCE = 'SET_INHERITANCE';
export const DELETE_LABEL = 'DELETE_LABEL';
export const DELETE_CLASSIFIER = 'DELETE_CLASSIFIER';
export const FAQ_CONFLICT = 'FAQ_CONFLICT';
export const FLUSH_FAQ_CONFLICT = 'FLUSH_FAQ_CONFLICT';

export const fetchLabels = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        const token = rcsdk.TOKEN
        dispatch({ type: GET_LABELS, status: "pending" })

        const getByCompanyId = companyId && axios.get(`${NLU_URL}/labels/company/${companyId}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        const getByStar = axios.get(`${NLU_URL}/labels/company/*`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        Promise.all([getByCompanyId, getByStar])
            .then((response) => {
                dispatch({ type: GET_LABELS, labels: [...response[0].data, ...response[1].data], status: "success" })
            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: GET_LABELS, status: "error" })
            })
    }
};

export const fetchModels = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        const token = rcsdk.TOKEN
        dispatch({ type: GET_MODELS, status: "pending" })
        axios.get(`${NLU_URL}/models/${companyId}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                dispatch({ type: GET_MODELS, models: response.data, status: "success" })
            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: GET_MODELS, status: "error" })
            })
    }
};

export const fetchLabelsData = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        const token = rcsdk.TOKEN
        dispatch({ type: GET_LABELS_DATA, status: "pending" })

        const getByCompanyId = companyId && axios.get(`${NLU_URL}/data/company/${companyId}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        const getByStar = axios.get(`${NLU_URL}/data/company/*`, {
            headers: { Authorization: `Bearer ${token}` }
        })


        Promise.all([getByCompanyId, getByStar])
            .then((response) => {
                dispatch({ type: GET_LABELS_DATA, data: [...response[0].data, ...response[1].data], status: "success" })
            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: GET_LABELS_DATA, status: "error" })
            })
    }
};

export const createLabel = (data, text, language) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: CREATE_LABEL, status: "pending" })

        const token = rcsdk.TOKEN
        const axiosConfig = {
            headers: { Authorization: `Bearer ${token}` },
        }

        axios.post(`${NLU_URL}/labels`, data, axiosConfig)
            .then((response) => {
                Promise.all([text.length > 0 && dispatch(createData(data.companyId, { text, labelIds: [parseInt(response.data.id)], language }))])
                    .then(() => {
                        toast.success("label created successfully");
                        dispatch({
                            type: CREATE_LABEL, status: "success", data: {
                                "id": response.data.id,
                                "name": data.name,
                                "companyId": data.companyId,
                                "type": data.type,
                                "description": data.description,
                                "color": data.color,
                                "modelTagId": data.modelTagId,
                                "parentTagId": null,
                                "statTagId": null,
                                "graphData": {},
                                "descendantIds": [],
                                "personalizationIds": [],
                                "statChildIds": []
                            }
                        })
                    })
                    .catch((error) => {
                        toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                    })

            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: CREATE_LABEL, status: "error" })
            })
    }
};

export const editLabel = (data, text, language) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: EDIT_LABEL, status: "pending" })

        const token = rcsdk.TOKEN
        const axiosConfig = {
            headers: { Authorization: `Bearer ${token}` },
        }

        axios.patch(`${NLU_URL}/labels`, data, axiosConfig)
            .then((response) => {
                Promise.all([text.length > 0 && dispatch(createData(data.companyId, { text, labelIds: [parseInt(response.data.id)], language }))])
                    .then(() => {
                        toast.success("label updated successfully");
                        dispatch({
                            type: EDIT_LABEL, status: "success", data: {
                                "id": data.labelId,
                                "name": data.name,
                                "companyId": data.companyId,
                                "type": data.type,
                                "description": data.description,
                                "color": data.color,
                                "modelTagId": data.modelTagId,
                                "parentTagId": null,
                                "statTagId": null,
                                "graphData": {},
                                "descendantIds": [],
                                "personalizationIds": [],
                                "statChildIds": []
                            }
                        })
                    })
                    .catch((error) => {
                        toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                    })

            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: EDIT_LABEL, status: "error" })
            })
    }
};

export const deleteLabel = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: DELETE_LABEL, status: "pending" })

        const token = rcsdk.TOKEN
        const axiosConfig = {
            headers: { Authorization: `Bearer ${token}` },
        }

        axios.delete(`${NLU_URL}/labels/id/${id}`, axiosConfig)
            .then(() => {
                toast.success("label deleted successfully");
                dispatch({ type: DELETE_LABEL, status: "success", id: id })
            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: DELETE_LABEL, status: "error" })
            })
    }
};

export const deleteClassifier = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: DELETE_CLASSIFIER, status: "pending" })

        const token = rcsdk.TOKEN
        const axiosConfig = {
            headers: { Authorization: `Bearer ${token}` },
        }

        axios.delete(`${NLU_URL}/models/${id}`, axiosConfig)
            .then(() => {
                toast.success("Classifier deleted successfully");
                dispatch({ type: DELETE_CLASSIFIER, status: "success", id: id })
            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: DELETE_CLASSIFIER, status: "error" })
            })
    }
};

export const createData = (companyId, data) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {

        dispatch({ type: CREATE_DATA, status: "pending" })

        const token = rcsdk.TOKEN
        const axiosConfig = {
            headers: { Authorization: `Bearer ${token}` },
        }

        const textData = {
            "companyId": companyId,
            "data": isArray(data) ? data : data.text.map(sentence => {
                return {
                    "text": sentence,
                    "metadata": {},
                    "language": data.language,
                    "labelIds": data.labelIds
                }
            })
        }

        axios.post(`${NLU_URL}/data/batch`, textData, axiosConfig)
            .then((response) => {
                toast.success("data created successfully");
                dispatch({ type: CREATE_DATA, data: response.data, status: "success" })
                return response
            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: CREATE_DATA, status: "error" })
            })
    }
};

export const patchData = (data) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: PATCH_DATA, status: "pending" })

        const token = rcsdk.TOKEN
        const axiosConfig = {
            headers: { Authorization: `Bearer ${token}` },
        }

        axios.patch(`${NLU_URL}/data/batch`, data, axiosConfig)
            .then((response) => {
                toast.success("data updated successfully");
                dispatch({ type: PATCH_DATA, data: response.data, status: "success" })
                return response
            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: PATCH_DATA, status: "error" })
            })
    }
};

export const createClassifier = (data, train) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: CREATE_CLASSIFIER, status: "pending" })

        const token = rcsdk.TOKEN
        const axiosConfig = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .post(`${NLU_URL}/models/train`, data, axiosConfig)
            .then((response) => {
                if (response.data.faqConflicts.length > 0) {
                    dispatch({ type: FAQ_CONFLICT, faqConflicts: response.data.faqConflicts, data, train, response })
                } else {
                    dispatch(msnluUpdate(data, train, response))
                }
            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: CREATE_CLASSIFIER, status: "error" })
            })
    }
};

export const msnluUpdate = (data, train, response) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(handleClassifierCreationConfirmation(train, response))
        axios.post(`${MSNLU_URL}/update`, {
            "companyId": data.companyId,
            "key": data.key
        }).then(res => {
            dispatch({ type: FLUSH_FAQ_CONFLICT })
            dispatch({ type: HIDE_MODAL })
            toast.success("classifier updated successfully");
        }).catch(err => toast.error(`Oups... something went wrong while updating classifier. Please retry. Error message : ${err}`))
    }
};

export const handleClassifierCreationConfirmation = (train, response) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        toast.success(train ? "classifier trained successfully" : "classifier created successfully");
        dispatch({ type: CREATE_CLASSIFIER, data: response.data, status: "success" })
    }
};


export const setLabelActivation = (data, status) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: SET_ACTIVATION, status: "pending" })

        const token = rcsdk.TOKEN
        const axiosConfig = {
            headers: { Authorization: `Bearer ${token}` },
        }

        axios
            .post(`${NLU_URL}/labels/${status ? "deactivate" : "activate"}`, data, axiosConfig)
            .then((response) => {
                toast.success("label status updated successfully");
                dispatch({ type: SET_ACTIVATION, data: response.data, status: "success" })
            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: SET_ACTIVATION, status: "error" })
            })
    }
};

export const setInheritance = (data) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: SET_INHERITANCE, status: "pending" })

        console.log(data)


        const token = rcsdk.TOKEN
        const axiosConfig = {
            headers: { Authorization: `Bearer ${token}` },
        }

        axios
            .post(`${NLU_URL}/labels/inherit`, data, axiosConfig)
            .then((response) => {
                toast.success("label inheritance set successfully");
                dispatch({ type: SET_INHERITANCE, data: response.data, status: "success" })
            })
            .catch((error) => {
                toast.error(`Oups... something went wrong. Please retry. Error message : ${error}`);
                dispatch({ type: SET_INHERITANCE, status: "error" })
            })
    }
};