import React from "react";
import {
  Card,
  CardBody,
  Button,
  Badge,
  UncontrolledTooltip
} from "reactstrap"
import {useSelector, useDispatch} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent"
import Team from "../../../components/reecall/team/Team"
import { getDefaultNames, getDefaultAvatar } from '../../../utility/utils';
import { startOutgoingCall } from '../../../redux/actions/call';
import { newInternalChat, selectInternalLiveBetween } from '../../../redux/actions/lives';

import ChatTyping from "../../../components/reecall/chat/ChatTyping"  
import { FormattedMessage } from "react-intl";

const MemberListItem = ({member, onChat, onClose}) => {
    const dispatch = useDispatch();
    const loggedUserId = useSelector(state => state.user.rcuser.rcId);
    const [lastName, firstName] = getDefaultNames(member, true);
    const internalLive = useSelector(state => selectInternalLiveBetween(state, {member1Id: loggedUserId, member2Id: member.id}));

    const isPhoneAvailable = () => {
        if(typeof member?.status?.phone === "undefined") return true;
        return !!member?.status?.phone;
    }
    return <Card className="membersList__item">
        <CardBody>
            <div className="d-flex align-items-center">
                <Avatar 
                    img={member.avatar ? member.avatar : getDefaultAvatar(member)}
                    className="membersList__item--avatar mr-1"
                    size="md"
                    status={member?.connected ? !isPhoneAvailable() ? "busy" : "online" : "offline"}
                />

                <div className="d-flex flex-column">
                    <div className="membersList__item--name font-medium-2">
                        <span className="text-bold-600">{lastName}</span>{' '}{firstName}
                    </div>
                    {
                        member.teams && <div className="membersList__item--teams d-flex">
                            {member.teams.map(team => <Team 
                                team={team}
                                showIcon
                                colored
                                className="mr-1"
                                key={`membersList-member-${member.id}-team-${team.id}`}
                            />)}
                        </div>
                    }
                </div>

                <div className="membersList__item--actions d-flex align-items-center mr-0 ml-auto">

                    {
                        !internalLive && <Button 
                            color="flat-secondary" 
                            className="btn-icon mr-1"
                            onClick={() => {
                                dispatch(newInternalChat(member)).then(({data}) => {
                                    onChat(data.conversationId)
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={["far", "comment"]} />
                        </Button>
                    }

                    {
                        internalLive && <Button 
                            color="flat-primary" 
                            className="btn-icon mr-1"
                            onClick={() => {
                                onChat(internalLive.id)
                            }}
                        >
                            <FontAwesomeIcon icon="comment" />

                            {
                                internalLive?.typing === true && <ChatTyping 
                                    bubbleRadius={3}
                                    height={10}
                                    className="typing"
                                    color="#fff"
                                />
                            }

                            { 
                               internalLive.unread !== 0 && <Badge
                                    color="primary"
                                    className="badge-sm badge-up"
                                    pill
                                >
                                    {internalLive.unread}
                                </Badge>
                            }
                        </Button>
                    }


                    <Button 
                        color="flat-secondary"
                        className="btn-icon"
                        disabled={!isPhoneAvailable()}
                        onClick={() => {
                            dispatch(startOutgoingCall(member));
                            onClose();
                        }}
                        id={`membersDrawer-call-button-${member.id}`}
                    >
                        <FontAwesomeIcon icon={!isPhoneAvailable() ? ["far" , "phone-slash"] : ["far", "phone"]} />
                    </Button>


                    {
                        !isPhoneAvailable() && <UncontrolledTooltip
                            placement="left"
                            target={`membersDrawer-call-button-${member.id}`}
                        >
                            <FormattedMessage id="global.busy" defaultMessage="busy"/>
                        </UncontrolledTooltip>
                    }
                </div>
            </div>
        </CardBody>
    </Card>
}

export default MemberListItem;