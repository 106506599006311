import React, { useState } from "react"
import Can from "../../../components/reecall/authorization/Can";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from "reactstrap"



const GroupItem = ({ item, setEdit, edit }) => {
    const [hover, setHover] = useState(false)
    
    return <Can
        key={`group-header-${item.groupTitle}`}
        permissions={item.permissions || []}
        yes={() =>
            <li className="navigation-header" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <div className="w-100 d-flex justify-content-between align-items-center" style={{minHeight: "28px"}}>
                    <span>{item.groupTitle}</span>
                    {(hover || edit) && item.editable && <Button
                        className="p-50 mr-1"
                        color={edit ? "flat-success" : "flat-primary"}
                        onClick={() => setEdit(!edit)}
                    >
                        <FontAwesomeIcon
                            icon={["far", edit ? "check" : "pen"]}
                            style={{ width: "12px", height: "12px" }}
                        />
                    </Button>}
                </div>
                {
                    item.actions && <div className="navigation-header--actions">
                        {item.actions()}
                    </div>
                }
            </li>
        }
    />
}

export default GroupItem