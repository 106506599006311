import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {getChannelOptionsByType} from "../../../configs/channelsConfig";

const ChannelIcon = ({channelType = "phone", size = 28, className = null, ...rest}) => {
    let channelOptions = getChannelOptionsByType(channelType);

    if(!channelOptions){
        console.error(channelType);
        return null;
    }

    if(channelOptions.icon){
        return <FontAwesomeIcon 
            icon={channelOptions.icon}
            style={{ color: channelOptions.color, width: `${size}px`, height: `${size}px` }}
            className={classnames({[className]: classnames})}
        />
    }

    if(channelOptions.img){
        return <img 
            alt="channelOptionsImg"
            src={channelOptions.img}
            style={{ maxWidth: `${size}px`, maxheight: `${size}px` }}
            className={classnames({[className]: classnames})}
        />
    }

    return null;
}

export default ChannelIcon;