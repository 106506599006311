import {
    ACD_REQUEST_STATUS,
    RESET_COMPANY_ACDS,
    SET_PENDING,
    STORE_COMPANY_ACDS,
    STORE_CURRENT_ACD,
    STORE_MEMBER_CHANNEL_CONNECTIONS
} from '../../actions/acd';

const initialState = {
    companyACDs: [],
    memberChannelConnections: [],
    pending: false,
    currentACD: {},
    requestStatus: "",
}

const ACDsReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_COMPANY_ACDS:
            return {
                ...state,
                companyACDs: action.companyACDs,
            }
        case RESET_COMPANY_ACDS:
            return {
                ...state,
                companyACDs: [],
            }
        case STORE_MEMBER_CHANNEL_CONNECTIONS:
        return {
            ...state,
            memberChannelConnections: action.memberChannelConnections,
        }
        case SET_PENDING:
            return {
                ...state,
                pending: action.pending,
            }
        case STORE_CURRENT_ACD:
            return {
                ...state,
                currentACD: action.currentACD,
            }
        case ACD_REQUEST_STATUS:
            return {
                ...state,
                requestStatus: action.requestStatus,
            }
        default:
            return state;
    }
}
export default ACDsReducer;