import {AUTOMATHEQUE_FETCH_RULES} from "../../actions/automatheque/rulesActions"

const initialState = {
    status: "pending",
    error: null,
    rules: []
}

export const rules = (state = initialState, action) => {
    switch (action.type) {
        case AUTOMATHEQUE_FETCH_RULES:
            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                rules: action.status === "success" ? [...action.rules] : [...state.rules]
            }

        default: 
            return state
  }
}