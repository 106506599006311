import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux"
import {pushMessage, RESET_UNREAD} from "../../../redux/actions/lives";
import Chat from "../chat/Chat";
import Deck from '../deck/deck';
import classnames from  "classnames";
import _ from "lodash";
import {getChannel} from "pusher-redux";

const scrollToBottom = (id, smooth = true) => {
    if(document.querySelector(`#chat-widget-${id}`)){
        if(smooth){
            document.querySelector(`#chat-widget-${id}`).style.scrollBehavior = "smooth";
        } else {
            document.querySelector(`#chat-widget-${id}`).style.scrollBehavior = "auto";
        }
        document.querySelector(`#chat-widget-${id}`).scrollTop = document.querySelector(`#chat-widget-${id}`).scrollHeight;    
    }
}

const InternalLive = ({className, live}) => {
    const dispatch = useDispatch();
    const decks    = useSelector(state => state.deck.decks);
    const loggedUserId = useSelector(state => state.user.rcuser.rcId);
    let availableDeck = _.first(decks.filter(deck => deck.contexts.includes("internal-live")));
    const [inputVal, setInputVal] = useState("");
    const [shouldResetUnread, setShouldResetUnread] = useState(true);

    useEffect(() => {
        if(live && shouldResetUnread){
            scrollToBottom(live.id, true);
            dispatch({type: RESET_UNREAD, liveId: live.id});
            setShouldResetUnread(false);
        }
    }, [dispatch, live, shouldResetUnread]);

    if(!live) return null;

    return <div className={classnames({[className]: className, "internalChat": true})}>          
        <Chat
            inbounds={live.inbounds}
            room={{...live}}
            inputVal={inputVal}
            onChange={(val) => {
                setInputVal(val);

                var privateChannelBetweenMember = getChannel(`private-chat-${live.id}`);
                privateChannelBetweenMember.trigger("client-typing", {memberId: loggedUserId, conversationId: live.id});
            }}
            onSubmit={() => {
                if(inputVal.trim() !== ""){
                    dispatch(pushMessage(live.id, inputVal));
                }
                setInputVal("");
            }}
            renderAdditionnalFooter={() => {
                return <>
                    {
                        availableDeck && <div className="quick-actions d-flex align-items-center">
                            <Deck 
                                direction={"horizontal"}
                                maxRows={1}
                                size={"sm"}
                                tooltipPlacement="top"
                                deckDatas={availableDeck?.children}
                            />
                        </div>
                    }
                </>
            }}
        />
    </div>
}

InternalLive.defaultProps = {
    live: null,
    className: null
}

export default InternalLive;