import React from "react";
import { useSelector, connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const checkRights = ({userRights, permissions}) => {
    if(permissions.length === 0) return true;
    if(userRights["ADMIN"]) return true;

    let canAccess = permissions.map(routeRight => {
        let item = routeRight.split('.')[0];
        let action = routeRight.split('.')[1];
        return userRights?.[item]?.[action] || false;
    }).some(elt => elt);

    return canAccess;
}
export {checkRights};


const mapStateToProps = state => {
    const { auth: {login}, user } = state;
    const { isAuthenticated, errorMessage } = login;
  
    return {
      isAuthenticated,
      errorMessage,
      user
    }
}
const Authorization = (allowedRoles, redirect) => (WrappedComponent) => {
    return connect(mapStateToProps)(class WithAuthorization extends React.Component {
        // constructor(props) {
        //     super(props);
        // }

        render() {
            if(!this.props.user.rcuser) return <WrappedComponent {...this.props} />;

            let userCanAccess = checkRights({userRights: this.props.user.rcuser?.rights || [], permissions: Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles]});
            if (userCanAccess) {
                return <WrappedComponent {...this.props} />
            } else if(redirect){
                return <Redirect to={{ pathname: "/not-authorized" }} />
            } else {
                return <h1>No page for you!</h1>
            }
        }
    })
};
export {Authorization};

const Can = ({yes, no, permissions}) => {
    const rcuser = useSelector(state => state.user.rcuser);
    if(!rcuser) return null;
    
    return checkRights({userRights: rcuser?.rights || [], permissions: Array.isArray(permissions) ? permissions : [permissions]})
    ? yes()
    : no();
}

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export default Can;