import {
    SET_POSTING,
    STORE_CURRENT_HELP_TEXT,
    REQUEST_STATUS,
    STORE_HELP_TEXTS, 
    STORE_SELECTED_HELP_TEXT,
    STORE_PAGE_LIST,
    RESET__HELP_TEXTS_REDUCER,
    FETCH_HELPTEXT,
} from "../../actions/helpText";

const initialState = {
    helpTextList: [],
    currentHelpText: {},
    selectedHelpText: {},
    posting: false,
    pageList: [],
    requestStatus: "",
    helpTexts: {}
}

const helpTextReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_HELPTEXT:
            return {
                ...state,
                helpTexts: {
                    ...state.helpTexts,
                    [action.pageSlug]: {
                        ...state.helpTexts?.[action.pageSlug],
                        [action.sectionSlug]: action.helpText
                    }
                }
            }
        case STORE_CURRENT_HELP_TEXT:
            return {
                ...state,
                currentHelpText: action.response,
            }
        case STORE_PAGE_LIST:
            return {
                ...state,
                pageList: action.pageList,
            }
        case STORE_SELECTED_HELP_TEXT:
            return {
                ...state,
                selectedHelpText: 
                {
                    pageSlug: action.pageSlug,
                    fieldSlug: action.fieldSlug,
                    language: action.language,
                },
            }
        case SET_POSTING:
            return {
            ...state,
            posting: action.posting,
            }
        case RESET__HELP_TEXTS_REDUCER: 
            return {
                ...state,
                helpTextList: [],
                currentHelpText: {},
                selectedHelpText: {},
                posting: false,
                pageList: [],
                requestStatus: "",
            }
        case REQUEST_STATUS:
            return {
                ...state,
                requestStatus: action.status,
            }
        case STORE_HELP_TEXTS:
            return {
                ...state,
                helpTextList: action.response,
            }
        default:
            return state;
    }
}
export default helpTextReducer;
