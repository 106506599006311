
import { toast } from "react-toastify";


export const FETCH_HOOKS = 'FETCH_HOOKS';
export const UPDATE_HOOK = 'UPDATE_HOOK';
export const FLUSH_HOOK_STATUS = 'FLUSH_HOOK_STATUS';
export const CREATE_HOOK = 'CREATE_HOOK';
export const DELETE_HOOK = 'DELETE_HOOK';

export const fetchHooks = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        let where = {}
        if (companyId) where = {...where, companyId}
        dispatch({ type: FETCH_HOOKS, status: "pending" });
        return rcsdk.getHooks().where(where).then(response => {
            return dispatch({ type: FETCH_HOOKS, status: "success", hooks: response });
        }).catch(err => {
            dispatch({ type: FETCH_HOOKS, status: "error", error: err });
        })
    }
}

export const fetchHook = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.getHook(id).then(response => {
            return response
        }).catch(err => {
           console.log(err)
        })
    }
}

export const updateHook = (id, data) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: UPDATE_HOOK, status: "pending" });
        return rcsdk.updateHook(id, data).then(response => {
            toast.success("hook updated successfully");
            return dispatch({ type: UPDATE_HOOK, status: "success", hook: response });
        }).catch(err => {
            toast.error(`Oups... something went wrong. Please retry. Error message : ${err}`);
            dispatch({ type: UPDATE_HOOK, status: "error", error: err });
        })
    }
}

export const createHook = (data) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: CREATE_HOOK, status: "pending" });
        return rcsdk.createHook(data).then(response => {
            toast.success("hook created successfully");
            return dispatch({ type: CREATE_HOOK, status: "success", hook: response });
        }).catch(err => {
            toast.error(`Oups... something went wrong. Please retry. Error message : ${err}`);
            dispatch({ type: CREATE_HOOK, status: "error", error: err });
        })
    }
}

export const deleteHook = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: DELETE_HOOK, status: "pending" });
        return rcsdk.deleteHook(id).then(response => {
            toast.success("hook deleted successfully");
            return dispatch({ type: DELETE_HOOK, status: "success", hook: response, id: id });
        }).catch(err => {
            toast.error(`Oups... something went wrong. Please retry. Error message : ${err}`);
            dispatch({ type: DELETE_HOOK, status: "error", error: err });
        })
    }
}
