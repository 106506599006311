import mock from "../mock"
// import avatarImg from "../../assets/img/portrait/small/avatar-s-20.jpg"
// import avatarImg2 from "../../assets/img/portrait/small/avatar-s-19.jpg"
// import avatarImg3 from "../../assets/img/portrait/small/avatar-s-18.jpg"

import avatarRaph from "../../assets/img/portrait/small/raphael.jpg"
import avatarMathieu from "../../assets/img/portrait/small/mathieub.jpg"
// import avatarCharles from "../../assets/img/portrait/small/charles.jpg"
// import avatarMaxime from "../../assets/img/portrait/small/maxime.jpg"

import membersDatas from "./members"
import organizationsDatas from "./organizations"

export const todosDatas = [
  {
    id: "1",
    label: "Demande d'informations complémentaires",
    description: "Nullam ipsum augue, mattis non vestibulum nec, interdum at est. Vestibulum in lacinia sapien.",
    done: true,
    doneBy: null,
    quickActions: []
  },
  {
    id: "2",
    label: "Appeler le contact",
    description: "Nullam ipsum augue, mattis non vestibulum nec, interdum at est. Vestibulum in lacinia sapien.",
    done: false,
    quickActions: []
  },
  {
    id: "3",
    label: "Rembourser le client",
    description: "Aenean molestie erat ac libero porttitor, dignissim ornare eros pulvinar. Integer dignissim, nisi et laoreet pretium, justo tellus accumsan turpis, et auctor dolor urna id erat. Maecenas suscipit non arcu sit amet pretium.",
    done: false,
    quickActions: [
      // {
      //   label: "Appeler le contact",
      //   icon: ["fal" , "phone"],
      //   color: "primary",
      //   name: "call-contact",
      //   action: {
      //     name: "call-contact"
      //   },
      //   done: false
      // },
      // {
      //   label: "Send Mail",
      //   description: "Envoyer un mail au contact",
      //   icon: ["fal" , "comment"],
      //   color: "danger",
      //   name: "send-message",
      //   action: {
      //     name: "send-message"
      //   },
      //   done: false
      // },
    ]
  }
];

const collectedDatas = [
  {
    label: "Nom de l'interlocuteur",
    value: "Mathieu Gréard",
    original: "Je m'appele Mathieu Gréart",
    guess: true
  },
  {
    label: "Date de naissance",
    value: "23/06/1987",
    original: "23/06/1987",
    validated: true
  },
  {
    label: "Numero de dossier",
    value: "1H32BdsQDdf2123s",
    original: "1H32BdsQDdf2123s"
  }
];
  
const timelineDatas = [{
  "type": "alexa",
  "id": 1,
  "contactId": 1,
  "orgContactSourceId": "5cdd0c9c2f77170007dac875",
  "time": "2020-04-15T09:39:28.439Z",
  "isInbound": true,
  "author": {
    "firstName": "Mathieu",
    "lastName": "Gréard"
  },
  "title": "Bonjour, je souhaiterais parler à mathieu"
}, {
  "type": "field_updated",
  "field": "status",
  "oldValue": "in_progress",
  "newValue": "todo",
  "timestamp": "2020-04-15T09:42:51.361Z",
  "isInbound": false,
  "author": {}
}, {
  "type": "todo_updated",
  "field": "status",
  "oldValue": "undone",
  "newValue": "done",
  "todoId": 1,
  "timestamp": "2020-04-20T15:00:37.308Z",
  "isInbound": false,
  "author": {
    "firstName": "Raphaël",
    "lastName": "Szymocha",
    "avatar": avatarRaph
  }
}, {
  "type": "todo_updated",
  "field": "status",
  "oldValue": "done",
  "newValue": "undone",
  "todoId": 2,
  "timestamp": "2020-04-20T15:00:37.308Z",
  "isInbound": false,
  "author": {
    "firstName": "Raphaël",
    "lastName": "Szymocha",
    "avatar": avatarRaph
  }
}, {
  "type": "field_updated",
  "field": "assigneeId",
  "oldValue": null,
  "newValue": "5cffbe3206a65f000756d19d",
  "memberId": "5cffbe3206a65f000756d19d",
  "timestamp": "2020-04-20T12:56:38.560Z",
  "isInbound": false,
  "author": {
    "id": "5cffbe3206a65f000756d19d",
    "firstName": "Raphaël",
    "lastName": "Szymocha",
    "avatar": avatarRaph
  }
},{
  "type": "field_updated",
  "field": "assigneeId",
  "oldValue": "5cffbe3206a65f000756d19d",
  "newValue": "5cb99ddb366a9b00069120d7",
  "memberId": "5cb99ddb366a9b00069120d7",
  "timestamp": "2020-04-20T12:56:38.560Z",
  "isInbound": false,
  "author": {
    "id": "5cb99ddb366a9b00069120d7",
    "firstName": "Mathieu",
    "lastName": "Buonomo",
    "avatar": avatarMathieu
  }
},{
  "type": "field_updated",
  "field": "assigneeId",
  "oldValue": "5cb99ddb366a9b00069120d7",
  "newValue": null,
  "memberId": "5cb99ddb366a9b00069120d7",
  "timestamp": "2020-04-20T12:56:38.560Z",
  "isInbound": false,
  "author": {
    "id": "5cb99ddb366a9b00069120d7",
    "firstName": "Mathieu",
    "lastName": "Buonomo",
    "avatar": avatarMathieu
  }
}, {
  "type": "data_collect_created",
  "memberId": "5cffbe3206a65f000756d19d",
  "timestamp": "2020-04-20T15:00:06.938Z",
  "fields": [{
    "from": "contact",
    "key": "firstName"
  }, {
    "from": "contact",
    "key": "lastName"
  }, {
    "from": "contact",
    "key": "phone"
  }],
  "message": "Bonjour, \nSuite à votre échange avec notre assistant, pourriez-vous remplir quelques informations s'il vous plaît.",
  "channels": {
    "sms": true,
    "email": true
  },
  "isInbound": false,
  "author": {
    "id": "5cb99ddb366a9b00069120d7",
    "firstName": "Mathieu",
    "lastName": "Buonomo",
    "avatar": avatarMathieu
  }
},
{
  "type": "phone",
  "id": 1,
  "contactId": "5cb99ddb366a9b00069120d7",
  "orgContactSourceId": "5cdd0c9c2f77170007dac875",
  "time": "2020-04-15T09:39:28.439Z",
  "isInbound": true,
  "author": {
    "firstName": "Mathieu",
    "lastName": "Gréard"
  },
  "title": "Bonjour, je souhaiterais parler à mathieu"
},
{
  "type": "chat",
  "id": 1,
  "contactId": "5cb99ddb366a9b00069120d7",
  "orgContactSourceId": "5cdd0c9c2f77170007dac875",
  "time": "2020-04-15T09:39:28.439Z",
  "isInbound": true,
  "author": {
    "firstName": "Mathieu",
    "lastName": "Gréard"
  },
  "title": "Bonjour, je souhaiterais parler à mathieu"
}, {
  "type": "form_submitted",
  "formId": "5e9db8f6b9412000097ea1fd",
  "timestamp": "2020-04-20T15:00:37.308Z",
  "values": [{
    "value": "Mathieu",
    "from": "contact",
    "key": "firstName"
  }, {
    "value": "Gréard",
    "from": "contact",
    "key": "lastName"
  }, {
    "value": ["06123456789"],
    "from": "contact",
    "key": "phone"
  }],
  "isInbound": false,
  "author": {
    "firstName": "Mathieu",
    "lastName": "Gréard"
  }
}, {
  "type": "data_collect_created",
  "memberId": "5cffbe3206a65f000756d19d",
  "timestamp": "2020-04-28T14:09:14.376Z",
  "fields": [{
    "from": "contact",
    "key": "firstName"
  }, {
    "from": "contact",
    "key": "lastName"
  }, {
    "from": "contact",
    "key": "phone"
  }, {
    "from": "contact",
    "key": "email"
  }],
  "message": "Bonjour, \nSuite à votre échange avec notre assistant, pourriez-vous remplir quelques informations s'il vous plaît.",
  "channels": {
    "sms": false,
    "email": false
  },
  "isInbound": false,
  "author": {
    "id": "5cffbe3206a65f000756d19d",
    "firstName": "Raphaël",
    "lastName": "Szymocha",
    "avatar": avatarRaph
  }
}, {
  "type": "field_updated",
  "field": "status",
  "oldValue": "todo",
  "newValue": "archived",
  "memberId": "5cb99ddb366a9b00069120d7",
  "timestamp": "2020-05-28T15:02:00.362Z",
  "isInbound": false,
  "author": {
    "id": "5cb99ddb366a9b00069120d7",
    "firstName": "Mathieu",
    "lastName": "Buonomo",
    "avatar": avatarMathieu
  }
},
{
  "ticketId": "5dfb3e5fce324d0009839d87",
  "memberId": "5cffbe3206a65f000756d19d",
  "type": "note",
  "params": {
    "message": "test de note"
  },
  "id": "5e4e7899f89289000710eb8a",
  "isDeleted": false,
  "createdAt": "2020-02-20T12:16:25.268Z",
  "updatedAt": "2020-02-20T12:16:25.268Z",
  "isInbound": false,
  "author": {}
}, {
  "type": "field_updated",
  "field": "status",
  "oldValue": "archived",
  "newValue": "closed",
  "memberId": "5cb99ddb366a9b00069120d7",
  "timestamp": "2020-05-28T15:02:00.362Z",
  "isInbound": false,
  "author": {
    "id": "5cffbe3206a65f000756d19d",
    "firstName": "Raphaël",
    "lastName": "Szymocha",
    "avatar": avatarRaph
  }
}]

const data = [
  {
    // id: "5ee2304745c602000bbf6087",
    id: 1,
    title: "Ticket 1",
    date: new Date(),
    status: "in_progress",
    assigneeId: "5cffbe3206a65f000756d19d",
    assignee: membersDatas.find(member => member.id === "5cffbe3206a65f000756d19d"),
    todos: todosDatas,
    collectedDatas: collectedDatas,
    timeline: timelineDatas,
    organization: organizationsDatas[1]
  },
  {
    // id: "5ee22fd745c602000bbf607f",
    id: 2,
    title: "Ticket 2",
    date: new Date(),
    status: "in_progress",
    todos: [],
    collectedDatas: [],
    timeline: [],
    organization: organizationsDatas[2]
  },
  {
    // id: "5ee0e8c4f2524b2d30fb0e28",
    id: 3,
    title: "Ticket 3",
    date: new Date(),
    status: "archived",
    assigneeId: 2,
    assignee: membersDatas.find(member => member.id === 2),
    todos: [],
    collectedDatas: [],
    timeline: [],
    organization: organizationsDatas[3]
  }
]


// POST : Add new Tasks
mock.onPost("/api/ticket/new-ticket").reply(request => {
  // Get task from post data
  let ticket = JSON.parse(request.data).ticket

  ticket = {
    ...ticket, 
    assignee: ticket.assigneeId ? membersDatas.find(member => member.id === ticket.assigneeId) : null,
    date: new Date(),
    status: "in_progress",
    collectedDatas: [],
    todos: [],
    timeline: ticket.assigneeId ? [{
      "type": "field_updated",
      "field": "assigneeId",
      "oldValue": null,
      "newValue": ticket.assigneeId,
      "timestamp": "2020-04-20T12:56:38.560Z",
      "isInbound": false,
      "author": {}
    }] : []
  }

  const length = data.length
  let lastIndex = 0
  if (length) {
    lastIndex = data[length - 1].id
  }
  ticket.id = lastIndex + 1

  data.push(ticket)

  return [201, { id: ticket.id }]
})


// POST : Toggle todos
mock.onPost("/api/ticket/todos/toggle").reply(request => {
  let todoId = JSON.parse(request.data).todoId;
  let ticketId = JSON.parse(request.data).ticketId;
  let status = JSON.parse(request.data).status;

  let currentTicket = data.find(ticket => ticket.id.toString() === ticketId.toString());
  let currentTodo = currentTicket.todos.find(todo => todo.id === todoId);
  currentTodo.done = status || !currentTodo.done;

  return [201, { todoId: todoId, ticketId: ticketId }]
})



// POST : Close quickAction
mock.onPost("/api/ticket/quickaction/toggle").reply(request => {
  let quickActionName = JSON.parse(request.data).quickActionName;
  let todoId = JSON.parse(request.data).todoId;
  let ticketId = JSON.parse(request.data).ticketId;

  let currentTicket = data.find(ticket => ticket.id.toString() === ticketId.toString());
  let currentTodo = currentTicket.todos.find(todo => todo.id === todoId);
  let currentQuickAction = currentTodo.quickActions.find(quickAction => quickAction.name === quickActionName);
  currentQuickAction.done = !currentQuickAction.done;

  return [201, { quickAction: currentQuickAction, todo: currentTodo, ticket: currentTicket }]
})


//POST : Close Ticket
mock.onPost("/api/ticket/close").reply(request => {
  let ticketId = JSON.parse(request.data).ticketId;
  let currentTicket = data.find(ticket => ticket.id.toString() === ticketId.toString());
  currentTicket.status = "closed";

  return [201, { ticketId: ticketId }]
})

// POST : Add todo
mock.onPost("/api/ticket/todos/add").reply(request => {
  console.log(request.data, "request.data");
  let todoDatas = JSON.parse(request.data).todo;
  let ticketId = JSON.parse(request.data).ticketId;

  let currentTicket = data.find(ticket => ticket.id.toString() === ticketId.toString());
  let todo = {
    ...todoDatas,
    id: currentTicket.todos.length + 1,
    done: false,
    doneBy: null,
    quickActions: [...todoDatas.quickActions]
  }
  // console.log(todo, "todo");
  currentTicket.todos.push(todo);
  return [201, { todo: todo, ticketId: ticketId }]
})


// GET : TICKET By ID
mock.onGet("/api/ticket").reply(request => {
    const id = request?.params?.id || null;
    if(id){
      const currentTicket = data.find(ticket => {
        return ticket.id.toString() === id.toString();
      });
      return [200, currentTicket]
    }
    return [500];
});

// GET : TICKETS
mock.onGet("/api/tickets").reply(request => {
    let ticketType = request.params.ticketType;
    if(!ticketType || ticketType === "all"){
      return [200, data];
    } else {
      return [200, data.filter(ticket => ticket?.organization && ticket.organization.name.toLowerCase() === ticketType.toLowerCase())]
    }
    // return [200, data];
});