import {
    START_OUTGOING_CALL,
    END_CALL,
    SET_PHONE_NUMBER_TO,
    SET_PHONE_NUMBER_FROM,
    TOGGLE_SOFT_PHONE,
    TOGGLE_MUTE,
    SET_TWILIO_CONN,
    SET_TWILIO_CONN_STATUS,
    SET_CALL_DIRECTION,
    CALL_ERROR,
    SET_START_TIME
} from '../../actions/call';

import { toast } from "react-toastify";

const initialState = {
    phoneNumberTo : "",
    phoneNumberFrom: "",
    softPhoneOpen: false,
    muted: false,
	startTime: null,
    contact: null,
    twilioConn: null,
    twilioStatus: null,
    callDirection: null,
    error: null
}


const callReducer = (state = initialState, action) => {
    switch(action.type) {

        case START_OUTGOING_CALL:
            return {
                ...state,
                startTime: action.startTime,
                contact: action.contact,
                twilioConn: action.conn
            };

        case END_CALL:
            return {
                ...state,
                startTime: null,
                contact: null,
                twilioConn: null,
                twilioStatus: null
            };

        case SET_PHONE_NUMBER_TO:
            return {
                ...state,
                phoneNumberTo: action.number
            };

        case SET_PHONE_NUMBER_FROM:
            return {
                ...state,
                phoneNumberFrom: action.number
            };

        case TOGGLE_SOFT_PHONE:
            return {
                ...state,
                softPhoneOpen: action.open
            };

        case TOGGLE_MUTE:
            return {
                ...state,
                muted: !state.muted
            }

        case SET_TWILIO_CONN:
            return {
                ...state,
                twilioConn: action.conn
            }

        case SET_TWILIO_CONN_STATUS:
            return {
                ...state,
                twilioStatus: action.status
            }

        case SET_CALL_DIRECTION:
            return {
                ...state,
                callDirection: action.dir
            }

        case CALL_ERROR:
            toast.error(action.message);
            return {
                ...state,
                error: action.message
            }

        case SET_START_TIME: 
            return {
                ...state,
                startTime: action.time
            }

        default: 
            return state;
    }
}
export default callReducer;