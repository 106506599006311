import React from "react"
import { FormattedMessage } from "react-intl";
export const FETCH_CONTACT_GROUPS_PENDING = 'FETCH_CONTACT_GROUPS_PENDING';
export const FETCH_CONTACT_GROUPS_SUCCESS = 'FETCH_CONTACT_GROUPS_SUCCESS';
export const FETCH_CONTACT_GROUPS_ERROR = 'FETCH_CONTACT_GROUPS_ERROR';

export const UPDATE_CONTACT_GROUP = 'UPDATE_CONTACT_GROUP';
export const ADD_CONTACT_GROUP = 'ADD_CONTACT_GROUP';
export const DELETE_CONTACT_GROUP = 'DELETE_CONTACT_GROUP';

function fetchContactGroupsPending() {
    return {
        type: FETCH_CONTACT_GROUPS_PENDING
    }
}

function fetchContactGroupsSuccess(contactGroups) {
    return {
        type: FETCH_CONTACT_GROUPS_SUCCESS,
        contactGroups: contactGroups
    }
}

function fetchContactGroupsError(error) {
    return {
        type: FETCH_CONTACT_GROUPS_ERROR,
        error: error
    }
}

export const fetchContactGroups = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchContactGroupsPending());

        return rcsdk.getContactGroups()
            .where({ companyId: getState().company.company.id })
            .then(contactGroups => {
                dispatch(fetchContactGroupsSuccess(contactGroups));
            }).catch(err => {
                dispatch(fetchContactGroupsError(err.message));
            })
    }
}

function addContactGroupSuccess(contactGroup) {
    return {
        type: ADD_CONTACT_GROUP,
        contactGroup: contactGroup,
        successToast: {
            type: "ADD",
            message: <FormattedMessage id="contactGroups.toast.add" defaultMessage="Contact Group added successfully" />
        }
    }
}

export const addContactGroup = (datas) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchContactGroupsPending());

        return rcsdk.createContactGroup(datas)
            .then((contactGroup) => {
                dispatch(addContactGroupSuccess(contactGroup));
                return contactGroup;
            }).catch(err => {
                console.log(err, "err");
                dispatch(fetchContactGroupsError(err.message));
            });
    }
}

function updateContactGroupSuccess(contactGroup) {
    return {
        type: UPDATE_CONTACT_GROUP,
        contactGroup: contactGroup,
        successToast: {
            type: "UPDATE",
            message: <FormattedMessage id="contactGroups.toast.update" defaultMessage="Contact Group updated successfully" />
        }
    }
}

export const updateContactGroup = ({ id, datas }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchContactGroupsPending());

        return rcsdk.updateContactGroup(id, datas)
            .then((response) => {
                dispatch(updateContactGroupSuccess(response));
            }).catch(err => {
                dispatch(fetchContactGroupsError(err.message));
            });
    }
}

function deleteContactGroupSuccess(contactGroupId) {
    return {
        type: DELETE_CONTACT_GROUP,
        id: contactGroupId,
        successToast: {
            type: "DELETE",
            message: <FormattedMessage id="contactGroups.toast.delete" defaultMessage="Contact Group deleted successfully" />
        }
    }
}

export const deleteContactGroup = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchContactGroupsPending());

        return rcsdk.deleteContactGroup(id)
            .then((response) => {
                dispatch(deleteContactGroupSuccess(id));
            }).catch(err => {
                if (err.error.response.status !== 400) {
                    dispatch(fetchContactGroupsError(err.message));
                } else {
                    dispatch(deleteContactGroupSuccess(id));
                }
            });
    }
}
