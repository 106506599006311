import {getSectionsByPageSlug} from "../../../configs/formHelperConfig";

export const FETCH_HELPTEXT = 'FETCH_HELPTEXT';
export const STORE_HELP_TEXTS = "STORE_HELP_TEXTS";
export const STORE_CURRENT_HELP_TEXT = "STORE_CURRENT_HELP_TEXT";
export const SET_POSTING = "SET_POSTING";
export const RESET__HELP_TEXTS_REDUCER = "RESET__HELP_TEXTS_REDUCER";
export const REQUEST_STATUS = "REQUEST_STATUS";
export const STORE_SELECTED_HELP_TEXT = "STORE_SELECTED_HELP_TEXT";
export const STORE_PAGE_LIST = "STORE_PAGE_LIST";

export const storeHelpTexts = (response) => ({
    type: STORE_HELP_TEXTS,
    response,
})

export const storePageList = (pageList) => ({
    type: STORE_PAGE_LIST,
    pageList,
})

export const storeCurrentHelpText = (response) => ({
    type: STORE_CURRENT_HELP_TEXT,
    response,
});

export const storeSelectedHelpText = (pageSlug, fieldSlug, language) => ({
    type: STORE_SELECTED_HELP_TEXT,
    pageSlug,
    fieldSlug,
    language,
});

export const setPosting = (posting) => ({
    type: SET_POSTING,
    posting,
});

export const resetHelpTextsReducer = () => ({
    type: RESET__HELP_TEXTS_REDUCER,
});

export const requestStatus = (status) => ({
    type: REQUEST_STATUS,
    status
});

export const createHelpText = (helpText) => { 
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(requestStatus(''));
    return (
        rcsdk._request()
        .method('POST')
        .route(`/helpTexts`)
        .data(helpText)
        .then(response => {
            dispatch(requestStatus('success'));
        })
        .catch(error => 
            dispatch(requestStatus('error'))
        ).then(dispatch(setPosting(false)))
    );
}};

export const getHelpTextBySlug = (pageSlug, sectionSlug) => {  
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
    return (
        rcsdk._request()
        .method('GET')
        .route(`/helpTexts/findBySlug/${pageSlug}/${sectionSlug}`)
        .then(response => {
            dispatch(storeCurrentHelpText(response));
        })
        .catch(err =>
            console.log(err)
        )
    );
}};

export const updateHelpText = (id, helpText) => {  
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(requestStatus(''));
    return (
        rcsdk._request()
        .method('PATCH')
        .route(`/helpTexts/${id}`)
        .data(helpText)
        .then(response => {
            dispatch(requestStatus('success'));
        })
        .catch(error => 
            dispatch(requestStatus('error'))
        ).then(dispatch(setPosting(false)))
    );
}};

export const getHelpTexts = () => {  
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
    return (
        rcsdk._request()
        .method('GET')
        .route(`/helpTexts`)
        .then(response => {
           dispatch(storeHelpTexts(response));
        })
        .catch(error => 
            console.log(error)
        )
    );
}};

export const deleteHelpText = (id) => {  
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
    return (
        rcsdk._request()
        .method('DELETE')
        .route(`/helpTexts/${id}`)
        .then(response => {
            dispatch(storeCurrentHelpText(response));
            dispatch(requestStatus('deleted'))
        })
        .catch(error => 
            dispatch(requestStatus('error'))
        ).then(dispatch(setPosting(false)))
    );
}};

export const fetchHelpText = (pageSlug, sectionSlug) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        return rcsdk.getHelpTextBySlug(pageSlug, sectionSlug).then(helpText => {
            return dispatch({
                type: FETCH_HELPTEXT,
                pageSlug: pageSlug,
                sectionSlug: sectionSlug,
                helpText: helpText.strings
            }); 
        }).catch(e => {
        })
    }
}

export const fetchPageHelpText = (pageSlug) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        getSectionsByPageSlug(pageSlug).map(section => {
            dispatch(fetchHelpText(pageSlug, section.slug));
            return null;
        })
    }
}

export const selectHelpTextsByPageSlugAndLocal = (state, pageSlug, local) => {
    if(!state.helpText.helpTexts?.[pageSlug]) return null;
    return Object.entries(state.helpText.helpTexts?.[pageSlug]).map(([section, contents]) => {
        if(contents?.[local]){
            return {
                for: section,
                content: contents[local]?.content,
                published: contents[local]?.published
            }
        } else {
            return null
        }
    }).filter(el => el);
}