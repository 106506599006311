import {
    FETCH_SNIPPETS,
    CREATE_SNIPPET,
    UPDATE_SNIPPET,
    DELETE_SNIPPET,
    FLUSH_SNIPPETS_STATUS
} from '../../actions/snippets';


const initialState = {
    snippets: [],
    status: null,
    creationStatus: null,
    updateStatus: null,
    deleteStatus: null,
}

const snippetsReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_SNIPPETS:
            return {
                ...state,
                snippets: action.status === "success" ? action.snippets : state.snippets,
                status: action.status
            }
        case CREATE_SNIPPET:
            return {
                ...state,
                snippets: action.status === "success" ? [...state.snippets, action.snippet] : state.snippets,
                creationStatus: action.status
            }
        case UPDATE_SNIPPET:
            return {
                ...state,
                snippets: action.status === "success" ? [...state.snippets.filter(el => el.id !== action.snippet.id), action.snippet] : state.snippets,
                updateStatus: action.status
            }
        case DELETE_SNIPPET:
            return {
                ...state,
                snippets: action.status === "success" ? state.snippets.filter(el => el.id !== action.snippetId) : state.snippets,
                deleteStatus: action.status
            }
        case FLUSH_SNIPPETS_STATUS:
            return {
                ...state,
                creationStatus: null,
                updateStatus: null,
                deleteStatus: null,
            }

        default:
            return state;
    }
}
export default snippetsReducer;