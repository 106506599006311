import {
    createStore,
    applyMiddleware,
    // compose
} from "redux"
import { composeWithDevTools } from 'redux-devtools-extension';
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import { routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger'
import { configurePusher } from 'pusher-redux';
import rootReducer from "../reducers/rootReducer"
import ReecallSdk from "@reecall/reecall_sdk";
import { history } from "../../history"
import RPAService from "../../RPAService"
import { PUSHER_APP_KEY, PUSHER_CLUSTER } from "../../configs/pusherConfig";
import PaymentService from "../../PaymentService"
import { API_BASE, CORE_URL, STATS_URL } from "../../configs/constants";

const rcsdk = new ReecallSdk(localStorage.getItem('id_token') || 'notoken', { godmode: true, withStats: true });
if (API_BASE) {
    rcsdk.API_BASE = `${API_BASE}/api`;
    // rcsdk.API_BASE = 'http://localhost:3090/api';
}

if (STATS_URL) {
    rcsdk.Stats.API_BASE = `${STATS_URL}/api`;
    // rcsdk.API_BASE = 'http://localhost:3090/api';
}

const rcRPA = new RPAService(null, null);
const rcPayment = new PaymentService();

const loggerMiddleware = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error
})

const middlewares = [thunk.withExtraArgument({ rcsdk, rcPayment, rcRPA }), createDebounce(), loggerMiddleware, routerMiddleware(history)]

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
const store = createStore(
    rootReducer(history),
    {},
    composeEnhancers(applyMiddleware(...middlewares))
)

const CONNECTOR_URL = CORE_URL;

//INIT REDUX PUSHER
configurePusher(store, PUSHER_APP_KEY, {
    cluster: PUSHER_CLUSTER,
    authEndpoint: CONNECTOR_URL + "/chat/getToken"
});

export { store }