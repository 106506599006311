import React, { useState, useEffect } from "react"
import * as Icon from "react-feather"
// import AlexaLogo from "../assets/img/logo/Amazon_Alexa_blue_logo.svg"
// import GAssistantLogo from "../assets/img/logo/Google_Assistant_logo.svg"
// import AircallLogo from "../assets/img/logo/aircall-icon.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from "react-intl"
import { store } from '../redux/storeConfig/store';
import ChartStatusIcon from "../components/reecall/chart/ChartStatusIcon";
import { STORE_USER_MENU_CONFIG, setUserMenuConfig } from '../redux/actions/menu'


const DisplayImg = ({ company }) => {
  const [imgError, setImgError] = useState(false);

  const displayIconOnError = () => setImgError(true)

  useEffect(() => {
    const target = document.getElementById(`${company?.name}-logo`)
    if (target) target.addEventListener("error", displayIconOnError);
    return () => {
      window.removeEventListener("error", displayIconOnError); // cleanup
    };
  }, [company]);

  return company?.settings?.logo_url && !imgError ? <img src={company.settings.logo_url} alt="" id={`${company.name}-logo`} /> : <Icon.Briefcase size={20} className="circle" />
}

export const formatNavItem = (target, companyId) => {
  return target ? { ...target, navLink: target?.navLink?.replace("{{companyId}}", companyId) } : undefined
}


export const getSelectedCompanyItems = () => {
  const innerMenuIconSize = 15 //feather icons
  const innerMenuIconStyle = { width: "15px", height: "15px" } //fontawesome icons
  return [
    {
      id: "selected-company-dashboard",
      title: <FormattedMessage id="menu.item.companyDashboard" defaultMessage="Dashboard" />,
      type: "item",
      icon: <Icon.Activity size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/dashboard`,
    },
    // {
    //   id: "selected-company-details",
    //   title: <FormattedMessage id="menu.item.companyDetails" defaultMessage="Details" />,
    //   type: "item",
    //   icon: <Icon.Briefcase size={innerMenuIconSize} />,
    //   navLink: `/companies/all/{{companyId}}/details/general`

    // },
    {
      id: "selected-company-conversations",
      title: <FormattedMessage id="menu.item.conversations" defaultMessage="Conversations" />,
      type: "item",
      icon: <Icon.MessageCircle size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/conversations`,
    },
    {
      id: "selected-company-chat",
      title: <FormattedMessage id="menu.item.ReecallChat" defaultMessage="Chat" />,
      type: "item",
      icon: <FontAwesomeIcon icon={["far", "comment-dots"]} style={innerMenuIconStyle} />,
      navLink: `/companies/all/{{companyId}}/chat`
    },
    {
      id: "selected-company-tickets",
      title: <FormattedMessage id="menu.item.tickets" defaultMessage="Tickets" />,
      type: "item",
      icon: <Icon.Flag size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/tickets/all`
    },
    {
      id: "selected-company-contacts",
      title: <FormattedMessage id="menu.item.contacts" defaultMessage="Contacts" />,
      type: "item",
      icon: <Icon.MessageSquare size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/contacts/all`
    },
    {
      id: "selected-company-agents",
      title: <FormattedMessage id="menu.item.agents" defaultMessage="Agents" />,
      type: "item",
      icon: <Icon.Headphones size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/agents/`
    },
    {
      id: "selected-company-acds",
      title: <FormattedMessage id="menu.item.acds" defaultMessage="ACD" />,
      type: "item",
      icon: <Icon.PhoneForwarded size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/acds`
    },
    {
      id: "selected-company-channels",
      title: <FormattedMessage id="menu.item.channels" defaultMessage="Channels" />,
      type: "item",
      icon: <Icon.Grid size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/channels/all`
    },
    {
      id: "selected-company-members",
      title: <FormattedMessage id="menu.item.members" defaultMessage="Members" />,
      type: "item",
      icon: <Icon.Users size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/members/all`
    },
    {
      id: "selected-company-collections",
      title: <FormattedMessage id="menu.item.collections" defaultMessage="Collections" />,
      type: "item",
      icon: <Icon.BookOpen size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/collections/`
    },
    {
      id: "selected-company-invitations",
      title: <FormattedMessage id="menu.item.invitations" defaultMessage="Invitations" />,
      type: "item",
      icon: <Icon.UserPlus size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/invitations/list`
    },
    {
      id: "selected-company-faqs",
      title: <FormattedMessage id="menu.item.faqs" defaultMessage="Faqs" />,
      type: "item",
      icon: <Icon.HelpCircle size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/faqs/all`
    },
    {
      id: "selected-company-rules",
      title: <FormattedMessage id="menu.item.rules" defaultMessage="Rules" />,
      type: "item",
      icon: <Icon.Clipboard size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/rules`
    },
    {
      id: "selected-company-hooks",
      title: <FormattedMessage id="menu.item.hooks" defaultMessage="Hooks" />,
      type: "item",
      icon: <Icon.Anchor size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/hooks/all`
    },
    {
      id: "selected-company-tags",
      title: <FormattedMessage id="menu.item.tags" defaultMessage="Tags" />,
      type: "item",
      icon: <Icon.Tag size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/tags`
    },
    {
      id: "selected-company-smartTags",
      title: <FormattedMessage id="menu.item.smartTags" defaultMessage="Smart Tags" />,
      type: "item",
      icon: <FontAwesomeIcon icon={["far", "brain"]} style={innerMenuIconStyle} />,
      navLink: `/companies/all/{{companyId}}/smartTags`
    },
    {
      id: "selected-company-labels",
      title: <FormattedMessage id="menu.item.labels" defaultMessage="Labels" />,
      type: "item",
      icon: <FontAwesomeIcon icon={["far", "tags"]} style={innerMenuIconStyle} />,
      navLink: `/companies/all/{{companyId}}/labels`
    },
    {
      id: "selected-company-tokens",
      title: <FormattedMessage id="menu.item.tokens" defaultMessage="Tokens" />,
      type: "item",
      icon: <Icon.Disc size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/tokens/all`
    },
    {
      id: "selected-company-settings",
      title: <FormattedMessage id="menu.item.settings" defaultMessage="Settings" />,
      type: "item",
      icon: <Icon.Settings size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/details/settings`
    },
    {
      id: "selected-company-quickActions",
      title: <FormattedMessage id="menu.item.quickActions" defaultMessage="Quick Actions" />,
      type: "item",
      icon: <FontAwesomeIcon icon={["far", "magic"]} style={innerMenuIconStyle} />,
      navLink: `/companies/all/{{companyId}}/quickActions/all`
    },
    {
      id: "selected-company-integrations",
      title: <FormattedMessage id="menu.item.integrations" defaultMessage="Integrations" />,
      type: "item",
      icon: <Icon.Box size={innerMenuIconSize} />,
      navLink: `/companies/all/{{companyId}}/integrations/all`
    },
    {
      id: "selected-company-auditLogs",
      title: <FormattedMessage id="menu.item.auditLogs" defaultMessage="Audit logs" />,
      type: "item",
      icon: <FontAwesomeIcon icon={["far", "file-medical-alt"]} style={innerMenuIconStyle} />,
      navLink: `/companies/all/{{companyId}}/auditLogs/all`
    },
    {
      id: "selected-company-clusters",
      title: <FormattedMessage id="menu.item.clusters" defaultMessage="Clusters" />,
      type: "item",
      icon: <FontAwesomeIcon icon={["far", "tilde"]} style={innerMenuIconStyle} />,
      navLink: `/companies/all/{{companyId}}/clusters/all`
    },
    {
      id: "selected-company-exports",
      title: <FormattedMessage id="menu.item.exports" defaultMessage="Exports" />,
      type: "item",
      icon: <FontAwesomeIcon icon={["far", "file-export"]} style={innerMenuIconStyle} />,
      navLink: `/companies/all/{{companyId}}/exports/list`
    },
  ]
}

const navigationConfig = (dispatch, company, companyId) => {

  const rcuser = store.getState().user.rcuser;
  const menuUpdate = store.getState().user.menuUpdate;
  const menuStore = store.getState().menu;
  const globalDashboardStatus = store.getState().dashboards.main.globalStatus;

  const getItemsByProfile = (profile, menuStore) => {
    let result = []
    const main = []
    const secondary = []

    if (rcuser?.settings?.boMenuCustomConfig?.length > 0) {

      const innerMain = []
      const innerSecondary = []
      rcuser.settings.boMenuCustomConfig.forEach(el => {
        if (el.column === "Main") innerMain.push(el)
        if (el.column === "Others") innerSecondary.push(el)
      })
      innerMain.sort((a, b) => a.innerOrder - b.innerOrder).forEach(elem => main.push(elem.id?.replace("selected-company-", '')))
      innerSecondary.sort((a, b) => a.innerOrder - b.innerOrder).forEach(elem => secondary.push(elem.id?.replace("selected-company-", '')))
      
      if (rcuser?.settings?.boMenuCustomConfig?.length < getSelectedCompanyItems()?.length && !menuUpdate) {
        const res = [...main, ...secondary]
        const items = getSelectedCompanyItems().map(elem => elem.id?.replace("selected-company-", ''))
        const difference = items.filter(item => !res.includes(item))
        const newMenuConfig = [...rcuser?.settings?.boMenuCustomConfig, ...difference.map((el, i) => {
          return {
            column: "Others",
            id: "selected-company-" + el,
            innerOrder: rcuser?.settings?.boMenuCustomConfig.filter(item => item.column === "Others").length + i
          }
        })]
        dispatch(setUserMenuConfig(newMenuConfig, true))
      }

    } else menuStore.ids.map(id => menuStore.mainByProfile[profile].includes(id) ? main.push(id) : secondary.push(id))

    result = [
      ...main.map(el => formatNavItem(getSelectedCompanyItems().find(item => item.id === "selected-company-" + el), company?.id || companyId)),
      {
        id: "selected-company-others",
        title: <FormattedMessage id="menu.item.others" defaultMessage="Autres" />,
        type: "collapse",
        icon: <Icon.MoreHorizontal size={15} />,
        navLink: "",
        children: [...secondary.map(el => formatNavItem(getSelectedCompanyItems().find(item => item.id === "selected-company-" + el), company?.id || companyId)).filter(el => el)]
      },
    ]
    if (result.length === 0 && menuStore.orderArray) {
      const orderArray = menuStore.orderArray
      result = [
        ...orderArray.filter(el => el.category === "main").sort((a, b) => a.order - b.order).map(el => formatNavItem(getSelectedCompanyItems().find(item => item.id === el.id), company?.id || companyId)),
        {
          id: "selected-company-others",
          title: <FormattedMessage id="menu.item.others" defaultMessage="Autres" />,
          type: "collapse",
          icon: <Icon.MoreHorizontal size={15} />,
          navLink: "",
          children: [...orderArray.filter(el => el.category === "secondary").sort((a, b) => a.order - b.order).map(el => formatNavItem(getSelectedCompanyItems().find(item => item.id === el.id), company?.id || companyId))]
        },
      ]
    }
    dispatch({ type: STORE_USER_MENU_CONFIG, userMenuConfig: result, inMain: result.map(el => el?.id !== "selected-company-others" ? el?.id.replace("selected-company-", "") : null).filter(el => el) })
    return result
  }

  if (!rcuser) return [];

  return company !== null || companyId ? [
    {
      id: "general-reporting",
      title: <FormattedMessage id="menu.item.reporting.dashboard" defaultMessage="Dashboard" />,
      type: "item",
      icon: <Icon.Activity size={20} />,
      navLink: "/general-reporting/",
      home: false
    },
    {
      type: "groupHeader",
      groupTitle: <FormattedMessage id="menu.group.clients" defaultMessage="CLIENTS" />,
      name: "group-first-data",
      id: "group-first-data",
      permissions: ["Conversation.read", "NewTicket.read", "NewTicket.readOwn"]
    },
    {
      id: "companies",
      title: <FormattedMessage id="menu.item.companiesList" defaultMessage="Companies List" />,
      type: "item",
      icon: <FontAwesomeIcon icon={["far", "building"]} style={{ width: "20px", height: "20px" }} />,
      navLink: "/companies/all",
      home: true
    },

    {
      type: "groupHeader",
      groupTitle: <FormattedMessage id="menu.group.selectedCompany" defaultMessage="CLIENT SELECTIONNÉ" />,
      // groupTitle: <FormattedMessage id="menu.group.selectedCompany" defaultMessage="SELECTED COMPANY" />,
      name: "group-second-data",
      id: "group-second-data",
      permissions: ["Conversation.read", "NewTicket.read", "NewTicket.readOwn"],
      editable: true
    },
    {
      id: "selected-company",
      title: company?.name,
      type: "collapse",
      icon: <DisplayImg company={company} />,
      navLink: "/companies/all",
      dashboardStatus: globalDashboardStatus && globalDashboardStatus !== "error" && <ChartStatusIcon color={globalDashboardStatus} />,
      children: company?.id ? getItemsByProfile(rcuser.settings.backOfficeProfile, menuStore) : [],
    },

    {
      type: "groupHeader",
      groupTitle: <FormattedMessage id="menu.group.allCompaniesData" defaultMessage="All COMPANIES DATA" />,
      name: "group-third-data",
      id: "group-tihrd-data",
      permissions: ["Conversation.read", "NewTicket.read", "NewTicket.readOwn"]
    },

    {
      id: "collected-data",
      title: <FormattedMessage id="menu.item.collectedData" defaultMessage="Collected data" />,
      type: "collapse",
      icon: <Icon.DownloadCloud size={20} className="circle" />,
      navLink: "",
      children: [
        {
          id: "all-conversations",
          title: <FormattedMessage id="menu.item.conversations" defaultMessage="Conversations" />,
          type: "item",
          icon: <Icon.MessageCircle size={15} className="circle" />,
          navLink: "/conversations/all",
        },
        {
          id: "all-tickets",
          title: <FormattedMessage id="menu.item.tickets" defaultMessage="Tickets" />,
          type: "item",
          icon: <Icon.Flag size={15} />,
          navLink: "/tickets/all"
        },
        {
          id: "all-contacts",
          title: <FormattedMessage id="menu.item.contacts" defaultMessage="Contacts" />,
          type: "item",
          icon: <Icon.MessageSquare size={15} />,
          navLink: "/contacts/all"
        },

      ]
    },

    {
      id: "provided-data",
      title: <FormattedMessage id="menu.item.providedData" defaultMessage="Provided data" />,
      type: "collapse",
      icon: <Icon.UploadCloud size={20} className="circle" />,
      navLink: "",
      children: [
        {
          id: "all-collections",
          title: <FormattedMessage id="menu.item.collections" defaultMessage="Collections" />,
          type: "item",
          icon: <Icon.BookOpen size={15} />,
          navLink: "/collections/all"
        },

        {
          id: "all-faqs",
          title: <FormattedMessage id="menu.item.faqs" defaultMessage="Faqs" />,
          type: "item",
          icon: <Icon.HelpCircle size={15} />,
          navLink: "/faqs/all"
        },

        {
          id: "all-integrations",
          title: <FormattedMessage id="menu.item.integrations" defaultMessage="Integrations" />,
          type: "item",
          icon: <Icon.Box size={15} />,
          navLink: "/integrations"
        },
        {
          id: "all-quick-actions",
          title: <FormattedMessage id="menu.item.quickActions" defaultMessage="Quick Actions" />,
          type: "item",
          icon: <FontAwesomeIcon icon={["far", "magic"]} style={{ width: "15px", height: "15px" }} />,
          navLink: "/quick-actions/list"
        },

      ]
    },

    {
      id: "members",
      title: <FormattedMessage id="menu.item.members" defaultMessage="Members" />,
      type: "collapse",
      icon: <Icon.Users size={20} className="circle" />,
      navLink: "",
      children: [
        {
          id: "all-members",
          title: <FormattedMessage id="menu.item.members" defaultMessage="Members" />,
          type: "item",
          icon: <Icon.Users size={15} />,
          navLink: "/members/all"
        },
        {
          id: "all-invitations",
          title: <FormattedMessage id="menu.item.invitations" defaultMessage="Invitations" />,
          type: "item",
          icon: <Icon.UserPlus size={15} />,
          navLink: "/invitations/list"
        },

      ]
    },

    {
      id: "AgentsChannels",
      title: <FormattedMessage id="menu.AgentsChannels" defaultMessage="Agents / Channels" />,
      type: "collapse",
      icon: <Icon.Layers size={20} className="circle" />,
      navLink: "",
      children: [
        {
          id: "all-agents",
          title: <FormattedMessage id="menu.item.agents" defaultMessage="Agents" />,
          type: "item",
          icon: <Icon.Headphones size={15} />,
          navLink: "/agents/all"
        },
        {
          id: "all-channels",
          title: <FormattedMessage id="menu.item.channels" defaultMessage="Channels" />,
          type: "item",
          icon: <Icon.Grid size={15} />,
          navLink: "/channels/all"
        },
      ]
    },

    {
      id: "administrative",
      title: <FormattedMessage id="menu.item.administrative" defaultMessage="Administrative" />,
      type: "collapse",
      icon: <Icon.Paperclip size={20} />,
      navLink: "",
      children: [
        {
          id: "all-tokens",
          title: <FormattedMessage id="menu.item.tokens" defaultMessage="Tokens" />,
          type: "item",
          icon: <Icon.Disc size={15} />,
          navLink: "/tokens/all",
        },
        {
          id: "all-rules",
          title: <FormattedMessage id="menu.item.rules" defaultMessage="Rules" />,
          type: "item",
          icon: <Icon.Clipboard size={15} />,
          navLink: "/rules/all"
        },
        {
          id: "all-progressions",
          title: <FormattedMessage id="menu.item.progression" defaultMessage="Progression" />,
          type: "item",
          icon: <FontAwesomeIcon icon={["far", "chart-line-up"]} style={{ width: "15px", height: "15px" }} />,
          navLink: "/progressions/all"
        },
        {
          id: "all-users",
          title: <FormattedMessage id="menu.item.users" defaultMessage="Users" />,
          type: "item",
          icon: <FontAwesomeIcon icon={["far", "users"]} style={{ width: "15px", height: "15px" }} />,
          navLink: "/users/backOffice/all"
        },
      ]
    },
    {
      id: "billing",
      title: <FormattedMessage id="menu.item.billing" defaultMessage="Billing" />,
      type: "collapse",
      icon: <FontAwesomeIcon icon={["far", "file-invoice-dollar"]} style={{ width: "20px", height: "15px" }} />,
      navLink: "",
      children: [
        {
          id: "all-billing-settings",
          title: <FormattedMessage id="menu.item.billingSettings" defaultMessage="Settings" />,
          type: "item",
          icon: <FontAwesomeIcon icon={["far", "cog"]} style={{ width: "15px", height: "15px" }} />,
          navLink: "/billing-settings/all"
        },
      ]
    },

    {
      id: "automatheque",
      title: <FormattedMessage id="menu.item.automatheque" defaultMessage="Automathèque" />,
      type: "collapse",
      icon: <Icon.Package size={20} />,
      navLink: "",
      children: [
        {
          id: "automatheque-examples",
          title: <FormattedMessage id="menu.item.automathequeExample" defaultMessage="Examples Page" />,
          type: "item",
          icon: <FontAwesomeIcon icon={["far", "cards-blank"]} style={{ width: "15px", height: "15px" }} />,
          navLink: "/automatheque/examples"
        },
        {
          id: "automatheque-rules",
          title: <FormattedMessage id="menu.item.automathequeRules" defaultMessage="Rules" />,
          type: "item",
          icon: <Icon.Clipboard size={15} />,
          navLink: "/automatheque/rules"
        },
        {
          id: "automatheque-quickActions",
          title: <FormattedMessage id="menu.item.automathequeQuickActions" defaultMessage="Quick Actions" />,
          type: "item",
          icon: <FontAwesomeIcon icon={["far", "magic"]} style={{ width: "15px", height: "15px" }} />,
          navLink: "/automatheque/quickActions"
        }
      ]
    },

    {
      id: "snippets",
      title: <FormattedMessage id="menu.item.snippets" defaultMessage="Snippets" />,
      type: "collapse",
      icon: <FontAwesomeIcon icon={["far", "chart-pie-simple"]} style={{ width: "20px", height: "15px" }} />,
      navLink: "",
      children: [
        {
          id: "snippets-list",
          title: < FormattedMessage id="menu.item.allSnippets" defaultMessage="All" />,
          type: "item",
          icon: <FontAwesomeIcon icon={["far", "code-simple"]} style={{ width: "15px", height: "15px" }} />,
          navLink: "/snippets/list/all"
        },
        {
          id: "snippets-agents",
          title: < FormattedMessage id="menu.item.agents" defaultMessage="Agents" />,
          type: "item",
          icon: <Icon.Headphones size={15} />,
          navLink: "/snippets/agents/all"
        },
        {
          id: "snippets-events",
          title: < FormattedMessage id="menu.item.events" defaultMessage="Events" />,
          type: "item",
          icon: <FontAwesomeIcon icon={["far", "calendar-circle-plus"]} style={{ width: "15px", height: "15px" }} />,
          navLink: "/snippets/events/all"
        },
        {
          id: "snippets-documentations",
          title: < FormattedMessage id="menu.item.documentations" defaultMessage="Documentations" />,
          type: "item",
          icon: <FontAwesomeIcon icon={["far", "book"]} style={{ width: "15px", height: "15px" }} />,
          navLink: "/snippets/documentations/all"
        },
      ]
    },

    {
      type: "groupHeader",
      groupTitle: <FormattedMessage id="menu.group.tools" defaultMessage="TOOLS" />,
      name: "group-tools",
      id: "group-tools",
      permissions: ["Conversation.read", "NewTicket.read", "NewTicket.readOwn"]
    },

    {
      id: "general-settings",
      title: <FormattedMessage id="menu.item.general-settings" defaultMessage="Settings" />,
      type: "item",
      icon: <Icon.Settings size={20} />,
      navLink: "/general-settings"
    },

    {
      id: "white-space-80px",
      title: <div style={{ height: "80px" }}></div>,
      type: "item",
    },

  ]


    :


    [
      {
        id: "general-reporting",
        title: <FormattedMessage id="menu.item.reporting.dashboard" defaultMessage="Dashboard" />,
        type: "item",
        icon: <Icon.Activity size={20} />,
        navLink: "/general-reporting",
        home: false
      },
      {
        type: "groupHeader",
        groupTitle: <FormattedMessage id="menu.group.clients" defaultMessage="CLIENTS" />,
        name: "group-first-data",
        id: "group-first-data",
        permissions: ["Conversation.read", "NewTicket.read", "NewTicket.readOwn"]
      },
      {
        id: "companies",
        title: <FormattedMessage id="menu.item.companiesList" defaultMessage="Companies List" />,
        type: "item",
        icon: <FontAwesomeIcon icon={["far", "building"]} style={{ width: "20px", height: "20px" }} />,
        navLink: "/companies/all",
        home: true
      },


      {
        type: "groupHeader",
        groupTitle: <FormattedMessage id="menu.group.allCompaniesData" defaultMessage="All COMPANIES DATA" />,
        name: "group-third-data",
        id: "group-tihrd-data",
        permissions: ["Conversation.read", "NewTicket.read", "NewTicket.readOwn"]
      },

      {
        id: "collected-data",
        title: <FormattedMessage id="menu.item.collectedData" defaultMessage="Collected data" />,
        type: "collapse",
        icon: <Icon.DownloadCloud size={20} className="circle" />,
        navLink: "",
        children: [
          {
            id: "all-conversations",
            title: <FormattedMessage id="menu.item.conversations" defaultMessage="Conversations" />,
            type: "item",
            icon: <Icon.MessageCircle size={15} className="circle" />,
            navLink: "/conversations/all",
          },
          {
            id: "all-tickets",
            title: <FormattedMessage id="menu.item.tickets" defaultMessage="Tickets" />,
            type: "item",
            icon: <Icon.Flag size={15} />,
            navLink: "/tickets/all"
          },
          {
            id: "all-contacts",
            title: <FormattedMessage id="menu.item.contacts" defaultMessage="Contacts" />,
            type: "item",
            icon: <Icon.MessageSquare size={15} />,
            navLink: "/contacts/all"
          },

        ]
      },

      {
        id: "provided-data",
        title: <FormattedMessage id="menu.item.providedData" defaultMessage="Provided data" />,
        type: "collapse",
        icon: <Icon.UploadCloud size={20} className="circle" />,
        navLink: "",
        children: [
          {
            id: "all-collections",
            title: <FormattedMessage id="menu.item.collections" defaultMessage="Collections" />,
            type: "item",
            icon: <Icon.BookOpen size={15} />,
            navLink: "/collections/all"
          },

          {
            id: "all-faqs",
            title: <FormattedMessage id="menu.item.faqs" defaultMessage="Faqs" />,
            type: "item",
            icon: <Icon.HelpCircle size={15} />,
            navLink: "/faqs/all"
          },

          {
            id: "all-integrations",
            title: <FormattedMessage id="menu.item.integrations" defaultMessage="Integrations" />,
            type: "item",
            icon: <Icon.Box size={15} />,
            navLink: "/integrations"
          },
          {
            id: "all-quick-actions",
            title: <FormattedMessage id="menu.item.quickActions" defaultMessage="Quick Actions" />,
            type: "item",
            icon: <FontAwesomeIcon icon={["far", "magic"]} style={{ width: "15px", height: "15px" }} />,
            navLink: "/quick-actions/list"
          },

        ]
      },

      {
        id: "members",
        title: <FormattedMessage id="menu.item.members" defaultMessage="Members" />,
        type: "collapse",
        icon: <Icon.Users size={20} className="circle" />,
        navLink: "",
        children: [
          {
            id: "all-members",
            title: <FormattedMessage id="menu.item.members" defaultMessage="Members" />,
            type: "item",
            icon: <Icon.Users size={15} />,
            navLink: "/members/all"
          },
          {
            id: "all-invitations",
            title: <FormattedMessage id="menu.item.invitations" defaultMessage="Invitations" />,
            type: "item",
            icon: <Icon.UserPlus size={15} />,
            navLink: "/invitations/list"
          },

        ]
      },

      {
        id: "AgentsChannels",
        title: <FormattedMessage id="menu.AgentsChannels" defaultMessage="Agents / Channels" />,
        type: "collapse",
        icon: <Icon.Layers size={20} className="circle" />,
        navLink: "",
        children: [
          {
            id: "all-agents",
            title: <FormattedMessage id="menu.item.agents" defaultMessage="Agents" />,
            type: "item",
            icon: <Icon.Headphones size={15} />,
            navLink: "/agents/all"
          },
          {
            id: "all-channels",
            title: <FormattedMessage id="menu.item.channels" defaultMessage="Channels" />,
            type: "item",
            icon: <Icon.Grid size={15} />,
            navLink: "/channels/all"
          },
        ]
      },

      {
        id: "administrative",
        title: <FormattedMessage id="menu.item.administrative" defaultMessage="Administrative" />,
        type: "collapse",
        icon: <Icon.Paperclip size={20} />,
        navLink: "",
        children: [
          {
            id: "all-tokens",
            title: <FormattedMessage id="menu.item.tokens" defaultMessage="Tokens" />,
            type: "item",
            icon: <Icon.Disc size={15} />,
            navLink: "/tokens/all",
          },
          {
            id: "all-rules",
            title: <FormattedMessage id="menu.item.rules" defaultMessage="Rules" />,
            type: "item",
            icon: <Icon.Clipboard size={15} />,
            navLink: "/rules/all"
          },
          {
            id: "all-progressions",
            title: <FormattedMessage id="menu.item.progression" defaultMessage="Progression" />,
            type: "item",
            icon: <FontAwesomeIcon icon={["far", "chart-line-up"]} style={{ width: "15px", height: "15px" }} />,
            navLink: "/progressions/all"
          },
          {
            id: "all-users",
            title: <FormattedMessage id="menu.item.users" defaultMessage="Users" />,
            type: "item",
            icon: <FontAwesomeIcon icon={["far", "users"]} style={{ width: "15px", height: "15px" }} />,
            navLink: "/users/backOffice/all"
          },
        ]
      },
      {
        id: "billing",
        title: <FormattedMessage id="menu.item.billing" defaultMessage="Billing" />,
        type: "collapse",
        icon: <FontAwesomeIcon icon={["far", "file-invoice-dollar"]} style={{ width: "20px", height: "15px" }} />,
        navLink: "",
        children: [
          {
            id: "all-billing-settings",
            title: <FormattedMessage id="menu.item.billingSettings" defaultMessage="Settings" />,
            type: "item",
            icon: <FontAwesomeIcon icon={["far", "cog"]} style={{ width: "15px", height: "15px" }} />,
            navLink: "/billing-settings/all"
          },
        ]
      },

      {
        id: "automatheque",
        title: <FormattedMessage id="menu.item.automatheque" defaultMessage="Automathèque" />,
        type: "collapse",
        icon: <Icon.Package size={20} />,
        navLink: "",
        children: [
          {
            id: "automatheque-examples",
            title: <FormattedMessage id="menu.item.automathequeExample" defaultMessage="Examples Page" />,
            type: "item",
            icon: <FontAwesomeIcon icon={["far", "cards-blank"]} style={{ width: "15px", height: "15px" }} />,
            navLink: "/automatheque/examples"
          },
          {
            id: "automatheque-rules",
            title: <FormattedMessage id="menu.item.automathequeRules" defaultMessage="Rules" />,
            type: "item",
            icon: <Icon.Clipboard size={15} />,
            navLink: "/automatheque/rules"
          },
          {
            id: "automatheque-quickActions",
            title: <FormattedMessage id="menu.item.automathequeQuickActions" defaultMessage="Quick Actions" />,
            type: "item",
            icon: <FontAwesomeIcon icon={["far", "magic"]} style={{ width: "15px", height: "15px" }} />,
            navLink: "/automatheque/quickActions"
          },
        ]
      },

      {
        id: "snippets",
        title: <FormattedMessage id="menu.item.snippets" defaultMessage="Snippets" />,
        type: "collapse",
        icon: <FontAwesomeIcon icon={["far", "chart-pie-simple"]} style={{ width: "20px", height: "15px" }} />,
        navLink: "",
        children: [
          {
            id: "snippets-list",
            title: < FormattedMessage id="menu.item.allSnippets" defaultMessage="All" />,
            type: "item",
            icon: <FontAwesomeIcon icon={["far", "code-simple"]} style={{ width: "15px", height: "15px" }} />,
            navLink: "/snippets/list/all"
          },
          {
            id: "snippets-agents",
            title: < FormattedMessage id="menu.item.agents" defaultMessage="Agents" />,
            type: "item",
            icon: <Icon.Headphones size={15} />,
            navLink: "/snippets/agents/all"
          },
          {
            id: "snippets-events",
            title: < FormattedMessage id="menu.item.events" defaultMessage="Events" />,
            type: "item",
            icon: <FontAwesomeIcon icon={["far", "calendar-circle-plus"]} style={{ width: "15px", height: "15px" }} />,
            navLink: "/snippets/events/all"
          },
          {
            id: "snippets-documentations",
            title: < FormattedMessage id="menu.item.documentations" defaultMessage="Documentations" />,
            type: "item",
            icon: <FontAwesomeIcon icon={["far", "book"]} style={{ width: "15px", height: "15px" }} />,
            navLink: "/snippets/documentations/all"
          },
        ]
      },

      {
        type: "groupHeader",
        groupTitle: <FormattedMessage id="menu.group.tools" defaultMessage="TOOLS" />,
        name: "group-tools",
        id: "group-tools",
        permissions: ["Conversation.read", "NewTicket.read", "NewTicket.readOwn"]
      },

      {
        id: "general-settings",
        title: <FormattedMessage id="menu.item.general-settings" defaultMessage="Settings" />,
        type: "item",
        icon: <Icon.Settings size={20} />,
        navLink: "/general-settings"
      },
      {
        id: "white-space-80px",
        title: <div style={{ height: "80px" }}></div>,
        type: "item",
      },

    ]
}

export default navigationConfig