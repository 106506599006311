export const FETCH_COMPANY_ACDS = "FETCH_COMPANY_ACDS";
export const STORE_COMPANY_ACDS = "STORE_COMPANY_ACDS";
export const RESET_COMPANY_ACDS = "RESET_COMPANY_ACDS";
export const STORE_MEMBER_CHANNEL_CONNECTIONS = "STORE_MEMBER_CHANNEL_CONNECTIONS";
export const SET_PENDING = "SET_PENDING";
export const STORE_CURRENT_ACD = "STORE_CURRENT_ACD";
export const ACD_REQUEST_STATUS = "ACD_REQUEST_STATUS"

export const storeCompanyACDs = (companyACDs) => ({
    type: STORE_COMPANY_ACDS,
    companyACDs,
});

export const resetCompanyACDs = () => ({
    type: RESET_COMPANY_ACDS,
});

export const setPending = (pending) => ({
    type: SET_PENDING,
    pending,
})

export const storeCurrentACD = (currentACD) => ({
    type: STORE_CURRENT_ACD,
    currentACD,
})

export const storeMemberChannelConnections = (memberChannelConnections) => ({
    type: STORE_MEMBER_CHANNEL_CONNECTIONS,
    memberChannelConnections,
});

export const ACDrequestStatus = (requestStatus) => ({
    type: ACD_REQUEST_STATUS,
    requestStatus
});

export const fetchCompanyACDs = (companyId) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch(setPending(true));
        return rcsdk
        .getACDs()
        .where({"companyId": companyId})
        .then(response => {
            dispatch(storeCompanyACDs(response));
            dispatch(setPending(false));
        })
        .catch(error => {
            console.log(error);
        })
        .then(response => {
            dispatch(setPending(false));
        })
    }
}

export const fecthMemberChannelConnections = (memberId) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch(setPending(true));
        return rcsdk
        ._request()
        .route(`/members/${memberId}/channelConnections`)
        .then(response => {
            dispatch(storeMemberChannelConnections(response));
            dispatch(setPending(false));
        })
        .catch(error => {
            console.log(error);
        })
        .then(response => {
            dispatch(setPending(false));
        })
    }
}

export const getACDById = (acdId) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        return rcsdk
        .getACD(acdId)
        .then(response => {
            dispatch(storeCurrentACD(response));
        })
        .catch(error => {
            console.log(error)
        })
    }
}

export const updateACD = (acdId, acd) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        return rcsdk
        .updateACD(acdId, acd)
        .then(response => {
            dispatch(ACDrequestStatus("updated"));
        })
        .catch(error => {
            dispatch(ACDrequestStatus("error"));
        })
    }
}

export const createACD = (acd) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        return rcsdk
        .createACD(acd)
        .then(response => {
            dispatch(ACDrequestStatus("created"));
        })
        .catch(error => {
            dispatch(ACDrequestStatus("error"));
        })
    }
}

export const deleteACD = (acdId) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        return rcsdk
        .deleteACD(acdId)
        .then(response => {
            dispatch(ACDrequestStatus("deleted"));
        })
        .catch(error => {
            dispatch(ACDrequestStatus("error"));
        })
    }
}