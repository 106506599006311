import {TOGGLE_ASSISTANT, PUSH_INSIGHT, TOGGLE_INSIGHTS, REMOVE_INSIGHT} from '../../actions/assistant';
import {ASSISTANT_INSIGHT, NEW_NOTIFICATION, NEW_CONNECTION, NEW_INTERCONNECTION} from '../../actions/pusher';
import {uuid} from "uuidv4";

const initState = {
    show: false,
    showInsights: true,
    insights: [
    ]
};

const assistantReducer = (state = initState, action) => {
  switch (action.type) {
    
    case TOGGLE_ASSISTANT:
        return {
            ...state,
            show: !state.show
        }

    case TOGGLE_INSIGHTS:
        return {
            ...state,
            showInsights: !state.showInsights
        }

    case PUSH_INSIGHT:
        return {
            ...state,
            insights: [
                ...state.insights,
                action.insights
            ]
        }
    
    case ASSISTANT_INSIGHT:
        return {
            ...state,
            show: true,
            insights: [
                ...state.insights,
                {
                    ...action.data,
                    id: uuid()
                }
            ]
        }

    case NEW_NOTIFICATION:
        if(action.data.type === "assistant"){
            return {
                ...state,
                show: true,
                insights: [...state.insights, 
                {
                    ...action.data.datas,
                    id: uuid()
                }]
            }
        } else {
            return {...state}
        }

    case NEW_CONNECTION:
        if(action.data.from === action.additionalParams.loggedUserId) {
            return {...state}
        }
        return {
            ...state,
            show: true,
            insights: [...state.insights, 
            {
                id: uuid(),
                type: "new-chat",
                config: {
                    ...action.data
                }
            }]
        }

    case NEW_INTERCONNECTION:
        if(action.data.from === action.additionalParams.loggedUserId) {
            return {...state}
        }
        return {
            ...state,
            show: true,
            insights: [...state.insights, 
            {
                id: uuid(),
                type: "new-internal-chat",
                config: {
                    ...action.data
                }
            }]
        }

    case REMOVE_INSIGHT:
        return {
            ...state,
            insights: [
                ...state.insights.filter(el => el.id !== action.insightId)
            ]
        }

    default: {
    	return state
    }
  }
}

export default assistantReducer;