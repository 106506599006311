import {
  TOGGLE_GLOBAL_SEARCH,
  GLOBAL_SEARCH_SET_SELECTED_VALUES,
  FETCH_COMPANIES_GLOBAL_SEARCH,
  FETCH_AGENTS_GLOBAL_SEARCH,
  FETCH_CHANNELS_GLOBAL_SEARCH,
  FETCH_RULES_GLOBAL_SEARCH,
  FETCH_ACTIONS_GLOBAL_SEARCH,
  FETCH_TAGS_GLOBAL_SEARCH,
  GLOBAL_SEARCH_IN_SEARCH,
  FLUSH_GLOBAL_SEARCH,
} from "../../actions/globalSearch";

const initialState = {
  show: false,
  pending: false,
  selectedValues: null,
  options: [],
  companies: [],
  agents: [],
  channels: [],
  rules: [],
  quickActions: [],
  tags: [],
  status: null,
  inSearch: false,
  innerHover: null,
  innerCursor: null,
};

const globalSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_GLOBAL_SEARCH:
      return {
        ...state,
        show: !state.show,
      };

    case GLOBAL_SEARCH_IN_SEARCH:
      return {
        ...state,
        inSearch: action.status,
      };

    case GLOBAL_SEARCH_SET_SELECTED_VALUES:
      return {
        ...state,
        selectedValues: action.selectedValues,
      };

    case FETCH_TAGS_GLOBAL_SEARCH:
      return {
        ...state,
        tags:
          action.status === "success" ? action.tags : state.tags,
        status: action.status,
      };

    case FETCH_COMPANIES_GLOBAL_SEARCH:
      return {
        ...state,
        companies:
          action.status === "success" ? action.companies : state.companies,
        status: action.status,
      };

    case FETCH_AGENTS_GLOBAL_SEARCH:
      return {
        ...state,
        agents: action.status === "success" ? action.agents : state.agents,
        status: action.status,
      };

    case FETCH_CHANNELS_GLOBAL_SEARCH:
      return {
        ...state,
        channels:
          action.status === "success" ? action.channels : state.channels,
        status: action.status,
      };

    case FETCH_RULES_GLOBAL_SEARCH:
      return {
        ...state,
        rules: action.status === "success" ? action.rules : state.rules,
        status: action.status,
      };

    case FETCH_ACTIONS_GLOBAL_SEARCH:
      return {
        ...state,
        quickActions:
          action.status === "success"
            ? action.quickActions
            : state.quickActions,
        status: action.status,
      };

    case FLUSH_GLOBAL_SEARCH:
      return initialState;

    case "@@router/LOCATION_CHANGE":
      if (state.show) {
        return initialState;
      } else return state;

    default:
      return { ...state };
  }
};

export default globalSearchReducer;
