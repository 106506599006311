import {
    FETCH_AGENTS_FOR_DUPLICATION_PENDING,
    FETCH_AGENTS_FOR_DUPLICATION_SUCCESS,
    FETCH_AGENTS_FOR_DUPLICATION_ERROR,
    FLUSH_FETCH_FOR_DUPLICATION_AGENTS,
    SET_TEMP_DATA_FOR_DUPLICATION,
    CREATE_AGENT_BY_DUPLICATION,
    DUPLICATE_RULE_SUCCESS,
    DUPLICATE_RULE_PENDING,
    DUPLICATE_RULE_ERROR,
    FLUSH_DUPLICATE,
    DUPLICATE_QUICK_ACTION,
    DUPLICATE_EXPORT,
} from '../../actions/duplication';

import { toast } from "react-toastify";

const initialState = {
    pending: false,
    agents: [],
    error: null,
    fetchAgentsStatus: null,
    tempData: null,
    duplicationStatus: null,
}

const duplicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case DUPLICATE_QUICK_ACTION:
            action.status === "success" && toast.success(`quick action ${action.quickActionLibrairy.title} has been successfully duplicated to ${action.company.name}`);
            return {
                ...state,
                duplicationStatus: action.status
            }
        case DUPLICATE_EXPORT:
            action.status === "success" && toast.success(`export ${action.export.name} has been successfully duplicated to ${action.company.name}`);
            action.status === "error" && toast.error(action.error);
            return {
                ...state,
                duplicationStatus: action.status
            }
        case DUPLICATE_RULE_PENDING:
            return {
                ...state,
                duplicationStatus: "pending"
            }
        case DUPLICATE_RULE_SUCCESS:
            toast.success(`rule ${action.rule.name} has been successfully duplicated to ${action.company.name}`);
            return {
                ...state,
                duplicationStatus: "success"
            }
        case DUPLICATE_RULE_ERROR:
            toast.error(action.error);
            return {
                ...state,
            }
            case FLUSH_DUPLICATE:
                return {
                    ...state,
                    duplicationStatus: "",
                    quickActions: [],
                    steps: [],
                }
    
        case CREATE_AGENT_BY_DUPLICATION:
            return {
                ...state,
                duplicationStatus: action.status,
            }
        case SET_TEMP_DATA_FOR_DUPLICATION:
            return {
                ...state,
                tempData: action.data
            }
        case FETCH_AGENTS_FOR_DUPLICATION_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_AGENTS_FOR_DUPLICATION_SUCCESS:
            return {
                ...state,
                fetchAgentsStatus: "success",
                pending: false,
                agents: action.agents,
            }
        case FLUSH_FETCH_FOR_DUPLICATION_AGENTS:
            return {
                ...state,
                fetchAgentsStatus: null,
            }
        case FETCH_AGENTS_FOR_DUPLICATION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default duplicationReducer;