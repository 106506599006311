import axios from "axios"
import { CORE_URL } from "../../../configs/constants";

export const FETCH_LIVES_PENDING = 'FETCH_LIVES_PENDING';
export const FETCH_LIVES_SUCCESS = 'FETCH_LIVES_SUCCESS';
export const FETCH_LIVES_ERROR = 'FETCH_LIVES_ERROR';
export const SET_LIVES_TYPE = 'SET_LIVES_TYPE';
export const SET_INPUT_VALUE = 'SET_INPUT_VALUE';
export const SET_CURRENT_LIVE_ID = 'SET_CURRENT_LIVE_ID';
export const PUSH_MESSAGE = 'PUSH_MESSAGE';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const USER_TYPING = 'USER_TYPING';
export const RESET_TYPING = 'RESET_TYPING';
export const RESET_UNREAD = 'RESET_UNREAD';

const CONNECTOR_URL = CORE_URL;
// const CONNECTOR_URL = "https://rcconnectors.eu.ngrok.io/";

// function fetchLivesPending() {
//     return {
//         type: FETCH_LIVES_PENDING
//     }
// }

// function fetchLivesSuccess(lives) {
//     return {
//         type: FETCH_LIVES_SUCCESS,
//         lives: lives
//     }
// }

// function fetchLivesError(error) {
//     return {
//         type: FETCH_LIVES_ERROR,
//         error: error
//     }
// }

// export const fetchLives = () => {
//     return (dispatch, getState, {rcsdk, rcAlgolia}) => {
//         dispatch(fetchLivesPending());
//         return axios.get("/api/lives").then(response => {
//           return dispatch(fetchLivesSuccess(response.data));
//         }).catch(err => {
//           dispatch(fetchLivesError(err.message));
//         })
//     }
// }

export const setLivesFilter = type => ({
  type: SET_LIVES_TYPE,
  payload: type
});

export const setInputValue = (liveId, value) => ({
  type: SET_INPUT_VALUE,
  liveId: liveId,
  value: value
})

export const setCurrentLiveId = (liveId) => ({
  type: SET_CURRENT_LIVE_ID,
  liveId: liveId
})


export function pushMessage(conversationId, message){
  return (dispatch, getState, {rcsdk, rcAlgolia}) => {
    if(getState().lives.lives.find(el => el.id === conversationId).internal){
      return axios.post(CONNECTOR_URL+"internchat/say", {
        message: message,
        conversationId: conversationId,
        from: 'agent',
        fromId: getState().user.rcuser.rcId
      })
    } else {
      return axios.post(CONNECTOR_URL+"chat/say", {
        message: message,
        conversationId: conversationId,
        from: 'agent',
        fromId: getState().user.rcuser.rcId
      })
    }
  }
}

export function newInternalChat(member){
  return (dispatch, getState, {rcsdk, rcAlgolia}) => {
    return axios.post(CONNECTOR_URL+"internchat/connect", {
        from: getState().user.rcuser.rcId,
        to: member.id
    })
  }
}


export const selectLiveById = (state, {liveId}) => state.lives.lives.find(el => el.id === `${liveId}`) || null;
export const selectCurrentInternalLive = state => selectInternalLives(state).find(el => el.id ===  state.members.drawer.currentLiveId)
export const selectInternalLives = state => state.lives.lives.filter(el => el.internal);
export const selectExternalLives = state => state.lives.lives.filter(el => !el.internal);

export const selectInternalLiveBetween = (state, {member1Id, member2Id}) => {
  return state.lives.lives.filter(live => { return (live.id === `${member1Id}${member2Id}` || live.id === `${member2Id}${member1Id}`) && live.internal})?.[0]
}

export const selectTotalUnreadInbounds = state => state.reduce((acc, el) => acc + el.unread, 0)