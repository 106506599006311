import React from 'react';
import { FormattedMessage } from 'react-intl'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulbExclamation, faMapMarkerAlt, faCalendarDay, faCalendarAlt, faLongArrowRight } from '@fortawesome/pro-solid-svg-icons';
import reecallAvatarUrl from '../../../assets/img/logo/bubble_reecall_2020.svg';

const ChatGuess = ({guess, ...rest}) => {
    const guessInfos = getGuessInfos(guess);
    let text = guessInfos.text;
    let icon = guessInfos.icon;
    return <div className={`chat-guess chat-message chat-message-right`} {...rest} >
        <div className="chat-message-body">
            <div className="chat-message-content">
                <div className={`chat-avatar chat-avatar-reecall`}>
                  <div className="avatar m-0">
                    <img
                      src={reecallAvatarUrl}
                      alt="chat avatar"
                      height="40"
                      width="40"
                    />
                  </div>
                </div>
                <FontAwesomeIcon icon={icon} />
                {text}
            </div>
        </div>
    </div>
}
export default ChatGuess

export function getGuessInfos(guess, context = "userline") {
    let dateFormats = context === "userline" ? {multiple: "L LT", simple: "LLLL"} : {multiple: "L LT", simple: "L LT"} ;
    let text = "";
    let icon = faLightbulbExclamation;

    if(typeof guess === "string"){
        text = guess;
    } else if(Array.isArray(guess)){
        text = guess.join(" | ");
    } else if (typeof guess.type === 'undefined') {
        if (typeof guess.nb !== 'undefined') {
            if (guess.nb > 1) {
                text = [ context === "userline" ? <FormattedMessage id="I think it's either :" /> : '', guess.result.map((item, i) => <b key={`guess_${i}`}>{item}</b>).reduce((prev, curr) => [prev, ` or `, curr])];
            } else {
                text = [ context === "userline" ? <FormattedMessage id="I think it's more :" /> : '', <b key={`guess`}>{guess.result[0]}</b>]
            }
        }
    } else {
        switch (guess.type) {
            case 'time':
                ["date", "from", "to"].map((type) => {
                    if(guess.datas[type]){
                        if(guess.datas[type].toString().includes("T00:00:00")) dateFormats = {multiple: "L", simple: "L"};
                    }
                    return type;
                });

                if (guess.datas.from && guess.datas.to) {
                    icon = faCalendarAlt;
                    text = <b>
                        <Moment unix={typeof guess.datas.from === "number"} format={dateFormats.multiple}>{guess.datas.from}</Moment>
                        <FontAwesomeIcon icon={faLongArrowRight} className="date-arrow" />
                        <Moment unix={typeof guess.datas.to === "number"} format={dateFormats.multiple}>{guess.datas.to}</Moment>
                    </b>
                } else if(guess.datas.date || guess.datas.from){
                    icon = faCalendarDay;
                    let date = guess.datas.date || guess.datas.from;
                    text = <b>
                        <Moment unix={typeof date === "number"} format={dateFormats.simple}>{date}</Moment>
                    </b>
                }
                break;
            case 'person':
                if (guess.datas.nb > 1) {
                    text = [ context === "userline" ? <FormattedMessage id="I think it's either :" /> : '', guess.datas.result.map((item, i) => <b key={`guess_person_${i}`}>{item}</b>).reduce((prev, curr) => [prev, ` or `, curr])];
                } else {
                    text = [ context === "userline" ? <FormattedMessage id="I think it's more :" /> : '', <b key={`guess_person`}>{guess.datas.result[0]}</b>];
                }
                break;
            case 'address':
                icon = faMapMarkerAlt;
                text = <div>{guess.datas.formatted_address} - <a href={'https://www.google.com/maps/place/' + guess.datas.coordinates.lat + ',' + guess.datas.coordinates.lng} target='_blank' rel='noopener noreferrer'>{<FormattedMessage id="Display map" />}</a></div>
                break;
             case 'choice':
                if (guess.datas.nb > 1) {
                    text = [ context === "userline" ? <FormattedMessage id="I think it's either :" key={`guess_choice-label`} /> : '', guess.datas.result.map((item, i) => <b key={`guess_choice_${i}`}>{item}</b>).reduce((prev, curr) => [prev, ` or ` , curr])];
                } else {
                    text = [ context === "userline" ? <FormattedMessage id="I think it's more :" key={`guess_choice-label`} /> : '', <b key={`guess_choice`}>{guess.datas.result[0]}</b>];
                }
                break;
            default:
                let values = (Array.isArray(guess.datas)) ? guess.datas : [guess.datas];
                text = values.join("|");
                break;
        }
    }
    return {text, icon};
}