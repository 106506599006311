import {
    FETCH_INTEGRATIONS_PENDING,
    FETCH_INTEGRATIONS_SUCCESS,
    FETCH_INTEGRATIONS_ERROR,
    FLUSH_INTEGRATIONS,

    FETCH_INTEGRATION_CONNECTIONS_PENDING,
    FETCH_INTEGRATION_CONNECTIONS_SUCCESS,
    FETCH_INTEGRATION_CONNECTIONS_ERROR,

    CREATE_INTEGRATION_CONNECTION,
    DELETE_INTEGRATION_CONNECTION,

    FETCH_INTEGRATIONS_CONNECTIONS_BY_COMPANY_ID,
    FLUSH_FETCH_INTEGRATIONS_CONNECTIONS_BY_COMPANY_ID,
    UPDATE_LIST_AFTER_DELETE,

    SET_SHOW_INTEGRATION_CTA_MODAL,
    SET_SHOW_INTEGRATION_DETAIL_CTA_MODAL,
    
} from '../../actions/integrations';

const initialState = {
    pending: false,
    integrations: [],
    integrationConnections: [],
    integrationConnectionsByCompanyId: [],
    integrationConnectionsByCompanyIdStatus: null,
    error: null,
}

// const quickActionsMockup = [
//     {
//         id: "request",
//         title:"Request",
//         description:"Faire une requete",
//         color: "#ff5451",
//         icon: "phone",
//         fields:[
//             {
//                 key: "method",
//                 label: "type de requete",
//                 description: "",
//                 type: "select",
//                 options: [{
//                     value: "GET",
//                     label: "GET"
//                 },{
//                     value: "POST",
//                     label: "POST"
//                 },{
//                     value: "PATCH",
//                     label: "PATCH"
//                 },{
//                     value: "DELETE",
//                     label: "DELETE"
//                 }],
//                 default: "GET",
//                 isRequired: true 
//             },
//             {
//                 key: "url",
//                 label: "Endpoint Url",
//                 description: "",
//                 type: "url",
//                 default: "",
//                 isRequired:true
//             },
//             {
//                 key:"data",
//                 label:"Datas",
//                 description:"",
//                 type:"textarea",
//                 isRequired:false
//             }
//         ]
//     }
// ]

const integrationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INTEGRATIONS_CONNECTIONS_BY_COMPANY_ID:
            return {
                ...state,
                integrationConnectionsByCompanyId: action.integrationConnections || state.integrationConnectionsByCompanyId,
                integrationConnectionsByCompanyIdStatus: action.status
            }
        case FLUSH_FETCH_INTEGRATIONS_CONNECTIONS_BY_COMPANY_ID:
            return {
                ...state,
                integrationConnectionsByCompanyIdStatus: null
            }
        case FETCH_INTEGRATIONS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_INTEGRATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                integrations: action.integrations
            }
        case FETCH_INTEGRATIONS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case FLUSH_INTEGRATIONS:
            return {
                ...state,
                integrations: []
            }

        case FETCH_INTEGRATION_CONNECTIONS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_INTEGRATION_CONNECTIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                integrations: [...state.integrations.map(integration => {
                    return {
                        ...integration,
                        integrationConnectionId: action.integrationConnections.find(el => el.integrationId === integration.id)?.id
                    }
                })],
                integrationConnections: action.integrationConnections
            }
        case FETCH_INTEGRATION_CONNECTIONS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case CREATE_INTEGRATION_CONNECTION:
            return {
                ...state,
                integrations: [
                    ...state.integrations.map(integration => {
                        return {
                            ...integration,
                            integrationConnectionId: integration.id === action.integrationConnection.integrationId ? action.integrationConnection.id : integration?.integrationConnectionId
                        }
                    })
                ],
                integrationConnections: [
                    ...state.integrationConnections,
                    action.integrationConnection
                ]
            }

        case DELETE_INTEGRATION_CONNECTION:

            return {
                ...state,
                integrations: [
                    ...state.integrations.map(integration => {
                        return {
                            ...integration,
                            integrationConnectionId: integration.id === action.integrationId ? null : integration?.integrationConnectionId
                        }
                    })
                ],
                integrationConnections: [
                    ...state.integrationConnections.filter(el => el.id !== action.integrationConnectionId)
                ]
            }

        case UPDATE_LIST_AFTER_DELETE:
            return {
                ...state,
                integrationConnectionsByCompanyId: [
                    ...state.integrationConnectionsByCompanyId.filter(el => el.id !== action.integrationConnectionId)
                ]
            }

        case SET_SHOW_INTEGRATION_CTA_MODAL:
            return {
                ...state,
                showCTAModal: action.payload || false
            }

        case SET_SHOW_INTEGRATION_DETAIL_CTA_MODAL:
            return {
                ...state,
                showDetailCTAModal: action.payload || false
            }

        default:
            return state;
    }
}
export default integrationsReducer;