import React from "react";
import { FormattedMessage } from "react-intl";

export const FETCH_TEAMS_PENDING = 'FETCH_TEAMS_PENDING';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_ERROR = 'FETCH_TEAMS_ERROR';

export const UPDATE_TEAM = 'UPDATE_TEAM';
export const ADD_TEAM = 'ADD_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';

function fetchTeamsPending() {
    return {
        type: FETCH_TEAMS_PENDING
    }
}

function fetchTeamsSuccess(teams) {
    return {
        type: FETCH_TEAMS_SUCCESS,
        teams: teams
    }
}

function fetchTeamsError(error) {
    return {
        type: FETCH_TEAMS_ERROR,
        error: error
    }
}

export const fetchTeams = () => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch(fetchTeamsPending());
        return rcsdk.getTeams()
        .then(teams => {
            dispatch(fetchTeamsSuccess(teams));
        }).catch(err => {
            dispatch(fetchTeamsError(err.message));
        })
    }
}

function addTeamSuccess(team){
    return {
        type: ADD_TEAM,
        team: team,
        successToast: {
            type: "ADD",
            message: <FormattedMessage id="teams.toast.add" defaultMessage="Team added successfully"/>
        }
    }
}

export const addTeam = (datas) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch(fetchTeamsPending());

        return rcsdk.createTeam(datas)
        .then((team) => {
            dispatch(addTeamSuccess(team));
            return team;
        }).catch(err => {
            dispatch(fetchTeamsError(err.message));
        });
    }
}

function updateTeamSuccess(team){
    return {
        type: UPDATE_TEAM,
        team: team,
        successToast: {
            type: "UPDATE",
            message: <FormattedMessage id="teams.toast.update" defaultMessage="Team updated successfully"/>
        }
    }
}

export const updateTeam = ({id, datas}) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch(fetchTeamsPending());

        return rcsdk.updateTeam(id, datas)
        .then((response) => {
            dispatch(updateTeamSuccess(response));
        }).catch(err => {
            dispatch(fetchTeamsError(err.message));
        });
    }
}

function deleteTeamSuccess(teamId){
    return {
        type: DELETE_TEAM,
        id: teamId,
        successToast: {
            type: "DELETE",
            message: <FormattedMessage id="teams.toast.delete" defaultMessage="Team deleted successfully"/>
        }
    }
}

export const deleteTeam = (id) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch(fetchTeamsPending());

        return rcsdk.deleteTeam(id)
        .then((response) => {
            dispatch(deleteTeamSuccess(id));
        }).catch(err => {
            dispatch(fetchTeamsError(err.message));
        });
    }
}