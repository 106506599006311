import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getIntegrationsRPA } from '../integrations';
import axios from 'axios';
import { ReplaceByContext } from '../../../utility/utils';
import { getUrlByInfra } from '../../../configs/constants';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';

export const FETCH_QUICK_ACTIONS = 'FETCH_QUICK_ACTIONS';
export const ADD_QUICK_ACTION = 'ADD_QUICK_ACTION';
export const UPDATE_QUICK_ACTION = 'UPDATE_QUICK_ACTION';
export const DELETE_QUICK_ACTION = 'DELETE_QUICK_ACTION';
export const FETCH_QUICK_ACTION_LIBRAIRIES_PENDING = 'FETCH_QUICK_ACTION_LIBRAIRIES_PENDING';
export const FETCH_QUICK_ACTION_LIBRAIRIES_SUCCESS = 'FETCH_QUICK_ACTION_LIBRAIRIES_SUCCESS';
export const FETCH_QUICK_ACTION_LIBRAIRIES_ERROR = 'FETCH_QUICK_ACTION_LIBRAIRIES_ERROR';
export const UPDATE_QUICK_ACTION_LIBRAIRIES_PENDING = 'UPDATE_QUICK_ACTION_LIBRAIRIES_PENDING';
export const UPDATE_QUICK_ACTION_LIBRAIRIES_SUCCESS = 'UPDATE_QUICK_ACTION_LIBRAIRIES_SUCCESS';
export const UPDATE_QUICK_ACTION_LIBRAIRIES_ERROR = 'UPDATE_QUICK_ACTION_LIBRAIRIES_ERROR';
export const CREATE_QUICK_ACTION_LIBRAIRIES_PENDING = 'CREATE_QUICK_ACTION_LIBRAIRIES_PENDING';
export const CREATE_QUICK_ACTION_LIBRAIRIES_SUCCESS = 'CREATE_QUICK_ACTION_LIBRAIRIES_SUCCESS';
export const CREATE_QUICK_ACTION_LIBRAIRIES_ERROR = 'CREATE_QUICK_ACTION_LIBRAIRIES_ERROR';
export const DELETE_QUICK_ACTION_LIBRAIRIES_PENDING = 'DELETE_QUICK_ACTION_LIBRAIRIES_PENDING';
export const DELETE_QUICK_ACTION_LIBRAIRIES_SUCCESS = 'DELETE_QUICK_ACTION_LIBRAIRIES_SUCCESS';
export const DELETE_QUICK_ACTION_LIBRAIRIES_ERROR = 'DELETE_QUICK_ACTION_LIBRAIRIES_ERROR';
export const FLUSH_QA_LIBRAIRIES = 'FLUSH_QA_LIBRAIRIES';
export const FLUSH_QA_LIBRAIRY_UPDATE_CREATE_DELETE = 'FLUSH_QA_LIBRAIRY_UPDATE_CREATE_DELETE';
export const DISPLAY_SUBFORM_STATUS = 'DISPLAY_SUBFORM_STATUS';
export const DISPLAY_SUBFORM_BUILDER_STATUS = 'DISPLAY_SUBFORM_BUILDER_STATUS';
export const SET_SUBFORM_VALUES = 'SET_SUBFORM_VALUES';
export const FLUSH_QUICK_ACTION_LIBRAIRIES = 'FLUSH_QUICK_ACTION_LIBRAIRIES';

export const addQuickAction = (datas) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({
            type: ADD_QUICK_ACTION,
            status: 'pending',
        });

        return rcsdk
            ._request()
            .method('POST')
            .route(`/quickActions`)
            .data(datas)
            .err(`Could not create quick Action`)
            .then((quickAction) => {
                dispatch({
                    type: ADD_QUICK_ACTION,
                    status: 'success',
                    quickAction: {
                        ...quickAction,
                        integration: {
                            ...getState().integrations.integrations.find(
                                (inte) => inte.id === quickAction.integrationId,
                            ),
                        },
                    },
                    successToast: {
                        type: 'ADD',
                        message: (
                            <FormattedMessage
                                id="quickActions.toast.add"
                                defaultMessage="Quick Actions added successfully"
                            />
                        ),
                    },
                });
                return {
                    quickAction: {
                        ...quickAction,
                        integration: {
                            ...getState().integrations.integrations.find(
                                (inte) => inte.id === quickAction.integrationId,
                            ),
                        },
                    },
                };
            })
            .catch((err) => {
                dispatch({
                    type: ADD_QUICK_ACTION,
                    status: 'error',
                    error: err,
                });
            });
    };
};

export const updateQuickAction = (id, datas) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({
            type: UPDATE_QUICK_ACTION,
            status: 'pending',
        });

        return rcsdk
            ._request()
            .method('PATCH')
            .route(`/quickActions/${id}`)
            .data(datas)
            .err(`Could not update quick Action`)
            .then((quickAction) => {
                dispatch({
                    type: UPDATE_QUICK_ACTION,
                    status: 'success',
                    quickAction: {
                        ...quickAction,
                        integration: {
                            ...getState().integrations.integrations.find(
                                (inte) => inte.id === quickAction.integrationId,
                            ),
                        },
                    },
                    successToast: {
                        type: 'UPDATE',
                        message: (
                            <FormattedMessage
                                id="quickActions.toast.update"
                                defaultMessage="Quick Actions updated successfully"
                            />
                        ),
                    },
                });
                return {
                    quickAction: {
                        ...quickAction,
                        integration: {
                            ...getState().integrations.integrations.find(
                                (inte) => inte.id === quickAction.integrationId,
                            ),
                        },
                    },
                };
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_QUICK_ACTION,
                    status: 'error',
                    error: err,
                });
            });
    };
};

export const deleteQuickAction = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({
            type: DELETE_QUICK_ACTION,
            status: 'pending',
        });

        return rcsdk
            ._request()
            .method('DELETE')
            .route(`/quickActions/${id}`)
            .err(`Could not delete quick Action`)
            .then((resp) => {
                dispatch({
                    type: DELETE_QUICK_ACTION,
                    status: 'success',
                    id: id,
                    successToast: {
                        type: 'DELETE',
                        message: (
                            <FormattedMessage
                                id="quickActions.toast.delete"
                                defaultMessage="Quick Actions deleted successfully"
                            />
                        ),
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: DELETE_QUICK_ACTION,
                    status: 'error',
                    error: err,
                });
            });
    };
};

export const fetchQuickActions = ({ companyId, searchTerm, startIndex, stopIndex, order, flush }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({
            type: FETCH_QUICK_ACTIONS,
            status: 'pending',
            flush: flush,
            startIndex,
            stopIndex,
        });

        let where = {};
        if (companyId) {
            where = { companyId: companyId };
        }
        if (searchTerm.field === 'title') {
            where = Object.assign({}, where, { title: { like: `.*${searchTerm.search}.*`, options: 'i' } });
        } else if (searchTerm.field === 'integrationId') {
            where = Object.assign({}, where, { integrationId: { eq: searchTerm.search } });
        }

        return rcsdk
            .getQuickActions()
            .include(['integration'])
            .where(where)
            .skip(startIndex)
            .limit(stopIndex - startIndex)
            .order(order)
            .raw()
            .then((response) => {
                const total = parseInt(
                    response.headers['content-range'].substr(response.headers['content-range'].indexOf('/') + 1),
                );
                dispatch({
                    type: FETCH_QUICK_ACTIONS,
                    status: 'success',
                    quickActions: response.data,
                    total: total,
                    startIndex,
                    stopIndex,
                });

                return { quickActions: response.data, total: total };
            })
            .catch((err) => {
                dispatch({
                    type: FETCH_QUICK_ACTIONS,
                    status: 'error',
                    error: err,
                });
            });
    };
};

export const fetchQuickAction = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk
            .getQuickAction(id)
            .then((response) => {
                return response;
            })
            .catch((err) => {});
    };
};

export const getQuickActionsRPA = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return dispatch(getIntegrationsRPA()).then((integrations) => {
            return integrations
                .map((integration) => {
                    return Object.entries(integration.actions).map(([key, action]) => {
                        return { ...action, integration: integration };
                    });
                })
                .flat();
        });
    };
};

export const getQuickActionRPA = (quickAction, integration) => {
    return (dispatch, getState, { rcsdk, rcRPA }) => {
        const infra = getState().company?.company?.companySetting?.[0]?.value?.infra || 'production';
        rcRPA.url = getUrlByInfra('rpa', infra);
        return rcRPA
            .getQuickActionRPA(quickAction, integration)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                console.log(err.message, 'err');
            });
    };
};

export const fetchQuickActionLibrairies = (searchTerm, companyId, filterOutStar = null) => {
    const search = () => {
        if (searchTerm.search.length === 0) return;
        switch (searchTerm.field) {
            case '':
                return;
            case 'keyword':
                if (companyId)
                    return {
                        and: [
                            { title: { like: `.*${searchTerm.search}.*`, options: 'i' } },
                            filterOutStar ? { companyId: { eq: companyId } } : { companyId: { inq: ['*', companyId] } },
                        ],
                    };
                else return { title: { like: `.*${searchTerm.search}.*`, options: 'i' } };
            case 'companyId':
                return filterOutStar
                    ? { companyId: searchTerm.search }
                    : { companyId: { inq: ['*', searchTerm.search] } };
            case 'id':
                return { id: searchTerm.search };
            case 'ids':
                return {
                    id: {
                        inq: searchTerm.search,
                    },
                };
            default:
                break;
        }
    };

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({
            type: FETCH_QUICK_ACTION_LIBRAIRIES_PENDING,
            status: 'pending',
        });
        return rcsdk
            .getQuickActionLibraries()
            .where(searchTerm && search())
            .then((response) => {
                dispatch({
                    type: FETCH_QUICK_ACTION_LIBRAIRIES_SUCCESS,
                    status: 'success',
                    quickActionLibrairies: response,
                });
            })
            .catch((err) => {
                dispatch({
                    type: FETCH_QUICK_ACTION_LIBRAIRIES_ERROR,
                    status: 'error',
                    error: err,
                });
            });
    };
};

export const updateQuickActionLibrairy = (id, data, inJsonBeautifier = false) => {
    console.log('ici');
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({
            type: UPDATE_QUICK_ACTION_LIBRAIRIES_PENDING,
            status: 'pending',
            inJsonBeautifier: inJsonBeautifier,
        });
        return rcsdk
            .updateQuickActionLibrary(id, data)
            .then((response) => {
                dispatch({
                    type: UPDATE_QUICK_ACTION_LIBRAIRIES_SUCCESS,
                    status: 'success',
                    quickActionLibrairy: response,
                    inJsonBeautifier: inJsonBeautifier,
                });
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_QUICK_ACTION_LIBRAIRIES_ERROR,
                    status: 'error',
                    error: err,
                    inJsonBeautifier: inJsonBeautifier,
                });
            });
    };
};

export const createQuickActionLibrary = (data, inJsonBeautifier = false) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({
            type: CREATE_QUICK_ACTION_LIBRAIRIES_PENDING,
            status: 'pending',
            inJsonBeautifier: inJsonBeautifier,
        });
        return rcsdk
            .createQuickActionLibrary(data)
            .then((response) => {
                dispatch({
                    type: CREATE_QUICK_ACTION_LIBRAIRIES_SUCCESS,
                    status: 'success',
                    quickActionLibrairy: response,
                    inJsonBeautifier: inJsonBeautifier,
                });

                toast.success(
                    `Quick Action with id ${response.id} successfully updated`,
                    Object.assign(
                        {},
                        inJsonBeautifier
                            ? {
                                  onClick: () => {
                                      dispatch(
                                          push(`/companies/all/${response.companyId}/quickActions/${response.id}`),
                                      );
                                  },
                              }
                            : null,
                    ),
                );

                return response;
            })
            .catch((err) => {
                dispatch({
                    type: CREATE_QUICK_ACTION_LIBRAIRIES_ERROR,
                    status: 'error',
                    error: err,
                    inJsonBeautifier: inJsonBeautifier,
                });
            });
    };
};

export const deleteQuickActionLibrary = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({
            type: DELETE_QUICK_ACTION_LIBRAIRIES_PENDING,
            status: 'pending',
        });
        return rcsdk
            .deleteQuickActionLibrary(id)
            .then((response) => {
                dispatch({
                    type: DELETE_QUICK_ACTION_LIBRAIRIES_SUCCESS,
                    status: 'success',
                    id: id,
                });
            })
            .catch((err) => {
                dispatch({
                    type: DELETE_QUICK_ACTION_LIBRAIRIES_ERROR,
                    status: 'error',
                    error: err,
                });
            });
    };
};

export const fetchQuickActionLibrary = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk
            .getQuickActionLibrary(id)
            .then((response) => {
                return response;
            })
            .catch((err) => {});
    };
};

export const executeQuickAction = ({ quickAction, context, directExecution = false, showToast = true }) => {
    return (dispatch, getState, { rcsdk }) => {
        const infra = getState().company?.company?.companySetting?.[0]?.value?.infra || 'production';

        if (directExecution) {
            //Todo: Gérer les variable nested
            let data = JSON.stringify({
                ...quickAction.datas,
                ...context,
            });

            data = JSON.parse(data, (key, value) => {
                return typeof value === 'string' ? value.replace(/"/g, '\\"') : value;
            });

            return axios({
                method: 'POST',
                url: `${getUrlByInfra('rpa', infra)}/quickAction/${quickAction.id}`,
                data: data,
                // data: {
                //     ...quickAction.datas,
                //     ...context
                // }
            })
                .then((response) => {
                    if (showToast) {
                        dispatch({
                            type: 'SHOW_EXECUTE_QA_TOAST',
                            successToast: {
                                type: 'EXECUTE_QA',
                                message: (
                                    <FormattedMessage
                                        id="quickAction.toast.executionSuccess"
                                        defaultMessage="Quick action executed successfully"
                                    />
                                ),
                            },
                        });
                    }
                    return response.data;
                })
                .catch((err) => {
                    console.log(err.message, 'err');
                });
        } else {
            let formattedQuickAction = {
                ...quickAction,
                datas: {
                    ...ReplaceByContext(quickAction.datas, '{{(.+?)}}', context),
                    companyId: getState().company.company.id,
                },
            };

            let quickActionIntegrationKey = formattedQuickAction?.integrationId
                ? getState().integrations.integrations.find((el) => el.id === formattedQuickAction.integrationId).key
                : formattedQuickAction?.integrationKey;

            return axios({
                method: 'POST',
                url: `${getUrlByInfra('rpa', infra)}/action`,
                data: {
                    config: {
                        integration: quickActionIntegrationKey,
                        action: formattedQuickAction.key,
                        datas: { ...formattedQuickAction.datas },
                    },
                },
            })
                .then((response) => {
                    if (showToast) {
                        dispatch({
                            type: 'SHOW_EXECUTE_QA_TOAST',
                            successToast: {
                                type: 'EXECUTE_QA',
                                message: (
                                    <FormattedMessage
                                        id="quickAction.toast.executionSuccess"
                                        defaultMessage="Quick action executed successfully"
                                    />
                                ),
                            },
                        });
                    }
                    return response.data;
                })
                .catch((err) => {
                    console.log(err.message, 'err');
                });
        }
    };
};

export const executeAction = ({ quickAction, context = {}, showToast = false }) => {
    return (dispatch, getState, { rcRPA }) => {
        console.groupCollapsed('QA: executeAction');
        console.log(quickAction, 'quickAction');
        console.log(context, 'context');
        console.log(showToast, 'showToast');

        let formattedQuickAction = {
            ...quickAction,
            datas: {
                ...ReplaceByContext(quickAction.datas, '{{(.+?)}}', context),
                companyId: getState().company.company.id,
            },
        };
        let quickActionIntegrationKey = formattedQuickAction?.integrationId
            ? getState().integrations.integrations.find((el) => el.id === formattedQuickAction.integrationId).key
            : formattedQuickAction?.integrationKey;

        console.log(formattedQuickAction, 'formattedQuickAction');
        console.groupEnd();

        const infra = getState().company?.company?.companySetting?.[0]?.value?.infra || 'production';
        rcRPA.url = getUrlByInfra('rpa', infra);

        return rcRPA
            .executeAction({
                config: {
                    integration: quickActionIntegrationKey,
                    action: formattedQuickAction.key,
                    datas: { ...formattedQuickAction.datas },
                },
            })
            .then((resp) => {
                if (showToast) {
                    dispatch({
                        type: 'SHOW_EXECUTE_QA_TOAST',
                        successToast: {
                            type: 'EXECUTE_QA',
                            message: (
                                <FormattedMessage
                                    id="quickAction.toast.executionSuccess"
                                    defaultMessage="Quick action executed successfully"
                                />
                            ),
                        },
                    });
                }
                return resp;
            });
    };
};