import React from "react"
import { Card, CardBody } from "reactstrap"
import ToggleSwitch from "../form/ToggleSwitch";
import ChannelIcon from "./ChannelIcon"


const ChannelCard = ({channel, active, className, onToggle, showToggle, showDesc}) => {
  
  return <Card 
    className={`card-channel ${className ? className : ''}`}
  >
    <CardBody>
      <span className="card-channel--icon">
        <ChannelIcon channelType={channel.type} size={20}/>
      </span>
      <h4 className="card-channel--label">{channel.name}</h4>
      { showDesc && <p className="card-channel--description">{channel.description}</p> }
      {
        showToggle && <div className="card-channel--toggle">
          <ToggleSwitch 
            name={`channels-disable-${channel.id}`}
            className="mr-0"
            checked={active}
            onChange={onToggle}
          />
        </div>
      }
    </CardBody>
  </Card>
}

ChannelCard.defaultProps = {
  channel: null,
  active: false,
  onToggle: () => {},
  showToggle: false,
  showDesc: true
}

export default ChannelCard;