import {
    FETCH_QUICK_ACTIONS,
    ADD_QUICK_ACTION,
    UPDATE_QUICK_ACTION,
    DELETE_QUICK_ACTION,
    FETCH_QUICK_ACTION_LIBRAIRIES_PENDING,
    FETCH_QUICK_ACTION_LIBRAIRIES_SUCCESS,
    // FETCH_QUICK_ACTION_LIBRAIRIES_ERROR,
    UPDATE_QUICK_ACTION_LIBRAIRIES_PENDING,
    UPDATE_QUICK_ACTION_LIBRAIRIES_SUCCESS,
    // UPDATE_QUICK_ACTION_LIBRAIRIES_ERROR,
    CREATE_QUICK_ACTION_LIBRAIRIES_PENDING,
    CREATE_QUICK_ACTION_LIBRAIRIES_SUCCESS,
    // CREATE_QUICK_ACTION_LIBRAIRIES_ERROR,
    // DELETE_QUICK_ACTION_LIBRAIRIES_PENDING,
    DELETE_QUICK_ACTION_LIBRAIRIES_SUCCESS,
    // DELETE_QUICK_ACTION_LIBRAIRIES_ERROR,
    FLUSH_QA_LIBRAIRIES,
    FLUSH_QA_LIBRAIRY_UPDATE_CREATE_DELETE,
    DISPLAY_SUBFORM_STATUS,
    DISPLAY_SUBFORM_BUILDER_STATUS,
    SET_SUBFORM_VALUES,
    FLUSH_QUICK_ACTION_LIBRAIRIES
} from '../../actions/quickActions';

import { toast } from "react-toastify";


const initialState = {
    status: "pending",
    updateQaLibrairyStatus: "",
    createQaLibrairyStatus: "",
    deleteQaLibrairyStatus: "",
    newQaId: null,
    error: null,
    quickActions: [],
    quickActionLibrairies: [],
    total: null,
    displaySubFormStatus: null,
    displaySubFormBuilderStatus: null,
    subFormValues: null,
    fetchQaStatus: null,
}

const quickActionsReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_QUICK_ACTIONS:

            let newQuickActions = [];
            if (action.status === "pending") {
                if (action.flush) {
                    newQuickActions = new Array(action.stopIndex - action.startIndex).fill("pending");
                } else {
                    newQuickActions = [...state.quickActions]
                    newQuickActions.splice(action.startIndex, action.stopIndex - state.quickActions.length, ...new Array(action.stopIndex - state.quickActions.length).fill("pending"));
                }
            } else if (action.status === "success") {
                newQuickActions = [...state.quickActions]
                newQuickActions.splice(action.startIndex, action.quickActions.length, ...action.quickActions);
                newQuickActions = newQuickActions.filter(el => el !== "pending");
            } else {
                newQuickActions = [...state.quickActions];
            }

            return {
                ...state,
                status: action.status,
                error: action.status === "error" ? action.error : null,
                quickActions: [...newQuickActions],
                total: action.status === "success" ? action.total : state.total
            }

        case FLUSH_QUICK_ACTION_LIBRAIRIES:
            return {
                ...state,
                quickActionLibrairies: [],
                fetchQaStatus: null
            }

        case SET_SUBFORM_VALUES:
            return {
                ...state,
                subFormValues: action.values
            }


        case ADD_QUICK_ACTION:
            if (action.status === "success") {
                return {
                    ...state,
                    quickActions: [...state.quickActions, { ...action.quickAction }]
                }
            } else {
                return state
            }

        case UPDATE_QUICK_ACTION:
            if (action.status === "success") {
                return {
                    ...state,
                    quickActions: [
                        ...state.quickActions.map(quickAction => {
                            return quickAction.id !== action.quickAction.id ? { ...quickAction } : { ...action.quickAction }
                        })
                    ]
                }
            } else {
                return state
            }

        case DELETE_QUICK_ACTION:
            if (action.status === "success") {
                return {
                    ...state,
                    quickActions: [...state.quickActions.filter(quickAction => quickAction.id !== action.id)]
                }
            } else {
                return state
            }

        case FETCH_QUICK_ACTION_LIBRAIRIES_PENDING:
            if (action.status === "pending") {
                return {
                    ...state,
                    fetchQaStatus: "pending",
                }
            } else {
                return state
            }

        case FETCH_QUICK_ACTION_LIBRAIRIES_SUCCESS:
            if (action.status === "success") {
                return {
                    ...state,
                    fetchQaStatus: "success",
                    quickActionLibrairies: action.quickActionLibrairies
                }
            } else {
                return state
            }

        case CREATE_QUICK_ACTION_LIBRAIRIES_PENDING:
            if (action.status === "pending") {
                return {
                    ...state,
                    createQaLibrairyStatus: !action.inJsonBeautifier ? "pending" : "",
                }
            } else {
                return state
            }
        case CREATE_QUICK_ACTION_LIBRAIRIES_SUCCESS:
            // toast.success(`Quick Action successfully created`);
            if (action.status === "success") {
                return {
                    ...state,
                    createQaLibrairyStatus: !action.inJsonBeautifier ? "success" : "",
                    newQaId: action.quickActionLibrairy.id,
                    quickActionLibrairies: [...state.quickActionLibrairies, { ...action.quickActionLibrairy }]
                }
            } else {
                return state
            }

        case UPDATE_QUICK_ACTION_LIBRAIRIES_PENDING:
            if (action.status === "pending") {
                return {
                    ...state,
                    updateQaLibrairyStatus: !action.inJsonBeautifier ? "pending" : ""
                }
            } else {
                return state
            }
        case UPDATE_QUICK_ACTION_LIBRAIRIES_SUCCESS:
            toast.success(`Quick Action with id ${action.quickActionLibrairy.id} successfully updated`);
            if (action.status === "success") {
                return {
                    ...state,
                    updateQaLibrairyStatus: !action.inJsonBeautifier ? "success" : "",
                    quickActionLibrairies: [
                        ...state.quickActionLibrairies.map(quickActionLibrairy => {
                            return quickActionLibrairy.id !== action.quickActionLibrairy.id ? { ...quickActionLibrairy } : { ...action.quickActionLibrairy }
                        })
                    ]
                }
            } else {
                return state
            }
        case DELETE_QUICK_ACTION_LIBRAIRIES_SUCCESS:
            toast.success(`Quick Action with id ${action.id} successfully deleted`);
            if (action.status === "success") {
                return {
                    ...state,
                    deleteQaLibrairyStatus: "success",
                    quickActionLibrairies: [...state.quickActionLibrairies.filter(quickAction => quickAction.id !== action.id)]
                }
            } else {
                return state
            }
        case FLUSH_QA_LIBRAIRY_UPDATE_CREATE_DELETE:
            return {
                ...state,
                updateQaLibrairyStatus: "",
                createQaLibrairyStatus: "",
                deleteQaLibrairyStatus: "",
                newQaId: null
            }
        case FLUSH_QA_LIBRAIRIES:
            return {
                ...state,
                quickActionLibrairies: [],
                fetchQaStatus: ""
            }

        case DISPLAY_SUBFORM_STATUS:
            return {
                ...state,
                displaySubFormStatus: action.status,
            }

        case DISPLAY_SUBFORM_BUILDER_STATUS:
            return {
                ...state,
                displaySubFormBuilderStatus: action.status,
            }

        default:
            return state;
    }
}
export default quickActionsReducer;