import React from "react"
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SuccessIcon = ({type}) => {
    switch(type){
        case "UPDATE":
            return <FontAwesomeIcon 
                icon={["fas", "edit"]}
                className="mr-1"
            />

        case "DELETE":
            return <FontAwesomeIcon 
                icon={["fas", "trash"]}
                className="mr-1"
            />

        default:
        case "ADD":
            return <FontAwesomeIcon 
                icon={["fas", "plus"]}
                className="mr-1"
            />
    }
}

const initialState = {};
const toastsReducer = (state = initialState, action) => {
	if(action?.successToast?.message){
        toast.success(<>
            <SuccessIcon type={action.successToast?.type} />
            {action.successToast.message}
        </>, {})
	}
    return {...state}
}
export default toastsReducer;