import React from "react";
import {
    Modal,
	ModalHeader,
	ModalBody,
    DropdownItem
} from "reactstrap"
import { FormattedMessage } from "react-intl"
import ReactCountryFlag from "react-country-flag"
import { IntlContext } from "../../../utility/context/Internationalization"

const ModalLanguage = ({isOpen = false, onToggle = () => {}}) => {

	return <Modal 
	    isOpen={isOpen}
	    size="sm"
	    scrollable={false}
	    backdrop={true}
	    toggle={() => onToggle()}
	    className="modal-change-locale modal-dialog-centered"
  	>
		<ModalHeader toggle={() => onToggle()}>
            <FormattedMessage id="modal.changelanguage.title" defaultMessage="Choose your language" />
        </ModalHeader>
		<ModalBody
			style={{
			    flexGrow: 1,
			    justifyContent: "center",
			    display: "flex",
			    flexDirection: "column"
			}}
		>
        {
        <IntlContext.Consumer>
          {context => {
            return (
                <>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("en")}
                    className="px-50"
                  >
                    <ReactCountryFlag className="country-flag" countryCode="us" svg />
                    <span className="ml-1"><FormattedMessage id="navbar.lang.english" defaultMessage="English" /></span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("fr")}
                    className="px-50"
                  >
                    <ReactCountryFlag className="country-flag" countryCode="fr" svg />
                    <span className="ml-1"><FormattedMessage id="navbar.lang.french" defaultMessage="French" /></span>
                  </DropdownItem>
                </>
            )
          }}
        </IntlContext.Consumer>
        }
		</ModalBody>
	  </Modal>
}

export default ModalLanguage