import {
    GET_OLDEST_3_TICKETS_OPEN,
    FETCH_GLOBAL_STATUS,
    FETCH_CARD_DATAS,
    SET_PERIOD_UNIT
} from '../../actions/dashboards/main';

const initialState = {
    dateRange: null,
    periodUnit: "day",
    dateRangeLabel: null,
    globalStatus: null,
    oldest3TicketsOpenStatus: null,
    oldest3TicketsOpen: [],
    incomingRequestsCardStatus: null,
    incomingRequestsCardDatas: {},
    createdTicketsCardStatus: null,
    createdTicketsCardDatas: {},
    escalationCardStatus: null,
    escalationCardDatas: {},
    closedTicketsByAgentsCardStatus: null,
    closedTicketsByAgentsCardDatas: {},
    TagsCategoryCardStatus: null,
    TagsCategoryCardDatas: {},
    TagsLifeCycleCardStatus: null,
    TagsLifeCycleCardDatas: {},
    TagsCardStatus: null,
    TagsCardDatas: {}, 
}

const mainDashboardReducer = (state = initialState, action) => {
    switch(action.type) {

        case GET_OLDEST_3_TICKETS_OPEN:
            return {
                ...state,
                oldest3TicketsOpen: action.status === "success" ? action?.tickets : [],
                oldest3TicketsOpenStatus: action.status
            }

        case FETCH_GLOBAL_STATUS:
            return {
                ...state,
                globalStatus: action.status
            }
    
        case FETCH_CARD_DATAS:
            return {
                ...state,
                [`${action.cardType}CardStatus`]: action.status,
                [`${action.cardType}CardDatas`]: action.status === "success" ? action.datas : state[`${action.cardType}CardDatas`],
            }

        case SET_PERIOD_UNIT:
            return {
                ...state,
                periodUnit: action.unit
            }

        default: 
            return state;
    }
}
export default mainDashboardReducer;