import moment from 'moment';

moment.updateLocale('en', {
    calendar : {
        sameDay : '[Today at] LT',
        nextDay : '[Tomorrow at] LT',
        nextWeek : 'dddd [at] LT',
        lastDay : '[Yesterday at] LT',
        lastWeek : '[last] dddd [at] LT',
        sameElse : 'LL - LT'
    }
});
moment.locale('small_en', {
    ...moment.locale(), 
    relativeTime : {
        future : '%s',
        past : '%s',
        s : '1 s',
        ss : '%d s',
        m : '1 min',
        mm : '%d mins',
        h : '1 h',
        hh : '%d h',
        d : '1 d',
        dd : '%d d',
        M : '1 month',
        MM : '%d month',
        y : '1 y',
        yy : '%d y'
    },
    calendar : {
        sameDay : '[Today]',
        nextDay : '[Tomorrow]',
        nextWeek : 'dddd',
        lastDay : '[Yesterday]',
        lastWeek : '[last] dddd',
        sameElse : 'L'
    }
});
moment.locale('en');
