import {
    FETCH_WPINTEGRATIONS_PENDING,
    FETCH_WPINTEGRATIONS_SUCCESS,
    FETCH_WPINTEGRATIONS_ERROR,
    FETCH_WPINTEGRATION_PAGE_PENDING,
    FETCH_WPINTEGRATION_PAGE_SUCCESS,
    FETCH_WPINTEGRATION_PAGE_ERROR
} from '../../actions/wp_integrations';

const initialState = {
    pendingList: false,
    pendingPage: false,
    integrations: null,
    integration_page: null,
    error: null
}

const integrationsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_WPINTEGRATIONS_PENDING: 
            return {
                ...state,
                pendingList: true
            }
        case FETCH_WPINTEGRATIONS_SUCCESS:
            return {
                ...state,
                pendingList: false,
                integrations: action.integrations
            }
        case FETCH_WPINTEGRATIONS_ERROR:
            return {
                ...state,
                pendingList: false,
                error: action.error
            }
        case FETCH_WPINTEGRATION_PAGE_PENDING: 
            return {
                ...state,
                pendingPage: true
            }
        case FETCH_WPINTEGRATION_PAGE_SUCCESS:
            return {
                ...state,
                pendingPage: false,
                integration_page: action.integration_page
            }
        case FETCH_WPINTEGRATION_PAGE_ERROR:
            return {
                ...state,
                pendingPage: false,
                error: action.error
            }
        default: 
            return state;
    }
}
export default integrationsReducer;