import React from "react"
import classnames from "classnames"
import { Badge } from "reactstrap"

const Avatar = ({color, className, size, style, onClick, img, badgeUp, badgeColor, badgeText, badgePill, content, icon, imgHeight, imgWidth, status}) => {

    return (
      <div
        className={classnames(
          `avatar ${color && !color.startsWith("#") ? `bg-${color}` : ''}  ${
            className ? className : ''
          }`,
          {
            "avatar-sm": size && size === "sm",
            "avatar-lg": size && size === "lg",
            "avatar-xl": size && size === "xl",
            "avatar-chat-message": size && size === "chat-message"
          }
        )}
        style={Object.assign({...style}, color && color.startsWith("#") ? {backgroundColor: color}: null)}
        onClick={onClick}
      >
        {img === false || img === undefined ? (
          <span
            className={classnames("avatar-content", {
              "position-relative": badgeUp
            })}
          >
            {content ? content : null}

            {icon ? (
              <div className="avatar-icon">{icon}</div>
            ) : null}
            {badgeUp ? (
              <Badge
                color={
                  badgeColor ? badgeColor : "primary"
                }
                className="badge-sm badge-up"
                pill={badgePill}
              >
                {badgeText ? badgeText : "0"}
              </Badge>
            ) : null}
          </span>
        ) : (

          <span
            className={classnames("avatar-content", {
              "position-relative": badgeUp
            })}
          >
            <img
              src={img}
              alt="avatarImg"
              height={
                imgHeight && !size
                  ? imgHeight
                  : 32
              }
              width={
                imgWidth && !size ? imgWidth : 32
              }
            />

            {badgeUp ? (
              <Badge
                color={
                  badgeColor ? badgeColor : "primary"
                }
                className="badge-sm badge-up"
                pill={badgePill}
              >
                {badgeText ? badgeText : "0"}
              </Badge>
            ) : null}
          </span>
        )}
        {status ? (
          <span className={`avatar-status-${status}`}></span>
        ) : null}
      </div>
    )
}

Avatar.defaultProps = {
  badgePill: true,
  color: "light"  
}

export default Avatar
