import {
    FETCH_HOOKS,
    UPDATE_HOOK,
    FLUSH_HOOK_STATUS,
    CREATE_HOOK,
    DELETE_HOOK
} from '../../actions/hooks';

const initialState = {
    hooks: [],
    updateHookStatus: null,
    createHookStatus: null,
    deleteHookStatus: null,
    fetchHookStatus: null,
}
const hooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HOOKS:
            return {
                ...state,
                hooks: action.hooks,
                fetchHookStatus: action.status
            }

        case UPDATE_HOOK:
            return {
                ...state,
                updateHookStatus: action.status,
                hooks: action.status === "success" ? [...state.hooks.filter(hk => hk.id !== action.hook?.id), action.hook] : [...state.hooks]
            }

        case FLUSH_HOOK_STATUS:
            return {
                ...state,
                updateHookStatus: null,
                createHookStatus: null,
            }

        case CREATE_HOOK:
            return {
                ...state,
                createHookStatus: action.status,
                hooks: action.status === "success" ? [...state.hooks, action.hook] : [...state.hooks]
            }

        case DELETE_HOOK:
            return {
                ...state,
                deleteHookStatus: action.status,
                hooks: action.status === "success" ? [...state.hooks.filter(hk => hk.id !== action.id)] : [...state.hooks]
            }
        default:
            return state;
    }
}
export default hooksReducer;