import {RequestBuilder} from "./RPAService";
import { BILLING_URL } from './configs/constants'

class PaymentService {

    constructor(companyId, userId){
        this.companyId = companyId;
        this.userId = userId;
        this.secret = null;
    }

    _request() {
        const headers = {
            "x-reecall-source": "B0",
            "x-reecall-sourceId": this.userId,
            "x-reecall-company": this.companyId
        }
        return new RequestBuilder({ headers, url: BILLING_URL });
    }

    setCompanyId = (companyId) => {
        this.companyId = companyId;
    }

    setUserId = (userId) => {
        this.userId = userId;
    }

    setSecret = (secret) => {
        this.secret = secret;
    }

    getHubspotOwners = () => {
        return this._request().route(`/hubspot/owners`).err(`Could not get hubspot owners`);
    }

    getUpcoming = () => {
        return this._request().route(`/upcoming`).err(`Could not get upcoming`);
    }

    getBilling = () => {
        return this._request().route(`/billing`).err(`Could not get upcoming`);
    }

    getInvoices = () => {
        return this._request().route(`/invoices`).err(`Could not get upcoming`);
    }

    getUsageRecord = () => {
        return this._request().route(`/usageRecord`).err(`Could not get upcoming`);
    }

    getStripeSecret = () => {
        return this._request().route(`/stripe/secret`).err(`Could not get secret`);
    }

    getPaymentsMethods = (id) => {
        return this._request().route(`/stripe/paymentsmethods`).err(`Could not get payments methods`);
    }

    setPaymentMethodAsDefault = (id) => {
        return this._request()
        .method('POST')
        .route(`/stripe/setdefaultpayment`)
        .data({"paymentId": id})
        .err(`Could not set payment method as default`);
    }

    updatePaymentMethod = ({id, datas}) => {
        return this._request()
        .method('PUT')
        .route(`/stripe/paymentmethods/${id}`)
        .data({...datas})
        .err(`Could not update payment method`);
    }
}

export default PaymentService;