import {
    FETCH_COMPANIES_COMPANY,
    FETCH_COMPANIES,
    FETCH_COMPANIES_FILTER,
    FLUSH_COMPANIES,
    FETCH_ALL_COMPANIES,
    FLUSH_ALL_COMPANIES_STATUS,
    FLUSH_STATUS
} from '../../actions/companies';

const initialState = {
    status: false,
    companies: [],
    allCompanies: [],
    allCompaniesStatus: null,
    total: 0
}

const companiesReducer = (state = initialState, action) => {
    switch (action.type) {

        case FLUSH_STATUS:
            return {
                ...state,
                status: false,
            };
        case FLUSH_COMPANIES:
            return {
                ...state,
                companies: [],
            };
        case FLUSH_ALL_COMPANIES_STATUS:
            return {
                ...state,
                allCompaniesStatus: null,
            };
        case FETCH_ALL_COMPANIES:
            return {
                ...state,
                allCompanies: action.status === "success" ? action.companies : [...state.allCompanies],
                allCompaniesStatus: action.status
            };
        case FETCH_COMPANIES:
            return {
                ...state,
                companies: action.flush ? [...action.companies] : action.status === "success" ? [...state.companies, ...action.companies] : [...state.companies],
                status: action.status,
                total: action.status === "success" ? action.total : state.total,
            };
        case FETCH_COMPANIES_FILTER:
            return {
                ...state,
                companies: action.flush ? [...action.companies] : action.status === "success" ? [...state.companies, ...action.companies] : [...state.companies],
                status: action.status,
            };
        case FETCH_COMPANIES_COMPANY:
            return {
                ...state,
                companies: action.status === "success" ? [action.company] : [],
                total: action.status === "success" ? action.total : state.total,
                status: action.status,
            };
        default:
            return state;
    }
}

export default companiesReducer;