import { combineReducers } from "redux"
import { rules } from "./rules"
import { quickActions } from "./quickActions"

const automathequeReducers = combineReducers({
    rules,
    quickActions
})

export default automathequeReducers
