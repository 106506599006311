const AUTOMATHEQUE_COMPANY_ID = "61e8175572468d00088e32a2";
const AUTOMATHEQUE_FETCH_QAS = "AUTOMATHEQUE_FETCH_QAS";
export { AUTOMATHEQUE_COMPANY_ID, AUTOMATHEQUE_FETCH_QAS }


export const fetchQuickActionLibrairies = () => {

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({
            type: AUTOMATHEQUE_FETCH_QAS,
            status: "pending"
        });
        return rcsdk
            .getQuickActionLibraries()
            .where({companyId: AUTOMATHEQUE_COMPANY_ID})
            .then(response => {
                dispatch({
                    type: AUTOMATHEQUE_FETCH_QAS,
                    status: "success",
                    quickActionLibrairies: response,
                });
            }).catch(err => {
                dispatch({
                    type: AUTOMATHEQUE_FETCH_QAS,
                    status: "error",
                    error: err
                });
            })
    }
}