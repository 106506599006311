import React, {useState} from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
import {useDispatch, useSelector} from "react-redux";
import Notification from './Notification'
import { markAllNotificationsAsRead } from "../../../redux/actions/notifications"
import { FormattedMessage } from 'react-intl'

const NotificationsDropdown = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const notifications = useSelector(state => state.notifications.notifications);
    const wizzNotification = useSelector(state => state.notifications.wizzNotification);

    const onToggle = () => {
        if(isOpen){
            dispatch(markAllNotificationsAsRead());
        }

        setIsOpen(!isOpen)
    }

    return <Dropdown
      tag="li"
      className="dropdown-notification nav-item"
      toggle={onToggle}
      isOpen={isOpen}
    >
      <DropdownToggle tag="a" className="nav-link nav-link-label">
        <Icon.Bell size={21} className={`${wizzNotification ? "anim-ring" : "" }`}/>
        {
          notifications.filter(notif => notif.status === "new").length > 0 && <Badge pill color="primary" className="badge-up">
            {" "}
            {notifications.filter(notif => notif.status === "new").length}{" "}
          </Badge>
        }
      </DropdownToggle>
      <DropdownMenu tag="ul" right className="dropdown-menu-media">
        <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
                <h3 className="text-white">
                    <FormattedMessage id="navbar.notifications.title" defaultMessage="Notifications" />
                    {
                      notifications.filter(notif => notif.status === "new").length > 0 &&
                      <>
                        {" - "}
                        <FormattedMessage
                          id="navbar.notifications.new"
                          defaultMessage="{newNotifCount, plural,
                            one {# New}
                            other {# News}
                          }"
                          values={{
                            newNotifCount: notifications.filter(notif => notif.status === "new").length
                          }}
                        />
                      </> 
                    }
                </h3>
            </div>
        </li>
        {
          notifications.length > 0 && <PerfectScrollbar
            className="media-list overflow-hidden position-relative"
            options={{
              wheelPropagation: false
            }}
          >
            {notifications.map(notification => {
              return <Notification
                {...notification}
                key={`notification-${notification.id}`}
              />
            })}
          </PerfectScrollbar>
        }

        {
          notifications.length === 0 && <p className="p-1 font-medium-1 mb-0 text-center">
            <FormattedMessage id="navbar.notifications.nonotification" defaultMessage="No notification"/>
          </p>
        }
        
        {
          // <li className="dropdown-menu-footer">
          //   <DropdownItem tag="a" className="p-1 text-center">
          //     <span className="align-middle">
          //       <FormattedMessage id="navbar.notifications.readall" defaultMessage="Read all notifications" />
          //     </span>
          //   </DropdownItem>
          // </li>
        }
      </DropdownMenu>
    </Dropdown>
}

NotificationsDropdown.defaultProps = {
}

export default NotificationsDropdown;