export const STORE_TOKENS = "STORE_TOKENS";
export const SET_TOKENS_REQUEST_STATUS = "SET_TOKENS_REQUEST_STATUS";

export const storeTokens = (tokens) => ({
    type: STORE_TOKENS,
    tokens,
});

export const setTokensRequestStatus = (requestStatus) => ({
    type: SET_TOKENS_REQUEST_STATUS,
    requestStatus,
});

export const fetchTokens = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return (
            rcsdk._request()
            .method('GET')
            .route(`/customTokens`)
            .then(response => {
                dispatch(storeTokens(response));
            })
            .catch(error => 
                console.log(error)
            )
        );
    }
};

export const fetchTokensByCompanyId = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return (
            rcsdk._request()
            .method('GET')
            .route(`/customTokens`)
            .where({"companyId": companyId})
            .then(response => {
                dispatch(storeTokens(response));
            })
            .catch(error => 
                console.log(error)
            )
        );
    }
};

export const createToken = (datas) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return (
            rcsdk._request()
            .method('POST')
            .route(`/customTokens`)
            .data(datas)
            .then(response => {
                dispatch(setTokensRequestStatus("success"));
            })
            .catch(error => 
                dispatch(setTokensRequestStatus("error"))
            )
        );
    }
};