import React from "react"
import {FormattedMessage} from "react-intl";

import _ from "lodash"
export const CREATE_DECK = 'CREATE_DECK';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const SET_ACTIONS_LIST = 'SET_ACTIONS_LIST';
export const ADD_ACTION = 'ADD_ACTION';
export const REMOVE_ACTION = 'REMOVE_ACTION';
export const SET_CURRENT_DECK = 'SET_CURRENT_DECK';
export const SELECT_FOLDER = 'SELECT_FOLDER';
export const SELECT_ACTION = 'SELECT_ACTION';
export const UPDATE_ACTION = 'UPDATE_ACTION';
export const UPDATE_DECK = 'UPDATE_DECK';
export const DELETE_DECK = 'DELETE_DECK';
export const FETCH_DECKS_PENDING = 'FETCH_DECKS_PENDING';
export const FETCH_DECKS_SUCCESS = 'FETCH_DECKS_SUCCESS';
export const FETCH_DECKS_ERROR = 'FETCH_DECKS_ERROR';
export const FLUSH_DECKS = 'FLUSH_DECKS';
export const FETCH_DECKS_BY_COMPANY_ID = 'FETCH_DECKS_BY_COMPANY_ID';
export const FLUSH_FETCH_DECKS_BY_COMPANY_ID = 'FLUSH_FETCH_DECKS_BY_COMPANY_ID';


function fetchDecksPending() {
    return {
        type: FETCH_DECKS_PENDING
    }
}

function fetchDecksSuccess(decks) {
    return {
        type: FETCH_DECKS_SUCCESS,
        decks: decks
    }
}

function fetchDecksError(error) {
    return {
        type: FETCH_DECKS_ERROR,
        error: error
    }
}

export const fetchDecks = () => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch(fetchDecksPending());
        return rcsdk.getDecks().then(decks => {
            dispatch(fetchDecksSuccess(decks));
        }).catch(err => {
            dispatch(fetchDecksError(err.message));
        })
    }
}

export const fetchDecksByCompanyId = (companyId) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch({type: FETCH_DECKS_BY_COMPANY_ID, status: "pending"});
        return rcsdk.getDecks().where({"companyId" : companyId}).then(decks => {
            dispatch({type: FETCH_DECKS_BY_COMPANY_ID, status: "success", decks: decks});
            return decks
        }).catch(err => {
            dispatch({type: FETCH_DECKS_BY_COMPANY_ID, status: "error", error: err.message});
        })
    }
}

export function flushDecks() {
    return {
        type: FLUSH_DECKS
    }
}


export const setCurrentDeck = (deckId) => {
    return dispatch => {
        dispatch({ type: SET_CURRENT_DECK, deckId: deckId })
    }
}

export const selectFolder = (folderId) => {
    return dispatch => {
        dispatch({ type: SELECT_FOLDER, folderId: folderId })
    }
}

export const selectAction = (actionId) => {
    return dispatch => {
        dispatch({ type: SELECT_ACTION, actionId: actionId })
    }
}

export const updateAction = (updatedValues, actionId, deckId) => {
    return dispatch => {
        dispatch({
            type: UPDATE_ACTION,
            updatedValues: updatedValues,
            actionId: actionId,
            deckId: deckId,
            successToast: {
                type: "UPDATE",
                message: <FormattedMessage id="deckActions.toast.update" defaultMessage="Deck Action updated successfully"/>
            }
        })
    }
}

export const createDeck = () => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        let newDeckDatas = {
            companyId: getState().company.company.id,
            name: "New deck",
            contexts: [],
            children: []
        }

        rcsdk.createDeck(newDeckDatas).then(deck => {
            dispatch({ 
                type: CREATE_DECK,
                deck: deck,
                successToast: {
                    type: "ADD",
                    message: <FormattedMessage id="decks.toast.add" defaultMessage="Deck added successfully"/>
                }
            })
        });
    }
}

export const createFolder = (deckId, index) => {
    return dispatch => {
        dispatch({ type: CREATE_FOLDER, deckId: deckId, index: index })
    }
}

export const updateDeck = (deckId, deckDatas) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {

        let formattedDeckDatas = {
            ...deckDatas
        };

        if(formattedDeckDatas.children){
            formattedDeckDatas = {
                ...formattedDeckDatas,
                children: [
                    ...deckDatas.children.map(child => _.omit({
                        ...child,
                        childrens: child.childrens ? [
                            ...child.childrens.map(child2 => _.omit(child2, ["fields", "createdAt", "updatedAt", "isDeleted", "id", "type"]))
                        ] : null,
                        actionId: child.id,
                        action: child.key,
                        integration: getState().integrations.integrations.find(el => el.id === child.integrationId)?.key || null,
                    }, ["fields", "createdAt", "updatedAt", "isDeleted", "id", "type"]))
                ]
            }
        }

        rcsdk.updateDeck(deckId, deckDatas).then(deck => {
            dispatch({ 
                type: UPDATE_DECK,
                deckId: deckId,
                deck: deck,
                successToast: {
                    type: "UPDATE",
                    message: <FormattedMessage id="decks.toast.update" defaultMessage="Deck updated successfully"/>
                }
            })
        })
    }
}

export const setActionsList = (deckId, actionsList) => {
    return dispatch => {
        dispatch({ type: SET_ACTIONS_LIST, deckId: deckId, children: actionsList })
    }
}

export const addActionToActionsList = (deckId, folderId, index, action) => {
    return (dispatch, getState, {rcsdk, rcAlgolia}) => {
        dispatch({ type: ADD_ACTION, deckId: deckId, folderId: folderId, index: index, action: action })
    }
}

export const removeAction = (deckId, actionId) => {
    return dispatch => {
        dispatch({
            type: REMOVE_ACTION,
            deckId: deckId,
            actionId: actionId,
            successToast: {
                type: "DELETE",
                message: <FormattedMessage id="deckActions.toast.delete" defaultMessage="Deck Action deleted successfully"/>
            }
        })
    }
}



function deleteDeckSuccess(deckId, showToast){
    return {
        type: DELETE_DECK,
        deckId: deckId,
        successToast: showToast ? {
            type: "DELETE",
            message: <FormattedMessage id="decks.toast.delete" defaultMessage="Deck deleted successfully"/>
        } : null
    }
}

export const deleteDeck = (deckId, showToast = true) => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch(fetchDecksPending());

        return rcsdk.deleteDeck(deckId).then((response) => {
            dispatch(deleteDeckSuccess(deckId, showToast));
        }).catch(err => {
            if(err.error?.response?.status !== 400){
                dispatch(fetchDecksError(err.message));
            } else {
                dispatch(deleteDeckSuccess(deckId, showToast));
            }
        });
    }
}