import axios from "axios";
import { omit } from "lodash";
import { RPA_URL } from "./configs/constants";

export class RequestBuilder {
    constructor(options = {}) {
        this.options = { method: 'GET', params: { filter: {} }, data: {}, headers: {}, ...options };
        this.BASE = `${options.url}`;
        this.RAW = false;
    }

    url(url) { this.options.url = url; return this; }
    route(route) { return this.url(`${this.BASE}${route}`); }
    slash(route) { return this.url(`${this.options.url}/${route}`); }
    method(method) { this.options.method = method; return this; }
    err(err) { this.err = err; return this; }
    data(data) { this.options.data = data; return this; }

    // Additional options
    raw(r = true) { this.RAW = r; return this; }

    // Build the axios request, after calling any of the methods below, config methods won't have any effect.
    build() {
        const r = axios(this.options).then(res => this.RAW === true ? res : res.data);
        if (this.err) {
            return r.catch(error => {
                const err = new Error(this.err);
                err.error = error;
                throw err;
            });
        }
        return r;
    }

    // Promise-like stuff
    then(a, b) { return this.build().then(a, b); }
    catch(a) { return this.build().catch(a); }

    // Helper stuff
    print() { this.then(console.log, console.error) }
}


class RPAService {

    constructor(companyId, userId) {
        this.companyId = companyId;
        this.userId = userId;
        this.url = RPA_URL
    }

    setUrl(url) {
        this.url = url
        return this
    }

    _request() {
        const headers = {
            "x-reecall-source": "BO",
            "x-reecall-sourceId": this.userId,
            "x-reecall-company": this.companyId
        }
        return new RequestBuilder({ headers, url: this.url });
    }

    setCompanyId = (companyId) => {
        this.companyId = companyId;
        return this;
    }

    setUserId = (userId) => {
        this.userId = userId;
        return this;
    }

    getIntegrations() {
        return this._request()
            .route("/integrations")
            .then((response) => {

                return response.integrations;
            });
    }

    getQuickActionRPA(quickAction, integration) {
        return this._request()
            .route(`/integrations/${integration === "reecall_dev" ? "reecall" : integration}/${quickAction}`)
            .then(response => {
                return response
            }).catch(err => {
                console.log(err.message, "err");
            });
    }

    getIntegration(integrationKey) {
        if (!integrationKey) throw new Error("integrationKey missing");
        return this._request()
            .route(`/integrations/${integrationKey}`)
            .then((response) => {
                let formattedIntegration = {
                    quickActions: Object.entries(response.integration.actions).map(
                        ([key, action]) => {
                            return {
                                ...action,
                                title: action.display?.label,
                                description: action.display?.description,
                                fromRPA: true,
                                fields: [
                                    ...action.fields
                                        .filter((el) => el.key !== "companyId")
                                        .map((el) => {
                                            return {
                                                ...el,
                                                fromRPA: true,
                                                RPARequired: el?.isRequired,
                                            };
                                        }),
                                ],
                            };
                        }
                    ),
                    ...response.integration,
                };

                return omit(formattedIntegration, ["noun", "actions", "version"]);
            });
    }


    executeQuickAction = ({id, data}) => {
        return this._request()
        .method('POST')
        .route(`/quickaction/${id}`)
        .data(data)
        .err(`Could not execute Quick action`);
    }

    executeAction = ({config, data}) => {
        return this._request()
        .method('POST')
        .route(`/action`)
        .data({
            config: config,
            datas: data
        })
        .err(`Could not execute action`);
    }

    executeRule = ({id, data}) => {
        return this._request()
        .method('POST')
        .route(`/rules/${id}`)
        .data(data)
        .err(`Could not execute Quick action`);
    }

    // action(config) {
    //     return this.axios.post(`action`, {
    //         config: {...config}
    //     })
    //     .then(response => {
    //         return response;
    //     })
    //     .catch(err => {
    // console.log(err.message, "Err action RPA");
    //     });
    // }

    getCollectedDatas = (companyId) => {
        return this._request().route(`/collected/${companyId}`).err(`Could not get collected datas`);
    }
}

export default RPAService;