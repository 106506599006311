import {
    SET_LIVES_TYPE,
    SET_INPUT_VALUE,
    SET_CURRENT_LIVE_ID,
    NEW_MESSAGE,
    USER_TYPING,
    RESET_TYPING,
    RESET_UNREAD
} from '../../actions/lives';
import { subscribe } from 'pusher-redux';

import {NEW_CONNECTION, NEW_INTERCONNECTION} from "../../actions/pusher"

const initialState = {
    pending: false,
    lives: [],
    error: null,
    type: null,
    currentLiveId: null
}

const livesReducer = (state = initialState, action) => {
    let privateChannelName;

    switch(action.type) {

        case NEW_CONNECTION:
            privateChannelName = "private-" + action.data.conversationId;
            subscribe(privateChannelName, 'new-message', NEW_MESSAGE, {});
            
            return {
                ...state,
                lives: state.lives.find(el => el.id === action.data.conversationId) ?
                [...state.lives]
                : 
                [
                    ...state.lives,
                    {
                        id: action.data.conversationId,
                        name: "",
                        contact: action.data?.user ? { ...action.data.user } : {},
                        inbounds: [],
                        typing: false,
                        typingDate: null,
                        unread: 0,
                        status: "online",
                        internal: false,
                        from: action.data.from
                    }
                ]
            }


        case NEW_INTERCONNECTION:
            privateChannelName = "private-chat-" + action.data.conversationId;
            subscribe(privateChannelName, 'new-message', NEW_MESSAGE, {});
            subscribe(privateChannelName, 'client-typing', USER_TYPING, {});
            
            return {
                ...state,
                lives: state.lives.find(el => el.id === action.data.conversationId) ?
                [...state.lives]
                : 
                [
                    ...state.lives,
                    {
                        id: action.data.conversationId,
                        name: "",
                        contact: action.data?.user ? { ...action.data.user } : {},
                        inbounds: [],
                        typing: false,
                        typingDate: null,
                        unread: 0,
                        status: "online",
                        internal: true,
                        from: action.data.from
                    }
                ]
            }

        case USER_TYPING:
            return {
                ...state,
                lives: [...state.lives.map(live => {
                    return action.data.conversationId === live.id ? {...live, typing: true, typingDate: new Date()} : {...live}
                })]
            }

        
        case RESET_TYPING:
            return {
                ...state,
                lives: [...state.lives.map(live => {
                    return action.liveId === live.id ? {...live, typing: false, typingDate: null} : {...live}
                })]
            }

        case RESET_UNREAD:
            return {
                ...state,
                lives: [...state.lives.map(live => {
                    return action.liveId === live.id ? {...live, unread: 0} : {...live}
                })]
            }

        // case FETCH_LIVES_PENDING: 
        //     return {
        //         ...state,
        //         pending: true
        //     }
        // case FETCH_LIVES_SUCCESS:
        //     return {
        //         ...state,
        //         pending: false,
        //         lives: [
        //             ...action.lives.filter(live => (live.channelId === state.type) || !state.type).map(live => {
        //                 return {...live, inputValue: ""}
        //             })
        //         ]
        //     }
        // case FETCH_LIVES_ERROR:
        //     return {
        //         ...state,
        //         pending: false,
        //         error: action.error
        //     }

        case SET_LIVES_TYPE:
        	return {
                ...state,
                type: action.payload
            }
            
        case SET_INPUT_VALUE:
            return {
                ...state,
                lives: [...state.lives.map(live => {
                    return {
                        ...live,
                        inputValue: action.liveId === live.id ? action.value : live?.inputValue
                    }
                })]
            }


        case NEW_MESSAGE:
            return {
                ...state,
                lives: [...state.lives.map(live => {
                    return {
                        ...live,
                        inbounds: action.channel === `private-${live.id}` || action.channel === `private-chat-${live.id}` ? [
                            ...live.inbounds,
                            {
                                id: live.inbounds.length,
                                time: new Date(),
                                type: "message",
                                ...action.data
                            }
                        ] : live?.inbounds,
                        unread: action.channel === `private-${live.id}` || action.channel === `private-chat-${live.id}` ? live.unread + 1 : live.unread
                    }
                })]
            }

        // case PUSH_MESSAGE:
        //     console.log(action, "actioon push message");
        //     return {
        //         ...state,
        //         lives: [...state.lives.map(live => {
        //             return {
        //                 ...live,
        //                 inbounds: action.liveId === live.id ? [
        //                     ...live.inbounds,
        //                     {
        //                         from: "agent",
        //                         id: live.inbounds.length,
        //                         time: new Date(),
        //                         type: "message",
        //                         message: action.message
        //                     }
        //                 ] : live?.inbounds
        //             }
        //         })]
        //     }

        case SET_CURRENT_LIVE_ID:
            return {
                ...state,
                currentLiveId: action.liveId
            }
        default: 
            return state;
    }
}
export default livesReducer;