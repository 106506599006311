import React, {useState, useEffect} from "react";
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    TabContent,
    TabPane,
    NavItem,
    Button
} from "reactstrap"
import {useSelector, useDispatch} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {TOGGLE_LIST_DRAWER, isDrawerListOpen, openInternalChat} from "../../../redux/actions/members";
import {selectCurrentInternalLive, selectTotalUnreadInbounds, selectInternalLives} from "../../../redux/actions/lives";
import {getDefaultNames, getDefaultAvatar} from '../../../utility/utils';
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent"

import MembersList from "./MembersList";
import InternalLive from "../lives/InternalLive";
import { FormattedMessage } from "react-intl";

const MembersDrawerToggle = () => {
    const dispatch = useDispatch();
    const isEnabled = useSelector(state => state.members.drawer.enabled);
    const totalUnread = useSelector(state => selectTotalUnreadInbounds(selectInternalLives(state)));
    const toggleDrawer = () => dispatch({type: TOGGLE_LIST_DRAWER});

    if(!isEnabled) return null;

    return <NavItem onClick={toggleDrawer}>
        <a className="nav-link nav-link-label" href="/">
            <FontAwesomeIcon icon={["fal", "people-arrows"]} style={{fontSize: "21px"}}/>
            {
                totalUnread !== 0 && <Badge
                    color="primary"
                    className="badge-sm badge-up"
                    pill
                >
                    {totalUnread}
                </Badge>
            }
        </a>
    </NavItem>
}

export { MembersDrawerToggle }

const MembersListDrawerHeader = ({showGoBack, onGoBack, onClose, children}) => {
    return <CardHeader className="text-uppercase">
        {
            showGoBack && <Button
                color="flat-primary"
                className="btn-icon btn-round mr-1"
                size="md"
                onClick={() => onGoBack(null)}
            >
                <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            </Button>
        }

        <CardTitle>
            {children}
        </CardTitle>

        <Button
            color="flat-primary"
            className="btn-icon btn-round ml-auto d-block d-lg-none"
            size="md"
            onClick={() => onClose()}
        >
            <FontAwesomeIcon icon={["fas", "times"]} />
        </Button>
    </CardHeader>
}

const MembersListDrawer = () => {
    const dispatch = useDispatch();
    const members = useSelector(state => state.members.members);
    const isEnabled = useSelector(state => state.members.drawer.enabled);
    const [currentInternalChatMember, setCurrentInternalChatMember] = useState(null);
    const toggleDrawer = () => dispatch({type: TOGGLE_LIST_DRAWER})
    const isOpen = useSelector(isDrawerListOpen);
    const currentLive = useSelector(selectCurrentInternalLive);
    
    const [activeTab, setActiveTab] = useState("members-list");

    useEffect(() => {
        if(currentLive){
            setCurrentInternalChatMember(members.find(el => el.id === currentLive.from));
            setActiveTab("member-chat")
        } else {
            setActiveTab("members-list")
        }
    }, [currentLive, members]);

    if(!isEnabled) return null;

    return <>
        <div className={`drawer drawer-members ${isOpen ? "show" : ""}`}>
            <Card className="h-100 mb-0">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={"members-list"}>
                        {
                            isOpen && <>
                                <MembersListDrawerHeader onClose={toggleDrawer}>
                                    <FormattedMessage id="members.drawer.membersList.title" defaultMessage="Members List" />
                                </MembersListDrawerHeader>
                                <CardBody className="d-flex flex-column p-0">
                                    <MembersList
                                        onChat={(liveId) => {
                                            dispatch(openInternalChat(liveId));
                                        }}
                                        onClose={toggleDrawer}
                                    />
                                </CardBody>
                            </>
                        }
                    </TabPane>
                    <TabPane tabId={"member-chat"}>
                        {
                            isOpen && currentLive && <div className="internalChat__wrapper">
                                <MembersListDrawerHeader
                                    showGoBack
                                    onGoBack={() => {
                                        dispatch(openInternalChat(null));
                                    }}
                                    onClose={toggleDrawer}
                                >
                                    {
                                        currentInternalChatMember && <>
                                            <Avatar 
                                                img={getDefaultAvatar(currentInternalChatMember)}
                                                size="md"
                                                className="mr-1"
                                            />
                                            {getDefaultNames(currentInternalChatMember).join(' ')}
                                        </>
                                    }
                                </MembersListDrawerHeader>
                                <CardBody className="d-flex flex-column p-0 mb-0">
                                    {currentLive && <InternalLive live={currentLive} />}
                                </CardBody>
                            </div>
                        }
                    </TabPane>
                </TabContent>
            </Card>
        </div>
        <div 
            className={`overlay ${isOpen ? "show" : ""}`} 
            onClick={toggleDrawer} 
        />
    </>
}

export default MembersListDrawer;