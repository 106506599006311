import React from "react"
import { Navbar, NavItem, NavLink, Alert } from "reactstrap"
import { NavLink as NavLinkRouter } from "react-router-dom"
import classnames from "classnames"
import * as Icon from "react-feather"
import NavbarUser from "./NavbarUser"
import { useSelector, useDispatch } from 'react-redux';
import { toggleQuotaMessage } from "../../../redux/actions/app"

const ThemeNavbar = props => {
  const dispatch = useDispatch();
  const quota = useSelector(state => state.app.quota);

  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-start align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">

                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className="navbar-nav d-xl-none">
                    <NavItem className="mobile-menu mr-auto">
                      <NavLink
                        className="nav-menu-main menu-toggle hidden-xs is-active"
                        onClick={props.sidebarVisibility}
                      >
                        <Icon.Menu className="ficon" />
                      </NavLink>
                    </NavItem>
                  </ul>
                </div> 
    
                {
                  quota.show && <NavLinkRouter
                    to={'/settings/plans'}
                    onClick={() => {
                      dispatch(toggleQuotaMessage(false));
                    }}
                  >
                    <Alert color="danger" className="mb-0 ml-50 d-flex align-items-center mr-1">
                      <Icon.AlertOctagon size={16} className="mr-0"/>
                        <p className="d-none d-lg-inline-block ml-1 mr-1 truncate">{quota.message}</p>
                    </Alert>
                  </NavLinkRouter>
                }

              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                </div>
              ) : null}
              <NavbarUser />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

export default ThemeNavbar
