import axios from "axios";
import { toast } from "react-toastify";
import { INGESTION_URL } from "../../../configs/constants";

export const FETCH_LIBRARY_PAGE = 'FETCH_LIBRARY_PAGE';
export const ADD_LIBRARY_PAGE = 'ADD_LIBRARY_PAGE';
export const UPDATE_LIBRARY_PAGE = 'UPDATE_LIBRARY_PAGE';
export const DELETE_LIBRARY_PAGE = 'DELETE_LIBRARY_PAGE';
export const SET_JSON_ERRORS = 'SET_JSON_ERRORS';
export const FETCH_LIBRARY = "FETCH_LIBRARY";
export const IS_LIBRARIES_ACTIVE = false;

export const fetchLibraryPages = () => {
    return (dispatch, getState, {rcsdk}) => {
        dispatch({
            type: FETCH_LIBRARY_PAGE,
            status: "pending"
        });

        return rcsdk.getLibraryPages()
            .then(libraryPages => {
                dispatch({
                    type: FETCH_LIBRARY_PAGE,
                    status: "success",
                    pages: libraryPages
                });
            }).catch(err => {
                dispatch({
                    type: FETCH_LIBRARY_PAGE,
                    status: "error",
                    error: err
                });
            })
    }
}

export const saveLibraryPage = (page, pageToMakeInactiveId) => {
    return (dispatch, getState, {rcsdk})  => {

        if(pageToMakeInactiveId){
            dispatch(saveLibraryPage({
                ...getState().libraries.pages.find(el => el.id === pageToMakeInactiveId),
                active: false
            }))
        }

        if(page?.id){
            return rcsdk.updateLibraryPage(page.id, page).then(resp => {
                dispatch({type: UPDATE_LIBRARY_PAGE, payload: resp})
            })
        } else {
            return rcsdk.createLibraryPage(page).then(resp => {
                dispatch({type: ADD_LIBRARY_PAGE, payload: resp})
            })
        }
    }
}

export const deleteLibraryPage = (id) => {
    return (dispatch, getState, {rcsdk})  => {
        return rcsdk.deleteLibraryPage(id).then(resp => {
            dispatch({type: DELETE_LIBRARY_PAGE, id: id})
        }).catch(err => {
            if(err.error?.response?.status === 400){
                dispatch({type: DELETE_LIBRARY_PAGE, id: id})
            }
        })
    }
}

export const duplicateLibraryPage = (id) => {
    return (dispatch, getState, {rcsdk})  => {
        dispatch(saveLibraryPage({
            ...getState().libraries.pages.find(el => el.id === id),
            title: `${getState().libraries.pages.find(el => el.id === id).title} (duplicated)`,
            id: null,
            active: false
        }));
    }
}



export const validateLibraryJSON = ({json, type , showToast = false}) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return axios({
            method: 'post',
            url: `${INGESTION_URL}/${type}/validate`,
            data: json
        }).then(response => {
            return response.data;
        }).catch(err => {
            if(showToast){
                if(err?.response?.data?.message){
                    toast.error(err.response?.data?.message);
                } else {
                    toast.error(err.message)
                }
            }

            if(err?.response?.data){
                return err?.response?.data
            } else {
                return err.message;
            }
        })
    }
}

export function selectJsonErrors(state, sectionIndex, itemIndex) {
    return state.libraries.jsonErrors.find(el => el.sectionIndex === sectionIndex && el.itemIndex === itemIndex)
}

export const fetchLibrary = ({context = "tag", lang = "fr"}) => {
    return (dispatch, getState, {rcsdk, rcRPA}) => {
        dispatch({type: FETCH_LIBRARY, status: "pending"})

        return rcsdk.getLibraryPages()
        .then(resp => {
            dispatch({
                type: FETCH_LIBRARY,
                status: "success",
                library: resp.find(el => el?.context === context && el?.lang === lang && el?.active)
            })
        }).catch(err => {
            dispatch({type: FETCH_LIBRARY, status: "error", err: err})
        });
    }
}