
import moment from "moment";
import { getVariationDefaultColorsByKey } from "../../../configs/dashboardsConfig"
import { add, sub, intervalToDuration } from 'date-fns';
import { toast } from "react-toastify";


export const GET_OLDEST_3_TICKETS_OPEN = 'GET_OLDEST_3_TICKETS_OPEN';
export const FETCH_GLOBAL_STATUS = "FETCH_GLOBAL_STATUS";
export const FETCH_CARD_DATAS = "FETCH_CARD_DATAS";
export const SET_PERIOD_UNIT = "SET_PERIOD_UNIT";


export const getOldest3TicketsOpen = (companyId) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: GET_OLDEST_3_TICKETS_OPEN, status: "pending" });
        rcsdk.getTickets()
            .include(["assignee", { "relation": "conversations", "fields": ["id"] }])
            .where({ "companyId": companyId, "status": { "in": ["todo", "open"] } })
            .limit(3)
            .order("createdAt ASC")
            .then(tickets => {
                dispatch({
                    type: GET_OLDEST_3_TICKETS_OPEN,
                    status: "success",
                    tickets: [...tickets.map(ticket => {
                        const ticketTags = ticket?.tags ? ticket?.tags : [];
                        // if (ticket?.tagIds) {
                        //     ticket.tagIds.map(tagId => {
                        //         ticketTags.push({
                        //             id: tagId,
                        //             confirmed: true,
                        //             confirmedType: "agent"
                        //         })
                        //         return null;
                        //     })
                        // }
                        return {
                            ...ticket,
                            tags: ticketTags
                        }
                    })]
                });
            }).catch(err => {
                dispatch({ type: GET_OLDEST_3_TICKETS_OPEN, status: "error", error: err });
            })
    }
}


const getFakeDatas = ({ cardType }) => {
    switch (cardType) {
        case "escalation":
            return {
                escalationsTotal: 0,
                escalationsTransfered: 0,
                escalationsTime: "0:37s",
                doItForMeTotal: 0
            }
        case "TagsLifeCycle":
            return [
                { "tagged_tickets": 80, "tags_autonomous": 70, "tags_collaborators": 20, "tags_confirmed": 30, "tags_removed": 40, "tags_total": 90, "total_tickets": 200 },
                { "tagged_tickets": 50, "tags_autonomous": 35, "tags_collaborators": 40, "tags_confirmed": 30, "tags_removed": 30, "tags_total": 75, "total_tickets": 563 },
            ]
        default:
            return {};
    }
}

// const getCardDatas = ({cardType, statSDK, companyId}) => {
//         // console.log(cardType, statSDK, companyId)
//         switch(cardType){
//             case "incomingRequests":
//                 return statSDK.getActivityCard().header('x-rcll-company', companyId)
//             case "createdTickets":
//                 return statSDK.getPerformanceCard().header('x-rcll-company', companyId)
//             case "closedTicketsByAgents":
//                 return statSDK.getTicketsClosedByMember().header('x-rcll-company', companyId)
//             case "TagsCategory":
//                 return statSDK.getTicketTagCategories().header('x-rcll-company', companyId)
//             case "Tags":
//                 let promiseEvolution = statSDK.getTicketTagCountByDay().header('x-rcll-company', companyId);
//                 let promiseFrequences = statSDK.getTicketTags().header('x-rcll-company', companyId);
//                 return Promise.all([promiseEvolution, promiseFrequences]);
//             default:
//                 return Promise.resolve(getFakeDatas({cardType}));
//         }
// }

const getPeriodDatas = (dateRange) => {
    const from = dateRange?.startDate === null ? moment().subtract(1, "years").valueOf() : dateRange.startDate
    const to = dateRange?.endDate
    const diffInDays = moment(to).diff(moment(from), "days") + 1;
    // const diffInWeeks = moment(to).diff(moment(from), "weeks") + 1;
    const diffInMonths = moment(to).diff(moment(from), "months") + 1;

    let unit = "hour"
    if (diffInDays > 1) { unit = "day"; }
    // if (diffInWeeks > 5) { unit = "week"; }
    if (diffInMonths > 3) { unit = "month"; }


    let intervalObj = intervalToDuration({
        start: from,
        end: add(to, { seconds: 1 })
    });

    let previousDateRange = { from: sub(from, intervalObj), to: sub(to, intervalObj) };

    return { to, from, unit, previous: previousDateRange }
}

const getCardDatas = ({ cardType, statSDK, dateRange, dispatch, companyId }) => {
    const { to, from, unit, previous } = getPeriodDatas(dateRange);

    dispatch({ type: SET_PERIOD_UNIT, unit: unit });
    const isToday = moment(to).isSame(new Date(), "day") && moment(to).isSame(new Date(), "month") && moment(to).isSame(new Date(), "year");
    const isFuture = moment(to).isAfter(new Date());

    let formattedFrom = moment(from).startOf(unit);
    let formattedTo = isToday || isFuture ? new Date() : moment(to);

    let promiseSelection, promiseCompare;
    
    switch (cardType) {
        case "incomingRequests":
            promiseSelection = statSDK.getActivityCard().period(moment(from).startOf(unit), isToday ? new Date() : moment(to), unit).header('x-rcll-company', companyId);
            promiseCompare = statSDK.getActivityCard().period(moment(previous.from).startOf(unit), moment(previous.to), unit).header('x-rcll-company', companyId);
            return Promise.all([promiseSelection, promiseCompare])
        case "createdTickets":
            promiseSelection = statSDK.getPerformanceCard().period(moment(from).startOf(unit), isToday ? new Date() : moment(to), unit).header('x-rcll-company', companyId)
            promiseCompare = statSDK.getPerformanceCard().period(moment(previous.from).startOf(unit), moment(previous.to), unit).header('x-rcll-company', companyId)
            return Promise.all([promiseSelection, promiseCompare])
        case "closedTicketsByAgents":
            promiseSelection = statSDK.getTicketsClosedByMember().period(moment(from).startOf(unit), isToday ? new Date() : moment(to), unit).header('x-rcll-company', companyId)
            promiseCompare = statSDK.getTicketsClosedByMember().period(moment(previous.from).startOf(unit), moment(previous.to), unit).header('x-rcll-company', companyId)
            return Promise.all([promiseSelection, promiseCompare])
        case "TagsCategory":
            promiseSelection = statSDK.getTicketTagCategories().period(moment(from).startOf(unit), isToday ? new Date() : moment(to), unit).header('x-rcll-company', companyId)
            promiseCompare = statSDK.getTicketTagCategories().period(moment(previous.from).startOf(unit), moment(previous.to), unit).header('x-rcll-company', companyId)
            return Promise.all([promiseSelection, promiseCompare])
        case "Tags":
            let promiseEvolutionSelection = statSDK.getTicketTagCountByDay().period(moment(from).startOf(unit), isToday ? new Date() : moment(to), unit).header('x-rcll-company', companyId);
            let promiseFrequencesSelection = statSDK.getTicketTags().period(moment(from).startOf(unit), isToday ? new Date() : moment(to), unit).header('x-rcll-company', companyId);
            return Promise.all([promiseEvolutionSelection, promiseFrequencesSelection]);
        case "TagsLifeCycle":
            promiseSelection = statSDK.getTagsLifecycleData().period(moment(from).startOf(unit), isToday ? new Date() : moment(to), unit).header('x-rcll-company', companyId)
            promiseCompare = statSDK.getTagsLifecycleData().period(moment(previous.from).startOf(unit), moment(previous.to), unit).header('x-rcll-company', companyId)
            return Promise.all([promiseSelection, promiseCompare]);
        case "escalation":
            return statSDK.getMiscStats().period(formattedFrom, formattedTo, unit).header('x-rcll-company', companyId).then(resp => {
                return {
                    di4m_triggered: resp?.di4m_triggered
                }
            })

        default:
            return Promise.resolve(getFakeDatas({ cardType }));
    }
}

export const fetchCardDatas = ({ cardType = null, dateRange, companyId }) => {
    return (dispatch, getState, { rcsdk }) => {

        if (!cardType) return null;
        dispatch({ type: FETCH_CARD_DATAS, status: "pending", cardType: cardType });

        getCardDatas({ statSDK: rcsdk.Stats, cardType: cardType, companyId: companyId, dateRange: dateRange, dispatch: dispatch }).then((resp) => {
            dispatch({
                type: FETCH_CARD_DATAS,
                status: "success",
                cardType: cardType,
                datas: resp
            });
        })
    }
}


export const fetchGlobalStatus = (companyId) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: FETCH_GLOBAL_STATUS, status: "pending" });
        let KPI = 0;
        rcsdk.Stats.getKPI().header('x-rcll-company', companyId).then(resp => {
            KPI = resp.kpi * 100;
            dispatch({ type: FETCH_GLOBAL_STATUS, status: KPI >= 150 ? "danger" : KPI >= 100 ? "warning" : "success" });
        }).catch(err => {
            toast.error(`Unable to fetch current company global status: ${err}`)
            dispatch({ type: FETCH_GLOBAL_STATUS, status: "error" });
        })

        // let KPI = 0;

        // let totalTicketsOpenPromise = rcsdk.getTickets().raw().where({
        //     "companyId": { "eq" : getState().company.company.id },
        //     "status": {"inq" : ["todo", "open"]}
        // });

        // let totalTicketsClosedPromise = rcsdk.getTickets().raw().where({
        //     "companyId": { "eq" : getState().company.company.id },
        //     "status": "closed"
        // })

        // Promise.all([
        //     totalTicketsOpenPromise,
        //     totalTicketsClosedPromise
        // ]).then(resp => {
        //     const totalOpen = parseInt(resp[0].headers["content-range"].substr(resp[0].headers["content-range"].indexOf("/") + 1));
        //     const totalClosed = parseInt(resp[1].headers["content-range"].substr(resp[1].headers["content-range"].indexOf("/") + 1));
        //     KPI = (totalOpen / totalClosed) * 100;
        //     dispatch({ type: FETCH_GLOBAL_STATUS, status: KPI >= 150 ? "danger" : KPI >= 100 ? "warning" : "success"});
        // })
    }
}


export const getVariationColors = (key, value) => {
    return (dispatch, getState, { rcsdk }) => {
        if (value === null || value === undefined) return null;

        let variationsColors = getState().company.companySettings?.value?.statsSettings?.variationsColors;
        let currentData = variationsColors?.find(el => el.key === key)
        if (!currentData) currentData = getVariationDefaultColorsByKey(key);
        if (!currentData) return "muted";

        let colors = currentData.colors.map(el => {
            return {
                ...el,
                from: el.from === "Infinity" ? Infinity : el.from === "-Infinity" ? -Infinity : el.from,
                to: el.to === "Infinity" ? Infinity : el.to === "-Infinity" ? -Infinity : el.to
            }
        });

        let valueColor = colors.find(el => value >= el.from && value <= el.to);
        return valueColor?.color || "muted";
    }
}