import {
	TOGGLE_QUOTA_MESSAGE,
	TOGGLE_JSON_BEAUTIFIER_MODAL,
	SET_JSON_BEAUTIFIER_DATA,
	SET_JSON_BEAUTIFIER_PENDING_UPDATE,
	TOGGLE_MARKDOWN_BEAUTIFIER_MODAL,
	SET_APP_TITLE,
	SET_APP_LOADED,
} from '../../actions/app';
import { NEW_QUOTA_MESSAGE } from '../../actions/pusher';

const initState = {
	loaded: false,
	quota:{
		show: false,
		message: "",
		maxMembers: 12
	},
	headers: {
		title: "BO Reecall"
	},
	jsonBeautifierIsShow : false,
	jsonBeautifierData: null,
	jsonBeautifierTitle: "JSON Beautifier",
	jsonBeautifierOnUpdate: null,
	jsonBeautifierUpdatableKeys: [],
	jsonBeautifierPendingUpdate: false,
	markdownBeautifierIsShow : false,
};

const appReducer = (state = initState, action) => {
  switch (action.type) {
	
	case SET_APP_LOADED:
		return {
			...state,
			loaded: true
		}

	case TOGGLE_QUOTA_MESSAGE:
		return {
			...state,
			quota: {
				...state.quota,
				show: action?.show || !state.quota.show,
			}
		}
	
	case TOGGLE_JSON_BEAUTIFIER_MODAL:
		return {
			...state,
			jsonBeautifierIsShow: action?.show || !state.jsonBeautifierIsShow,
			jsonBeautifierData: action?.json || null,
			jsonBeautifierTitle: action?.title || "JSON Beautifier",
			jsonBeautifierOnUpdate: action?.onUpdate || null,
			jsonBeautifierUpdatableKeys: action?.updatableKeys || []
		}

	case SET_JSON_BEAUTIFIER_DATA:
		return {
			...state,
			jsonBeautifierData: action?.json || null
		}

	case SET_JSON_BEAUTIFIER_PENDING_UPDATE:
		return {
			...state,
			jsonBeautifierPendingUpdate: action?.status
		}

	case TOGGLE_MARKDOWN_BEAUTIFIER_MODAL:
		return {
			...state,
			markdownBeautifierIsShow: action?.show || !state.markdownBeautifierIsShow
		}

	case NEW_QUOTA_MESSAGE:
		return {
			...state,
			quota: {
				...state.quota,
				message: action.data.message,
				show: true
			}
		}

	case SET_APP_TITLE:
		return {
			...state,
			headers: {
				...state.headers,
				title: action.title
			}
		}

    default: {
    	return state
    }
  }
}

export default appReducer;