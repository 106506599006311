import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";


export const DELETE_TAG_IN_TAGS_AND_TICKETS = 'DELETE_TAG_IN_TAGS_AND_TICKETS';

export const FETCH_TICKET_TAGS_PENDING = 'FETCH_TICKET_TAGS_PENDING';
export const FETCH_TICKET_TAGS_SUCCESS = 'FETCH_TICKET_TAGS_SUCCESS';
export const FETCH_TICKET_TAGS_ERROR = 'FETCH_TICKET_TAGS_ERROR';
export const UPDATE_TICKET_TAG = 'UPDATE_TICKET_TAG';
export const UPDATE_TICKET_TAG_PENDING = 'UPDATE_TICKET_TAG_PENDING';
export const ADD_TICKET_TAG = 'ADD_TICKET_TAG';
export const DELETE_TICKET_TAG = 'DELETE_TICKET_TAG';
export const FETCH_MODEL_TAGS_PENDING = 'FETCH_MODEL_TAGS_PENDING';
export const FETCH_MODEL_TAGS_SUCCESS = 'FETCH_MODEL_TAGS_SUCCESS';
export const FETCH_MODEL_TAGS_ERROR = 'FETCH_MODEL_TAGS_ERROR';
export const FLUSH_ADD = 'FLUSH_ADD';
export const FLUSH_UPDATE = 'FLUSH_UPDATE';
export const FLUSH_FETCH_DATA = 'FLUSH_FETCH_DATA';
export const FETCH_TICKET_DATA_PENDING = 'FETCH_TICKET_DATA_PENDING';
export const FETCH_TICKET_DATA_SUCCESS = 'FETCH_TICKET_DATA_SUCCESS';
export const FETCH_TICKET_DATA_ERROR = 'FETCH_TICKET_DATA_ERROR';
export const UPDATE_TICKET_DATA_PENDING = 'UPDATE_TICKET_DATA_PENDING';
export const UPDATE_TICKET_DATA_SUCCESS = 'UPDATE_TICKET_DATA_SUCCESS';
export const UPDATE_TICKET_DATA_ERROR = 'UPDATE_TICKET_DATA_ERROR';
export const FLUSH_TAGS = 'FLUSH_TAGS';
export const GET_DIRTY = "GET_DIRTY";
export const TRAIN_NOTIF = "TRAIN_NOTIF";
export const FLUSH_FETCH_TAGS = "FLUSH_FETCH_TAGS";


export const setTrainNotifStatus = (value) => {
    return {
        type: TRAIN_NOTIF,
        status: value
    }
}

function fetchTicketTagsPending() {
    return {
        type: FETCH_TICKET_TAGS_PENDING
    }
}

function fetchTicketTagsSuccess(ticketTags) {
    return {
        type: FETCH_TICKET_TAGS_SUCCESS,
        ticketTags: ticketTags
    }
}

function fetchTicketTagsError(error) {
    return {
        type: FETCH_TICKET_TAGS_ERROR,
        error: error
    }
}

export const fetchModelTags = (companyId, star) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_MODEL_TAGS_PENDING });

        return rcsdk.getTicketTagDatas()
            .include(["ticketTag"])
            .where({ "companyId": companyId })
            // .where({ "and": [{ "companyId": companyId }, { "companyId": star }] })
            .then(modelTags => {
                dispatch({
                    type: FETCH_MODEL_TAGS_SUCCESS,
                    modelTags: modelTags
                });
            }).catch(err => {
                dispatch({ type: FETCH_MODEL_TAGS_ERROR, error: err });
            })
    }
}

export const fetchTicketTags = (searchTerm, companyId) => {

    const search = () => {
        if (searchTerm.search.length === 0) return;
        switch (searchTerm.field) {
            case "":
                return
            case "keyword":
                if (companyId) return { "and": [{ "name": { "like": `.*${searchTerm.search}.*`, "options": "i" } }, { "companyId": { "eq": companyId } }] };
                else return { "name": { "like": `.*${searchTerm.search}.*`, "options": "i" } };
            case "companyId":
                return { "companyId": searchTerm.search };
            case "id":
                return { "id": searchTerm.search };
            default:
                break;
        }
    }

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchTicketTagsPending());

        return rcsdk.getTicketTags()
            .where(searchTerm && search())
            .then(ticketTags => {
                dispatch(fetchTicketTagsSuccess(ticketTags));
            }).catch(err => {
                dispatch(fetchTicketTagsError(err.message));
            })
    }
}

export const fetchTicketTagData = (ticketsIdsArray) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_TICKET_DATA_PENDING });

        return rcsdk.getTicketTagDatas()
            .where({ ticketTagId: { inq: ticketsIdsArray } })
            .then(data => {
                dispatch({ type: FETCH_TICKET_DATA_SUCCESS, data: data });
            }).catch(err => {
                dispatch({
                    type: FETCH_TICKET_DATA_ERROR,
                    error: err
                });
            })
    }
}

export const updateTicketTagData = (ticketTagDataId, data, name) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: UPDATE_TICKET_DATA_PENDING });

        return rcsdk.updateTicketTagData(ticketTagDataId, data)
            .then(data => {
                dispatch({ type: UPDATE_TICKET_DATA_SUCCESS, data: data, name: name });
            }).catch(err => {
                dispatch({
                    type: UPDATE_TICKET_DATA_ERROR,
                    error: err
                });
            })
    }
}

function addTicketTagSuccess(ticketTag) {
    return {
        type: ADD_TICKET_TAG,
        ticketTag: ticketTag,
        successToast: {
            type: "ADD",
            message: <FormattedMessage id="ticketTags.toast.add" defaultMessage="Ticket Tag added successfully" />
        }
    }
}

export const addTicketTag = (datas) => {

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        // dispatch(fetchTicketTagsPending());

        return rcsdk.createTicketTag(datas)
            .then((ticketTag) => {
                rcsdk.createTicketTagData({
                    companyId: ticketTag.companyId,
                    ticketTagId: ticketTag.id,
                    sentences: [],
                }).then((ticketTagData => dispatch(addTicketTagSuccess(ticketTag))))
                return ticketTag;
            }).catch(err => {
                // dispatch(fetchTicketTagsError(err.message));
            });
    }
}

function updateTicketTagSuccess(ticketTag) {
    return {
        type: UPDATE_TICKET_TAG,
        ticketTag: ticketTag,
        successToast: {
            type: "UPDATE",
            message: <FormattedMessage id="ticketTags.toast.update" defaultMessage="Ticket Tag updated successfully" />
        }
    }
}

function updateTicketTagError() {
    return {
        type: UPDATE_TICKET_TAG,
        errorToast: {
            type: "ERROR",
            message: <FormattedMessage id="ticketTags.toast.update.error" defaultMessage="error updating Ticket Tag" />
        }
    }
}

export const updateTicketTag = (id, datas) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: UPDATE_TICKET_TAG_PENDING });

        return rcsdk.updateTicketTag(id, datas)
            .then((response) => {
                dispatch(updateTicketTagSuccess(response));
            }).catch(err => {
                dispatch(updateTicketTagError(err.message));
            });
    }
}

function deleteTicketTagSuccess(ticketTagId) {
    return {
        type: DELETE_TICKET_TAG,
        id: ticketTagId,
        successToast: {
            type: "DELETE",
            message: <FormattedMessage id="ticketTags.toast.delete" defaultMessage="Ticket Tag deleted successfully" />
        }
    }
}

export const deleteTicketTag = (id) => {

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        // dispatch(deleteTicketTagPending());
        return rcsdk.deleteTicketTag(id)
            .then((response) => {
                dispatch(deleteTicketTagSuccess(id));
            }).catch(err => {
                if (err.error.response.status !== 400) {
                    dispatch(fetchTicketTagsError(err.message));
                } else {
                    dispatch(deleteTicketTagSuccess(id));
                }
            });
    }
}

export const deleteTagInTagsAndTickets = (id, companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: DELETE_TAG_IN_TAGS_AND_TICKETS, status: "pending" });
        dispatch(deleteTicketTag(id))
        rcsdk.getTickets()
            .where({ tags: { elemMatch: { id } } })
            .then(tickets => {
                if (tickets.length > 0) tickets.forEach(ticket => {
                    if (ticket.companyId === companyId) {
                        rcsdk
                            .updateTicket(ticket.id, { ...ticket, tags: [...ticket.tags.filter(ticketTag => ticketTag.id !== id)] })
                            .then(res => toast.success(`tag successfully deleted from ticket width id ${ticket.id}`))
                            .catch(err => console.log("update", err))
                    }
                })
            })
            .catch(err => console.log("getTickets", err))
    }
}

