import React from 'react';
import {useSelector} from "react-redux"
import ChatMessage from "./ChatMessage"
import ChatHangUp from "./ChatHangUp"
import ChatGuess from "./ChatGuess"
import ChatFooter from "./ChatFooter"
// import avatarImg1 from "../../../assets/img/portrait/small/avatar-s-18.jpg"
import { getDefaultAvatar } from '../../../utility/utils';

const Chat = ({
  inbounds = null,
  room = null,
  inputVal,
  onChange = () => {},
  onSubmit = () => {},
  renderAdditionnalFooter = () => {return null}
}) => {
  const loggedUserId = useSelector(state => state.user.rcuser.rcId);
  const members = useSelector(state => state.members.members);
  const contacts = useSelector(state => state.contacts.contacts);

  return <div className="chat-wrapper">
    <div className="chat-widget" id={`chat-widget-${room.id}`}>
    {
      inbounds && inbounds.map((inbound, index) => {
        let from = loggedUserId === inbound?.fromId ? "me" : inbound.from;
        let direction = loggedUserId === inbound?.fromId || inbound.from === "bot" ? "right" : inbound.from === "agent" && room.from !== inbound.fromId ? "right" : "left";
        
        let avatarUrl = "";
        switch(inbound.from){
          case "agent":
            let member = inbound?.fromId ? members.find(el => el.id === inbound.fromId) : null;
            avatarUrl = getDefaultAvatar({firstName: member?.firstName, lastName: member?.lastName});
            break;
          case "user":
            let contact = inbound?.fromId ? contacts.find(el => el.id === inbound.fromId) : null;
            avatarUrl = getDefaultAvatar({firstName: contact?.firstName, lastName: contact?.lastName});
            break;
          default:
            break
        }

        return (
          <React.Fragment key={`chat-inbound-${index}`}>
            {
              inbound.type === "message" && <ChatMessage
                direction={direction}
                avatarUrl={avatarUrl}
                reecallAvatar={from === "bot"}
                time={inbound.time}
                from={from}
                audioUrl={inbound?.audioUrl}
              >
                {inbound.message}
              </ChatMessage>
            }
            {
              inbound.guess && <ChatGuess guess={inbound.guess}/>
            }
            {
              inbound.type === "hangup" && <ChatHangUp />
            }
          </React.Fragment>
          )
      })
    }
    </div>
    <ChatFooter
      inputVal={inputVal}
      onChange={onChange}
      onSubmit={onSubmit}
      renderAdditionnalFooter={renderAdditionnalFooter}
    />
  </div>
}

export default Chat;
