// import React from "react";
// import { Button } from "reactstrap";
// import {FormattedMessage} from "react-intl";
// import { strongFormattedMessageHelper, italicFormattedMessageHelper } from "../utility/formattedMessageHelpers"

// const helpersConfig = [
//     {
//         page: "invitation-add",
//         fields : [
//             {
//                 for: "firstName",
//                 content: <>
//                     <h4 className="primary">Aide firstName</h4>
//                     <p>test help firstname</p>
//                 </>
//             },
//             {
//                 for: "lastName",
//                 content: <>
//                     <h4 className="primary">Aide lastName</h4>
//                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id urna hendrerit ipsum hendrerit elementum. Donec tempus aliquam erat eget ultricies. Nulla porttitor felis at vestibulum elementum. Nulla nec turpis lectus. Aliquam tincidunt, odio lacinia auctor tincidunt, ante dui dignissim ligula, in fermentum metus metus vitae ante. Fusce congue dui ac convallis egestas. Ut ut ex id mi fringilla porttitor ac a metus. Morbi lorem erat, rutrum non nisi ut, volutpat accumsan eros. In aliquam quam vel consequat ornare. Nulla facilisi. Sed vel sollicitudin purus. Etiam mattis ipsum quis ex aliquam, a sagittis nulla viverra.</p>
//                     <p>Ut dictum risus ac lorem faucibus, in consectetur lorem faucibus. Curabitur rhoncus orci pulvinar turpis ullamcorper, in facilisis neque semper. Nunc consequat blandit enim. Duis ac tortor quis ipsum commodo egestas. In ultricies velit sed justo mollis, eu imperdiet sapien dapibus. Donec eget ligula porta lorem facilisis porta nec non justo. Maecenas magna elit, tempor quis lectus quis, condimentum convallis justo. Sed malesuada mattis dui vitae facilisis. Mauris et felis a velit faucibus cursus ac ac turpis. Maecenas sodales dignissim dui laoreet molestie. Vestibulum non neque faucibus, cursus orci vitae, finibus augue. Aliquam sed nisl ipsum.</p>
//                     <Button>Test</Button>
//                 </>
//             },
//             {
//                 for: "channels",
//                 content: <>
//                     <h4>Aide Channels</h4>
//                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id urna hendrerit ipsum hendrerit elementum. Donec tempus aliquam erat eget ultricies. Nulla porttitor felis at vestibulum elementum. Nulla nec turpis lectus. Aliquam tincidunt, odio lacinia auctor tincidunt, ante dui dignissim ligula, in fermentum metus metus vitae ante. Fusce congue dui ac convallis egestas. Ut ut ex id mi fringilla porttitor ac a metus. Morbi lorem erat, rutrum non nisi ut, volutpat accumsan eros. In aliquam quam vel consequat ornare. Nulla facilisi. Sed vel sollicitudin purus. Etiam mattis ipsum quis ex aliquam, a sagittis nulla viverra.</p>
//                 </>
//             }
//         ]
//     },
//     {
//         page: "settings-profil",
//         fields: [
//             {
//                 for: "firstName",
//                 content: <>
//                     <h4 className="primary">Aide firstName Profil</h4>
//                 </>
//             },
//             {
//                 for: "lastName",
//                 content: <>
//                     <h4 className="primary">Aide lastName Profil</h4>
//                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id urna hendrerit ipsum hendrerit elementum. Donec tempus aliquam erat eget ultricies. Nulla porttitor felis at vestibulum elementum. Nulla nec turpis lectus. Aliquam tincidunt, odio lacinia auctor tincidunt, ante dui dignissim ligula, in fermentum metus metus vitae ante. Fusce congue dui ac convallis egestas. Ut ut ex id mi fringilla porttitor ac a metus. Morbi lorem erat, rutrum non nisi ut, volutpat accumsan eros. In aliquam quam vel consequat ornare. Nulla facilisi. Sed vel sollicitudin purus. Etiam mattis ipsum quis ex aliquam, a sagittis nulla viverra.</p>
//                  </>
//             }
//         ]
//     },
//     {
//         page: "channel-wizard-phone",
//         fields: [
//             {
//                 for: "firstName",
//                 content: "test firstName"
//             },
//             {
//                 for: "lastName",
//                 content: "test lastName"
//             },
//             {
//                 for: "email",
//                 content: "test Email"
//             }
//         ]
//     },
//     {
//         page: "faq-add",
//         fields : [
//             {
//                 for: "question",
//                 content: <>
//                     <h4 className="primary"><FormattedMessage id="faqs.form.helpers.question.title" defaultMessage="Question" /></h4>
//                     <p><FormattedMessage id="faqs.form.helpers.question.sentence1" defaultMessage="Always remind you that <strong>the question is going to be asked orally</strong>. It is not written in the same way as in writing." values={{...strongFormattedMessageHelper}} /></p>
//                     <p><FormattedMessage id="faqs.form.helpers.question.sentence2" defaultMessage="Attention : there are many ways to ask the question." /></p>
//                     <p><FormattedMessage id="faqs.form.helpers.question.sentence3" defaultMessage="Exemple : <italic>opening hours</italic>" values={{...italicFormattedMessageHelper}} /></p>
//                     <ul>
//                         <li><FormattedMessage id="faqs.form.helpers.question.exampleList1" defaultMessage="What are your opening hours?" /></li>
//                         <li><FormattedMessage id="faqs.form.helpers.question.exampleList2" defaultMessage="Are you open Tuesday?" /></li>
//                         <li><FormattedMessage id="faqs.form.helpers.question.exampleList3" defaultMessage="I would like to know your opening hours" /></li>
//                         <li><FormattedMessage id="faqs.form.helpers.question.exampleList4" defaultMessage="Are you open next weekend ?" /></li>
//                     </ul>
//                 </>
//             },
//             {
//                 for: "answer",
//                 content: <>
//                     <h4 className="primary"><FormattedMessage id="faqs.form.helpers.answer.title" defaultMessage="Answer" /></h4>
//                     <p><FormattedMessage id="faqs.form.helpers.answer.sentence1" defaultMessage="The answer must <strong>reformulate the subject</strong> and <strong>answer it</strong>." values={{...strongFormattedMessageHelper}} /></p>
//                     <p><FormattedMessage id="faqs.form.helpers.answer.sentence2" defaultMessage="It must not be too long to be perfectly audible : The answer must not exceed <strong>60 words</strong>." values={{...strongFormattedMessageHelper}} /></p>
//                     <p><FormattedMessage id="faqs.form.helpers.answer.sentence3" defaultMessage="The sentence must answer all possible questions on the subject." /></p>
//                     <p>
//                         <FormattedMessage id="faqs.form.helpers.answer.sentence4" defaultMessage="To use the previous example about <italic>opening hours</italic>. We have to find a generic formulation like :" values={{...italicFormattedMessageHelper}}/><br/>
//                         <mark><FormattedMessage id="faqs.form.helpers.answer.example1" defaultMessage="We are open every day from 9 a.m. to 6pm, except Sunday." /></mark>
//                     </p>
//                     <p><FormattedMessage id="faqs.form.helpers.answer.sentence5" defaultMessage="It is really important to <strong>keep a close lexical field between the question and the answer</strong>." values={{...strongFormattedMessageHelper}} /></p>
//                 </>
//             },
//             {
//                 for: "url",
//                 content: <>
//                     <h4 className="primary"><FormattedMessage id="faqs.form.helpers.url.title" defaultMessage="URL" /></h4>
//                     <p><FormattedMessage id="faqs.form.helpers.url.sentence1" defaultMessage="If you have an online documentation relative to this question please provide the url here. " /></p>
//                 </>
//             }
//         ]
//     },
//     {
//         page: "channel-detail",
//         fields: [
//             // {
//             //     for: "name",
//             //     content: <>
//             //         <h4 className="primary"><FormattedMessage id="channel.form.helpers.channelName.title" defaultMessage="Channel Name" /></h4>
//             //         <p>
//             //             <FormattedMessage id="channel.form.helpers.channelName.help1" defaultMessage="lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat nunc volutpat nibh porta, nec dictum nulla egestas. Integer ornare mi efficitur tempus volutpat. Sed dictum orci in tincidunt mattis. Proin ut enim arcu. Proin posuere dolor id condimentum pretium. Fusce feugiat, nulla et malesuada placerat, lorem dui blandit libero, non luctus tellus arcu et mi. Nunc ut euismod justo. Cras posuere mattis tortor, ac blandit metus lobortis eu. Nulla mattis, sem facilisis eleifend facilisis, nisl mi viverra diam, et tempus felis magna at eros. Nunc blandit sem quis metus consectetur pretium. Vestibulum vitae tincidunt nisi, non aliquet ligula. Curabitur quis tempor sem, quis molestie dolor. Vivamus non molestie urna, eget laoreet ante. Ut ullamcorper et quam a ultricies. Duis maximus hendrerit ligula non pretium."/>
//             //         </p>
//             //     </>
//             // },

//             // {
//             //     for: "description",
//             //     content: "helper description"
//             // },

//             // {
//             //     for: "gdprSms",
//             //     content: <>
//             //         <h4 className="primary"><FormattedMessage id="channel.form.helpers.channelName.title" defaultMessage="Channel Name" /></h4>
//             //         <p>
//             //             <FormattedMessage id="channel.form.helpers.channelName.help1" defaultMessage="lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat nunc volutpat nibh porta, nec dictum nulla egestas. Integer ornare mi efficitur tempus volutpat. Sed dictum orci in tincidunt mattis. Proin ut enim arcu. Proin posuere dolor id condimentum pretium. Fusce feugiat, nulla et malesuada placerat, lorem dui blandit libero, non luctus tellus arcu et mi. Nunc ut euismod justo. Cras posuere mattis tortor, ac blandit metus lobortis eu. Nulla mattis, sem facilisis eleifend facilisis, nisl mi viverra diam, et tempus felis magna at eros. Nunc blandit sem quis metus consectetur pretium. Vestibulum vitae tincidunt nisi, non aliquet ligula. Curabitur quis tempor sem, quis molestie dolor. Vivamus non molestie urna, eget laoreet ante. Ut ullamcorper et quam a ultricies. Duis maximus hendrerit ligula non pretium."/>
//             //             <FormattedMessage id="channel.form.helpers.channelName.help1" defaultMessage="lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat nunc volutpat nibh porta, nec dictum nulla egestas. Integer ornare mi efficitur tempus volutpat. Sed dictum orci in tincidunt mattis. Proin ut enim arcu. Proin posuere dolor id condimentum pretium. Fusce feugiat, nulla et malesuada placerat, lorem dui blandit libero, non luctus tellus arcu et mi. Nunc ut euismod justo. Cras posuere mattis tortor, ac blandit metus lobortis eu. Nulla mattis, sem facilisis eleifend facilisis, nisl mi viverra diam, et tempus felis magna at eros. Nunc blandit sem quis metus consectetur pretium. Vestibulum vitae tincidunt nisi, non aliquet ligula. Curabitur quis tempor sem, quis molestie dolor. Vivamus non molestie urna, eget laoreet ante. Ut ullamcorper et quam a ultricies. Duis maximus hendrerit ligula non pretium."/>
//             //             <FormattedMessage id="channel.form.helpers.channelName.help1" defaultMessage="lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat nunc volutpat nibh porta, nec dictum nulla egestas. Integer ornare mi efficitur tempus volutpat. Sed dictum orci in tincidunt mattis. Proin ut enim arcu. Proin posuere dolor id condimentum pretium. Fusce feugiat, nulla et malesuada placerat, lorem dui blandit libero, non luctus tellus arcu et mi. Nunc ut euismod justo. Cras posuere mattis tortor, ac blandit metus lobortis eu. Nulla mattis, sem facilisis eleifend facilisis, nisl mi viverra diam, et tempus felis magna at eros. Nunc blandit sem quis metus consectetur pretium. Vestibulum vitae tincidunt nisi, non aliquet ligula. Curabitur quis tempor sem, quis molestie dolor. Vivamus non molestie urna, eget laoreet ante. Ut ullamcorper et quam a ultricies. Duis maximus hendrerit ligula non pretium."/>
//             //         </p>
//             //     </>
//             // },
//             // {
//             //     for: "timeBeforeAnswerCheck",
//             //     content: <>
//             //         <h4 className="primary"><FormattedMessage id="channel.form.helpers.channelName.title" defaultMessage="Channel Name" /></h4>
//             //         <p>
//             //             <FormattedMessage id="channel.form.helpers.channelName.help1" defaultMessage="lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat nunc volutpat nibh porta, nec dictum nulla egestas. Integer ornare mi efficitur tempus volutpat. Sed dictum orci in tincidunt mattis. Proin ut enim arcu. Proin posuere dolor id condimentum pretium. Fusce feugiat, nulla et malesuada placerat, lorem dui blandit libero, non luctus tellus arcu et mi. Nunc ut euismod justo. Cras posuere mattis tortor, ac blandit metus lobortis eu. Nulla mattis, sem facilisis eleifend facilisis, nisl mi viverra diam, et tempus felis magna at eros. Nunc blandit sem quis metus consectetur pretium. Vestibulum vitae tincidunt nisi, non aliquet ligula. Curabitur quis tempor sem, quis molestie dolor. Vivamus non molestie urna, eget laoreet ante. Ut ullamcorper et quam a ultricies. Duis maximus hendrerit ligula non pretium."/>
//             //             <FormattedMessage id="channel.form.helpers.channelName.help1" defaultMessage="lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat nunc volutpat nibh porta, nec dictum nulla egestas. Integer ornare mi efficitur tempus volutpat. Sed dictum orci in tincidunt mattis. Proin ut enim arcu. Proin posuere dolor id condimentum pretium. Fusce feugiat, nulla et malesuada placerat, lorem dui blandit libero, non luctus tellus arcu et mi. Nunc ut euismod justo. Cras posuere mattis tortor, ac blandit metus lobortis eu. Nulla mattis, sem facilisis eleifend facilisis, nisl mi viverra diam, et tempus felis magna at eros. Nunc blandit sem quis metus consectetur pretium. Vestibulum vitae tincidunt nisi, non aliquet ligula. Curabitur quis tempor sem, quis molestie dolor. Vivamus non molestie urna, eget laoreet ante. Ut ullamcorper et quam a ultricies. Duis maximus hendrerit ligula non pretium."/>
//             //             <FormattedMessage id="channel.form.helpers.channelName.help1" defaultMessage="lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat nunc volutpat nibh porta, nec dictum nulla egestas. Integer ornare mi efficitur tempus volutpat. Sed dictum orci in tincidunt mattis. Proin ut enim arcu. Proin posuere dolor id condimentum pretium. Fusce feugiat, nulla et malesuada placerat, lorem dui blandit libero, non luctus tellus arcu et mi. Nunc ut euismod justo. Cras posuere mattis tortor, ac blandit metus lobortis eu. Nulla mattis, sem facilisis eleifend facilisis, nisl mi viverra diam, et tempus felis magna at eros. Nunc blandit sem quis metus consectetur pretium. Vestibulum vitae tincidunt nisi, non aliquet ligula. Curabitur quis tempor sem, quis molestie dolor. Vivamus non molestie urna, eget laoreet ante. Ut ullamcorper et quam a ultricies. Duis maximus hendrerit ligula non pretium."/>
//             //         </p>
//             //     </>
//             // },
//         ]
//     },
// ]

// export default helpersConfig;

// const getHelpersByPageName = (pageName) =>  helpersConfig.find(help => help.page === pageName).fields;
// export { getHelpersByPageName }

const HelpersPages = [
    {
        "page_slug": "company_progression",
        "page_label": "Company progression",
        "sections": [
            {
                "slug": "create_company",
                "label": "Create company",
            },
            {
                "slug": "create_agent",
                "label": "Create agent",
            },
            {
                "slug": "create_channel",
                "label": "Create channel",
            },
            {
                "slug": "create_invitation",
                "label": "Create invitation",
            },
            {
                "slug": "create_member",
                "label": "Create member",
            },
            {
                "slug": "create_tag",
                "label": "Create tag",
            },
            {
                "slug": "create_integration",
                "label": "Create integration",
            },
            {
                "slug": "create_rule",
                "label": "Create rule",
            },
            {
                "slug": "create_deck",
                "label": "Create deck",
            },
        ]
    },

    {
        "page_slug": "faqs_form",
        "page_label": "FAQ form",
        "sections": [
            {
                "slug": "question",
                "label": "Field 'Question'",
            },
            {
                "slug": "answer",
                "label": "Field 'Answer'",
            },
            {
                "slug": "url",
                "label": "Field 'Url'",
            }
        ]
    },

    {
        "page_slug": "channel_form",
        "page_label": "Channel form",
        "sections": [
            //Global Fields
            {
                "slug": "name",
                "label": "Field 'Name'",
            },
            {
                "slug": "description",
                "label": "Field 'Description'",
            },
            {
                "slug": "timeBeforeAnswer",
                "label": "Fields 'timeBeforeAnswer'",
            },

            //ACD Fields
            {
                "slug": "acdMember",
                "label": "Fields 'membre ACD'",
            },
            {
                "slug": "agent",
                "label": "Field 'Agent'",
            },

            //Gdpr Fields
            {
                "slug": "gdprConsent",
                "label": "Toggle 'gdprConsent'",
            },
            {
                "slug": "gdprConsentWelcome",
                "label": "Field 'gdprConsent welcome sentence'",
            },
            {
                "slug": "gdprConsentAccept",
                "label": "Field 'gdprConsent accept sentence'",
            },
            {
                "slug": "gdprConsentDecline",
                "label": "Field 'gdprConsent decline sentence'",
            },
            {
                "slug": "textRecording",
                "label": "Fields 'textRecording'",
            },
            {
                "slug": "callRecording",
                "label": "Fields 'callRecording'",
            },
            {
                "slug": "callRecordingFrequency",
                "label": "Fields 'callRecordingFrequency'",
            },
            {
                "slug": "gdprSms",
                "label": "Toggle 'gdprSms'",
            },
            {
                "slug": "gdprSmsWelcome",
                "label": "Field 'gdprSms welcome sentence'",
            },
            {
                "slug": "gdprSmsAccept",
                "label": "Field 'gdprSms accept sentence'",
            },
            {
                "slug": "gdprSmsDecline",
                "label": "Field 'gdprSms decline sentence'",
            },
            {
                "slug": "gdprTracker",
                "label": "Toggle 'gdprTracker'",
            },

            //Token/phone fields
            {
                "slug": "phonevia-token",
                "label": "Field 'Public number (phone)'",
            },
            {
                "slug": "phone-token",
                "label": "Field 'Public number (phonevia)'",
            },
            {
                "slug": "chat-token",
                "label": "Field 'Chat Token'",
            },
            {
                "slug": "clicktocall-token",
                "label": "Field 'Clicktocall token'",
            },
            {
                "slug": "wildix-token",
                "label": "Field 'Wildix token'",
            },
            {
                "slug": "threecx-token",
                "label": "Field 'Threecx token'",
            },
            {
                "slug": "aircall-token",
                "label": "Field 'Aircall token'",
            },

            // channel data fields
            {
                "slug": "reecallPhone-data",
                "label": "Field 'Reecall’s phone via'",
            }
        ]
    },
    {
        "page_slug": "collected_datas_form",
        "page_label": "Collected datas form",
        "sections": [
        ]
    }
]

const getSectionsByPageSlug = (pageSlug) =>  HelpersPages.find(helpPage => helpPage.page_slug === pageSlug).sections;
export {HelpersPages, getSectionsByPageSlug};