import React from "react"
import _ from "lodash";
import { uuid } from "uuidv4";
import { FormattedMessage } from "react-intl";
import { truncateWithEllipses, capitalize } from '../../../utility/utils';

import { UPDATE_CONVERSATION } from "../conversations"
import { generateWhere } from '../../../utility/utils'


const ITEM_PER_PAGE = 100;

export const FETCH_TICKETS_PENDING = 'FETCH_TICKETS_PENDING';
export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
export const FETCH_TICKETS_ERROR = 'FETCH_TICKETS_ERROR';

export const FETCH_TICKET_PENDING = 'FETCH_TICKET_PENDING';
export const FETCH_TICKET_SUCCESS = 'FETCH_TICKET_SUCCESS';
export const FETCH_TICKET_ERROR = 'FETCH_TICKET_ERROR';
export const CLEAR_TICKET = 'CLEAR_TICKET';

export const ADD_TICKET = 'ADD_TICKET';
export const CLOSE_TICKET = 'CLOSE_TICKET';
export const TOGGLE_QUICK_ACTION = 'TOGGLE_QUICK_ACTION';

export const TOGGLE_TODO = 'TOGGLE_TODO';
export const ADD_TODO = 'ADD_TODO';

export const FLUSH_TICKETS = 'FLUSH_TICKETS';
export const UNSET_CURRENT_TICKET = 'UNSET_CURRENT_TICKET';

export const ASSIGN_TICKET = "ASSIGN_TICKET";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const UPDATE_TICKET_STATUS = "UPDATE_TICKET_STATUS";

export const ADD_NOTE = "ADD_NOTE";


function fetchTicketsPending() {
    return {
        type: FETCH_TICKETS_PENDING
    }
}

function fetchTicketsSuccess(tickets, total) {
    return {
        type: FETCH_TICKETS_SUCCESS,
        tickets: tickets,
        total: total
    }
}

function fetchTicketsError(error) {
    return {
        type: FETCH_TICKETS_ERROR,
        error: error
    }
}

function fetchTicketPending() {
    return {
        type: FETCH_TICKET_PENDING
    }
}

function fetchTicketSuccess(ticket) {
    return {
        type: FETCH_TICKET_SUCCESS,
        ticket: ticket
    }
}

function fetchTicketError(error) {
    return {
        type: FETCH_TICKET_ERROR,
        error: error
    }
}

export function flushTickets() {
    return {
        type: FLUSH_TICKETS
    }
}

export const unsetCurrentTicket = () => {
    return {
        type: UNSET_CURRENT_TICKET
    }
}

export const toggleQuickAction = (ticketId, todoId, quickActionId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        let currentTicket = getState().tickets.current;

        let updatedTodos = [...currentTicket.todo.map(todo => {
            return todo.id === todoId ? {
                ...todo,
                quickActions: [
                    ...todo.quickActions.map(quickAction => {
                        return quickAction.id === quickActionId ? { ...quickAction, done: true } : { ...quickAction }
                    })
                ]
            } : { ...todo }
        })]

        rcsdk.updateTicket(ticketId, { todo: updatedTodos }).then(ticket => {
            let currentTodo = ticket.todo.find(el => el.id === todoId);
            if (currentTodo.quickActions.filter(quickAction => quickAction.done).length === currentTodo.quickActions.length) {
                dispatch(toggleTodo({ ticketId, todoId, status: true }))
            }
        });
    }
}

export const toggleTodo = ({ ticketId, todoId, status }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.getTicket(ticketId).then(ticket => {
            let editedTodo = { ...ticket.todo.find(todo => todo.id === todoId) }
            let ticketTodos = [
                ...ticket.todo.filter(todo => todo.id !== todoId),
                {
                    ...editedTodo,
                    done: status ? status : typeof editedTodo?.done !== "undefined" ? !!!editedTodo.done : true,
                    doneBy: {
                        modelType: "members",
                        id: getState().user.rcuser.rcId
                    }
                }
            ]

            return rcsdk.updateTicket(ticketId, { todo: [...ticketTodos] }).then(ticket => {
                dispatch({ type: TOGGLE_TODO, ticket: ticket, done: typeof editedTodo?.done !== "undefined" ? !!!editedTodo.done : true });
            });
        });
    }
}

export const addTodo = (ticketId, todo) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        const currentTicket = { ...getState().tickets.current };
        let newTodo = {
            id: uuid(),
            ...todo,
            quickActions: [
                ...todo.quickActions.map(quickAction => {
                    return {
                        id: uuid(),
                        ..._.omit(quickAction, ["fields", "createdAt", "updatedAt", "isDeleted", "id", "type", "action"]),
                        actionId: quickAction.action,
                        integration: getState().integrations.integrations.find(el => el.id === quickAction.integrationId).key
                    }
                })
            ]
        };

        rcsdk.updateTicket(ticketId, { todo: [...currentTicket.todo, newTodo] }).then(ticket => {
            dispatch({ type: ADD_TODO, ticketId: ticketId, todo: newTodo });
        });
    }
}

export const addNewTicket = (ticket, conversationId = null) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {

        let newTicket = {
            title: ticket.title,
            assigneeId: ticket.assigneeId,
            tagIds: ticket.tagIds,
            todo: [],
            status: "todo",
            companyId: getState().company.company.id
        }

        dispatch(fetchTicketsPending());

        return rcsdk.createTicket(newTicket).then(ticketResponse => {
            if (conversationId) {
                rcsdk.setConversationTicket(conversationId, ticketResponse.id).then(conversationResponse => {
                    dispatch({
                        type: UPDATE_CONVERSATION,
                        conversation: conversationResponse
                    })
                }).catch(err => {
                    dispatch(fetchTicketsError(err.message));
                })
            }

            dispatch({
                type: "ADD_TICKET",
                ticket: ticketResponse,
                successToast: {
                    type: "ADD",
                    message: <FormattedMessage id="tickets.toast.add" defaultMessage="Ticket added successfully" />
                }
            });

        }).catch(err => {
            dispatch(fetchTicketsError(err.message));
        })
    }
}

const formatTicketTitle = (ticket, maxLength = 30) => {
    let title = ticket.title;
    let firstConv = ticket?.conversations?.[0];
    if (firstConv) {
        if (title === "New Ticket") {
            let hasGPDRConsent = firstConv.collectedData?.find(el => el.label === "GDPRconsentRec");
            let userMessages = firstConv?.messages.filter(mess => mess.from === "user");
            //Si le premier message est le consentement RGDP, on prend le deuxieme message
            let firstMessage = hasGPDRConsent ? userMessages?.[1] : userMessages?.[0];
            title = truncateWithEllipses(firstConv?.title || firstMessage?.message || "(sans objet)", maxLength)
        }
    }

    return capitalize(title.trim())
}

export const fetchTickets = ({
    startIndex = 0,
    stopIndex = ITEM_PER_PAGE,
    flush = false,
    searchTerm = "",
    companyId = null,
    order = "createdAt DESC",
    statusFilters = {},
    filtersFromTable = null
}) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        if (flush) {
            dispatch(flushTickets());
            dispatch(fetchTicketsPending());
        }

        const where = generateWhere({ searchTerm, statusFilters, companyId, filtersFromTable }) || {}

        return rcsdk.getTickets()
            .include(["assignee", { "relation": "conversations", "fields": ["id"] }])
            .skip(startIndex)
            .limit(stopIndex - startIndex + 1)
            .raw()
            .where(where)
            .order(order)
            .then((resp) => {
                const total = parseInt(resp.headers["content-range"].substr(resp.headers["content-range"].indexOf("/") + 1));
                let formattedTickets = resp.data.map(ticket => {
                    const ticketTags = ticket?.tags ? ticket?.tags : [];
                    let title = formatTicketTitle(ticket);

                    // if (ticket?.tagIds) {
                    //     ticket.tagIds.map(tagId => {
                    //         ticketTags.push({
                    //             id: tagId,
                    //             confirmed: true,
                    //             confirmedType: "agent"
                    //         })
                    //         return null;
                    //     })
                    // }

                    return { ...ticket, title: title, tags: ticketTags }
                });
                dispatch(fetchTicketsSuccess(formattedTickets, total));
                return formattedTickets
            }).catch(err => {
                dispatch(fetchTicketsError(err.message));
            })
    }
}

// export const fetchTickets = ({
//     startIndex = 0,
//     stopIndex = ITEM_PER_PAGE,
//     flush = false,
//     searchTerm = "",
//     order = "default",
//     filters = null,
//     facet = {},
// }) => {

//     return (dispatch, getState, {rcsdk, rcAlgolia}) => {
//         if(flush){
//             dispatch(flushTickets());
//             dispatch(fetchTicketsPending());
//         }
//         rcAlgolia.search({
//             model: "tickets",
//             searchTerm: searchTerm.field === "keyword" ? searchTerm.search : "",
//             startIndex: startIndex,
//             stopIndex: stopIndex,
//             filters: filters,
//             facetFilters: [
//                 facet.companyId ? `companyId:${facet.companyId}` : "",
//                 searchTerm.field === "companyId" ? `companyId:${searchTerm.search}` : "",
//                 searchTerm.field === "id" ? `objectID:${searchTerm.search}` : "",
//                 // searchTerm.field === "name" ? `name:${searchTerm.search}` : "",
//             ],
//             order: order
//         }).then(props => {
//             dispatch(fetchTicketsSuccess(props.hits, props.nbHits));
//         }).catch(err => {
//             dispatch(fetchTicketsError(err.message));
//         });
//     }
// }


const getTicketTimeline = async ({ ticket, members }) => {
    let ticketTimeline = [];

    ticket.conversations.forEach(conv => {
        let userFirstMessage = conv.messages.find(mess => mess.from === "user");

        ticketTimeline.push({
            type: conv?.channelType || 'phone',
            title: userFirstMessage ? truncateWithEllipses(userFirstMessage.message, 50) : "New conversation",
            author: conv?.contact ? {
                ...conv?.contact
            } : {},
            createdAt: conv.createdAt,
            id: conv.id,
            isInbound: true
        });
    })

    ticket.history.forEach(hist => {
        ticketTimeline.push({
            ...hist,
            createdAt: hist.timestamp,
            isInbound: false
        });
    });

    ticket.notes.forEach(note => {
        ticketTimeline.push({
            type: "note",
            ...note
        })
    });

    ticketTimeline = ticketTimeline.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt)
    })

    ticketTimeline = ticketTimeline.map(inbound => {
        return {
            ...inbound,
            author: inbound.author ? inbound.author : members?.find(memb => memb.id === inbound.memberId)
        }
    });

    return ticketTimeline;
}

const getPrimaryContactTimeline = (primaryContact, ticket) => {
    let primaryContactTimeline = [];

    if (primaryContact?.history) {
        let lastEvent = null;
        let lastEventDate = null;
        primaryContact.history.map(event => {
            if (event.timestamp > ticket.createdAt) {
                if (lastEvent) {
                    if (
                        lastEvent.type === `contact_${event.type}` &&
                        lastEvent.memberId === event?.memberId &&
                        lastEventDate.setMinutes(lastEventDate.getMinutes() + 5).valueOf() > new Date(event.timestamp).valueOf()
                    ) {
                        lastEvent = {
                            ...lastEvent,
                            fields: [
                                ...lastEvent.fields,
                                {
                                    field: event.field,
                                    oldValue: event?.oldValue,
                                    newValue: event?.newValue
                                }
                            ]
                        }
                    } else {
                        primaryContactTimeline.push(lastEvent);
                        lastEventDate = new Date(event.timestamp);
                        lastEvent = {
                            memberId: event?.memberId,
                            fields: [
                                { field: event.field, oldValue: event?.oldValue, newValue: event?.newValue }
                            ],
                            type: `contact_${event.type}`,
                            createdAt: event.timestamp,
                            isInbound: false
                        };
                    }
                } else {
                    lastEventDate = new Date(event.timestamp);
                    lastEvent = {
                        memberId: event?.memberId,
                        fields: [
                            { field: event.field, oldValue: event?.oldValue, newValue: event?.newValue }
                        ],
                        type: `contact_${event.type}`,
                        createdAt: event.timestamp,
                        isInbound: false
                    };
                }
            }
            return null;
        })

        if (lastEvent) {
            primaryContactTimeline.push(lastEvent);
        }
    }

    return primaryContactTimeline;
}

const SortAndFormatTimeline = ({ timeline, members }) => {
    timeline = timeline.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt)
    })

    timeline = timeline.map(inbound => {
        return {
            ...inbound,
            author: inbound.author ? inbound.author : members.find(memb => memb.id === inbound.memberId)
        }
    });

    return timeline;
}

const getFormattedTicket = ({ ticket, members }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        let collectedDatas = ticket.conversations.map(conv => conv.collectedData);
        return getTicketTimeline({ ticket: ticket }).then((timeline) => {
            let fullTimeline = [];
            let contactPromise = Promise.resolve();
            if (ticket?.primaryContactId) {
                contactPromise = rcsdk.getContact(ticket.primaryContactId).then(contact => {
                    return contact;
                });
            }

            return contactPromise.then(primaryContact => {
                if (!primaryContact) {
                    let conversationTypes = ["chat", "phone", "forwarded_phone", "google-home", "google-assistant", "aircall", "alexa", "new_conversation", "threecx", "wildix", "clicktocall"];
                    primaryContact = timeline.find(el => conversationTypes.includes(el.type))?.author;
                }

                fullTimeline = SortAndFormatTimeline({
                    timeline: [...timeline, ...getPrimaryContactTimeline(primaryContact, ticket)],
                    members: members
                })

                const ticketTags = ticket?.tags ? ticket?.tags : [];
                // if (ticket?.tagIds) {
                //     ticket.tagIds.map(tagId => {
                //         ticketTags.push({
                //             id: tagId,
                //             confirmed: true,
                //             confirmedType: "agent"
                //         })
                //         return null;
                //     })
                // }

                let title = formatTicketTitle(ticket, 50);

                return {
                    ...ticket,
                    title: title,
                    tags: ticketTags,
                    primaryContact: primaryContact,
                    collectedDatas: _.flatten(collectedDatas),
                    timeline: fullTimeline
                }
            })
        });
    }
}

export const fetchTicket = (id) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchTicketPending());
        //Recupération du ticket avec ces assignees, notes et conversations + contact
        return rcsdk.getTicket(id).include(["assignee", "notes", { "conversations": ["contact"] }]).then(ticket => {
            dispatch(getFormattedTicket({ ticket: ticket, members: getState().members.members })).then((formattedTicket) => {
                dispatch(fetchTicketSuccess(formattedTicket));
            });
        }).catch(err => {
            dispatch(fetchTicketError(err.message));
        })
    }
}

export const updateTicketStatus = ({ id, status }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.updateTicketStatus(id, status).then(success => {
            if (success) {
                let currentUser = getState().user.rcuser;
                dispatch({
                    type: UPDATE_TICKET_STATUS,
                    id: id,
                    status: status,
                    author: {
                        id: currentUser.rcId,
                        lastName: currentUser.lastName,
                        firstName: currentUser.firstName
                    },
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="ticket.toast.updateStatus" defaultMessage="Ticket status updated successfully" />
                    }
                })
            }
            return success;
        })
    }
}

export const closeTicket = (ticketId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return dispatch(updateTicketStatus({ id: ticketId, status: "closed" }));
    }
}

export const assignTicketToMember = ({ id, assigneeId }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.assignTicket(id, assigneeId).then(ticket => {
            return getFormattedTicket({ ticket: { ...getState().tickets.current, ...ticket }, members: getState().members.members }).then((formattedTicket) => {
                return dispatch({
                    type: ASSIGN_TICKET,
                    ticket: formattedTicket,
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="ticket.toast.updateAssign" defaultMessage="Ticket assigned successfully" />
                    }
                })
            });
        });
    }
}

export const unassignMember = ({ id }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.assignTicket(id).then(ticket => {
            return getFormattedTicket({ ticket: { ...getState().tickets.current, ...ticket }, members: getState().members.members }).then((formattedTicket) => {
                return dispatch({
                    type: ASSIGN_TICKET,
                    ticket: formattedTicket,
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="ticket.toast.updateUnassign" defaultMessage="Ticket unassigned successfully" />
                    }
                })
            });
        });
    }
}

export const addNote = ({ ticketId, content }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.createTicketNote(ticketId, getState().company.company.id, content).then(response => {
            let currentUser = getState().user.rcuser;
            dispatch({
                type: ADD_NOTE,
                ticketId: ticketId,
                note: {
                    ...response,
                    author: {
                        id: currentUser.rcId,
                        lastName: currentUser.lastName,
                        firstName: currentUser.firstName
                    }
                },
                successToast: {
                    type: "UPDATE",
                    message: <FormattedMessage id="ticket.toast.addNote" defaultMessage="Note added successfully" />
                }
            })
        });
    }
}

