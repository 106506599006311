import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Dropdown } from 'reactstrap';
import * as Icon from 'react-feather';
// import ReactCountryFlag from "react-country-flag"
// import { IntlContext } from "../../../utility/context/Internationalization"
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth/loginActions';
import { selectPhoneChannels, selectAircallChannels } from '../../../redux/actions/channels';
import NotificationsDropdown from '../../../components/reecall/notifications/NotificationsDropdown';
import { MembersDrawerToggle } from '../../../components/reecall/member/MembersListDrawer';
import ToolsDropdown from '../../../views/ui-elements/tools/ToolsDropdown';
import ModalLanguage from '../modals/ModalLanguage';

import { FormattedMessage } from 'react-intl';
import { setRpaUrl } from '../../../redux/actions/rpa';
import { authorizedIds } from '../../../configs/authorizationsConfig';
import { getRPA_URLs } from '../../../configs/quickActionsConfig';
import { getUrlByInfra } from '../../../configs/constants';

const screenfull = require('screenfull');

const UserDropdown = (props) => {
    return (
        <DropdownMenu right>
            {
                // <DropdownItem
                //   tag="a"
                //   href=""
                //   onClick={e => {
                //     e.preventDefault()
                //     props.toggleLanguageModal()
                //   }}
                // >
                //   <IntlContext.Consumer>
                //     {context => {
                //       let langArr = {
                //         "en" : "English",
                //         "fr" : "French"
                //       }
                //       return <>
                //         <ReactCountryFlag
                //           className="country-flag mr-50"
                //             countryCode={
                //             context.state.locale === "en"
                //               ? "us"
                //               : context.state.locale
                //             }
                //             svg
                //         />
                //         <span className="align-middle">
                //           {langArr[context.state.locale]}
                //         </span>
                //       </>
                //     }}
                //     </IntlContext.Consumer>
                // </DropdownItem>
            }

            <DropdownItem
                tag="a"
                href=""
                onClick={(e) => {
                    e.preventDefault();
                    props.toggleFullScreen();
                }}
            >
                {props.isFullScreen ? (
                    <Icon.Minimize size={14} className="mr-50" />
                ) : (
                    <Icon.Maximize size={14} className="mr-50" />
                )}
                <span className="align-middle">
                    <FormattedMessage id="navbar.fullscreen" defaultMessage="Full screen" />
                </span>
            </DropdownItem>

            <DropdownItem divider />

            <DropdownItem tag="a" href="/settings/my-profil">
                <Icon.User size={14} className="mr-50" />
                <span className="align-middle">
                    <FormattedMessage id="navbar.myprofil" defaultMessage="My profil" />
                </span>
            </DropdownItem>

            <DropdownItem
                tag="a"
                href="/pages/login"
                onClick={(e) => {
                    e.preventDefault();
                    if (props.isAuthenticated) {
                        return props.logout();
                    }
                }}
            >
                <Icon.Power size={14} className="mr-50" />
                <span className="align-middle">
                    <FormattedMessage id="navbar.logout" defaultMessage="Log Out" />
                </span>
            </DropdownItem>
        </DropdownMenu>
    );
};

const RpaDropdown = () => {
    const dispatch = useDispatch();
    

    const rcuser = useSelector((state) => state.user.rcuser);
    const company = useSelector(state => state.company.company);
    const infra = company?.companySetting?.[0]?.value?.infra || "production"
    const rpaUrl = getUrlByInfra("rpa", infra)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [value, setValue] = useState(getRPA_URLs(rpaUrl)[0]);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    useEffect(()=>{
      if (value.value !== rpaUrl) setValue(getRPA_URLs(rpaUrl)[0])
    }, [value, rpaUrl])

    return (
        authorizedIds.includes(rcuser?.rcId) && (
            <div className="d-flex align-items-center mr-0 ml-auto">
                <Dropdown className="dropdown-notification nav-item" isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle color="flat-primary" className="ml-50 btn-md px-50">
                        {value.label}
                    </DropdownToggle>
                    <DropdownMenu>
                        {getRPA_URLs(rpaUrl).map((menuItem, index) => {
                          return (
                            <DropdownItem
                                active={menuItem.value === value.value}
                                onClick={() => {
                                    setValue(menuItem);
                                    dispatch(setRpaUrl(menuItem.value));
                                }}
                                key={menuItem.value + index}
                            >
                                {menuItem.label}
                            </DropdownItem>
                        )})}
                    </DropdownMenu>
                </Dropdown>
            </div>
        )
    );
};

class NavbarUser extends React.PureComponent {
    state = {
        langDropdown: false,
        fullScreen: false,
        languageModal: false,
    };

    handleLangDropdown = () => this.setState({ langDropdown: !this.state.langDropdown });

    toggleFullScreen = (event) => {
        if (screenfull.isEnabled) {
            screenfull.toggle();
            this.setState({ fullScreen: !this.state.fullScreen });
        }
    };

    toggleLanguageModal = () => {
        this.setState({ languageModal: !this.state.languageModal });
    };

    render() {
        // const { rcuser, pending, profile, call, phoneChannels, aircallChannels } = this.props;
        const { rcuser, pending, profile } = this.props;

        return (
            <ul className="nav navbar-nav navbar-nav-user float-right ml-auto">
                {/*
          <IntlContext.Consumer>
            {context => {
              let langArr = {
                "en" : "English",
                "fr" : "French"
              }
              return (
                <Dropdown
                  tag="li"
                  className="dropdown-language nav-item"
                  isOpen={this.state.langDropdown}
                  toggle={this.handleLangDropdown}
                  data-tour="language"
                >
                  <DropdownToggle
                    tag="a"
                    className="nav-link"
                  >
                    <ReactCountryFlag
                    className="country-flag"
                      countryCode={
                        context.state.locale === "en"
                          ? "us"
                          : context.state.locale
                      }
                      svg
                    />
                    <span className="d-none text-capitalize align-middle ml-50">
                      {langArr[context.state.locale]}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      tag="a"
                      onClick={e => context.switchLanguage("en")}
                    >
                      <ReactCountryFlag className="country-flag" countryCode="us" svg />
                      <span className="ml-1"><FormattedMessage id="navbar.lang.english" defaultMessage="English" /></span>
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      onClick={e => context.switchLanguage("fr")}
                    >
                      <ReactCountryFlag className="country-flag" countryCode="fr" svg />
                      <span className="ml-1"><FormattedMessage id="navbar.lang.french" defaultMessage="French" /></span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )
            }}
          </IntlContext.Consumer>
          */}

                <MembersDrawerToggle />
                <RpaDropdown />

                <ToolsDropdown />
                <NotificationsDropdown />

                {!pending && rcuser && (
                    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                            <div className="user-nav d-xl-flex d-none">
                                <span className="user-name text-bold-600 mb-0 text-center">
                                    {rcuser.firstName} {rcuser.lastName}
                                </span>
                                {
                                    //<span className="user-status"></span>
                                }
                            </div>
                            <span data-tour="user">
                                <img src={profile.picture} className="round" height="40" width="40" alt="avatar" />
                            </span>
                        </DropdownToggle>
                        <UserDropdown
                            {...this.props}
                            toggleFullScreen={() => this.toggleFullScreen()}
                            toggleLanguageModal={() => this.toggleLanguageModal()}
                            isFullScreen={this.state.fullScreen}
                        />
                        <ModalLanguage isOpen={this.state.languageModal} onToggle={() => this.toggleLanguageModal()} />
                    </UncontrolledDropdown>
                )}
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        auth: { login },
        user: { rcuser, pending },
        call,
    } = state;
    const { isAuthenticated, profile } = login;

    return {
        isAuthenticated,
        profile,
        rcuser,
        pending,
        call: call,
        phoneChannels: selectPhoneChannels(state),
        aircallChannels: selectAircallChannels(state),
    };
};

export default connect(mapStateToProps, {
    logout,
})(NavbarUser);