import React from "react";

const ToggleSwitch = ({checked, onChange, size, name, disabled, text, className, textInner, colorOn, colorOff, style, ...rest}) => {
  return (
    <div
      className={`toggle-switch toggle-switch-${size} ${className ? className : ''} ${checked ? 'checked' : ''}`}
      style={{...style, "--colorOn" : colorOn, "--colorOff" : colorOff}}
      {...rest}
    >
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        id={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {name ? (
        <label className="toggle-switch-label" htmlFor={name}>
          <div
            className={
              disabled
                ? "toggle-switch-inner toggle-switch-disabled"
                : "toggle-switch-inner"
            }
            data-yes={text ? text[0] : null}
            data-no={text ? text[1] : null}
          >
          </div>

          <span
            className={
              disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
            }
          >
            {textInner}
          </span>
        </label>
      ) : null}
    </div>
  )
}

ToggleSwitch.defaultProps = {
  disabled: false,
  name: "",
  text: [],
  textInner: null,
  size: "sm",
  colorOn: "#465CFF",
  colorOff: "#E2E2E2",
  style: {},
  onChange: () => {}
}

export default ToggleSwitch;