import React, { useState, useRef, useEffect } from 'react';
import { Button, Collapse } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, injectIntl } from 'react-intl'

const HandleTextareaHeight = (el) => {
	el.style.height = "5px";
	el.style.height = (el.scrollHeight)+"px";			
}

const ChatFooter = ({inputVal = "", onChange = () => {}, onSubmit = () => {}, renderAdditionnalFooter, intl}) => {
	const [showAdditionnalFooter, setShowAdditionnalFooter] = useState(true);
  	const textareaRef = useRef(null);

	useEffect(() => {
		HandleTextareaHeight(textareaRef.current);
	}, [inputVal, textareaRef]);

	useEffect(() => {
		if(textareaRef?.current){
			textareaRef.current.focus();
		}
	}, [textareaRef]);

	return <div className="chat-footer">
		<form onSubmit={(e) => { 
			e.preventDefault();
			onSubmit(inputVal);
			textareaRef.current.focus();
		}}>
			<div className="chat-field">
				<Button color="flat-primary" tag="span" className={`btn-icon mr-0 ${showAdditionnalFooter ? "active" : ""}`} onClick={() => setShowAdditionnalFooter(!showAdditionnalFooter)}>
					<FontAwesomeIcon icon={["fas", "magic"]}/>
				</Button>

				<textarea
					className="form-control my-50"
					id="yourmessage"
					placeholder={intl.formatMessage({id: "chat.typemessageplaceholder", defaultMessage: "Type message..."})}
					autoComplete="off"
					value={inputVal}
					onChange={(e) => {
						onChange(e.target.value);
					}}
					style={{
						resize: "none",
						minHeight: "40px",
						maxHeight: "120px"
					}}
					onInput={(el) => HandleTextareaHeight(el.currentTarget)}
					onFocus={(el) => {
						HandleTextareaHeight(el.currentTarget)
					}}
					ref={textareaRef}
					onKeyDown={(e) => {
						if (e.keyCode === 13 && !e.shiftKey){
							e.preventDefault();
							onSubmit(inputVal);
						}
					}}
				/>

				<Button color="primary">
					<FontAwesomeIcon icon={["fas", "paper-plane"]} className="mr-50"/>
					<FormattedMessage id="chat.sendbtn" defaultMessage="Send" />
				</Button>
			</div>
	  	</form>
	  	<Collapse className={`chat-additionnalFooter`} isOpen={showAdditionnalFooter}>
	  		{renderAdditionnalFooter()}
	  	</Collapse>
	</div>
}

export default injectIntl(ChatFooter);

										