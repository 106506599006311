import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { ContextLayout } from "../../../utility/context/Layout"
import SidebarHeader from "./SidebarHeader"
import GroupHeader from "./GroupHeader"
import MenuItem from "./MenuItem"
import MenuChannel from "./MenuChannel"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from 'react-router-dom'
import MenuConfigurator from './MenuConfig/MenuConfigurator'

import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";

import "react-contexify/dist/ReactContexify.min.css"
import "../../../assets/scss/plugins/extensions/context-menu.scss"

import useWindowSize from '../../../utility/customHooks/windowSize'

import navigationConfig from "../../../configs/navigationConfig"
import ChannelsListModal from "../../../views/ui-elements/modals/channel/ChannelsListModal"
import { TOGGLE_CHANNEL_MODAL, SET_ACTIVE_MENU, SET_OPENED_MENU, TOGGLE_TAGS_TRAIN_MODAL, TOGGLE_CONTACTS_MODAL } from "../../../redux/actions/menu"
import { SAVE_CONTACTS_FROM_MODAL } from "../../../redux/actions/contacts"
import { fetchCompany } from "../../../redux/actions/company"
import { SHOW_MODAL } from "../../../redux/actions/modals";
import TrainingButton from '../../../views/app/tags/TrainingButton'
import { FormattedMessage } from 'react-intl'
import { GET_DIRTY } from "../../../redux/actions/ticketTags"
import { fetchUser } from "../../../redux/actions/user"


const Sidebar = ({
    visibilityState,
    toggleSidebarMenu,
    toggle,
    color,
    sidebarVisibility,
    activeTheme,
    collapsed,
    sidebarState,
    activePath,
    activeUrl
}) => {
    const windowSize = useWindowSize({});
    const dispatch = useDispatch();
    const [isMount, setIsMount] = useState(false);
    const [editSelectedCompanyMenu, setEditSelectedCompanyMenu] = useState(false);
    const showChannelsListModal = useSelector(state => state.menu.showChannelsModal);
    const showTagsTrainModal = useSelector(state => state.menu.showTagsTrainModal);
    const showContactsModal = useSelector(state => state.menu.showContactsModal);
    const activeMenu = useSelector(state => state.menu.activeMenu);
    const company = useSelector(state => state.company.company);
    const updateMemberStatus = useSelector(state => state.members.updateMemberStatus);

    const { companyId } = useParams();

    useEffect(() => {
        if (companyId && companyId !== company?.id) dispatch(fetchCompany(companyId))
    }, [companyId, company, dispatch])

    useEffect(() => {
        if (updateMemberStatus === "success") dispatch(fetchUser())
    }, [dispatch, updateMemberStatus])

    useEffect(() => {
        if (!isMount || activeUrl === "/") {

            const checkActiveItem = (item, status = null) => {

                if (companyId && activeMenu.includes("selected")) {
                    dispatch({
                        type: SET_OPENED_MENU,
                        id: "selected-company",
                    });
                } else if (item?.home && activeUrl === "/") {
                    dispatch({ type: SET_ACTIVE_MENU, id: item.id });
                    dispatch({ type: SET_OPENED_MENU, id: null });
                } else if (item.type === "collapse" && item?.match && activeUrl.startsWith(item.match)) {
                    dispatch({ type: SET_OPENED_MENU, id: item.id });
                } else if ((item.type === "item" || item.type === "channel") && (activeUrl === item.navLink || (item.navLink !== "/" && activeUrl.startsWith(item.navLink)))) {
                    dispatch({ type: SET_ACTIVE_MENU, id: item.id });
                }
            }

            navigationConfig(dispatch, company, companyId).map(item => {
                checkActiveItem(item);
                if (item.type === "collapse") {
                    item.children.map(child => {
                        checkActiveItem(child, "inner");
                        return null;
                    })
                }
                return null;
            });
        }
        setIsMount(true);
    }, [dispatch, activeUrl, isMount, companyId, company, activeMenu]);

    return <>
        <ContextLayout.Consumer>
            {context => {
                return <React.Fragment>
                    <div
                        className={classnames(
                            `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-${activeTheme}`,
                            {
                                collapsed: sidebarState === true,
                                "hide-sidebar": windowSize.width < 1200 && visibilityState === false
                            }
                        )}
                        data-tour='menu'
                    >
                        <SidebarHeader
                            toggleSidebarMenu={toggleSidebarMenu}
                            toggle={toggle}
                            sidebarVisibility={sidebarVisibility}
                            sidebarCollapsed={collapsed}
                        />

                        <ul className="navigation navigation-main" style={{ height: "calc(100% - (1.3rem + 1.3rem + 4.5rem)", overflow: "auto" }}>
                            {
                                navigationConfig(dispatch, company, companyId).map(item => {

                                    if (item.type === "groupHeader") {
                                        return <GroupHeader item={item} key={`menu-${item.id}`} setEdit={setEditSelectedCompanyMenu} edit={editSelectedCompanyMenu} />
                                    } else if (item.type === "channel") {
                                        return <MenuChannel
                                            item={item}
                                            windowSize={windowSize}
                                            key={`menu-${item.id}`}
                                            toggleMenu={sidebarVisibility}
                                        />
                                    } else {
                                        return <MenuItem
                                            item={item}
                                            windowSize={windowSize}
                                            sidebarCollapsed={collapsed}
                                            key={`menu-${item.id}`}
                                            toggleMenu={sidebarVisibility}
                                        />
                                    }
                                })
                            }
                        </ul>
                    </div>
                    {showTagsTrainModal && activeMenu === "selected-company-tags" && <div
                        className={classnames(
                            `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-${activeTheme}`,
                            {
                                collapsed: sidebarState === true,
                                "hide-sidebar": windowSize.width < 1200 && visibilityState === false
                            }
                        )}
                        data-tour='menu'
                        onClick={(e) => {
                            if (showTagsTrainModal && activeMenu === "selected-company-tags") {
                                e.stopPropagation();
                                e.preventDefault();
                                dispatch({
                                    type: SHOW_MODAL,
                                    modalType: "CUSTOM",
                                    modalProps: {
                                        customClose: () => { dispatch({ type: TOGGLE_TAGS_TRAIN_MODAL, show: false }); dispatch({ type: GET_DIRTY, value: false }) },
                                        specificYes: <TrainingButton companyId={companyId} inModal style={{ padding: "11px" }} />,
                                        title: <FormattedMessage id="tags.train.modalTitle" defaultMessage="Train tags engine" />,
                                        content: <FormattedMessage id="tags.train.modalContent" defaultMessage="Changes have been made on tags. Do you want to train the tag engine now ?" />,
                                        cb: () => {
                                            dispatch({ type: TOGGLE_TAGS_TRAIN_MODAL, show: false })
                                        }
                                    }
                                })
                            }
                        }}
                        style={{ backgroundColor: "transparent", cursor: "pointer" }}
                    ></div>}
                    {showContactsModal && activeMenu === "selected-company-contacts" && <div
                        className={classnames(
                            `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-${activeTheme}`,
                            {
                                collapsed: sidebarState === true,
                                "hide-sidebar": windowSize.width < 1200 && visibilityState === false
                            }
                        )}
                        data-tour='menu'
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch({
                                type: SHOW_MODAL,
                                modalType: "UNSAVED_CONTENT",
                                modalProps: {
                                    customClose: () => dispatch({ type: TOGGLE_CONTACTS_MODAL, show: false }),
                                    title: <FormattedMessage id="contacts.changes.modalTitle" defaultMessage="Contacts Update" />,
                                    content: <FormattedMessage id="contacts.changes.modalContent" defaultMessage="Changes have been made on contacts. Do you want to save them ?" />,
                                    cb: () => {
                                        dispatch({ type: SAVE_CONTACTS_FROM_MODAL, status: true })
                                    }
                                }
                            })
                        }}
                        style={{ backgroundColor: "transparent", cursor: "pointer" }}
                    ></div>}
                </React.Fragment>
            }}
        </ContextLayout.Consumer>

        <ChannelsListModal
            isOpen={showChannelsListModal}
            toggle={() => dispatch({ type: TOGGLE_CHANNEL_MODAL })}
        />

        <Modal
            isOpen={editSelectedCompanyMenu}
            toggle={() => setEditSelectedCompanyMenu(!editSelectedCompanyMenu)}
            size="lg"
            onClosed={() => setEditSelectedCompanyMenu(false)}
        >
            <ModalHeader>
                <FormattedMessage
                    id="menu.configurator.title"
                    defaultMessage="Configure your menu"
                />
            </ModalHeader>
            <ModalBody
                className='p-0'
            // style={{ backgroundColor: "#222235", borderRadius: "0.42rem" }}
            >
                <MenuConfigurator onCancel={() => setEditSelectedCompanyMenu(false)} />
            </ModalBody>
        </Modal>
    </>
}

export default Sidebar