import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'
import * as Yup from "yup"
import AlexaLogo from "../assets/img/logo/Amazon_Alexa_blue_logo.svg"
import GAssistantLogo from "../assets/img/logo/Google_Assistant_logo.svg"
import RingoverLogo from "../assets/img/logo/ringover_logo.jpeg"


const ChannelsMessage = defineMessages({
    "sip": { id: "channels.sip.label", defaultMessage: "SIP" },
    "ringover": { id: "channels.ringover.label", defaultMessage: "Ringover" },
    "phone-label": { id: "channels.phone.label", defaultMessage: "Phone" },
    "phone-description": { id: "channels.phone.desc", defaultMessage: "Add the possibility for your customers to reach you by phone" },
    "phonevia-label": { id: "channels.phonevia.label", defaultMessage: "Redirect Phone" },
    "chat-label": { id: "channels.chat.label", defaultMessage: "Reecall Chat" },
    "wildix-label": { id: "channels.wildix.label", defaultMessage: "Wildix" },
    "threecx-label": { id: "channels.3cx.label", defaultMessage: "3cx" },
    "aircall-label": { id: "channels.aircall.label", defaultMessage: "Aircall" },
    "sms-label": { id: "channels.sms.label", defaultMessage: "SMS" },
    "assistant-label": { id: "channels.assistant.label", defaultMessage: "Assistant" },
    "alexa-label": { id: "channels.alexa.label", defaultMessage: "Alexa" },
    "g-assistant-label": { id: "channels.gassistant.label", defaultMessage: "Google Assistant" },
    "clicktocall-label": { id: "channels.clicktocall.label", defaultMessage: "Click to call" },
});


const GDPRSentencesDefault = defineMessages({
    "gdprConsentWelcome": { id: "settings.channel.form.gdprSentences.gdprConsentWelcome.placeholder", defaultMessage: "Do you agree to the recording of this conversation in order to process your request?" },
    "gdprConsentAccept": { id: "settings.channel.form.gdprSentences.gdprConsentAccept.placeholder", defaultMessage: "Ok." },
    "gdprConsentDecline": { id: "settings.channel.form.gdprSentences.gdprConsentDecline.placeholder", defaultMessage: "Noted. I therefore suggest that you renew your call in order to reach one of my collaborators, or to contact us by e-mail or via our website." },
    "gdprSmsWelcome": { id: "settings.channel.form.gdprSentences.gdprSmsWelcome.placeholder", defaultMessage: "Do you want that I text you a summary of this call?" },
    "gdprSmsAccept": { id: "settings.channel.form.gdprSentences.gdprSmsAccept.placeholder", defaultMessage: "Ok, just sent." },
    "gdprSmsDecline": { id: "settings.channel.form.gdprSentences.gdprSmsDecline.placeholder", defaultMessage: "Noted." }
});
export { GDPRSentencesDefault };

const GDPRConfigMessages = defineMessages({
    "message": {id: "GDPRConfig.message.optionLabel", defaultMessage: "Message (then continue)"},
    "hangup": {id: "GDPRConfig.hangup.optionLabel", defaultMessage: "Hang up (End call)"},
    "transfert": {id: "GDPRConfig.transfert.optionLabel", defaultMessage: "Transfer (post announcement)"},
    "transfert.data.type.label": {id: "GDPRConfig.transfert.data.type.label", defaultMessage: "Transfer"},
    "transfert.data.type.option.tel": {id: "GDPRConfig.transfert.data.type.option.tel", defaultMessage: "Tel"},
    "transfert.data.type.option.sip": {id: "GDPRConfig.transfert.data.type.option.sip", defaultMessage: "SIP"},
    "transfert.data.phone.placeholder.tel": {id: "GDPRConfig.transfert.data.phone.placeholder.tel", defaultMessage: "Mandatory phone number"},
    "transfert.data.phone.placeholder.sip": {id: "GDPRConfig.transfert.data.phone.placeholder.sip", defaultMessage: "(recipient)@(sip_URI)(?options)"},
    "transfert.data.login.placeholder": {id: "GDPRConfig.transfert.data.login.placeholder", defaultMessage: "Login"},
    "transfert.data.password.placeholder": {id: "GDPRConfig.transfert.data.password.placeholder", defaultMessage: "Password"},
});
export {GDPRConfigMessages};

const Channelsi18n = {
    "phone": {
        label: ChannelsMessage["phone-label"],
        description: ChannelsMessage["phone-description"]
    },
    "sip": {
        label: ChannelsMessage["sip"],
    },
    "ringover": {
        label: ChannelsMessage["ringover"],
    },
    "phonevia": {
        label: ChannelsMessage["phonevia-label"]
    },
    "chat": {
        label: ChannelsMessage["chat-label"]
    },
    "wildix": {
        label: ChannelsMessage["wildix-label"]
    },
    "threecx": {
        label: ChannelsMessage["threecx-label"]
    },
    "aircall": {
        label: ChannelsMessage["aircall-label"]
    },
    "sms": {
        label: ChannelsMessage["sms-label"]
    },
    "assistant": {
        label: ChannelsMessage["assistant-label"]
    },
    "alexa": {
        label: ChannelsMessage["alexa-label"]
    },
    "g-assistant": {
        label: ChannelsMessage["gassistant-label"]
    },
    "clicktocall": {
        label: ChannelsMessage["clicktocall-label"]
    }
};

const ChannelTypeList = [
    {
        type: "sip",
        i18n: Channelsi18n["sip"],
        icon: ['fal', 'globe'],
        color: "#465CFF",
        helpText: "",
        helpLink: "",
        config: {
            type: "wizard"
        },
        tokenFieldType: "sip",
        tokenHelpSelector: "sip"
    },
    {
        type: "ringover",
        i18n: Channelsi18n["ringover"],
        img: RingoverLogo,
        color: "#465CFF",
        helpText: "",
        helpLink: "",
        config: {
            type: "wizard"
        },
        tokenFieldType: "ringover",
        tokenHelpSelector: "ringover"
    },
    {
        type: "phone",
        i18n: Channelsi18n["phone"],
        icon: ['fal', 'phone-alt'],
        color: "#465CFF",
        helpText: "",
        helpLink: "",
        config: {
            type: "wizard"
        },
        tokenFieldType: "phone",
        tokenHelpSelector: "phone-token"
    },
    {
        type: "phonevia",
        i18n: Channelsi18n["phonevia"],
        icon: ['fal', 'phone-alt'],
        color: "#465CFF",
        helpText: "",
        helpLink: "",
        config: {
            type: "wizard"
        },
        tokenFieldType: "phone",
        tokenHelpSelector: "phonevia-token",
        datas: { reecallPhone: "" }
    },
    {
        type: "chat",
        i18n: Channelsi18n["chat"],
        icon: ['fal', 'comment-dots'],
        color: "#ff9800",
        helpText: "",
        helpLink: "",
        config: {
            type: "standalone"
        },
        tokenFieldType: "token",
        tokenHelpSelector: "chat-token"
    },
    {
        type: "clicktocall",
        i18n: Channelsi18n["clicktocall"],
        icon: ['fal', 'phone-rotary'],
        color: "#465CFF",
        helpText: "",
        helpLink: "",
        config: {
            type: "standalone"
        },
        tokenFieldType: "token",
        tokenHelpSelector: "clicktocall-token"
    },
    {
        type: "wildix",
        i18n: Channelsi18n["wildix"],
        icon: ['fal', 'phone-alt'],
        color: "#465CFF",
        config: {
            type: "wizard"
        },
        tokenFieldType: "token_editable",
        tokenHelpSelector: "wildix-token"
    },
    {
        type: "threecx",
        i18n: Channelsi18n["threecx"],
        icon: ['fal', 'phone-alt'],
        color: "#465CFF",
        config: {
            type: "wizard"
        },
        tokenFieldType: "token_editable",
        tokenHelpSelector: "threecx-token"
    },
    {
        type: "aircall",
        i18n: Channelsi18n["aircall"],
        icon: ['fal', 'phone-alt'],
        color: "#465CFF",
        helpText: "",
        helpLink: "",
        config: {
            type: "wizard"
        },
        tokenFieldType: "token",
        tokenHelpSelector: "aircall-token"
    },
    {
        type: "sms",
        i18n: Channelsi18n["sms"],
        icon: ['fal', 'comment-alt'],
        color: "#465CFF"
    },
    {
        type: "assistant",
        i18n: Channelsi18n["assistant"],
        icon: ['fal', 'code'],
        color: "#11cb72"
    },
    {
        type: "alexa",
        i18n: Channelsi18n["alexa"],
        img: AlexaLogo
    },
    {
        type: "g-assistant",
        i18n: Channelsi18n["gassistant"],
        img: GAssistantLogo
    }
]

export { ChannelTypeList };

const getChannelOptionsByType = (type) => {
    return ChannelTypeList.find(el => el.type === type) || { type: type, icon: "phone", color: "#ff5451" }
}
export { getChannelOptionsByType }

const channelFieldsOptions = [
    {
        type: "ringover",
        label: <FormattedMessage id="settings.channel.form.sip" defaultMessage="Ringover" />,
        disabled: false,
        copy: false,
        required: true,
        validation: Yup.string().required(<FormattedMessage id="form.validation.required" defaultMessage="Required" />)
    },
    {
        type: "sip",
        label: <FormattedMessage id="settings.channel.form.sip" defaultMessage="Sip address" />,
        disabled: false,
        copy: false,
        required: true,
        validation: Yup.string().required(<FormattedMessage id="form.validation.required" defaultMessage="Required" />)
    },
    {
        type: "phone",
        label: <FormattedMessage id="settings.channel.form.phone" defaultMessage="Phone number" />,
        disabled: false,
        copy: false,
        required: true,
        validation: Yup.string().required(<FormattedMessage id="form.validation.required" defaultMessage="Required" />)
    },
    {
        type: "token",
        label: <FormattedMessage id="settings.channel.form.token" defaultMessage="Token" />,
        disabled: true,
        copy: true,
        required: true,
        validation: Yup.string().required(<FormattedMessage id="form.validation.required" defaultMessage="Required" />)
    },
    {
        type: "token_editable",
        label: <FormattedMessage id="settings.channel.form.token" defaultMessage="Token" />,
        disabled: false,
        copy: true,
        required: true,
        validation: Yup.string().required(<FormattedMessage id="form.validation.required" defaultMessage="Required" />)
    },
    {
        type: "url",
        label: <FormattedMessage id="settings.channel.form.url" defaultMessage="Url" />,
        disabled: true,
        copy: true
    },
    {
        type: "reecallPhone",
        label: <FormattedMessage id="settings.channel.form.reecall_phone" defaultMessage="Phone Reecall’s phone via" />,
        disabled: true,
        copy: true,
        validation: Yup.string()
    },
];


const getFieldOptionsByType = (type) => {
    return channelFieldsOptions.find(field => field.type === type);
}

export { getFieldOptionsByType }

const TwillioHoldMusic = [
    {
        label: "Aerosolspray - Living Taciturn",
        type: "Ambient",
        value: "http://com.twilio.music.ambient.s3.amazonaws.com/aerosolspray_-_Living_Taciturn.mp3"
    },
    {
        label: "Gurdonark - Exurb",
        type: "Ambient",
        value: "http://com.twilio.music.ambient.s3.amazonaws.com/gurdonark_-_Exurb.mp3"
    },
    {
        label: "Gurdonark - Plains",
        type: "Ambient",
        value: "http://com.twilio.music.ambient.s3.amazonaws.com/gurdonark_-_Plains.mp3"
    },
    {
        label: "BusyStrings",
        type: "Classical",
        value: "http://com.twilio.music.classical.s3.amazonaws.com/BusyStrings.mp3"
    },
    {
        label: "Chopin Op. 15 No. 2",
        type: "Classical",
        value: "http://com.twilio.music.classical.s3.amazonaws.com/ith_chopin-15-2.mp3"
    },
    {
        label: "Brahms Op. 116 No. 4",
        type: "Classical",
        value: "http://com.twilio.music.classical.s3.amazonaws.com/ith_brahms-116-4.mp3"
    },
    {
        label: "OldDog - Endless Goodbye",
        type: "Classical",
        value: "http://com.twilio.music.classical.s3.amazonaws.com/oldDog_-_endless_goodbye_%28instr.%29.mp3"
    },
    {
        label: "Mellotroniac - Flight Of Young Hearts Flute",
        type: "Classical",
        value: "http://com.twilio.music.classical.s3.amazonaws.com/Mellotroniac_-_Flight_Of_Young_Hearts_Flute.mp3"
    },
    {
        label: "MARKOVICHAMP Borghestral",
        type: "Classical",
        value: "http://com.twilio.music.classical.s3.amazonaws.com/MARKOVICHAMP-Borghestral.mp3"
    },
    {
        label: "ClockworkWaltz",
        type: "Classical",
        value: "http://com.twilio.music.classical.s3.amazonaws.com/ClockworkWaltz.mp3"
    },
    {
        label: "Spenceyb - O-T-S-H-T (Razma World IV Remix)",
        type: "Electro",
        value: "http://com.twilio.music.electronica.s3.amazonaws.com/spenceyb_-_O-T-S-H-T_%28Razma_World_IV_Remix%29.mp3"
    },
    {
        label: "Kaer Trouz - Seawall Stepper",
        type: "Electro",
        value: "http://com.twilio.music.electronica.s3.amazonaws.com/Kaer_Trouz_-_Seawall_Stepper.mp3"
    },
    {
        label: "teru - 110 Downtempo Electronic 4",
        type: "Electro",
        value: "http://com.twilio.music.electronica.s3.amazonaws.com/teru_-_110_Downtempo_Electronic_4.mp3"
    },
    {
        label: "Pitx - A Thought",
        type: "Guitar",
        value: "http://com.twilio.music.guitars.s3.amazonaws.com/Pitx_-_A_Thought.mp3"
    },
    {
        label: "Pitx - Long Winter",
        type: "Guitar",
        value: "http://com.twilio.music.guitars.s3.amazonaws.com/Pitx_-_Long_Winter.mp3"
    },
    {
        label: "Jlbrock44 - Apologize Guitar Deep Fried",
        type: "Rock",
        value: "http://com.twilio.music.rock.s3.amazonaws.com/jlbrock44_-_Apologize_Guitar_Deep_Fried.mp3"
    },
    {
        label: "Jlbrock44 - Apologize Guitar DropC",
        type: "Rock",
        value: "http://com.twilio.music.rock.s3.amazonaws.com/jlbrock44_-_Apologize_Guitar_DropC.mp3"
    },
    {
        label: "Nickleus - original guitar song 200907251723",
        type: "Rock",
        value: "http://com.twilio.music.rock.s3.amazonaws.com/nickleus_-_original_guitar_song_200907251723.mp3"
    },
    {
        label: "Per - Joachim - Instrumental",
        type: "Soft",
        value: "http://com.twilio.music.soft-rock.s3.amazonaws.com/Per_-_Joachim_-_Instrumental.mp3"
    },
    {
        label: "Tazzista - Cecil Helen instrumental loop pack 105 C",
        type: "Soft",
        value: "http://com.twilio.music.soft-rock.s3.amazonaws.com/tazzista_-_Cecil_Helen_instrumental_loop_pack_105_C.mp3"
    },
    {
        label: "Jacksontorreal - The First Sunny Sky",
        type: "Soft",
        value: "http://com.twilio.music.soft-rock.s3.amazonaws.com/jacksontorreal_-_The_First_Sunny_Sky.mp3"
    },
    {
        label: "Ghost - promo 2 sample pack",
        type: "Soft",
        value: "http://com.twilio.music.soft-rock.s3.amazonaws.com/_ghost_-_promo_2_sample_pack.mp3"
    },
    {
        label: "Fireproof Babies - Melancholy 4 a Sun-lit day",
        type: "Soft",
        value: "http://com.twilio.music.soft-rock.s3.amazonaws.com/Fireproof_Babies_-_Melancholy_4_a_Sun-lit_day.mp3"
    },
]

const TwillioDefaultMusic = TwillioHoldMusic.find(el => el.label === "OldDog - Endless Goodbye").value;

export { TwillioHoldMusic, TwillioDefaultMusic }

export const coreUrls = [
    {
        label: "CORE",
        value: "https://connectors.reecall.co"
    },
    {
        label: "PRE-CORE",
        value: "https://preconnectors.reecall.co"
    },
    {
        label: "ALPHA-CORE",
        value: "https://alphaconnector.reecall.io"
    },
    {
        label: "NEW-CORE",
        value: "https://core.reecall.io"
    },
    {
        label: "MATHIEU",
        value: "https://rcconnectors.eu.ngrok.io"
    },
]
