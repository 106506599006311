import React from "react";
import { store } from '../../../redux/storeConfig/store';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  addQuarters,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  addHours,
  startOfHour,
  endOfHour
} from 'date-fns';
import { enUS, fr } from 'date-fns/locale'
import { FormattedMessage } from "react-intl";
  
export const localesMap = {
  'fr': fr,
  'en': enUS
};

export const defineds = (locale = 'fr') => {
  let localeObject = localesMap[locale];
  return {
    startOfWeek: startOfWeek(new Date(), { locale: localeObject }),
    endOfWeek: endOfWeek(new Date(), { locale: localeObject }),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7), { locale: localeObject }),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7), { locale: localeObject }),
    startOfToday: startOfDay(new Date(), { locale: localeObject }),
    endOfToday: endOfDay(new Date(), { locale: localeObject }),
    startOfTodayWorkTime: addHours(startOfDay(new Date(), { locale: localeObject }), 8),
    midOfTodayWorkTime: addHours(startOfDay(new Date(), { locale: localeObject }), 13),
    endOfTodayWorkTime: addHours(startOfDay(new Date(), { locale: localeObject }), 20),
    startOfYesterday: startOfDay(addDays(new Date(), -1), { locale: localeObject }),
    endOfYesterday: endOfDay(addDays(new Date(), -1), { locale: localeObject }),
    startOfTomorrow: startOfDay(addDays(new Date(), 1), { locale: localeObject }),
    endOfTomorrow: endOfDay(addDays(new Date(), 1), { locale: localeObject }),
    startOfMonth: startOfMonth(new Date(), { locale: localeObject }),
    endOfMonth: endOfMonth(new Date(), { locale: localeObject }),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1), { locale: localeObject }),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1), { locale: localeObject }),
    startOfLast7Days: startOfDay(addDays(new Date(), -7), { locale: localeObject }),
    startOfLast30Days: startOfDay(addDays(new Date(), -30), { locale: localeObject }),
    startOfLast365Days: startOfDay(addDays(new Date(), -364), { locale: localeObject }),
    startOfCurrentQuarter: startOfQuarter(addQuarters(new Date(), 0), { locale: localeObject }),
    endOfCurrentQuarter: endOfQuarter(addQuarters(new Date(), 0), { locale: localeObject }),
    startOfLastQuarter: startOfQuarter(addQuarters(new Date(), -1), { locale: localeObject }),
    startOfLast2Quarters: startOfQuarter(addQuarters(new Date(), -2), { locale: localeObject }),
    endOfLastQuarter: endOfQuarter(addQuarters(new Date(), -1), { locale: localeObject }),
    startOfLastYear: startOfYear(addQuarters(new Date(), -1), { locale: localeObject }),
    endOfLastYear: endOfYear(addQuarters(new Date(), -1), { locale: localeObject }),
    startOfCurrentHour: startOfHour(new Date(), { locale: localeObject }),
    endOfCurrentHour: endOfHour(new Date(), { locale: localeObject }),
    startOfLastHour: addHours(new Date(), -1),
    startOfLast3Hour: addHours(new Date(), -3),
  }
};
  
export const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate)
      && isSameDay(range.endDate, definedRange.endDate) 
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = () => {
  let locale = localStorage.getItem('reecall-webapp-locale') || navigator.language.slice(0, 2) || 'en';

  const activeMenu = store.getState().menu.activeMenu;

  const staticRanges = createStaticRanges([
  {
    label: <FormattedMessage id="dashboard.toolbar.range.today" defaultMessage="Today" />,
    range: () => ({
      startDate: defineds(locale).startOfToday,
      endDate: defineds(locale).endOfToday,
    }),
  },
  {
    label: <FormattedMessage id="dashboard.toolbar.range.yesterday" defaultMessage="Yesterday" />,
    range: () => ({
      startDate: defineds(locale).startOfYesterday,
      endDate: defineds(locale).endOfYesterday,
    }),
  },
  {
    label: <FormattedMessage id="dashboard.toolbar.range.last7days" defaultMessage="Last 7 days" />,
    range: () => ({
      startDate: defineds(locale).startOfLast7Days,
      endDate: defineds(locale).endOfYesterday,
    }),
  },
  {
    label: <FormattedMessage id="dashboard.toolbar.range.thisweek" defaultMessage="This Week" />,
    range: () => ({
      startDate: defineds(locale).startOfWeek,
      endDate: defineds(locale).startOfCurrentHour,
    }),
  },
  {
    label: <FormattedMessage id="dashboard.toolbar.range.lastweek" defaultMessage="Last Week" />,
    range: () => ({
      startDate: defineds(locale).startOfLastWeek,
      endDate: defineds(locale).endOfLastWeek,
    }),
  },
  {
    label: <FormattedMessage id="dashboard.toolbar.range.last30days" defaultMessage="Last 30 days" />,
    range: () => ({
      startDate: defineds(locale).startOfLast30Days,
      endDate: defineds(locale).endOfYesterday,
    }),
  },
  {
    label: <FormattedMessage id="dashboard.toolbar.range.thismonth" defaultMessage="This Month" />,
    range: () => ({
      startDate: defineds(locale).startOfMonth,
      endDate: defineds(locale).endOfMonth
    }),
  },
  {
    label: <FormattedMessage id="dashboard.toolbar.range.lastmonth" defaultMessage="Last Month" />,
    range: () => ({
      startDate: defineds(locale).startOfLastMonth,
      endDate: defineds(locale).endOfLastMonth,
    }),
  },
  {
    label: <FormattedMessage id="dashboard.toolbar.range.CurrentQuarter" defaultMessage="Current Quarter" />,
    range: () => ({
      startDate: defineds(locale).startOfCurrentQuarter,
      endDate: defineds(locale).endOfCurrentQuarter,
    }),
  },
  {
    label: <FormattedMessage id="dashboard.toolbar.range.LastQuarter" defaultMessage="Last Quarter" />,
    range: () => ({
      startDate: defineds(locale).startOfLastQuarter,
      endDate: defineds(locale).endOfLastQuarter,
    }),
  },
  {
    homepage: true,
    label: <FormattedMessage id="dashboard.toolbar.range.Last2QuarterS" defaultMessage="Last 2 Quarters" />,
    range: () => ({
      startDate: defineds(locale).startOfLast2Quarters,
      endDate: defineds(locale).endOfLastQuarter,
    }),
  },
  {
    homepage: true,
    label: <FormattedMessage id="dashboard.toolbar.range.last365days" defaultMessage="Last 365 days" />,
    range: () => ({
      startDate: defineds(locale).startOfLast365Days,
      endDate: defineds(locale).endOfToday,
    }),
  },
  {
    homepage: true,
    label: <FormattedMessage id="dashboard.toolbar.range.LastCalendarYear" defaultMessage="Last Calendar Year" />,
    range: () => ({
      startDate: defineds(locale).startOfLastYear,
      endDate: defineds(locale).endOfLastYear,
    }),
  },
  {
    homepage: true,
    label: <FormattedMessage id="dashboard.toolbar.range.AllTime" defaultMessage="All Time" />,
    range: () => ({
      startDate: null,
      endDate: new Date(),
    }),
  },
])

return activeMenu === "general-reporting" ? staticRanges : staticRanges.filter(el => !el.homepage)

};

const indexToStartWith = 2;

export const initialRange = {
  startDate: defaultStaticRanges()[indexToStartWith].range().startDate,
  endDate: defaultStaticRanges()[indexToStartWith].range().endDate
}