import mock from "../mock"
// import avatarImg from "../../assets/img/portrait/small/avatar-s-20.jpg"
// import avatarImg3 from "../../assets/img/portrait/small/avatar-s-18.jpg"
// import avatarImg5 from "../../assets/img/portrait/small/avatar-s-16.jpg"
import avatarRaph from "../../assets/img/portrait/small/raphael.jpg"
import avatarMathieu from "../../assets/img/portrait/small/mathieub.jpg"
// import avatarCharles from "../../assets/img/portrait/small/charles.jpg"
// import avatarMaxime from "../../assets/img/portrait/small/maxime.jpg"

// chat: ["33678548120"]
// companyId: "5cb98d4c366a9b00069120d5"
// createdAt: "2019-05-09T12:59:10.148Z"
// email: ["mathieu@reecall.co"]
// firstName: "Mathieu"
// gender: "Mr"
// greeting: "[gender] [firstName] [lastName]"
// groupIds: ["5f291aab57e6fa508db59bb5"]
// history: []
// id: "5cd4241ed76b650006a1d919"
// isDeleted: false
// lastHistoryFields: []
// lastName: "BUONOMO"
// organization: "ReeCall"
// phone: ["33678548120"]
// type: "person"
// updatedAt: "2020-08-04T08:23:38.721Z"

const data = [
	{
		id: 1,
		firstName: "Raphaël",
		lastName: "Szymocha",
		avatar: avatarRaph,
		externalId: "1234567890",
    mail: "raphael@reecall.co",
    phone: "0123456789",
    types: ["person"],
    companyName: "Reecall",
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum congue odio, id tempor sapien fermentum ac. Morbi interdum lobortis nibh id fermentum. Sed ac scelerisque enim."
	},
  {
    id: 2,
    firstName: "Mathieu",
    lastName: "Buonomo",
    avatar: avatarMathieu,
    mail: "mathieu@reecall.co",
    phone: "0123456789",
    types: ["vip", "person"],
    companyName: "Reecall"
  },
  {
    id: 3,
    firstName: "Mathieu",
    lastName: "Gréard",
    externalId: "1234567890",
    mail: "mathieug@reecall.co",
    phone: "0683060863",
    types: ["person"],
    companyName: "Reecall"
  },
  {
    id: 4,
    firstName: "Charles",
    lastName: "Cousyn",
    mail: "charles@reecall.co",
    phone: "9876543210",
    types: ["prestataire"],
    companyName: "Reecall"
  },
  {
    id: 5,
    firstName: "John",
    lastName: "Doe",
    mail: "johndoe@company.com",
    phone: "0123456789",
    types: ["vip", "person"],
    companyName: "Reecall"
  }
]

export default data;

// GET : CONTACT By ID
mock.onGet("/api/contact").reply(request => {
    const id = parseInt(request?.params?.id) || null;
    if(id){
      const currentContact = data.find(contact => {
        if(contact.id.toString() === id.toString()){
          return contact;
        }
        return null
      });

      return [200, currentContact]
    }
    return [500];
});

// GET : CONTACTS
mock.onGet("/api/contacts").reply(request => {
    return [200, data];
});